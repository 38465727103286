import React, { useState } from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import {
	SypacAvatar,
	SypacBox,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { DriverMultipleNotificationProps } from './DriverMultipleNotification.interface';
import dayjs from 'dayjs';
import { T } from '@tolgee/react';
import classNames from 'classnames';
import ImageViewer from '../../ImageViewer/ImageViewer';

const DriverMultipleNotification = ({
	notification,
	title,
	description,
	avatar,
	driverName,
	loadingPlace,
	truckPlates,
	eta,
	onClick,
	truckType,
	unloadingPlace,
	product,
	quantity,
	loadingDate,
	unloadingDate,
	arrivalDate,
	files,
}: DriverMultipleNotificationProps) => {
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();

	const openImage = (item: { src: string; alt: string }) => {
		setUrl(item.src);
		setShowView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};
	return (
		<>
			<BoxComponent notification={notification}>
				<div className="flex flex-col p-3 gap-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{title}</p>
					</SypacText>
					{description ? (
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80 leading-3">{description}</p>
						</SypacText>
					) : null}
					{avatar ? (
						<div className="flex flex-row gap-3 items-center">
							<SypacAvatar size="sm" initials={avatar.name}>
								{avatar ? <img src={avatar.src} alt={avatar.name} /> : null}
							</SypacAvatar>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">{avatar.name}</p>
							</SypacText>
						</div>
					) : null}
					<SypacBox className="bg-gray-1">
						<div className="grid grid-cols-3 p-3 gap-2.5">
							{driverName ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.driverName">
													Driver Name:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80">{driverName}</p>
										</SypacText>
									</div>
								</>
							) : null}
							{truckPlates ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.truckPlates">
													Truck plates:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80">{truckPlates}</p>
										</SypacText>
									</div>
								</>
							) : null}
							{truckType ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.truckType">
													Truck type:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80">{truckType}</p>
										</SypacText>
									</div>
								</>
							) : null}
							{unloadingPlace ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.unloadingPlace">
													Unloading place:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80">{unloadingPlace}</p>
										</SypacText>
									</div>
								</>
							) : null}
							{eta ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.eta">ETA:</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80">{eta}</p>
										</SypacText>
									</div>
								</>
							) : null}
							{loadingPlace ? (
								<>
									<div>
										<SypacText variant="overline-regular-large">
											<p className="text-gray-40">
												<T keyName="driverMultipleNotification.loadingPlace">
													Loading place:
												</T>
											</p>
										</SypacText>
									</div>
									<div className="col-span-2">
										<SypacText variant="overline-regular-large">
											<p className="text-gray-80 whitespace-nowrap text-ellipsis overflow-hidden">
												{loadingPlace}
											</p>
										</SypacText>
									</div>
								</>
							) : null}
						</div>
					</SypacBox>
					{product ? (
						<div className="flex flex-row gap-3 overflow-hidden">
							<div
								style={{
									backgroundImage: `url(${product?.photoUrl})`,
								}}
								className="flex flex-none w-[47px] h-[47px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
							/>
							<div className="flex flex-col gap-2">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 whitespace-nowrap text-ellipsis overflow-hidden">
										{product?.name}
									</p>
								</SypacText>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">
										{product?.type} {product?.size ? `(${product?.size})` : ''}
									</p>
								</SypacText>
							</div>
						</div>
					) : null}
					{quantity ? (
						<div className="grid grid-cols-3 gap-2.5">
							<div>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="driverMultipleNotification.quantity">
											Quantity:
										</T>
									</p>
								</SypacText>
							</div>
							<div className="col-span-2">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">{quantity} t</p>
								</SypacText>
							</div>
						</div>
					) : null}
					{loadingDate ? (
						<div className="grid grid-cols-3 gap-2.5">
							<div>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="driverMultipleNotification.loadingDate">
											Loading date:
										</T>
									</p>
								</SypacText>
							</div>
							<div className="col-span-2">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">{loadingDate}</p>
								</SypacText>
							</div>
						</div>
					) : null}
					{unloadingDate ? (
						<div className="grid grid-cols-3 gap-2.5">
							<div>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="driverMultipleNotification.unloadingDate">
											Unloading date:
										</T>
									</p>
								</SypacText>
							</div>
							<div className="col-span-2">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">{unloadingDate}</p>
								</SypacText>
							</div>
						</div>
					) : null}
					{arrivalDate ? (
						<>
							<div className="grid grid-cols-3 gap-2.5">
								<div>
									<SypacText variant="overline-regular-large">
										<p className="text-gray-40">
											<T keyName="driverMultipleNotification.arrivalDate">
												Arrival Date:
											</T>
										</p>
									</SypacText>
								</div>
								<div className="col-span-2">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80">
											{dayjs(arrivalDate).format('DD MMM. YYYY')}
										</p>
									</SypacText>
								</div>
							</div>
							<div className="grid grid-cols-3 gap-2.5">
								<div>
									<SypacText variant="overline-regular-large">
										<p className="text-gray-40">
											<T keyName="driverMultipleNotification.arrivalTime">
												Arrival Time:
											</T>
										</p>
									</SypacText>
								</div>
								<div className="col-span-2">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80">
											{dayjs(arrivalDate).format('HH:mm')}
										</p>
									</SypacText>
								</div>
							</div>
						</>
					) : null}
					{files ? (
						<div className="grid grid-cols-2 gap-2.5 overflow-hidden">
							{files?.map((item: any) => {
								return (
									<div
										key={item.src}
										className="relative"
										onClick={() => openImage(item)}
										onMouseEnter={() => handleBoxToggle(item.src)}
										onMouseLeave={() => handleBoxToggle(undefined)}
									>
										<div
											style={{
												backgroundImage: `url(${item.src})`,
											}}
											className="flex flex-none w-[100%] h-[100px] bg-no-repeat bg-cover bg-center rounded-[8px] border border-solid border-gray-22"
										/>
										{showBlur === item.src ? (
											<div
												className={classNames(
													'bg-black-50 absolute z-0 top-0 left-0 rounded-[12px] backdrop-blur-sm h-full w-full flex flex-row justify-center items-center border border-solid border-kimberly',
													{},
												)}
											>
												<SypacIcon
													size="custom"
													width="32px"
													height="32px"
													icon-name="Magnifer"
													className="text-white"
												/>
											</div>
										) : null}
									</div>
								);
							})}
						</div>
					) : null}
					{onClick ? (
						<SypacButton className="w-fit" variant="secondary">
							<button onClick={onClick} className="px-4 py-1.5">
								<SypacText variant="overline-regular-medium">
									<p>
										<T keyName="driverMultipleNotification.viewOnMap">
											View on map
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					) : null}
				</div>
			</BoxComponent>
			{openView ? (
				<ImageViewer
					url={url!}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
		</>
	);
};

export default DriverMultipleNotification;
