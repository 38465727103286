import Flag from 'react-world-flags';

export const DropDownOption = (props: any, current?: string) => {
	const { innerProps, innerRef, data } = props;

	return (
		<div
			ref={innerRef}
			{...innerProps}
			className="flex flex-row h-14 w-[calc(100%-24px)]"
		>
			<div
				className={`flex flex-row h-12 hover:bg-mercury-50 justify-start items-center w-full min-w-full p-3 my-1 mx-3 rounded-md cursor-pointer ${
					data.value === current ? 'bg-mercury-50' : ''
				}`}
			>
				<Flag code={data.value} className="h-8 w-12 rounded" />
				<div className="ml-2 text-base text-gray-100 truncate">
					{data.label}
				</div>
			</div>
		</div>
	);
};
