import React, { useMemo, useState } from 'react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';

const Paid: React.FC<ProducerOrderProps> = (props) => {
	const { selectedOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={selectedOrder?.deliveryIncluded ? 5 : 6}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
					isFinalStep={true}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				{deliveryIncluded ? (
					<PickUpSchedule selectedOrder={selectedOrder} step={5} />
				) : (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				)}
			</div>

			{!deliveryIncluded && (
				<div className="px-10 py-3.5">
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				</div>
			)}

			<div className="px-10 pb-7 pt-3.5">
				{deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<PickUpSchedule selectedOrder={selectedOrder} step={5} />
				)}
			</div>
		</>
	);
};

export default Paid;
