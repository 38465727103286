import React from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { AwaitingConfirmationProps } from './AwaitingConfirmation.interface';
import { SypacButton, SypacText } from '@sypac/component-library-react';

const AwaitingConfirmation = ({
	notification,
	title,
	description,
	button,
	disableButton = true,
}: AwaitingConfirmationProps) => {
	return (
		<BoxComponent notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="overline-regular-large">
					<p className="text-gray-80 leading-3">{description}</p>
				</SypacText>
				{button.onClick ? (
					<SypacButton variant="primary" size="sm">
						<button
							className="px-4 py-1.5 m-0"
							disabled={!disableButton}
							onClick={button.onClick}
						>
							{button.text}
						</button>
					</SypacButton>
				) : null}
			</div>
		</BoxComponent>
	);
};

export default AwaitingConfirmation;
