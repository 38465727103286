import Flag from 'react-world-flags';
import {
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { Geo, LocationsProps } from './locations.interface';

import './search-location.css';
import { T, useTranslate } from '@tolgee/react';

const SearchLocation = (props: LocationsProps) => {
	const { t } = useTranslate();
	const { onChange, locations, selectLocation, inputName } = props;

	const [expandResults, setExpandResults] = useState(false);

	const inputRef = useDetectClickOutside({
		onTriggered: () => setExpandResults(false),
	});

	const onSelectLocation = (location: Geo) => {
		selectLocation(location);
		setExpandResults(false);
	};

	const noResults = (
		<div className="flex items-center rounded-lg cursor-pointer px-5 border border-solid border-gray-22 shadow-drop-shadow">
			<SypacText variant="body-normal-medium" className="my-[15px]">
				<p>
					<T keyName="searchLocationInput.noResults">No results found</T>
				</p>
			</SypacText>
		</div>
	);

	const renderDisplayLocation = (location: Geo) => {
		return (
			<div
				key={location.locationId}
				className="flex items-center hover:bg-gray-10-opacity-50 rounded-[5px] cursor-pointer"
				onClick={() => onSelectLocation(location)}
			>
				<div className="mx-5">
					<Flag
						className="rounded-[3px]"
						code={location.countryCode.toLocaleUpperCase()}
						width={22}
						height={16}
					/>
				</div>
				<SypacText variant="body-normal-medium" className="my-[15px]">
					<p>
						{location.address?.city}, {location.address?.street} str.,
						{location.address?.houseNumber
							? ` ${location.address?.houseNumber},`
							: ''}{' '}
						Zip {location.address?.postalCode}
					</p>
				</SypacText>
			</div>
		);
	};

	return (
		<div className="relative w-full group" ref={inputRef}>
			<SypacInput>
				<input
					name={inputName}
					autoComplete="off"
					type="text"
					className="search-location--input"
					placeholder={t(
						'searchLocation.enterThePlaceWhere',
						'Enter the place where you would like to receive your products',
					)}
					onFocus={() => setExpandResults(true)}
					onChange={(event) => {
						onChange(event.target.value);
					}}
				/>
			</SypacInput>
			<div
				className={`absolute top-[15px] left-5 ${
					expandResults ? 'stroke-cornflower-blue' : 'stroke-gray-80'
				}`}
			>
				<SypacIcon
					icon-name="Map Point"
					className="text-gray-40"
					size="custom"
					width="30px"
					height="30px"
				/>
			</div>
			<div
				className={`${
					expandResults ? 'flex' : 'hidden'
				} search-location--select-area`}
			>
				{locations?.length ? (
					<div className="search-location--select-list">
						{locations.map((location: Geo) => renderDisplayLocation(location))}
					</div>
				) : (
					noResults
				)}
			</div>
		</div>
	);
};

export default SearchLocation;
