export const Search = () => {
	return (
		<svg
			width="38"
			height="38"
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="18.125"
				cy="18.2188"
				r="16.625"
				stroke="#171717"
				strokeWidth="2"
			/>
			<path
				d="M33 33.0938L36.5 36.5938"
				stroke="#171717"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};
