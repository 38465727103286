import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import ModalAddTruck from '../../../components/ModalAddTruck/ModalAddTruck';
import {
	FleetQueryParams,
	DriverDto,
	TruckService,
} from '../../../services/truck.services';
import { TruckCard } from '../../../components/TruckCard/TruckCard';
import {
	SypacButton,
	SypacIcon,
	SypacTabButtonOutline,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';
import ModalVerificationCode from '../../../components/ModalVerificationCode/ModalVerificationCode';
import Clock from './Clock';
import Map from '../../../components/Map/Map';
import { Search } from '../../../assets/Search';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import { T, useTranslate } from '@tolgee/react';
import Emitter, { EventType } from '../../../services/events';
import { NotificationInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { MutatingDots } from 'react-loader-spinner';

const Fleet: React.FC = () => {
	const { t } = useTranslate();
	const [trucks, setTrucks] = useState<DriverDto[]>([]);
	const [showAddTruck, setShowAddTruck] = useState(false);
	const [showVerificationCode, setShowVerificationCode] = useState(false);
	const [status, setStatus] = useState<string>('');
	const [refresh, setRefresh] = useState(0);
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [editableTruck, setTruck] = useState<DriverDto>();
	const [loading, setLoading] = useState<boolean>(false);
	const [showMap, setShowMap] = useState<boolean>(false);
	let updateTimeout: string | number | NodeJS.Timeout | undefined;

	const getFleetCallback = useCallback(async () => {
		setShowMap(false);
		try {
			let query: FleetQueryParams = { limit: 20, offset: 0 };
			if (status?.length) {
				query = { ...query, status };
			}
			setMarkers([]);
			const { data } = await TruckService.getTrucks(query, 'transporter');
			setTrucks(data.items as unknown as DriverDto[]);
			const newMarkers = data?.items
				?.map((r) => ({
					latitude: r?.lastLocation?.coordinates[1]!,
					longitude: r?.lastLocation?.coordinates[0]!,
					variant: PinVariant.truck,
					truck: r,
				}))
				.filter((r) => r.latitude && r.longitude);
			setMarkers(newMarkers || []);
			setShowMap(true);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, refresh]);

	const handleAddTruckModal = () => {
		setShowAddTruck(true);
	};

	const closeTruckModal = () => {
		setShowAddTruck(false);
	};

	const closeVerificationCodeModal = () => {
		setShowVerificationCode(false);
		setTruck(undefined);
		setRefresh(refresh + 1);
	};

	useEffect(() => {
		getFleetCallback();
	}, [getFleetCallback, status]);

	const setCurrentTab = (status: string) => {
		if (status === 'all') {
			setStatus('');
		} else {
			setStatus(status);
		}
	};

	const editTruck = (truck: DriverDto) => {
		setTruck(truck);
		setShowAddTruck(true);
	};

	const showTruck = async (truck: DriverDto) => {
		try {
			setLoading(true);
			const result = await TruckService.generateQr(truck.id);
			setTruck(result.data);
			setShowVerificationCode(true);
		} finally {
			setLoading(false);
		}
	};

	const onSuccess = (truck?: DriverDto) => {
		if (!editableTruck) {
			showTruck(truck!);
		} else {
			setRefresh(refresh + 1);
			setTruck(undefined);
		}
		closeTruckModal();
	};

	const setUpdateLocation = useCallback(
		(notify: NotificationInterface) => {
			if (!markers.length) {
				return;
			}
			const lastMarkers = JSON.parse(JSON.stringify(markers)) as MapMarker[];
			const newMarkers = lastMarkers
				.map((r) => {
					if (r.truck?.uid === notify.content.driverId) {
						return {
							...r,
							latitude: notify.content.lat
								? parseFloat(notify.content.lat)
								: r.latitude,
							longitude: notify.content.long
								? parseFloat(notify.content.long)
								: r.longitude,
						};
					}
					return r;
				})
				.filter((r) => r.latitude && r.longitude);
			setMarkers(newMarkers);
		},
		[markers],
	);

	const checkUpdateLocation = useCallback((notify: NotificationInterface) => {
		clearTimeout(updateTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		updateTimeout = setTimeout(() => {
			setUpdateLocation(notify);
		}, 2000);
		return () => {
			clearTimeout(updateTimeout);
		};
	}, []);

	useEffect(() => {
		Emitter.on(
			EventType.TRUCK_UPDATE_LOCATION,
			(notify: NotificationInterface) => {
				if (notify.groupId) {
					checkUpdateLocation(notify);
				}
			},
		);
		return () => {
			Emitter.off(EventType.TRUCK_UPDATE_LOCATION);
		};
	}, [checkUpdateLocation]);

	return (
		<section className="h-[calc(100vh-147px)] flex lg:flex-row md:flex-col gap-3 relative lg:max-h-[calc(100vh-150px)] w-full mb-7.5">
			{loading ? (
				<div className="flex w-full h-full items-center justify-center absolute z-[1000]">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : null}
			<div className="flex flex-col gap-3 w-[70%] md:w-full lg:w-[50%] xl:w-[40%]">
				<div className="flex items-center justify-between">
					<SypacText variant="heading-4">
						<p className="text-gray-90">
							<T keyName="fleet.myFleet">My fleet</T>
						</p>
					</SypacText>
					<SypacButton
						variant="label"
						size="small"
						onClick={handleAddTruckModal}
					>
						<button className="flex flex-row gap-6 p-2 text-white bg-mountain-meadow">
							<SypacIcon
								size="custom"
								width="22px"
								height="22px"
								icon-name="Add Square"
							/>
						</button>
					</SypacButton>
				</div>
				<div className="flex items-center gap-3">
					<div className="flex gap-1">
						<SypacText variant="body-regular-small">
							<p className="text-gray-40">
								<T keyName="fleet.today">Today:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-100">{dayjs().format('DD dddd, YYYY')}</p>
						</SypacText>
					</div>
					<div className="h-1 w-1 rounded-full bg-gray-40" />
					<div className="flex gap-1">
						<SypacText variant="body-regular-small">
							<p className="text-gray-40">
								<T keyName="fleet.time">Time:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-100">
								<Clock />
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex">
					<SypacTabs variant="white_outline">
						<SypacTabButtonOutline
							label={t('fleet.all', 'All')}
							buttonId="all"
							isActive={status === ''}
							onClick={() => setCurrentTab('all')}
						/>
						<SypacTabButtonOutline
							label={t('fleet.inProgress', 'In progress')}
							icon="Revote"
							buttonId="not_available"
							isActive={status === 'not_available'}
							onClick={() => setCurrentTab('not_available')}
						/>
						<SypacTabButtonOutline
							label={t('fleet.available', 'Available')}
							icon="Archived Minimalistic"
							buttonId="available"
							isActive={status === 'available'}
							onClick={() => setCurrentTab('available')}
						/>
					</SypacTabs>
				</div>
				<div className="flex flex-col gap-3 overflow-y-auto">
					{trucks ? (
						trucks.map((truck: DriverDto) => (
							<TruckCard
								key={truck.code}
								data={truck}
								onUpdate={() => setRefresh(refresh + 1)}
								editTruck={() => editTruck(truck)}
								showTruck={() => showTruck(truck)}
							/>
						))
					) : (
						<div className="flex flex-col gap-3 items-center">
							<div className="my-16">
								<Search />
							</div>
							<SypacText variant="body-regular-large">
								<p className="text-gray-90 text-center">
									<T
										keyName={`fleet.${
											status === 'not_available'
												? 'fleetHasNoTrucksInProgress'
												: status === 'available'
												? 'fleetHasNoTrucksAvailable'
												: 'youHaventAddedAnyTrucks'
										}`}
									>
										{status === 'not_available'
											? "Your fleet has no trucks listed as 'In progress'"
											: status === 'available'
											? "Your fleet has no trucks listed as 'Available'"
											: 'You haven’t added any trucks to your fleet yet'}
									</T>
								</p>
							</SypacText>
							{status !== 'not_available' && status !== 'available' && (
								<>
									<SypacText variant="body-normal-small" className="mb-8">
										<p className="text-gray-40 text-center">
											<T keyName="fleet.addTrucksByClickingButton">
												Add trucks by clicking the 'Add truck' button
											</T>
										</p>
									</SypacText>
									<SypacButton
										variant="label"
										size="small"
										onClick={handleAddTruckModal}
									>
										<button className="text-white bg-mountain-meadow">
											<SypacIcon
												iconName="Add Square"
												className="text-white"
												size="custom"
												width="12px"
												height="12px"
											/>
											<SypacText variant="body-normal-medium">
												<p className="text-white text-center">
													<T keyName="fleet.addTruckButton">Add truck</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</>
							)}
						</div>
					)}
				</div>
			</div>
			<div className="w-full">
				{showMap && <Map poligons={[]} markers={markers || []} />}
			</div>
			{showAddTruck ? (
				<ModalAddTruck
					isOpen={showAddTruck}
					truck={editableTruck}
					onClose={closeTruckModal}
					onSuccess={(code) => onSuccess(code)}
				/>
			) : null}
			{/* {showLogin ? (
				<ModalLogin
					isOpen={showLogin}
					onClose={handleLoginModal}
					onSuccess={() => {
						handleLoginModal();
						handleVerificationCodeModal();
					}}
				/>
			) : null} */}
			{showVerificationCode ? (
				<ModalVerificationCode
					isOpen={showVerificationCode}
					onClose={closeVerificationCodeModal}
					truck={editableTruck}
				/>
			) : null}
		</section>
	);
};

export default Fleet;
