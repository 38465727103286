interface Props {
	width?: number[];
}

export const LineStroke = (props: Props = {}) => {
	let { width } = props;
	width = width || [268, 267.5, 273.154];

	return (
		<svg
			width={width[0]}
			height="1"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line
				x1="0.5"
				y1="0.5"
				x2={width[1]}
				y2="0.5"
				stroke={`url(#paint0_linear_1696_${width[0]})`}
				strokeLinecap="round"
			/>
			<defs>
				<linearGradient
					id={`paint0_linear_1696_${width[0]}`}
					x1="0"
					y1="1"
					x2={width[2]}
					y2="0.999999"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#E8E8E8" stopOpacity="0" />
					<stop offset="0.489583" stopColor="#D1D1D1" />
					<stop offset="1" stopColor="#E8E8E8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
