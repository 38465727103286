export const OrderTons = () => {
	return (
		<svg
			width="28"
			height="32"
			viewBox="0 0 28 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.6311 28.7955L18.7346 26.824M26.7224 25.8382L23.1668 24.1111M7.04507 10.1796V3.95733M11.9157 7.22222V1M16.0555 11.6667L18.9172 13.868C19.3547 14.2045 19.611 14.7251 19.611 15.2771V18.7778M12.4999 18.7778H10.7222M3.05549 15.8445V18.776C3.05549 22.4452 5.06931 25.8108 8.28229 27.5113L13.8838 30.5744M5.38882 14.212V19.788C5.38882 21.6067 6.34524 23.2873 7.8978 24.1967L12.6576 26.9846C14.2102 27.894 16.123 27.894 17.6756 26.9846L22.4354 24.1967C23.988 23.2873 24.9444 21.6067 24.9444 19.788V14.212C24.9444 12.3933 23.988 10.7127 22.4354 9.80332L17.6756 7.01537C16.123 6.10599 14.2102 6.10599 12.6576 7.01537L7.8978 9.80332C6.34524 10.7127 5.38882 12.3933 5.38882 14.212ZM6.61104 28.3333C6.61104 29.8061 5.41714 31 3.94438 31C2.47162 31 1.27771 29.8061 1.27771 28.3333C1.27771 26.8606 2.47162 25.6667 3.94438 25.6667C5.41714 25.6667 6.61104 26.8606 6.61104 28.3333Z"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
