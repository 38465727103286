export const PickupClient = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle cx="12" cy="12" r="12" fill="#20AC93" opacity="0.13"></circle>
			<path
				fill="#20AC93"
				d="M17.345 9.784c-.65-2.884-3.148-4.182-5.342-4.182h-.006c-2.188 0-4.691 1.292-5.342 4.176-.725 3.221 1.233 5.95 3.006 7.666.657.637 1.5.955 2.342.955a3.33 3.33 0 002.336-.955c1.773-1.717 3.731-4.438 3.006-7.66zm-5.342 3.128a1.96 1.96 0 01-1.952-1.967 1.96 1.96 0 011.952-1.966 1.96 1.96 0 011.952 1.966 1.96 1.96 0 01-1.952 1.967z"
			></path>
		</svg>
	);
};
