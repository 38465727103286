import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	SypacBadge,
	SypacButton,
	SypacCheckbox,
	SypacText,
} from '@sypac/component-library-react';
import { TransporterOrderProps } from '../../../transporterOrder.interface';
import { OrdersService } from '../../../../../../services/orders.services';
import { toastVariant } from '../../../../../../components/CompaniesTable/toastVariant/toastVariant';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import Emitter, { EventType } from '../../../../../../services/events';
import { T, useTranslate } from '@tolgee/react';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import { DumpTruck } from '../../../../../../assets/DumpTruck';
import { SemiTrailer } from '../../../../../../assets/SemiTrailer';
import { Hooklifter } from '../../../../../../assets/Hooklifter';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import LoadingSpinner from '../../../../../../assets/LoadingSpinner';
import { StatusClock } from '../../../../../../assets/StatusClock';

const Requested: React.FC<TransporterOrderProps> = (props) => {
	const { selectedOrder } = props;
	const { t } = useTranslate();
	const [disableAccept, setDisable] = useState<boolean>(true);
	const [truckIds, setTruckIds] = useState<number[]>([]);
	const [nextStep, setNextStep] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);

	const selectedTrucks = useMemo(() => {
		return (
			selectedOrder?.trucks?.filter((truck) => truckIds.includes(truck.id)) ||
			[]
		);
	}, [selectedOrder?.trucks, truckIds]);

	const handleClick = async (action: string) => {
		switch (action) {
			case 'reject':
				toastVariant(t('boxToDoStep.rejected', 'Rejected'), true);
				break;
			default:
				setIsLoading(true);
				try {
					setDisable(true);
					const query = {
						truckIds,
					};
					await OrdersService.acceptOffer(query, selectedOrder?.offerId!);

					Emitter.emit(EventType.ORDER_LIST_REFRESH, {
						id: selectedOrder?.offerId?.toString(),
						recipientId: '',
						groupId: selectedOrder?.offerId?.toString(),
						category: 'update_offer',
						content: {
							lastOffer:
								selectedOrder?.trucks?.length! === 1 ||
								selectedOrder?.trucks?.length === truckIds.length,
						},
					});

					if (selectedOrder?.trucks?.length! > 1) {
						setNextStep(false);
						setTruckIds([]);
					}
					toastVariant(t('boxToDoStep.accepted', 'Accepted'), false);
				} catch (e: any) {
					const message = e?.response?.data?.message || e?.toString();
					toastVariant(t(`acceptedStep.${message}`, message), true);
				} finally {
					setDisable(false);
					setIsLoading(false);
				}
		}
	};

	const handleCheck = (truckId: any) => {
		setTruckIds((prev) => {
			return prev.includes(truckId)
				? prev.filter((id) => id !== truckId)
				: [...prev, truckId];
		});
	};

	const handleNextStep = () => {
		setNextStep(true);
	};

	const handlePrevStep = () => {
		setNextStep(false);
	};

	useEffect(() => {
		setDisable(truckIds.length === 0);
	}, [truckIds]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	const TruckIcon = ({ type }: any) => {
		switch (type) {
			case 'aluminium':
				return <DumpTruck />;
			case 'steel':
				return <SemiTrailer />;
			case 'hooklifter':
				return <Hooklifter />;
			default:
				return null;
		}
	};

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	useEffect(() => {
		if (selectedOrder?.id) {
			setTruckIds([]);
		}
	}, [selectedOrder?.id]);

	useEffect(() => {
		if (selectedOrder?.trucks?.length === 1) {
			setTruckIds([selectedOrder?.trucks?.[0].id]);
		}
	}, [selectedOrder?.trucks]);

	return (
		<>
			<div style={{ marginBottom: `${boxHeight}px` }}>
				<div style={{ marginBottom: `${stepperMargin}px` }}>
					<Stepper
						current={nextStep ? 2 : 1}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						order={selectedOrder}
						onWidthChange={handleWidthChange}
						isOffer={true}
					/>
				</div>

				{nextStep ? (
					selectedTrucks.length > 1 ? (
						<div className="px-10 pt-7 pb-3.5">
							<div className="flex flex-col overflow-hidden">
								<div className="box-border w-[651px] max-h-[263px] flex flex-col gap-4 overflow-y-scroll overflow-x-hidden pr-[15px]">
									{selectedTrucks.map((orderTruck) => (
										<div
											key={orderTruck?.id}
											className="flex flex-col gap-4 bg-white-100 border border-solid border-gray-10 rounded-xl p-3"
										>
											<div className="flex gap-4">
												<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-[10px]">
													<TruckIcon type={orderTruck?.truckType} />
												</span>
												<div className="flex flex-col gap-[5px] justify-center">
													<SypacText variant="body-regular-medium">
														<p className="leading-[26px] text-gray-90">
															{orderTruck?.truckType?.charAt(0).toUpperCase()! +
																orderTruck?.truckType?.slice(1)}
														</p>
													</SypacText>
													<SypacText variant="body-regular-small">
														<p className="text-gray-40">
															<T keyName="quantityPicker.payload">Payload:</T>{' '}
															{orderTruck?.payload}{' '}
															<T keyName="quantityCounter.tons">tons</T>
														</p>
													</SypacText>
												</div>
												<div className="ml-auto">
													<SypacBadge color="gray" outlined={false}>
														<div className="flex w-fit gap-2.5 items-center text-sm font-medium whitespace-nowrap cursor-auto">
															<span className="p-0 m-0 flex scale-[1.2]">
																<StatusClock />
															</span>
															<p className="m-0 mr-[5px] flex-1 text-center">
																<T keyName="transportSolution.awaitingConfirmation">
																	Awaiting confirmation
																</T>
															</p>
														</div>
													</SypacBadge>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					) : (
						<div className="px-10 pt-7 pb-3.5">
							<div className="flex flex-col overflow-hidden border border-solid border-gray-10 bg-white-100 rounded-xl">
								<SypacText variant="body-regular-medium" className="px-5 py-4">
									<p className="text-gray-40">
										<T keyName="acceptedStep.allocateATruckForThisOrder">
											Allocate a truck for this order
										</T>
									</p>
								</SypacText>
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />

								<div className="box-border w-[634px] max-h-[263px] flex flex-col gap-4 overflow-y-scroll overflow-x-hidden pr-[15px] px-5 py-4">
									{selectedTrucks.map((orderTruck) => (
										<div key={orderTruck?.id} className="flex flex-col gap-4">
											<div className="flex gap-4">
												<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-[10px]">
													<TruckIcon type={orderTruck?.truckType} />
												</span>
												<div className="flex flex-col gap-[5px] justify-center">
													<SypacText variant="body-regular-medium">
														<p className="leading-[26px] text-gray-90">
															{orderTruck?.truckType?.charAt(0).toUpperCase()! +
																orderTruck?.truckType?.slice(1)}
														</p>
													</SypacText>
													<SypacText variant="body-regular-small">
														<p className="text-gray-40">
															<T keyName="quantityPicker.payload">Payload:</T>{' '}
															{orderTruck?.payload}{' '}
															<T keyName="quantityCounter.tons">tons</T>
														</p>
													</SypacText>
												</div>
												<div className="ml-auto">
													<SypacBadge color="gray" outlined={false}>
														<div className="flex w-fit gap-2.5 items-center text-sm font-medium whitespace-nowrap cursor-auto">
															<span className="p-0 m-0 flex scale-[1.2]">
																<StatusClock />
															</span>
															<p className="m-0 mr-[5px] flex-1 text-center">
																<T keyName="transportSolution.awaitingConfirmation">
																	Awaiting confirmation
																</T>
															</p>
														</div>
													</SypacBadge>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					)
				) : (
					<div className="px-10 pt-7 pb-3.5">
						<div className="flex flex-col overflow-hidden border border-solid border-gray-10 bg-white-100 rounded-xl">
							<SypacText variant="body-regular-medium" className="px-5 py-4">
								<p className="text-gray-40">
									<T keyName="requestedStep.selectTheOrdersYouCanFulfill">
										Select the orders you can fulfill for this delivery
									</T>
								</p>
							</SypacText>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />

							<div className="box-border w-[634px] max-h-[263px] flex flex-col gap-4 overflow-y-scroll overflow-x-hidden pr-[15px] px-5 py-4">
								{selectedOrder?.trucks?.map((orderTruck, index) => (
									<div key={orderTruck?.id} className="flex flex-col gap-4">
										<div className="flex gap-4">
											<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-[10px]">
												<TruckIcon type={orderTruck?.truckType} />
											</span>
											<div className="flex flex-col gap-[5px] justify-center">
												<SypacText variant="body-regular-medium">
													<p className="leading-[26px] text-gray-90">
														{orderTruck?.truckType?.charAt(0).toUpperCase()! +
															orderTruck?.truckType?.slice(1)}
													</p>
												</SypacText>
												<SypacText variant="body-regular-small">
													<p className="text-gray-40">
														<T keyName="quantityPicker.payload">Payload:</T>{' '}
														{orderTruck?.payload}{' '}
														<T keyName="quantityCounter.tons">tons</T>
													</p>
												</SypacText>
											</div>
											<div className="flex gap-3 [&_p]:text-gray-80 ml-auto p-[5px]">
												<div className="col-start-2 flex items-center gap-4">
													<SypacCheckbox size="md">
														<input
															type="checkbox"
															checked={truckIds
																.map(Number)
																.includes(orderTruck?.id)}
															onChange={() => handleCheck(orderTruck?.id)}
															className="border-gray-10 cursor-pointer"
														/>
													</SypacCheckbox>
													<SypacText variant="overline-normal-large">
														<p className="text-base mt-[4px]">
															{truckIds.map(Number).includes(orderTruck?.id) ? (
																<T keyName="requestedStep.selected">Selected</T>
															) : (
																<T keyName="requestedStep.selectTruck">
																	Select truck
																</T>
															)}
														</p>
													</SypacText>
												</div>
											</div>
										</div>

										{index !== selectedOrder?.trucks?.length! - 1 ? (
											<div className="border-0 border-solid border-t-[1px] border-gray-10" />
										) : null}
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				<div className="px-10 py-3.5">
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				</div>

				<div className="px-10 py-3.5">
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				</div>

				<div className="px-10 pb-7 pt-3.5">
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={selectedOrder?.deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				</div>

				<div
					className={`fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid ${
						nextStep ? 'border-primary-violet' : 'border-mountain-meadow'
					} border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign`}
					ref={boxRef}
				>
					<div className="flex flex-col justify-center p-8 gap-4">
						<SypacText variant="heading-5">
							<p className="text-2xl leading-7 tracking-tighter text-gray-90">
								{nextStep ? (
									<T keyName="requestedStep.confirmSelectedTruck">
										Confirm selected truck
									</T>
								) : (
									<T keyName="requestedStep.thisOrderHasNotBeenAssigned">
										This order has not been assigned to anyone yet
									</T>
								)}
							</p>
						</SypacText>
						{nextStep ? (
							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="requestedStep.ensureYouSelectSuitableTruck">
										Ensure you select a suitable truck to fulfill the order's
										requirements.
									</T>
								</p>
							</SypacText>
						) : (
							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="requestedStep.toProceedAndBeginExecution">
										To proceed and begin execution, you need to click on "Accept
										Order"
									</T>
								</p>
							</SypacText>
						)}
						<div
							className={`flex gap-6 justify-center ${
								nextStep ? 'mt-6' : '[&_*]:w-full mt-6'
							}`}
						>
							{nextStep ? (
								<>
									<SypacButton
										variant="primary"
										size="large"
										className="w-full"
									>
										<button
											className="w-full h-[54px] bg-white border border-solid border-gray-10 text-gray-80 rounded-[10px] transition hover:border-gray-40 hover:text-gray-90"
											onClick={() => handlePrevStep()}
										>
											<T keyName="requestedStep.backToTruckSelection">
												Back to truck selection
											</T>
										</button>
									</SypacButton>
									<SypacButton
										variant="primary"
										size="large"
										className="w-full"
									>
										<button
											className={`w-full h-[54px] rounded-[10px] ${
												disableAccept || isLoading
													? 'bg-primary-violet/80 text-gray-500'
													: 'bg-primary-violet text-white-100 hover:bg-primary-violet/70 hover:text-white'
											} transition`}
											onClick={() => handleClick('accept')}
											disabled={disableAccept}
										>
											<SypacText
												variant="body-normal-medium"
												className="flex items-center gap-4"
											>
												<p>
													<T keyName="requestedStep.allocateTruck">
														Allocate truck
													</T>
												</p>
												{isLoading && <LoadingSpinner />}
											</SypacText>
										</button>
									</SypacButton>
								</>
							) : (
								<>
									<SypacButton
										variant="primary"
										size="large"
										className="w-full"
									>
										<button
											className="w-full h-[54px] bg-white border border-solid border-gray-10 text-gray-80 rounded-[10px] transition hover:border-gray-40 hover:text-gray-90"
											// onClick={() => handlePrevStep()}
										>
											<T keyName="requestedStep.rejectOrder">Reject Order</T>
										</button>
									</SypacButton>
									<SypacButton variant="primary" size="large">
										<button
											className={`h-[54px] rounded-[10px] ${
												disableAccept
													? 'bg-[#e8e8e8] text-gray-500'
													: 'bg-mountain-meadow text-white-100 hover:bg-mountain-meadow/80 hover:text-white'
											} transition`}
											onClick={() => handleNextStep()}
											disabled={disableAccept}
										>
											<T keyName="requestedStep.acceptOrder">Accept Order</T>
										</button>
									</SypacButton>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Requested;
