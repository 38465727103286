const CircleValid = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			fill="none"
			viewBox="0 0 18 18"
		>
			<circle
				cx="8.75"
				cy="9"
				r="8"
				stroke="#20AC93"
				strokeWidth="1.3"
			></circle>
			<path
				stroke="#20AC93"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.3"
				d="M5.95 9.4l1.6 1.6 4-4"
			></path>
		</svg>
	);
};

export default CircleValid;
