import React, { useMemo } from 'react';
import TermsAndConditionsCustomerEn from './en/TermsAndConditionsCustomer';
import TermsAndConditionsCustomerPl from './pl/TermsAndConditionsCustomer';

const TermsAndConditionsCustomer = () => {
	const lang = useMemo(() => localStorage.getItem('lang'), []);

	return lang === 'pl' ? (
		<TermsAndConditionsCustomerPl />
	) : (
		<TermsAndConditionsCustomerEn />
	);
};

export default TermsAndConditionsCustomer;
