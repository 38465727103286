const LoadedQuantityIcon = () => {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6456 11.5569L7.50195 13.3512V17.0245L14.1186 18.8036C14.1186 18.8036 14.7419 19.1058 14.7608 19.6536L14.8597 25.5985C15.2661 25.6782 15.5744 25.7389 15.736 25.7712C15.8195 25.7878 15.9063 25.7808 15.9862 25.7511L33.34 19.2894V9.67489L25.4322 8.11328L22.5638 9.01836"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M17.5456 16.5273V23.3071"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.8505 15.7266V22.5064"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.1555 14.9219V21.7016"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.4605 14.1211V20.9009"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.7654 13.3203V20.1001"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.0704 12.5156V19.2954"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31.3754 11.7148V18.4946"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.0136 25.7217V15.3159L7.50165 13.3516"
				stroke="#454545"
				strokeMiterlimit="10"
			/>
			<path
				d="M16.0137 15.3169L33.3397 9.67578"
				stroke="#454545"
				strokeMiterlimit="10"
			/>
			<path
				d="M7.24856 17.5094L14.0764 19.3451C14.1818 19.4131 14.2722 19.5115 14.2765 19.5478L14.3678 25.029L12.0724 25.9055L12.0571 25.9113L12.042 25.9178C11.9978 25.937 11.5966 26.1138 11.1592 26.4183C10.7334 26.7148 10.415 27.0333 10.2068 27.3701C10.1287 27.4963 10.0113 27.5935 9.87256 27.6463L8.90522 28.0138C8.69723 28.0928 8.47662 28.1333 8.25413 28.1333H7.17838H6.65278C6.62407 28.1333 6.59891 28.1525 6.59121 28.1802C6.57594 28.2352 6.53908 28.2815 6.48891 28.3087C6.43875 28.3358 6.37982 28.3416 6.32542 28.3244L2.44783 27.1034C2.40658 27.0904 2.37613 27.0549 2.36956 27.0123C2.36948 27.0114 2.36933 27.0107 2.36918 27.0099C2.36918 27.0097 2.36918 27.0095 2.36911 27.0093L2.33594 26.7879C2.31887 26.6742 2.24566 26.5768 2.1411 26.5287L1.93749 26.4352L1.53247 26.2491C1.46349 26.2175 1.42065 26.1471 1.42398 26.0713C1.44596 25.5773 1.51086 24.2954 1.6427 22.9417C1.88249 20.4786 2.16671 19.5739 2.30564 19.2619L7.24856 17.5094ZM7.21766 16.7188L1.79886 18.6401C0.895735 19.7592 0.660095 26.2578 0.660095 26.2578C0.660095 26.6898 0.895659 26.788 0.895659 26.788L1.62207 27.1218C1.681 27.5144 1.79886 27.6911 1.79886 27.6911L6.41268 29.144C6.49412 29.156 6.56703 29.1613 6.63238 29.1613C7.10268 29.1613 7.17838 28.8888 7.17838 28.8888H8.72934L10.6927 28.1428C10.889 27.2396 12.3419 26.6113 12.3419 26.6113L15.132 25.5459L15.0317 19.5237C15.0121 18.9543 14.3641 18.6402 14.3641 18.6402L7.21766 16.7188Z"
				fill="#454545"
			/>
			<path
				d="M8.12976 22.1074C8.24731 22.1398 8.31984 22.2576 8.29521 22.3771L7.83647 24.6074C7.81117 24.7304 7.69255 24.8108 7.56903 24.7887C4.8333 24.2982 3.17913 23.6851 2.3357 23.3035C2.24813 23.2639 2.19419 23.1742 2.20008 23.0783C2.26015 22.1012 2.43149 21.2616 2.55456 20.7584C2.56983 20.6963 2.61009 20.6432 2.66585 20.6118C2.7216 20.5803 2.78794 20.5735 2.84898 20.5927C4.38325 21.0747 7.32311 21.8861 8.12976 22.1074Z"
				fill="#454545"
			/>
			<path
				d="M9.13223 24.5034C9.12785 24.5784 9.16026 24.6508 9.21896 24.6977C9.27774 24.7446 9.35555 24.76 9.4277 24.7391L9.8427 24.6189C9.8427 24.6189 9.92543 23.9236 10.832 23.678L11.4762 23.4327C11.566 23.3984 11.6253 23.3124 11.6253 23.2164V21.4814C11.6253 21.4077 11.5902 21.3384 11.5308 21.2948C11.4714 21.2512 11.3947 21.2385 11.3245 21.2607L9.43571 21.8547C9.344 21.8835 9.2797 21.9661 9.27411 22.062L9.13223 24.5034Z"
				fill="#454545"
			/>
			<path
				d="M25.5354 26.0242C25.6795 26.0546 25.8338 25.9006 25.8802 25.6804C25.9265 25.4602 25.8473 25.2571 25.7032 25.2268C25.5591 25.1965 25.4048 25.3504 25.3584 25.5706C25.3121 25.7908 25.3913 25.9939 25.5354 26.0242Z"
				fill="#454545"
			/>
			<path
				d="M26.0106 23.568C25.3186 23.4223 24.5654 24.2184 24.328 25.3464C24.0905 26.4743 24.459 27.5068 25.151 27.6523C25.8427 27.7979 26.5961 27.0018 26.8335 25.874C27.0709 24.7459 26.7025 23.7136 26.0106 23.568ZM26.4217 25.7879C26.2769 26.4757 25.7947 26.9565 25.3447 26.8618C24.8947 26.7671 24.6472 26.1328 24.7921 25.4449C24.9368 24.757 25.419 24.2761 25.869 24.3709C26.319 24.4657 26.5664 25.1001 26.4217 25.7879Z"
				fill="#454545"
			/>
			<path
				d="M12.8334 31.2642C12.9857 31.2962 13.1488 31.1335 13.1977 30.9007C13.2467 30.668 13.163 30.4533 13.0108 30.4213C12.8585 30.3893 12.6954 30.552 12.6464 30.7847C12.5975 31.0175 12.6812 31.2321 12.8334 31.2642Z"
				fill="#454545"
			/>
			<path
				d="M13.3448 28.6627C12.6133 28.5088 11.8172 29.3502 11.5664 30.5424C11.3154 31.7345 11.7048 32.8258 12.4362 32.9795C13.1674 33.1334 13.9636 32.2919 14.2144 31.0999C14.4653 29.9076 14.076 28.8167 13.3448 28.6627ZM13.7792 31.0091C13.6262 31.736 13.1166 32.2442 12.641 32.144C12.1652 32.044 11.9036 31.3734 12.0568 30.6464C12.2098 29.9194 12.7195 29.4112 13.195 29.5114C13.6706 29.6117 13.9321 30.2819 13.7792 31.0091Z"
				fill="#454545"
			/>
			<path
				d="M28.7987 24.7003C28.9349 24.7289 29.0808 24.5834 29.1246 24.3752C29.1684 24.1671 29.0935 23.9751 28.9573 23.9464C28.8211 23.9178 28.6752 24.0633 28.6314 24.2714C28.5876 24.4796 28.6625 24.6716 28.7987 24.7003Z"
				fill="#454545"
			/>
			<path
				d="M29.2479 22.3756C28.5935 22.238 27.8816 22.9907 27.6572 24.057C27.4326 25.1234 27.781 26.0994 28.4351 26.237C29.0891 26.3747 29.8013 25.6219 30.0258 24.5558C30.2502 23.4892 29.902 22.5133 29.2479 22.3756ZM29.6365 24.4745C29.4996 25.1246 29.0437 25.5792 28.6183 25.4898C28.1927 25.4002 27.9587 24.8003 28.0956 24.1501C28.2325 23.4997 28.6884 23.0452 29.1138 23.1348C29.5394 23.2243 29.7733 23.8241 29.6365 24.4745Z"
				fill="#454545"
			/>
			<path
				d="M1.38232 26.5695V30.3628C1.38232 30.3628 3.11166 31.8728 7.89774 32.7039C7.89774 32.7039 8.40392 32.9086 9.30221 32.6094L10.6375 32.0516C10.7314 32.0124 10.793 31.9209 10.7939 31.8193C10.8003 31.151 10.9029 28.6741 12.0598 28.0387L13.3063 27.472C14.2325 27.1388 14.464 28.1757 14.5185 28.5509C14.5225 28.578 14.5401 28.6012 14.5652 28.6122C14.5904 28.6232 14.6195 28.6202 14.642 28.6045L14.7578 28.5239C14.8216 28.4795 14.8596 28.4067 14.8596 28.3289V25.5977"
				stroke="#454545"
				strokeMiterlimit="10"
			/>
			<path
				d="M31.0828 23.0081C31.0828 23.0081 30.9565 21.1346 29.6298 20.8125"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M23.2675 25.2266L16.6022 27.9141"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M2.3374 29.7304C2.31202 29.7522 2.27613 29.7573 2.24561 29.7434L1.58387 29.4406C1.55327 29.4266 1.53363 29.396 1.53363 29.3624V28.9125C1.53363 28.8852 1.54746 28.8598 1.57027 28.845C1.59316 28.8302 1.62202 28.8279 1.64688 28.8391L2.17082 29.0736C2.19401 29.0841 2.21139 29.1041 2.21841 29.1286L2.36422 29.6416C2.37329 29.6739 2.36286 29.7086 2.3374 29.7304Z"
				fill="#454545"
			/>
			<path
				d="M6.2284 30.3042L6.0785 31.0112C6.07548 31.0255 6.06642 31.0379 6.05357 31.0452C6.04073 31.0526 6.02547 31.0539 6.01149 31.0492L2.76127 29.9381C2.73369 29.9286 2.71269 29.9059 2.70566 29.8775L2.52835 29.1693C2.52071 29.139 2.53023 29.1068 2.5532 29.0855C2.57624 29.0641 2.60896 29.057 2.6388 29.0669L6.19432 30.2447C6.21918 30.253 6.23391 30.2786 6.2284 30.3042Z"
				fill="#454545"
			/>
			<path
				d="M8.4406 31.3692C8.4406 31.409 8.41128 31.4429 8.37185 31.4486C7.86536 31.5227 6.7408 31.2703 6.48847 31.211C6.47502 31.2079 6.46346 31.1995 6.45628 31.1877C6.44903 31.1759 6.44691 31.1617 6.45031 31.1483L6.58169 30.6271C6.58691 30.6063 6.60028 30.5884 6.61879 30.5775C6.6373 30.5667 6.65944 30.5636 6.68014 30.5691C7.6332 30.8212 8.15812 30.8112 8.35235 30.7926C8.37494 30.7905 8.39738 30.7979 8.41415 30.8131C8.43093 30.8283 8.44052 30.85 8.44052 30.8726V31.3692H8.4406Z"
				fill="#454545"
			/>
			<path
				d="M14.4893 5.02678V1H19.6199V5.02678H22.982L17.0546 10.9542L11.1273 5.02678H14.4893Z"
				stroke="#454545"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default LoadedQuantityIcon;
