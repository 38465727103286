const ProducerIcon = () => {
	return (
		<svg
			width="34"
			height="20"
			viewBox="0 0 34 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.859375" width="33.0597" height="20" rx="10" fill="#5682FA" />
			<path
				d="M11.4395 9.30078V11.4008C11.4395 14.0406 11.4395 15.3606 12.2596 16.1807C13.0797 17.0008 14.3996 17.0008 17.0395 17.0008H17.7395C20.3793 17.0008 21.6993 17.0008 22.5194 16.1807C23.3395 15.3606 23.3395 14.0406 23.3395 11.4008V9.30078"
				stroke="white"
			/>
			<path
				d="M15.6393 3H19.1393L19.5955 7.56216C19.7261 8.8674 18.7011 10 17.3893 10C16.0776 10 15.0526 8.8674 15.1831 7.56216L15.6393 3Z"
				stroke="white"
			/>
			<path
				d="M11.3202 5.34593C11.4449 4.72265 11.5072 4.41101 11.6339 4.15839C11.901 3.62607 12.3815 3.23217 12.9558 3.07472C13.2284 3 13.5462 3 14.1818 3H15.6394L15.1322 8.07169C15.0228 9.16637 14.1016 10 13.0015 10C11.6502 10 10.6367 8.76373 10.9017 7.43865L11.3202 5.34593Z"
				stroke="white"
			/>
			<path
				d="M23.4579 5.34593C23.3332 4.72265 23.2709 4.41101 23.1441 4.15839C22.8771 3.62607 22.3966 3.23217 21.8223 3.07472C21.5497 3 21.2319 3 20.5963 3H19.1387L19.6458 8.07169C19.7553 9.16637 20.6765 10 21.7766 10C23.1279 10 24.1414 8.76373 23.8764 7.43865L23.4579 5.34593Z"
				stroke="white"
			/>
			<path
				d="M15.6387 16.6508V14.5508C15.6387 13.8966 15.6387 13.5694 15.7793 13.3258C15.8715 13.1662 16.0041 13.0336 16.1637 12.9415C16.4073 12.8008 16.7344 12.8008 17.3887 12.8008C18.0429 12.8008 18.37 12.8008 18.6137 12.9415C18.7733 13.0336 18.9058 13.1662 18.998 13.3258C19.1387 13.5694 19.1387 13.8966 19.1387 14.5508V16.6508"
				stroke="white"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default ProducerIcon;
