export const UnassignedOrder = () => {
	return (
		<svg
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5 11.0005L7.50004 16.0005M7.50002 11.0005L12.5 16.0005"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M1 9.00049C1 5.22925 1 3.34363 2.17157 2.17206C3.34315 1.00049 5.22876 1.00049 9 1.00049H11C14.7712 1.00049 16.6569 1.00049 17.8284 2.17206C19 3.34363 19 5.22925 19 9.00049V13.0005C19 16.7717 19 18.6573 17.8284 19.8289C16.6569 21.0005 14.7712 21.0005 11 21.0005H9C5.22876 21.0005 3.34315 21.0005 2.17157 19.8289C1 18.6573 1 16.7717 1 13.0005V9.00049Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
			<path
				d="M6 6.00049H14"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};
