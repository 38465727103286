export const ChevronRightIcon = () => {
	return (
		<svg
			width="4"
			height="8"
			viewBox="0 0 4 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L3.85355 3.64645C4.04882 3.84171 4.04882 4.15829 3.85355 4.35355L0.853553 7.35355C0.658291 7.54882 0.341709 7.54882 0.146447 7.35355C-0.0488155 7.15829 -0.0488155 6.84171 0.146447 6.64645L2.79289 4L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z"
				fill="#454545"
			/>
		</svg>
	);
};
