import React from 'react';

import './App.css';
import 'leaflet/dist/leaflet.css';
import 'react-tooltip/dist/react-tooltip.css';
import GlobalProvider from './context/provider';
import RedirectProvider from './context/redirectProvider';
import AppRouter from './router/Router';

function App() {
	return (
		<GlobalProvider>
			<RedirectProvider>
				<AppRouter />
			</RedirectProvider>
		</GlobalProvider>
	);
}

export default App;
