import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import AssignedTo from '../../../components/Notification/AssignedTo';
import { useTranslate } from '@tolgee/react';

interface NotificationAssignedToProps {
	notification: boolean;
	date: string;
	avatar: string;
	username: string;
}

const NotificationAssignedTo = ({
	notification,
	date,
	avatar,
	username,
}: NotificationAssignedToProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep
					isActive={notification}
					isFinalStep={true}
					minWidth={247}
				>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="w-full">
				<AssignedTo
					notification={notification}
					title={t(
						'transporterAssignedTo.orderHasBeenAssignedTo',
						'The order has been assigned to:',
					)}
					description={t(
						'transporterAssignedTo.confirmPickupDetails',
						'Confirm pick-up details.',
					)}
					avatar={avatar}
					username={username}
				/>
			</div>
		</div>
	);
};

export default NotificationAssignedTo;
