import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactInputVerificationCode from 'react-input-verification-code';
import {
	SypacBox,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { ModalConfirmationCodeProps } from './ModalConfirmationCode.interface';
import './modal-confirmation-code.css';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import { T, useTranslate } from '@tolgee/react';

const ModalConfirmationCode = ({
	isOpen,
	onClose,
	onSubmit,
}: ModalConfirmationCodeProps) => {
	const [error, setError] = useState(false);
	const [code, setCode] = useState('');
	const { t } = useTranslate();

	useEffect(() => {
		if (error) {
			toastVariant(
				t(
					'modalConfirmationCode.verificationCodeIncorrect',
					'Verification code incorrect. Double check your verification code and try again.',
				),
				true,
			);
		}
	}, [error, t]);

	useEffect(() => {
		if (code.length === 5) {
			setError(false);
		}
	}, [code]);

	const callSubmint = () => {
		if (code.length < 5) {
			setError(true);
			return;
		}
		setError(false);
		onSubmit && onSubmit();
	};

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[563px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Enter confirmation code</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<div className="flex flex-col items-center justify-center modal-confirmation-code-border px-6 py-4 gap-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							To complete the payment, enter the confirmation code in the field
							below.
						</p>
					</SypacText>
					<SypacBox className="flex flex-col items-center justify-center py-5 gap-6 bg-gray-10-opacity-50">
						<SypacText variant="heading-5">
							<p className="text-gray-80 font-semibold">Verification code</p>
						</SypacText>
						<div
							className={`confirmation-custom-styles ${
								error ? 'code-error' : 'code-success'
							}`}
						>
							<ReactInputVerificationCode
								value={code}
								length={5}
								autoFocus
								placeholder=""
								onChange={setCode}
							/>
						</div>
					</SypacBox>
					<SypacText variant="body-regular-small">
						<p className="text-gray-40 text-left">
							The code consists of 5 digits and does not contain or spaces. Make
							sure to enter the code exactly as it was received.
						</p>
					</SypacText>
				</div>

				<div className="flex justify-center w-full py-5 gap-5">
					<SypacButton variant="secondary" size="small" onClick={onClose}>
						<button className="px-10 py-2.5 hover:border-gray-2" type="reset">
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small">
						<button
							className={`${
								error ? 'opacity-50 pointer-events-none' : ''
							} px-15 py-2.5 border-primary-violet hover:border-kimberly`}
							onClick={callSubmint}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.nextStep">Next step</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalConfirmationCode;
