import {
	Circle,
	MapContainer,
	Polyline,
	TileLayer,
	useMap,
} from 'react-leaflet';
import React, { useEffect, useMemo, useState } from 'react';
import { MarkerLayer, Marker } from 'react-leaflet-marker';
import {
	LatLng,
	MapProps,
	MapView,
	PinVariant,
	RouteColorVariant,
} from './Map.interface';
import { Pickup } from '../../assets/Pickup';
import { Delivery } from '../../assets/Delivery';
import { TruckPin } from '../../assets/TruckPin';
import { SypacText } from '@sypac/component-library-react';
import { OrderStatusesEnum } from '../../services/orders.services';
import { CompletedPin } from '../../assets/CompletedPin';
import { TruckPinClient } from '../../assets/TruckPinClient';
import { DeliveryClient } from '../../assets/DeliveryClient';
import { PickupClient } from '../../assets/PickupClient';
import { PickupProduct } from '../../assets/PickupProduct';
import Flag from 'react-world-flags';

const ChangeMapView = (props: MapView) => {
	const { coords } = props;
	const map = useMap();
	map.setView([coords.lat, coords.lng], map.getZoom());
	return null;
};

const Map = (props: MapProps) => {
	const {
		poligons,
		markers,
		orderStatus,
		curentMarker,
		target = 'transporter',
	} = props;
	const [center, setCenter] = useState<LatLng>({
		lat: 52.232938,
		lng: 21.0611941,
	});

	useEffect(() => {
		if (markers?.length) {
			const variants = [
				PinVariant.truck,
				PinVariant.pickup,
				PinVariant.product,
				PinVariant.delivery,
			];
			let start = variants
				.map((v) => markers.find((r) => r.variant === v))
				.find((result) => result);

			if (start) {
				setCenter({
					lat: start.latitude,
					lng: start.longitude,
				});
			}
		} else if (poligons?.length) {
			const start = poligons[0].coordinates[0];
			if (start) {
				setCenter(start);
			}
		} else if (curentMarker) {
			setCenter({ lat: curentMarker.latitude, lng: curentMarker.longitude });
		}
	}, [poligons, markers, curentMarker]);

	const iconTransporter = (variant: string, truckStatus?: string) => {
		if (
			orderStatus &&
			[
				OrderStatusesEnum.COMPLETED.toString(),
				OrderStatusesEnum.DELIVERED.toString(),
			].includes(orderStatus)
		) {
			return <CompletedPin />;
		}
		if (variant === PinVariant.pickup) {
			return <Pickup />;
		}
		if (variant === PinVariant.delivery) {
			return <Delivery />;
		}
		if (variant === PinVariant.product) {
			return <PickupProduct />;
		}
		return <TruckPin status={truckStatus} />;
	};

	const iconCustomer = (variant: string) => {
		if (variant === PinVariant.pickup) {
			return <PickupClient />;
		}
		if (variant === PinVariant.delivery) {
			return <DeliveryClient />;
		}
		return <TruckPinClient />;
	};

	const icon = (variant: string, truckStatus?: string) => {
		if (target === 'customer') {
			return iconCustomer(variant);
		}
		return iconTransporter(variant, truckStatus);
	};

	const colorRoute = (orderRoute: boolean) => {
		// if (target === 'customer') {
		// 	return RouteColorVariant.customer;
		// }
		if (
			orderStatus &&
			[
				OrderStatusesEnum.COMPLETED.toString(),
				OrderStatusesEnum.DELIVERED.toString(),
			].includes(orderStatus)
		) {
			return RouteColorVariant.completed;
		}
		return orderRoute
			? RouteColorVariant.orderRoute
			: RouteColorVariant.toPickup;
	};

	const bgClassName = (status?: string) => {
		return status && status === 'not_available'
			? `bg-[#FFB352]`
			: 'bg-[#20AC93]';
	};

	const productLoaction = useMemo(
		() => markers?.find((r) => r.variant === PinVariant.product),
		[markers],
	);

	return (
		<div className="w-full h-full relative">
			{curentMarker && curentMarker.variant ? (
				<div className="absolute z-[500] top-12 flex flex-row w-full justify-center">
					<div className="rounded-lg bg-cornflower-blue px-4 py-2 flex flex-col">
						<SypacText variant="body-regular-large">
							<p className="text-white">
								Current location: 7 Rue Fernande Folral str., Charleroi, BE
							</p>
						</SypacText>
						<SypacText variant="overline-regular-large" className="mt-3">
							<p className="text-white">
								Latitude: 50.4, Longitude: 4.4333350° 24′ 0″ North, 4° 25′ 60″
								East
							</p>
						</SypacText>
					</div>
				</div>
			) : null}

			<MapContainer
				center={[center.lat, center.lng]}
				zoom={productLoaction ? 14 : 10}
				scrollWheelZoom={true}
				zoomControl={false}
				className="w-full h-full rounded-10 z-0"
				key={`${center.lat}${center.lng}`}
			>
				<TileLayer
					url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=kfaiWdCWdSlyi1Ye96mu"
					attribution={`<div>Logo</div>`}
					tileSize={512}
					zoomOffset={-1}
				/>
				{curentMarker && curentMarker.variant ? (
					<MarkerLayer>
						<Marker
							position={[curentMarker.latitude, curentMarker.longitude]}
							size={[100, 32]}
							placement="center"
						>
							<div className="flex flex-col items-center -mt-5">
								{icon(curentMarker.variant, curentMarker?.truck?.status)}
								{curentMarker.variant === PinVariant.truck &&
								curentMarker?.truck ? (
									<>
										<div
											className={`py-[1px] px-4 rounded-3xl flex flex-row ${bgClassName(
												curentMarker?.truck?.status,
											)}`}
										>
											<SypacText variant="body-regular-small">
												<p className="font-medium text-white max-w-[60px] truncate">
													{curentMarker.truck?.truckModel}
												</p>
											</SypacText>
											<SypacText variant="body-regular-small">
												<p className="font-medium text-white">
													&nbsp;#{curentMarker.truck?.id}
												</p>
											</SypacText>
										</div>
										<div className="p-3 flex flex-row bg-white rounded-10 mt-2 shadow-map-card">
											<div
												style={{
													backgroundImage: `url(${
														curentMarker.truck?.truckPhoto
															? curentMarker.truck?.truckPhoto
															: '/truck.jpg'
													})`,
												}}
												className="flex w-[58px] bg-center bg-cover bg-no-repeat h-[58px] rounded-md"
											/>
											<div className="ml-3 flex flex-col justify-center">
												<div className="flex gap-1.5 items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-gray-90">
															{curentMarker.truck?.truckModel}
														</p>
													</SypacText>
													<SypacText variant="overline-regular-large">
														<p className="text-gray-90">
															#{curentMarker.truck?.id}
														</p>
													</SypacText>
												</div>
												<div className="bg-gray-10-opacity-50 rounded-md px-4 py-1 mt-2">
													{curentMarker.truck?.truckNumber}
												</div>
											</div>
										</div>
									</>
								) : null}
							</div>
						</Marker>
					</MarkerLayer>
				) : null}
				{markers?.length
					? markers?.map((marker, index) => {
							const truck = marker?.truck;
							const delivery = marker?.delivery;
							const pickup = marker?.pickup;

							return (
								<MarkerLayer key={index}>
									<Marker
										position={[marker.latitude, marker.longitude]}
										size={[100, 32]}
										placement="center"
									>
										<div className="flex flex-col items-center -mt-5">
											{icon(marker.variant, truck?.status)}

											{marker.variant === PinVariant.truck &&
											target === 'transporter' &&
											truck?.id ? (
												<div
													className={`py-[1px] px-4 rounded-3xl flex flex-row ${bgClassName(
														truck?.status,
													)}`}
												>
													{truck?.truckModel ? (
														<SypacText variant="body-regular-small">
															<p className="font-medium text-white max-w-[60px] truncate">
																{truck?.truckModel}
															</p>
														</SypacText>
													) : null}
													{truck?.id ? (
														<SypacText variant="body-regular-small">
															<p className="font-medium text-white">
																&nbsp;#{truck?.id}
															</p>
														</SypacText>
													) : null}
												</div>
											) : (
												<p />
											)}

											{marker.variant === PinVariant.delivery ? (
												<div className="h-[24px] flex rounded-lg bg-white border border-solid border-gray-10 px-1">
													<div className="flex flex-row items-center">
														{delivery?.deliveryCountryCode ? (
															<Flag
																className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
																code={delivery?.deliveryCountryCode}
																width={22}
																height={16}
															/>
														) : null}
														<SypacText
															variant="body-regular-medium"
															className={
																delivery?.deliveryCountryCode ? 'ml-3' : ''
															}
														>
															<p className="w-[300px] text-gray-80 overflow-hidden whitespace-nowrap text-ellipsis">
																{delivery?.deliveryAddress}
															</p>
														</SypacText>
													</div>
												</div>
											) : marker.variant === PinVariant.pickup ? (
												<div className="h-[24px] flex rounded-lg bg-white border border-solid border-gray-10 px-1">
													<div className="flex flex-row items-center">
														{pickup?.pickupCountryCode ? (
															<Flag
																className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
																code={pickup?.pickupCountryCode}
																width={22}
																height={16}
															/>
														) : null}
														<SypacText
															variant="body-regular-medium"
															className={
																pickup?.pickupCountryCode ? 'ml-3' : ''
															}
														>
															<p className="w-[300px] text-gray-80 overflow-hidden whitespace-nowrap text-ellipsis">
																{pickup?.pickupAddress}
															</p>
														</SypacText>
													</div>
												</div>
											) : null}
										</div>
									</Marker>
								</MarkerLayer>
							);
					  })
					: null}

				{poligons?.length
					? poligons?.map((poligon, index) => (
							<Polyline
								key={index}
								positions={poligon.coordinates}
								color={colorRoute(poligon.orderRoute)}
							/>
					  ))
					: null}

				{center ? <ChangeMapView coords={center} /> : null}
				{productLoaction ? (
					<Circle
						center={[productLoaction.latitude, productLoaction.longitude]}
						pathOptions={{
							color: '#20AC93',
							weight: 1,
							fillColor: 'rgba(32, 172, 147, 0.5)',
						}}
						radius={1000}
					></Circle>
				) : null}
			</MapContainer>
		</div>
	);
};

export default Map;
