import React from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';

import { Success } from '../../../../assets/Success';

const SuccessScreen = ({ onClose }: { onClose: () => void }) => {
	return (
		<div className="flex flex-col gap-10 p-10 h-[calc(100vh-400px)] justify-center">
			<Success />
			<div className="flex flex-col gap-6">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">Company approved</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						The KYC process for this company has been successfully completed. A
						confirmation email has been sent to notify this company that they
						now have access to SYPAC services.
					</p>
				</SypacText>
			</div>
			<SypacButton className="self-start" variant="primary" size="small">
				<button className="px-6 py-3" onClick={onClose}>
					<SypacText variant="body-regular-medium">
						<p>View profile</p>
					</SypacText>
				</button>
			</SypacButton>
		</div>
	);
};

export default SuccessScreen;
