import { toast } from 'react-toastify';

export const toastVariant = (text: string, isError: boolean = false) => {
	const params: any = {
		className: 'w-max text-center z-[2000]',
		position: 'top-center',
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: false,
		pauseOnHover: true,
		closeButton: false,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	};
	if (isError) {
		return toast.error(text, params);
	}
	return toast.success(text, params);
};
