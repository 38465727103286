import React from 'react';
import { SypacLink, SypacText } from '@sypac/component-library-react';

const TermsAndConditionsCustomerPl = () => {
	return (
		<div>
			<div>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">
						Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie
						(wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego za
						numerem KRS: 0001014419), dalej zwana „
					</span>
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						”, prowadzi sprzedaż produktów oznaczonych co do gatunku (dalej
						zwanych „Produktami”), za pośrednictwem
					</span>{' '}
					<span className="text-gray-90 font-bold">Platformy SYPAC</span>
					<span className="text-gray-90">
						, oraz świadczy usługi dostawy zamawianych Produktów (pośrednio,
						umożliwiając za pośrednictwem
					</span>{' '}
					<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
					<span className="text-gray-90">
						dostawę z zastrzeżeniem najlepszej możliwej opcji dla klienta
						dokonania wyboru dostawcy), na rzecz osób (osób fizycznych, osób
						prawnych, jednostek organizacyjnych posiadających osobowość prawną)
						prowadzących działalność gospodarczą, odpowiednio do przedmiotu
						takiej działalności.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Rejestracja użytkownika oraz korzystanie z
					</span>{' '}
					<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
					<span className="text-gray-90">
						odbywa się w imieniu i na rzecz Klienta lub w Państwa własnym
						imieniu, jeżeli prowadzicie działalność i dokonujecie osobiście
						rejestracji na platformie
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Dokonanie rejestracji oznacza akceptację poniżej przedstawionych
						postanowień oraz Warunków Kupna, Warunków Świadczenia Usług i
						Polityki Prywatności i Ochrony Danych Osobowych, które stanowią
						integralną część niniejszej umowy.
					</span>
				</SypacText>

				<SypacText variant="body-regular-medium" className="mt-20">
					<span className="text-gray-90 font-bold">§ 1. Zastosowanie</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">Warunki Ogólne (dalej zwane „</span>
					<span className="text-gray-90 font-bold">Warunkami Ogólnymi</span>
					<span className="text-gray-90">
						") mają zastosowanie wyłącznie pomiędzy Klientem a SYPAC. Odmienne
						lub sprzeczne warunki nie stanowią treści stosunku prawnego
						ustanowionego z SYPAC, chyba że SYPAC udzieli stosowną wyraźną
						pisemną zgodę. Wersje Warunków Ogólnych, które są ważne w momencie
						zawarcia umowy są dostępne na stronie{' '}
						<SypacLink className="contents">
							<a
								href="https://www.sypac.app/en/terms-and-conditions"
								target="_blank"
								rel="noreferrer"
							>
								https://www.sypac.app/en/terms-and-conditions
							</a>
						</SypacLink>{' '}
						i są ostateczne i wiążące. Na swojej stronie internetowej SYPAC
						zapewnia możliwość zapoznania się wyłącznie z bieżącą wersją
						Warunków Ogólnych, natomiast wcześniejsze wersje Warunków Ogólnych
						SYPAC przechowuje w archiwach i zapewnia dostęp do nich Klientom, na
						ich wyraźne żądanie, pod warunkiem że byli Klientami Platformy SYPAC
						w dacie obowiązywania wcześniejszych Warunków Ogólnych.
					</span>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 2. Przedmiot umowy </p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								udostępnia platformę elektroniczną przeznaczoną do sprzedaży
								Produktów za pośrednictwem internetu, a także aplikacji mobilnej
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								<SypacLink className="contents">
									<a
										href="https://www.sypac.app"
										target="_blank"
										rel="noreferrer"
									>
										www.sypac.app
									</a>
								</SypacLink>{' '}
								(dalej łącznie zwanej „
							</span>
							<span className="text-gray-90 font-bold">Platformą SYPAC</span>{' '}
							<span className="text-gray-90">
								"). Platforma SYPAC umożliwia sprzedaż Produktów i ich dostawę w
								transparentny sposób.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Umowy zawierane pomiędzy SYPAC oraz jego dostawcami,
								przewoźnikami (dostawcami usług transportowych) oraz innymi
								klientami, które regulują wykonywanie zobowiązań pomiędzy
								stronami, w tym również zobowiązań partnerów SYPAC do
								przygotowania Produktu oraz jego dostarczenia Klientowi, są
								zawierane w drodze podpisywanej elektronicznie umowy
								cywilnoprawnej. Dokonując rejestracji na Platformie SYPAC,
								przystępujecie Państwo do umowy z SYPAC jako jego Klient.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli jesteście Państwo pracownikiem lub innym przedstawicielem
								jednego z Państwa klientów, który to klient wyznaczył Państwa do
								działania w jego imieniu na Platformie SYPAC w charakterze
								Klienta na rachunek tego klienta, działanie w imieniu tego
								klienta jest możliwe dopiero po dokonaniu autoryzacji dostępu na
								głównym profilu podmiotu (Klient).
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Niniejsze Warunki Ogólne regulują formy dostępu Klienta do
								Platformy SYPAC oraz dozwolone sposoby korzystania z platformy.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Mając na uwadze fakt, że Platforma SYPAC umożliwia wszystkim
								Klientom korzystanie z platformy w ich imieniu przez ich
								pracowników, wykonawców oraz innych upoważnionych
								przedstawicieli (innych użytkowników, o których mowa w
								podpunkcie 3 powyżej), Klient zobowiązuje się:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">
										przyjmować odpowiedzialność za wszystkie działania
										podejmowane przez innych użytkowników, z wykorzystaniem
										Platformy SYPAC w imieniu Klienta jak za własne działania,
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										dopilnować, aby pozostali użytkownicy, korzystający z
										Platformy SYPAC w imieniu Klienta, używali jej wyłącznie
										zgodnie z niniejszymi regulacjami oraz innymi regulacjami
										przyjętymi pomiędzy SYPAC a Klientem.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 3. Autoryzacja loginu</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Wszystkie koszty związane z usługami świadczonymi przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								są pokrywane wyłącznie przez Klienta zgodnie z podpisaną w
								formie elektronicznej umową cywilnoprawną. Klienci, którzy nie
								są klientami, a działają wyłącznie jako osoby upoważnione do
								działania w ich imieniu, nie dokonują płatności tytułem
								dodatkowych opłat.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Korzystanie z Platformy</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								wymaga rejestracji w charakterze Klienta. Dostępność Platformy
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								nie skutkuje przyjęciem założenia, że Klient dokona rejestracji
								na
							</span>{' '}
							<span className="text-gray-90 font-bold">
								Platformie SYPAC. SYPAC
							</span>{' '}
							<span className="text-gray-90">
								może, działając według własnego uznania, odrzucić wniosek o
								rejestrację na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>{' '}
							<span className="text-gray-90">bez wskazania przyczyny.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Rejestracja oraz korzystanie z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								może odbywać się wyłącznie przez osoby dorosłe posiadające pełną
								zdolność do podejmowania czynności prawnych. Wymóg ten ma
								zastosowanie do klientów, którzy nie są osobami fizycznymi oraz
								Klientów, którzy reprezentują klientów na
							</span>
							<span className="text-gray-90 font-bold">Platformie SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Dane kontaktowe oraz inne informacje, których dostarczenia
								wymaga
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								w procesie rejestracji, powinny być kompletne i prawdziwe.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do sprawdzenia dokładności przekazanych
								danych oraz dokonania weryfikacji ich kompletności oraz
								wiarygodności, poprzez skontaktowanie się z Państwem lub
								przedsiębiorcą, którego Państwo reprezentujecie w tym zakresie.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Po pomyślnym ukończeniu procesu rejestracji, następuje aktywacja
								dostępu, po której otrzymujecie Państwo stosowne powiadomienie
								na wskazany adres poczty elektronicznej lub za pomocą wiadomości
								tekstowej (w formie wskazanej przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								). Takie powiadomienie przesłana na wskazany adres poczty
								elektronicznej lub za pomocą wiadomości tekstowej ma skutek
								rejestracji i przystąpienia do umowy z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zgodnie z niniejszymi warunkami, pod warunkiem że potwierdzicie
								Państwo dane kontaktowe i ukończycie proces rejestracji na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, z użyciem linka aktywacyjnego. Po otrzymaniu powiadomienia
								przesłanego na wskazany adres poczty elektronicznej lub za
								pomocą wiadomości tekstowej, korzystanie z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jest możliwe zgodnie z niniejszymi Warunkami Ogólnymi.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 4. Odpowiedzialność za dane dostępu
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Podczas procesu rejestracji, zostaniecie Państwo poproszeni o
								wpisanie nazwy użytkownika i hasła. Po wpisaniu tych danych,
								możecie zalogować się na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, po dokonaniu aktywacji dostępu i potwierdzenia zgodnie z § 3
								podpunkt 6. Klient ma obowiązek upewnić się, że jego nazwa nie
								narusza praw osób trzecich, w szczególności praw do nazwy lub
								znaku handlowego, ani nie jest sprzeczna z zasadami współżycia
								społecznego, w szczególności nie zawiera wulgaryzmów ani wyrażeń
								powszechnie uznawanych za obraźliwe. Proces rejestracji
								Klientów, o których mowa w § 2 podpunkt 3 (pracownicy lub inni
								przedstawiciele), odbywa się na głównym koncie Klienta, który,
								po dodaniu dodatkowego użytkownika (pracownika lub
								przedstawiciela), wpisuje jego adres poczty elektronicznej, na
								który automatycznie przesyłany jest link aktywacyjny.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Klient zobowiązuje się zachować w poufności dane dostępu, w tym
								również hasło, i nie przekazywać takich danych nieupoważnionym
								osobom trzecim, bez względu na fakt czy dane dostępu zostały
								wskazane przez Klienta niezależnie lub czy zostały mu przekazane
								do użycia przez klienta.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Klient ma obowiązek zapewnić, aby korzystanie z usług
								udostępnianych na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, z użyciem jego danych dostępu, następowało wyłącznie przez
								niego. W razie powstania ryzyka, że nieupoważniona osoba trzecia
								uzyskała lub uzyska dane dostępu Klienta,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zostanie bezzwłocznie powiadomiony o tym fakcie. Do momentu
								zablokowania dostępu (które to czynności
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								podejmuje bezzwłocznie, tzn. nie później niż w terminie jednego
								dnia roboczego), uznaje się, że wszystkie czynności podjęte na
								rachunku Klienta zostały podjęte przez niego.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ponosicie Państwo odpowiedzialność za wszystkie działania
								podejmowane za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								z użyciem danych dostępu, zgodnie z właściwym prawem. Klient ma
								obowiązek wypłacić odszkodowanie za szkody poniesione przez
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								i spowodowane wskutek niewłaściwego przechowywania danych
								dostępu.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Macie Państwo obowiązek w każdym czasie dokonywać aktualizacji
								informacji zawartych na Państwa profilu (w tym również danych
								kontaktowych). Jeżeli Państwa informacje ulegną zmianie w
								okresie korzystania z platformy, należy bezzwłocznie skorygować
								informacje w ustawieniach osobistych na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								. W razie niedopełnienia takich czynności, należy bezzwłocznie
								powiadomić
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								o wszelkich zmiana w Państwa danych za pośrednictwem poczty
								elektronicznej.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 5. Zakończenie korzystania z platformy
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Dostęp do</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								ulega automatycznemu zakończeniu, bez uprzedniego odwołania lub
								innego zawiadomienia, w momencie rozwiązania stosunku umownego
								pomiędzy Klientem a
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								lub w razie wypowiedzenia statusu Klienta jako administratora
								lub innego użytkownika przez Klienta w jego stosunkach z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								. Klient jest uprawniony do wycofania statusu użytkownika w
								dowolnym terminie.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do tymczasowego lub stałego zablokowania
								dostępu Klienta do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								zgodnie z obowiązującymi warunkami oraz z zastrzeżeniem
								uzyskania zgody Klienta.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								może dokonać nieodwracalnego usunięcia wszystkich danych
								wprowadzonych lub powstałych w związku z korzystaniem z
								platformy przez Klienta w terminie 30 dni kalendarzowych po
								dacie zablokowania dostępu Klienta do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								oraz wygaśnięcia ustawowego terminu na przechowanie danych.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 6. Ogólne Obowiązki Klienta
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90">
						W celu zapewnienia odpowiedniego funkcjonowania Platformy SYPAC, aby
						SYPAC był w stanie należycie świadczyć jego usługi umowne, a w
						szczególności gdy istnieje znaczna liczba Klientów oraz innych
						użytkowników, wszyscy użytkownicy oraz bezpośredni Klienci mają
						obowiązek przestrzegać określonych regulacji oraz wykonywać
						określone obowiązki.
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Zobowiązujecie się Państwo przestrzegać wskazówek dotyczących
								korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">przekazanych przez</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								. Użytkownik będzie systematycznie sprawdzać źródła informacje
								przekazywane za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, a także źródła informacji dostępnych w internecie na stronie
								www.sypac.app i będzie je uwzględniać podczas korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC.</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-5 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Będziecie Państwo systematycznie sprawdzać informacje
								udostępnione przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">za pośrednictwem</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								oraz w szczególności za pośrednictwem dostępnej tam opcji
								powiadomień, w celu zapoznania się z nowymi wiadomościami,
								dokumentami, powiadomieniami oraz innymi informacjami
								przesłanymi do Użytkownika z zastosowaniem opcji powiadomień
								dostępnej na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, które będą uznawane za otrzymane przez Użytkownika nie później
								niż w stosownym dniu roboczym, w którym przesłane zostało
								powiadomienie.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Macie obowiązek używać aktualnego oprogramowania antywirusowego
								w lokalnych systemach zapewniających dostęp do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, a także stosować stosowne mechanizmy obronne przeciwko atakom
								hakerskim, wirusom i porównywalnym zakłóceniom w okresie
								obowiązywania umowy, co obejmuje w szczególności zawsze
								instalację bezzwłoczną aktualizacji zapewniających
								bezpieczeństwo i ochronę, jakie są dostępne dla zastosowanego
								systemu operacyjnego.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Użytkownik wyraża zgodę na dostęp
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								do jego danych w zakresie jaki okaże się konieczny, w celu
								przeprowadzenia analizy i korekty błędów i wad zgłoszonych przez
								Użytkownika, a także w kontekście wymaganego wsparcia, oraz aby
								zapewnić odpowiednie działanie
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, a w szczególności w celu przeprowadzenia analizy procesów
								funkcjonalnych oraz badania lub testowania struktur plików.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ponosicie Państwo pełną i wyłączną odpowiedzialność za treść
								umieszczaną na
							</span>{' '}
							<span className="text-gray-90 font-bold">
								Platformie SYPAC. SYPAC
							</span>{' '}
							<span className="text-gray-90">
								nie przeprowadza kontroli przekazanej treści pod kątem jej
								poprawności, dokładności, zgodności z prawem, terminowości,
								jakości lub odpowiedniości w danym celu.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Oświadczacie i zapewniacie wobec
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, że jesteście pełnoprawnym właścicielem praw do przekazanej
								treści lub wprowadzonych danych (np. zdjęć, nazw lub numerów
								telefonu) lub jesteście przynajmniej na innej podstawie
								upoważnieni (np. na mocy ważnego pełnomocnictwa udzielonego
								przez upoważnione osoby) do dokonania przeniesienia treści na
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, celem udostępnienia jej do wykorzystania na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, a także udostepnienia jej użytkownikom za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								. W związku z powyższym Użytkownik zapewni, że jest uprawniony
								do wykonania praw do używania takich informacji lub że stosowne
								pliki udostępnione na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>{' '}
							<span className="text-gray-90">
								nie naruszają przepisów prawa, dobrych praktyk ani praw osób
								trzecich przed złożeniem zdjęć, planów, rysunków oraz innych
								informacji. Użytkownik może opublikować zdjęcia, na których
								rozpoznane mogą zostać jakiekolwiek osoby, jedynie po uzyskaniu
								ich stosownej ważnej zgody.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Niniejszym zwalniacie Państwo
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								z wszelkiej odpowiedzialności i zobowiązujecie się go chronić
								przed odpowiedzialnością wynikającą z wszelkich roszczeń, jakie
								mogą być zgłoszone wobec
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								przez osoby trzecie (w tym również użytkowników zagranicznych)
								wskutek domniemanego naruszenia ich praw poprzez pobraną lub
								wprowadzoną treści lub wskutek korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								w dowolnej innej formie. Prawo do zwolnienia z odpowiedzialności
								w szczególności obejmuje koszty koniecznej obrony prawnej
								podjętej przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, w tym również wszystkie koszty sądowe i koszty obsługi
								prawnej.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">8.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do odmowy udostępnienia lub wprowadzenia
								treści, do edytowania, zablokowania lub usunięcia udostępnionej
								treści, bez uprzedzenia, jeżeli udostępniana treść może być
								uznana za naruszającą postanowienia § 6 podpunkt 6 lub jeżeli
								można bezwzględnie założyć, że mogło dojść do naruszenia
								postanowień § 6 podpunkt 6.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">9.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								przechowuje dla Klienta, jako dostawca usług technicznych, treść
								i dane, które Klient oraz jego użytkownicy przekazują w związku
								z korzystaniem z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, oraz które powstają w trakcie korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								. Użytkownik zobowiązuje się nie zamieszczać ani nie używać
								treści ani danych, których zamieszczenie podlega
								odpowiedzialności karnej lub na innej podstawie jest
								bezwzględnie niezgodne z prawem lub wobec osób trzecich, ani nie
								używać programów zawierających wirusy lub inne złośliwe
								oprogramowanie i nie narażać oprogramowania wykorzystywanego do
								przekazywania powiadomień na takie zagrożenie. Jesteście
								administratorem takich danych osobowych i w związku z powyższym
								macie obowiązek zawsze dokonać weryfikacji, czy przetwarzanie
								takich danych za pośrednictwem Platformy
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								jest dozwolone na podstawie należycie udzielonej zgody, zgodnie
								z obowiązującym prawem. Wyrażacie niniejszym zgodę na
								otrzymywanie wiadomości przesyłanych pocztą elektroniczną,
								wiadomości tekstowych oraz innych powiadomień generowanych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, w szczególności podczas początkowego procesu rejestracji.
								Możecie Państwo odwołać udzieloną zgodę w całości lub częściowo
								w dowolnym terminie w odniesieniu do
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, ale w takim przypadku jakiekolwiek dalsze korzystanie z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								może okazać się niemożliwe lub skutkować ograniczoną
								funkcjonalnością.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">10.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Korzystanie z</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								oraz jej aplikacji jest dozwolone wyłącznie i wyraźnie w celach
								określonych w § 2 podpunkt 2. Korzystanie z platformy
								wykraczające poza ten zakres jest zabronione. Zabrania się
								używania danych kontaktowych innych użytkowników, które są
								dostępne na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>{' '}
							<span className="text-gray-90">
								w celu innym niż komunikacja handlowa Klientów; takie dane
								kontaktowe mogą być używane wyłącznie w zakresie wskazanym w
								udzielonej zgodzie lub w sposób dozwolony zgodnie z przepisami
								prawa.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">11.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Zakłócanie funkcjonowania</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jest zabronione. W szczególności zabrania się podejmowania
								działań, które mogą doprowadzić do nadmiernego obciążenia
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								(np. poprzez przesyłanie powiadomień lub wiadomości masowych,
								przesyłanie niechcianych wiadomości (spam) lub ataki obejmujące
								odmowę usług) lub do nieuzasadnionego zaangażowania uwagi innych
								użytkowników.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">12.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Ataki elektroniczne na</span>{' '}
							<span className="text-gray-90 font-bold">Platformę SYPAC</span>{' '}
							<span className="text-gray-90">
								(włączając sprzęt oraz oprogramowanie używane do zapewnienia jej
								funkcjonowania) lub wobec indywidualnych użytkowników jest
								zabronione. Takie elektroniczne ataki obejmują, ale nie
								ograniczają się do poniżej wskazanych działań:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										ataki hakerskie, tj. próby przejęcia, obejścia lub innego
										uniemożliwienia funkcjonowania mechanizmów bezpieczeństwa
									</span>{' '}
									<span className="text-gray-90 font-bold">
										Platformy SYPAC;
									</span>{' '}
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										używanie lub przesyłanie wirusów, robaków, koni trojańskich
										oraz innych groźnych plików lub plików zawierających
										niebezpieczne kody;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										ataki typu “brute force", "reverse engineering" lub "data
										mining";
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										inne środki lub procedury lub zastosowanie programu exploit,
										które mogą zakłócić funkcjonowanie Platformy SYPAC, w tym
										również sprzętu oraz oprogramowania używanego do zapewnienia
										jego funkcjonowania, lub działać ze szkodą wobec SYPAC oraz
										dowolnego użytkownika.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 7. Dostępność i funkcjonowanie Platformy FALL
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zapewnia, w wykonaniu przyjętych przez niego zobowiązań, średnią
								roczną dostępność
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								na poziomie 99,99%. Co do zasady usługi
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								są świadczone od poniedziałku do niedzieli, w godzinach od 00:00
								do 24:00 każdego dnia (24 godzin dziennie, 7 dni w tygodniu).
								Wyliczenie dostępności nie obejmuje zaplanowanych okresów przerw
								na serwisowanie systemu, które przypadają na dni tygodnia w
								godzinach od 2:00 do 4:00 rano i przez cały dzień w niedziele i
								dni ustawowo wolne od prac.
							</span>{' '}
							<span className="text-gray-90 font-bold">Platforma SYPAC</span>{' '}
							<span className="text-gray-90">
								jest obsługiwana przez jeden lub kilka serwerów. Punktem
								przeniesienia obsługi jest połączenie internetowe korzystające
								ze stosownego centrum danych. Macie Państwo obowiązek zapewnić
								własne połączenie z internetem, zapewnić lub utrzymać połączenie
								sieciowe ze stosownym centrum danych, oraz uzyskać i zapewnić
								sprzęt zapewniający dostęp do sieci, w celu umożliwienia
								korzystania z internetu po stronie klienta platformy. Dostęp do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">, zapewniany przez</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								bez naliczenia z tego tytułu dodatkowych kosztów, odbywa się za
								pośrednictwem wyszukiwarki lub z użyciem aplikacji
								zaprojektowanej do pobrania na smartfony lub tablety, z użyciem
								technologii szyfrowania, takiej jak SSL dla
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">. Korzystanie z</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								wymaga stabilnego połączenia z internetem. Powyższe ma w
								szczególności zastosowanie dla uzyskania dostępu do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								za pośrednictwem aplikacji lub urządzeń mobilnych. Korzystanie z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jest możliwe, wyłącznie w przypadku zapewnienia niezakłóconego
								połączenia z internetem.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Posiadacie Państwo prawo do korzystania z usług udostępnianych
								za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jedynie w granicach technicznych i operacyjnych możliwości
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC. SYPAC</span>{' '}
							<span className="text-gray-90">
								podejmuje wszelkie starania, aby zapewnić niezakłócone
								korzystanie z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								w zakresie technicznie możliwym. Jednakże awarie techniczne (np.
								przerwy w dostawie prądu, błędy w sprzęcie i oprogramowaniu,
								problemy techniczne w linkach używanych do przesyłania danych)
								mogą powodować tymczasowe ograniczenia lub przerwy w dostępnie
								do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">zapewni, aby</span>{' '}
							<span className="text-gray-90 font-bold">Platforma SYPAC</span>{' '}
							<span className="text-gray-90">
								działała wyłącznie w oparciu o systemy do przetwarzania danych w
								Rzeczypospolitej Polskiej, w innym Państwie Członkowskim Unii
								Europejskiej lub w innym państwie, które jest stroną Umowy o
								Ustanowieniu Europejskiego Obszaru Gospodarczego, a także aby
								dane Klienta były przechowywane bez wyjątku w Rzeczypospolitej
								Polskiej, w innym Państwie Członkowskim Unii Europejskiej lub w
								innym państwie, które jest stroną Umowy o Ustanowieniu
								Europejskiego Obszaru Gospodarczego. Jednakże
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								może dokonać zmiany wykonania Umowy w dowolnym terminie w
								granicach wyżej wskazanych państw, działając w tym zakresie
								według własnego uznania.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zapewnia, że dane poszczególnych stron umowy będą podlegać
								odrębnym uzgodnieniom dotyczących administrowania danymi, tak
								aby inni Klienci lub inne nieupoważnione osoby trzecie nie
								uzyskały dostępu do ich danych. Powyższe nie obejmuje danych
								innych użytkowników
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, takich jak dostawcy i spedytorzy, z których usług
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								korzysta w zakresie, jaki jest konieczny w celu dostarczenia
								produktów zamówionych przez Klienta.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								będzie systematycznie oraz przynajmniej raz dziennie, zgodnie z
								odpowiednim harmonogramem, wykonywać kopię zapasową danych
								przechowywanych na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, w szczególności danych na temat zamówień, dostaw, faktur, itd.
								w celu zapewnienia przywrócenia utraconych danych i informacji w
								ramach przywrócenia działania systemu w ciągu dnia. Zmiany w
								danych, zarówno celowe (np. wprowadzanie danych) jak i
								przypadkowe (np. usuwanie danych), dokonane pomiędzy okresami
								wykonania kopii zapasowych nie są zachowywane. Jednakże klient
								ponosi wyłączną odpowiedzialność za zgodność z terminami
								przechowywania zgodnie z powszechnie obowiązującym prawem.
								Długoterminowe przechowywanie zapasowych kopii danych w celach
								archiwizacyjnych nie stanowi część usług świadczonych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">za pośrednictwem</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								podejmuje starania, w celu nieprzerwanego rozwijania
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">. W związku z powyższym</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								jest upoważniony do dokonania zmian w interfejsach użytkownika,
								funkcjach, usługach oraz aplikacjach udostępnionych na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>{' '}
							<span className="text-gray-90">
								w dowolnym terminie, w szczególności w odniesieniu do układu
								oraz projektu, a także do przywracania ich dostępności. Taki
								nieprzerwany rozwój może obejmować lub nie ograniczenie lub
								nawet usunięcie określonej funkcjonalności częściowej w
								pod-domenach na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								. Jeżeli zmiana w usłudze przekładająca się na zadeklarowany
								stan
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								może mieć istotny wpływ na uzasadniony interes Klienta,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								powiadomi Klienta o takiej zmianie na przynajmniej trzy miesiące
								przed datą jej wdrożenia. W takich okolicznościach Klient może
								zgłosić sprzeciw do zmiany działania w terminie jednego miesiąca
								po dacie jej ogłoszenia. Sprzeciw powinien być zgłoszony w
								formie pisemnej. Jeżeli
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								nie zapewni Klientowi możliwości korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								we wcześniej udostępnianej formie bezpośrednio po takim
								zgłoszeniu, partner może wypowiedzieć umowę z zachowaniem
								procedury działania w nagłych wypadkach ze skutkiem
								natychmiastowym, w terminie dwóch tygodni od daty wejścia zmiany
								w danej usłudze w życie.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ze względu na charakter sprzedawanych towarów, odpowiedzialność
								gwarancyjna wobec Klientów prowadzących działalność gospodarczą
								(przedsiębiorców), którzy są Stronami umów zawartych z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								regulowanych postanowieniami niniejszych Warunków Ogólnych, jest
								wykluczona, ale
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								będzie zaangażowany w rozstrzyganie kwestii dotyczących
								gwarancji.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 8. Blokowanie dostępu</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								może zablokować dostęp Klienta lub dostęp innego użytkownika do
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, w całości lub częściowo, tymczasowo lub na stałe, w sytuacji
								gdy istnieje podejrzenie powstania naruszenia Warunków Ogólnych,
								innych warunków
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								lub obowiązujących przepisów prawa po stronie Klienta, lub
								jeżeli
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								posiada jakikolwiek inny prawnie uzasadniony interes w
								zablokowaniu dostępu. Podejmując decyzję o zablokowaniu
								dostępu,has any other legitimate interest in blocking access. In
								deciding whether to block access,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								w należyty sposób uwzględni uzasadniony prawnie interes Klienta
								oraz prawnie uzasadnione interesy Klienta.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli dostęp do Platformy zostanie tymczasowo lub stale
								zablokowany,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zablokuje stosowne uprawnienia dostępu oraz powiadomi o tym
								fakcie Klienta pocztą elektroniczną.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 9. Okres obowiązywania i rozwiązanie umowy{' '}
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Niniejsza Umowa dotycząca korzystania z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jest zawarta na czas nieoznaczony. Umowa może być wypowiedziana
								przez każdą ze stron z zachowaniem wypowiedzenia w wymiarze
								jednego (1) pełnego miesiąca ze skutkiem na koniec miesiąca.
								Klient może odstąpić od Umowy bez wypowiedzenia, poprzez
								zastosowanie opcji rezygnacji lub opcji usunięcia dostępnych na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC</span>
							<span className="text-gray-90">
								, co oznacza rozwiązanie umowy ze skutkiem natychmiastowym, pod
								warunkiem że taka opcja jest dostępna na
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformie SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Umowa może być wypowiedziana przez każdą ze stron, bez
								wypowiedzenia, z podaniem odpowiedniej przyczyny.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Wypowiedzenie winno przynajmniej zastrzegać udokumentowaną formę
								oświadczenia woli. W takim przypadku opcja wypowiedzenia umowy
								przez Klienta za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>{' '}
							<span className="text-gray-90">
								jest wykluczona, jeżeli została udostępniona.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 10. Odpowiedzialność SYPAC</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Odpowiedzialność</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								z tytułu szkód, bez względu na przyczynę ich powstania, w tym
								również zwłokę, niewłaściwe wykonanie zobowiązań, naruszenie
								umowy, naruszenie obowiązku do negocjowania umowy, oraz czyny
								deliktowe, jest ograniczona do odpowiedzialności za szkody
								spowodowane celowym niewłaściwym postępowaniem.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W odniesieniu do obrażeń ciała powstałych z winy
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, jego przedstawicieli prawnych lub agentów,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								ponosi również odpowiedzialność za szkody powstałe wskutek
								poważnego zaniedbania.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jakakolwiek dalsza odpowiedzialność po stronie
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								jest wykluczona, bez względu na charakter prawny zgłoszonego
								roszczenia. Powyższe ma zastosowanie w szczególności do roszczeń
								deliktowych. Jeżeli odpowiedzialność
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								została wykluczona lub ograniczona, takie wykluczenie lub
								ograniczenie obejmuje również odpowiedzialność osobistą
								pracowników, przedstawicieli lub agentów.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W zakresie nieuregulowanym zapisami niniejszego punktu,
								zastosowanie mają postanowienia polskiego kodeksu cywilnego, w
								szczególności dotyczące odpowiedzialności deliktowej i
								odpowiedzialności umownej.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 11. Ochrona danych</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się przestrzegać obowiązujących przepisów
								dotyczących danych osobowych, w szczególności obowiązujących w
								Rzeczypospolitej Polskiej lub w kraju zamieszkania osób, których
								dotyczą dane, oraz zobowiąże jego pracowników zaangażowanych we
								współpracę pomiędzy Klientem i Klientem, z jednej strony, i
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, z drugiej strony, do zachowania poufności danych, pod
								warunkiem że nie są już zobowiązani do podejmowania takich
								czynności zgodnie z ogólnymi regulacjami.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli w związku z korzystaniem z
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								, następuje pozyskiwanie, przetwarzanie lub używanie danych
								osobowych, macie Państwo obowiązek zapewnić i potwierdzić, że
								posiadacie prawo do podejmowania takich czynności zgodnie z
								obowiązującymi przepisami prawa, w szczególności regulacjami
								dotyczącymi ochrony danych, oraz chronić
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								i zwolnić go z odpowiedzialności przed roszczeniami zgłoszonymi
								przez osoby trzecie w przypadku zgłoszenia takiego naruszenia.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Szczegółowe informacje dotyczące zobowiązań i regulacji
								dotyczących ochrony danych są dostępne w stosownych
								obowiązujących w
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								regulacjach dotyczących ochrony danych, które stanowią część
								umowy (wymienione na wstępie niniejszych Warunków Ogólnych).
								Zgodnie z wymogami prawnymi, kwestie dotyczące ochrony danych,
								które wymagają uregulowania, są uwzględnione w niniejszych
								regulacjach.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 12. Postanowienia końcowe</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Prawa i obowiązki wynikające z niniejszej Umowy mogą być
								przeniesione na osoby trzecie wyłącznie z zastrzeżeniem wyraźnej
								pisemnej zgody
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								powiadomi Klienta o zmianach wprowadzonych do niniejszych
								Warunków Ogólnych w formie pisemnej, za pomocą poczty
								elektronicznej lub za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Platformy SYPAC</span>
							<span className="text-gray-90">
								. Jeżeli Klient nie zgłosi sprzeciwu wobec takich zmian w
								terminie sześciu tygodni od daty otrzymania stosownego
								powiadomienia, zmiany zostaną uznane za przyjęte i zatwierdzone.
								Klient zostanie osobno powiadomiony o prawie do zgłoszenia
								sprzeciwu i konsekwencjach prawnych braku reakcji na otrzymane
								informacje na temat zmian Warunków Ogólnych.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Wszelkie spory powstałe w związku z niniejszą Umową (w tym
								również dotyczące jej ważności) będą rozstrzygane w pierwszej
								kolejności wyłącznie przez sądy w Polsce właściwe dla siedziby
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Niniejsza Umowa jest regulowana wyłącznie przepisami prawa
								polskiego, z wykluczeniem postanowień konwencji Organizacji
								Narodów Zjednoczonych w sprawie umów międzynarodowej sprzedaży
								towarów z dnia 11 kwietnia 1980 r. (Konwencja ONZ w sprawie
								sprzedaży towarów).
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Całość prawnego stosunku umownego pomiędzy Klientem,
								reprezentowanym przez Klienta, a
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, wskutek dokonania przez Klienta rejestracji na Platformie
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, jest regulowana postanowieniami niniejszych Warunków Ogólnych,
								w tym również Warunków Kupna, Warunków Świadczenia Usług oraz
								Polityką Prywatności i Ochrony Danych Osobowych.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditionsCustomerPl;
