const PaidBadge = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<rect width="40" height="40" fill="#20AC93" rx="8"></rect>
			<path
				fill="#fff"
				d="M18.074 12.96c.49-.417.734-.626.99-.748a2.17 2.17 0 011.872 0c.256.123.5.331.99.748.195.166.292.25.396.319.239.16.507.27.788.326.123.025.25.035.506.055.641.051.962.077 1.229.171a2.17 2.17 0 011.324 1.324c.094.267.12.588.17 1.23.021.254.031.382.056.505.055.281.166.55.326.788.07.104.153.201.319.396.417.49.626.734.748.99a2.17 2.17 0 010 1.872c-.122.256-.331.5-.748.99-.166.195-.25.292-.319.396a2.17 2.17 0 00-.326.788c-.025.123-.035.25-.055.506-.051.641-.077.962-.171 1.229a2.17 2.17 0 01-1.324 1.324c-.267.094-.588.12-1.23.17-.254.021-.382.031-.505.056a2.17 2.17 0 00-.788.326c-.104.07-.201.153-.396.319-.49.417-.734.626-.99.748a2.17 2.17 0 01-1.872 0c-.256-.122-.5-.331-.99-.748-.195-.166-.292-.25-.396-.319a2.17 2.17 0 00-.788-.326c-.123-.025-.25-.035-.506-.055-.641-.051-.962-.077-1.229-.171a2.17 2.17 0 01-1.324-1.324c-.094-.267-.12-.588-.17-1.23-.021-.254-.031-.382-.056-.505a2.17 2.17 0 00-.326-.788c-.07-.104-.153-.201-.319-.396-.417-.49-.626-.734-.748-.99a2.17 2.17 0 010-1.872c.123-.256.331-.5.748-.99.166-.195.25-.292.319-.396.16-.239.27-.507.326-.788.025-.123.035-.25.055-.506.051-.641.077-.962.171-1.229a2.17 2.17 0 011.324-1.324c.267-.094.588-.12 1.23-.17.254-.021.382-.031.505-.056a2.17 2.17 0 00.788-.326c.104-.07.201-.153.396-.319z"
			></path>
			<path
				stroke="#20AC93"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M16.96 20.433l1.737 1.737 4.34-4.34"
			></path>
		</svg>
	);
};

export default PaidBadge;
