export const Billing = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="10"
			fill="none"
			viewBox="0 0 13 10"
		>
			<path
				stroke="#20AC93"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M.898 5l3.92 4 7.28-8"
			></path>
		</svg>
	);
};
