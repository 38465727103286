import { T } from '@tolgee/react';
interface Props {
	value: number;
}

export const PaymentPeriod = ({ value }: Props) => {
	const thresholdsMap: Record<number, string> = {
		0: '0',
		5: '5',
		7: '7',
		10: '10',
		14: '14',
		30: '30',
		60: '60',
		90: '90',
		120: '120',
		180: '180',
		365: '365',
	};

	return (
		<span>
			{thresholdsMap[value] || '30'} <T keyName="paymentPeriod.days">days</T>
		</span>
	);
};
