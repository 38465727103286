import { AxiosInstance } from 'axios';
import api from './api';

export default class ApiFacade {
	public client: AxiosInstance;

	constructor(apiUrl: string, token: string) {
		this.client = api;
	}
}
