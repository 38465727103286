import React from 'react';
import './side-bar-menu-item.css';
import { SideBarMenuItemInterface } from './SideBarMenuItem.interface';
import { SypacIcon } from '@sypac/component-library-react';

export const SideBarMenuItem = ({
	active = false,
	url,
	logo,
	alt,
	count,
}: SideBarMenuItemInterface) => {
	const scaleUpIcons = ['Document Add', 'Money Bag', 'Shield Check'];

	return (
		<a href={url} className={`group side-bar-item ${active ? 'active' : ''}`}>
			{alt && (
				<div className="hidden group-hover:block side-bar-alt">
					<p className="relative m-0 side-bar-alt-txt whitespace-nowrap">
						{alt}
					</p>
				</div>
			)}
			<SypacIcon
				icon-name={logo}
				size="custom"
				width="36px"
				height="36px"
				className={`${scaleUpIcons.includes(logo) ? 'xl-2xl:scale-[1.4]' : ''}`}
			/>
			{count ? (
				<div className="ml-auto w-5 h-5 flex flex-row justify-center items-center bg-red rounded-[100px] text-xs font-medium text-gray-1 border-2 border-gray-1 border-solid absolute -top-3 -right-3">
					{count > 9 ? '9+' : count}
				</div>
			) : null}
		</a>
	);
};
