import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import { CategoriesResponse } from '../interfaces/category.interface';

type UseGetCategories = [
	CategoriesResponse | undefined,
	Error | undefined,
	boolean,
];

export interface UseGetProductsQuery {
	parentIds?: string[];
	ids?: string[];
	limit?: number;
	offset?: number;
	coordinates?: number[];
}

export const useGetCategories = (
	params: UseGetProductsQuery,
	role = 'customer',
): UseGetCategories => {
	const facade = useApiFacade();
	const [data, setData] = useState<CategoriesResponse>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);
	const language = localStorage.getItem('lang') || 'pl';

	const finishLoading = () => {
		setTimeout(() => {
			setIsLoading(false);
		}, 200);
	};

	useEffect(() => {
		const {
			parentIds = [],
			ids = [],
			limit = 100,
			offset = 0,
			coordinates,
		} = params;
		if (coordinates && coordinates.length === 0) {
			setData({ count: 0, items: [] });
			return;
		}
		setIsLoading(true);
		let query: any = {
			limit,
			offset,
			parentIds,
			ids: ids,
			language,
		};
		if (coordinates?.length) {
			query = {
				...query,
				coordinates,
				range: 100,
			};
		}
		facade.client
			.get(`/v1/${role}/categories`, {
				params: query,
			})
			.then((response) => {
				setData(response.data);
				finishLoading();
			})
			.catch((err) => {
				setError(err);
				finishLoading();
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(params), role, facade]);

	return [data, error, isLoading];
};
