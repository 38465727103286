import {
	SypacBox,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import React, { useContext, useMemo, useState } from 'react';
import { Payment, PaymentsProps } from './Payments.interface';
import { OrdersService } from '../../services/orders.services';
import { OrderContext } from '../../context/OrderContext/order.context';
import { QuantityPickerContext } from '../../context/QuantityPickerContext/quantity-picker.context';
import { GeoService } from '../../services/geo.services';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import classNames from 'classnames';
import { T, useTranslate } from '@tolgee/react';
import PaymentCard from '../../assets/PaymentCard';
import BankTransfer from '../../assets/BankTransfer';
import { useNavigate } from 'react-router-dom';
import ClientOrderSummary from '../ClientOrderSummary/ClientOrderSummary';
import LoadingSpinner from '../../assets/LoadingSpinner';

const Payments = (props: PaymentsProps) => {
	const { t } = useTranslate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paymentMethod, setPaymentMethod] = useState<string>('bank');
	const [activeButton, setActiveButton] = useState<number>(1);
	const { onSuccess, currentProduct, prices, quantity } = props;
	const { order } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);
	const navigate = useNavigate();

	const address = useMemo(
		() =>
			order?.location
				? [
						[
							order?.location?.address?.street,
							order?.location?.address?.houseNumber,
						].join(' ') || '',
						order?.location.address?.postalCode || '',
						order?.location.address?.city || '',
						order?.location.address?.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '',
		[order?.location],
	);

	const callPayment = async (paymentMethod: string) => {
		setIsLoading(true);
		try {
			let latDelivery = 0;
			let longDelivery = 0;
			let deliveryCountryCode = '';
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
				deliveryCountryCode = resp?.data?.Country;
			}
			const createOrderBody: any = {
				paymentMethod: paymentMethod,
				productId: order?.product?.id!,
				latPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[1]
					: 47.04014,
				longPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[0]
					: 28.80058,
				pickupAddress: order?.product?.address,
				pickupCountryCode: order?.product?.countryCode,
				latDelivery,
				longDelivery,
				deliveryAddress: address,
				deliveryCountryCode,
				locationPhoto: order?.locationPhoto?.replace('dev/', ''),
				language: localStorage.getItem('lang') || 'pl',
				trucks: order?.trucks,
				deliveryIncluded:
					typeof order?.deliveryIncluded === 'boolean'
						? order?.deliveryIncluded
						: true,
			};

			const { data } = await OrdersService.createOrder(createOrderBody);
			setInputQuantity(0);
			setTotalQuantityCounter([]);
			onSuccess(data.id);
		} catch (e: any) {
			if (e?.response?.status === 402) {
				return toastVariant(
					t(
						`payments.youHaveInsufficientFounds`,
						'You have insufficient founds or overdue payments. Please check you billing details',
					),
					true,
				);
			}
			const message = e?.response?.data?.message || e?.toString();
			return toastVariant(t(`payments.${message}`, message), true);
		} finally {
			setIsLoading(false);
		}
	};

	const PAYMENTS = [
		{
			icon: BankTransfer,
			title: t('payments.bankTransfer', 'Bank transfer'),
			description: t(
				'payments.bankTransferDescription',
				'Lorem ipsum dolor sit amet, consectetur.',
			),
			paymentMethod: 'bank',
			disabled: false,
		},
		{
			icon: PaymentCard,
			title: t('payments.directDebit', 'Direct debit'),
			description: t(
				'payments.directDebitDescription',
				'Lorem ipsum dolor sit amet, consectetur.',
			),
			paymentMethod: 'card',
			disabled: true,
		},
	];

	const handleClick = (value: string, id: number) => {
		if (activeButton === id) {
			setPaymentMethod('');
			setActiveButton(0);
		} else {
			setPaymentMethod(value);
			setActiveButton(id);
		}
	};

	const renderPayment = (payment: Payment, index: number) => {
		const isDisabled = payment.disabled || isLoading;
		return (
			<SypacBox
				key={index + 1}
				hover={!isDisabled}
				onClick={() =>
					isDisabled ? null : handleClick(payment.paymentMethod, index + 1)
				}
				className={classNames('h-[96px] transition', {
					'cursor-auto opacity-60': isDisabled,
					'border-kimberly': activeButton === index + 1,
				})}
			>
				<div className="flex gap-2.5 px-[20px] py-[25px]">
					<span className="scale-[0.8]">
						<payment.icon />
					</span>
					<div className="flex flex-col gap-[8px]">
						{payment.title && (
							<>
								<SypacText variant="body-regular-small">
									<p className="text-xl text-gray-90">{payment.title}</p>
								</SypacText>
								<SypacText variant="body-regular-small">
									<p className="text-gray-40">{payment.description}</p>
								</SypacText>
							</>
						)}
					</div>
				</div>
			</SypacBox>
		);
	};

	return (
		<>
			<div className="max-w-[652px] w-full flex flex-col gap-6 sm:gap-0 justify-between">
				<div className="w-[calc(100vw-26px)] sm:w-full flex flex-col gap-6 ml-[14px] sm:ml-0">
					{PAYMENTS.map((payment, index) => renderPayment(payment, index))}
				</div>
				<div className="hidden sm:flex gap-5 justify-center">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => {
							navigate(-1);
						}}
						className="w-full"
					>
						<button
							className="w-full flex flex-row gap-2 px-6 py-[13px] rounded-lg transition"
							type="reset"
						>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Left"
							/>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full border-none bg-primary-violet transition 
							${
								!activeButton || isLoading
									? 'bg-primary-violet/80'
									: 'hover:bg-primary-violet/70'
							}`}
							disabled={!activeButton}
							onClick={() => callPayment(paymentMethod!)}
						>
							<SypacText
								variant="body-normal-medium"
								className="flex items-center gap-4"
							>
								<p className="text-white mt-[3px]">
									<T keyName="clientSelectQuantity.placeOrder">Place order</T>
								</p>
								{isLoading ? <LoadingSpinner /> : null}
							</SypacText>
						</button>
					</SypacButton>
				</div>

				<div className="inline-table sm:w-[418px] xl:w-[460px] flex-shrink-0 sm:hidden">
					<ClientOrderSummary
						currentProduct={currentProduct}
						prices={prices!}
						quantity={quantity!}
						step={3}
					/>
				</div>

				<div className="w-[calc(100vw-26px)] flex flex-col sm:hidden gap-5 justify-center ml-[14px]">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => {
							navigate(-1);
						}}
						className="w-full"
					>
						<button
							className="w-full flex flex-row gap-2 px-6 py-[13px] rounded-lg transition"
							type="reset"
						>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Left"
							/>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full border-none bg-primary-violet transition 
							${
								!activeButton || isLoading
									? 'bg-primary-violet/80'
									: 'hover:bg-primary-violet/70'
							}`}
							disabled={!activeButton}
							onClick={() => callPayment(paymentMethod!)}
						>
							<SypacText
								variant="body-normal-medium"
								className="flex items-center gap-4"
							>
								<p className="text-white mt-[3px]">
									<T keyName="clientSelectQuantity.placeOrder">Place order</T>
								</p>
								{isLoading ? <LoadingSpinner /> : null}
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</>
	);
};

export default Payments;
