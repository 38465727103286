export const HashtagBox = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.20078 5L5.80078 13"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2008 5L9.80078 13"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.8 7.40039H5"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.9992 10.5996H4.19922"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 9C1 5.22876 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1C12.7712 1 14.6569 1 15.8284 2.17157C17 3.34315 17 5.22876 17 9C17 12.7712 17 14.6569 15.8284 15.8284C14.6569 17 12.7712 17 9 17C5.22876 17 3.34315 17 2.17157 15.8284C1 14.6569 1 12.7712 1 9Z"
				stroke="#A2A2A2"
			/>
		</svg>
	);
};
