import React from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import './modal-delete-document.css';
import { ModalDeleteDocumentProps } from './ModalDeleteDocument.interface';
import DeleteDocument from '../../assets/DeleteDocument';
import { T } from '@tolgee/react';

const ModalDeleteDocument = ({
	isOpen,
	onClose,
	onSubmit,
}: ModalDeleteDocumentProps) => {
	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[475px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="modalDeleteDocument.confirmDelete">Confirm delete</T>
						</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<div className="flex flex-col items-start justify-center modal-delete-document-border px-6 py-6 gap-6">
					<DeleteDocument />
					<SypacText variant="heading-5" className="pt-4">
						<p className="text-gray-80">
							<T keyName="modalDeleteDocument.deleteDocument">
								Delete Document
							</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-gray-40 text-left">
							<T keyName="modalDeleteDocument.sureDeleteDocument">
								Are you sure you want to delete this document from the list?
							</T>
						</p>
					</SypacText>
				</div>
				<div className="grid grid-cols-2 gap-4 justify-center p-6">
					<SypacButton variant="secondary" size="small" onClick={onClose}>
						<button className="modal-delete-document-reject-button">
							<SypacText variant="body-regular-medium">
								<p className="text-red -tracking-[0.01em]">
									<T keyName="modalDeleteDocument.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" onClick={onSubmit}>
						<button className="modal-delete-document-confirm-button">
							<SypacText variant="body-regular-medium">
								<p className="text-mountain-meadow -tracking-[0.01em]">
									<T keyName="modalDeleteDocument.confirm">Confirm</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteDocument;
