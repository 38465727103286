import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacAvatar,
	SypacBadge,
	SypacBox,
	SypacButton,
	SypacCheckbox,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';

import { UserCardProps } from './UserCard.interface';
import './user-card.css';
import {
	colorsTarget,
	titlesTarget,
} from '../../constants/company-target.constants';
import { Viber } from '../../assets/Viber';
import { WhatsUp } from '../../assets/WhatsUp';
import { Telegram } from '../../assets/Telegram';
import { T, useTranslate } from '@tolgee/react';

const UserCard = (props: UserCardProps) => {
	const { t } = useTranslate();
	const { isOpen, data, saveProfile } = props;

	const [open, setOpen] = useState(isOpen);
	const [readOnly, setReadOnly] = useState(true);

	const handleClick = () => {
		setOpen(!open);
	};

	const formik = useFormik({
		initialValues: {
			firstName: data.firstName,
			lastName: data.lastName,
			role: data.role,
			primaryPhoneNumber: data.primaryPhoneNumber,
			primaryIsViber: data.primaryIsViber,
			primaryIsTelegram: data.primaryIsTelegram,
			primaryIsWhatsUp: data.primaryIsWhatsUp,
			secondaryIsViber: data.secondaryIsViber,
			secondaryIsTelegram: data.secondaryIsTelegram,
			secondaryIsWhatsUp: data.secondaryIsWhatsUp,
			secondaryPhoneNumber: data.secondaryPhoneNumber,
			primaryEmail: data.primaryEmail,
			secondaryEmail: data.secondaryEmail,
		},
		onSubmit: (values) => {
			saveProfile({
				uid: data.uid,
				...values,
				secondaryEmail: values.secondaryEmail || undefined,
			});
			setReadOnly(true);
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required('First name number is required'),
			lastName: Yup.string().required('Last name is required'),
			primaryEmail: Yup.string()
				.email('Email address must be an email')
				.required('Email is required'),
			primaryPhoneNumber: Yup.string().required('Phone number is required'),
			secondaryEmail: Yup.string().email(
				'Secondary email address must be an email',
			),
		}),
	});

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<SypacBox
			hover={false}
			box-style="solid"
			box-color="primary"
			boxShadow="sm"
			className={classNames('p-2.5 bg-white', { '-mb-[34px] z-10': open })}
		>
			<div
				className="flex justify-between cursor-pointer"
				onClick={handleClick}
			>
				<div className="flex gap-5 ">
					<div className="flex gap-2.5  items-center">
						<SypacAvatar initials={data.fullName} size="sm">
							{data.avatarUrl ? (
								<img src={data.avatarUrl} alt={data.fullName} />
							) : null}
						</SypacAvatar>
						<SypacText variant="body-regular-small">
							<p className="text-gray-90">{data.fullName}</p>
						</SypacText>
					</div>
					<div className="flex items-center">
						<SypacBadge color={colorsTarget[data.role]} size="large">
							<div className="px-4">
								<SypacText variant="overline-normal-large">
									<p>{titlesTarget[data.role]}</p>
								</SypacText>
							</div>
						</SypacBadge>
					</div>
				</div>
				<div className={classNames('flex items-center', { 'hidden': open })}>
					<SypacText variant="body-regular-small">
						<p className="text-gray-40">
							<T keyName="userCard.lastOnline">Last Online</T>{' '}
							{data.lastOnline || ''}
						</p>
					</SypacText>
				</div>
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className={`${open ? 'user-card-show' : 'user-card-hide'}`}>
					<div className="flex justify-between items-center user-card-border py-5 mt-2.5">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90 -tracking-[0.01em]">
								<T keyName="userCard.generalCompanyInformation">
									General company information
								</T>
							</p>
						</SypacText>
						{readOnly ? (
							<SypacButton variant="secondary" size="small">
								<button
									className="px-5 py-[5px]"
									onClick={() => setReadOnly(false)}
								>
									<SypacIcon
										iconName="Document Add"
										size="custom"
										width="24px"
										height="24px"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="userCard.edit">Edit</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						) : (
							<div className="flex gap-4">
								<SypacButton variant="secondary" size="small">
									<button
										className="border-red text-red px-5 py-[5px]"
										onClick={() => setReadOnly(true)}
									>
										<SypacIcon
											iconName="Close Circle"
											size="custom"
											width="24px"
											height="24px"
										/>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="userCard.cancel">Cancel</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="secondary" size="small">
									<button
										type="submit"
										className="border-mountain-meadow text-mountain-meadow px-5 py-[5px]"
									>
										<SypacIcon
											iconName="Check Circle"
											size="custom"
											width="24px"
											height="24px"
										/>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="userCard.save">Save</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						)}
					</div>
					<div className="grid grid-cols-3 gap-4 mt-6">
						<SypacInput
							error={!!(formik.touched.firstName && formik.errors.firstName)}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userCard.firstName">First Name</T>
							</label>
							<input
								readOnly={readOnly}
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="firstName"
								type="text"
								placeholder={t('userCard.enterFirstName', 'Enter First Name')}
								value={formik?.values.firstName}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
						<SypacInput
							error={!!(formik.touched.lastName && formik.errors.lastName)}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userCard.lastName">Last Name</T>
							</label>
							<input
								readOnly={readOnly}
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="lastName"
								type="text"
								placeholder={t('userCard.enterLastName', 'Enter Last Name')}
								value={formik?.values.lastName}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
						<SypacInput error={!!(formik.touched.role && formik.errors.role)}>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userCard.role">Role</T>
							</label>
							<input
								readOnly={readOnly}
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="role"
								type="text"
								placeholder={t('userCard.enterRole', 'Enter Role')}
								value={formik?.values.role}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
					</div>
					<div className="grid grid-cols-2 gap-4 mt-5">
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.primaryPhoneNumber &&
										formik.errors.primaryPhoneNumber
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="userCard.primaryPhoneNumber">
										Primary phone number
									</T>
								</label>
								<input
									readOnly={readOnly}
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="primaryPhoneNumber"
									type="text"
									placeholder={t(
										'userCard.enterPrimaryPhoneNumber',
										'Enter Primary Phone Number',
									)}
									value={formik?.values.primaryPhoneNumber}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="primaryIsWhatsUp"
												checked={formik?.values.primaryIsWhatsUp}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.primaryIsWhatsUp || !readOnly ? (
										<WhatsUp />
									) : null}
								</div>
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="primaryIsTelegram"
												checked={formik?.values.primaryIsTelegram}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.primaryIsTelegram || !readOnly ? (
										<Telegram />
									) : null}
								</div>
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="primaryIsViber"
												checked={formik?.values.primaryIsViber}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.primaryIsViber || !readOnly ? (
										<Viber />
									) : null}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.secondaryPhoneNumber &&
										formik.errors.secondaryPhoneNumber
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="userCard.secondaryPhoneNumber">
										Secondary phone number
									</T>
								</label>
								<input
									readOnly={readOnly}
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="secondaryPhoneNumber"
									type="text"
									placeholder={t(
										'userCard.enterSecondaryPhoneNumber',
										'Enter Secondary Phone Number',
									)}
									value={formik?.values.secondaryPhoneNumber}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="secondaryIsWhatsUp"
												checked={formik?.values.secondaryIsWhatsUp}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.secondaryIsWhatsUp || !readOnly ? (
										<WhatsUp />
									) : null}
								</div>
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="secondaryIsTelegram"
												checked={formik?.values.secondaryIsTelegram}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.secondaryIsTelegram || !readOnly ? (
										<Telegram />
									) : null}
								</div>
								<div className="flex gap-1.5">
									{!readOnly ? (
										<SypacCheckbox size="md">
											<input
												name="secondaryIsViber"
												checked={formik?.values.secondaryIsViber}
												onChange={(event) => formik?.handleChange(event)}
												type="checkbox"
											/>
										</SypacCheckbox>
									) : null}
									{formik?.values.secondaryIsViber || !readOnly ? (
										<Viber />
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-4 mt-5">
						<SypacInput
							error={
								!!(formik.touched.primaryEmail && formik.errors.primaryEmail)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userCard.primaryEmailAddress">
									Primary email address
								</T>
							</label>
							<input
								readOnly={readOnly}
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="primaryEmail"
								type="text"
								placeholder={t(
									'userCard.enterCompanyName',
									'Enter Primary Email',
								)}
								value={formik?.values.primaryEmail}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
						<SypacInput
							error={
								!!(
									formik.touched.secondaryEmail && formik.errors.secondaryEmail
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userCard.secondaryEmailAddress">
									Secondary email address
								</T>
							</label>
							<input
								readOnly={readOnly}
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="secondaryEmail"
								type="text"
								placeholder={t(
									'userCard.enterSecondaryEmail',
									'Enter Secondary Email',
								)}
								value={formik?.values.secondaryEmail}
								onChange={(event) => formik?.handleChange(event)}
							/>
							{formik.touched.secondaryEmail && formik.errors.secondaryEmail ? (
								<span className="input-error">
									{formik.errors.secondaryEmail}
								</span>
							) : null}
						</SypacInput>
					</div>
				</div>
			</form>
		</SypacBox>
	);
};

export default UserCard;
