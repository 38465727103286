import React from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { AssignedToProps } from './AssignedTo.interface';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';

const AssignedTo = ({
	notification,
	title,
	description,
	avatar,
	username,
}: AssignedToProps) => {
	return (
		<BoxComponent notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<div className="flex flex-row gap-3 items-center">
					<SypacAvatar size="sm" initials={username}>
						{avatar ? <img src={avatar} alt={username} /> : null}
					</SypacAvatar>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{username}</p>
					</SypacText>
				</div>
				{description ? (
					<SypacText variant="overline-regular-large">
						<p className="text-gray-80 leading-3">{description}</p>
					</SypacText>
				) : null}
			</div>
		</BoxComponent>
	);
};

export default AssignedTo;
