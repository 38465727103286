import React, { useCallback, useEffect, useState } from 'react';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import BillingTabs from '../../../../components/BillingTabs/BillingTabs';
import AvatarDropDown from '../../../../components/AvatarDropDown/AvatarDropDown';
import { Sorting } from '../../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../../components/Sorting/Sorting.interface';
import { BillingService } from '../../../../services/billing.service';
import { PaymentsTable } from '../../../../components/PaymentsTable/PaymentsTable';
import { InvoiceInterface } from '../../../../components/PaymentsTable/interfaces/Payment.interface';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../../components/Pagination/Pagination';
import { LIMIT } from '../../../../constants';
import { AvatarItem } from '../../../../components/AvatarDropDown/AvatarDropDown.interface';
import ModalConfirmPayment from '../../../../components/ModalConfirmPayment/ModalConfirmPayment';
import { Order, OrdersService } from '../../../../services/orders.services';
import { ConfirmPaymentProps } from '../../../../components/ModalConfirmPayment/ModalConfirmPayment.interface';
import ModalSuccessPayment from '../../../../components/ModalSuccessPayment/ModalSuccessPayment';
import ModalConfirmPaymentStatus from '../../../../components/ModalConfirmPayment/ModalConfirmPaymentStatus';
import { useTranslate } from '@tolgee/react';

interface Props {
	target?: string;
}

const ProducerAndTransporterBilling: React.FC<Props> = ({
	target = 'producer',
}: Props) => {
	const { t } = useTranslate();
	const sortOptions = [
		{
			title: 'Sort by date descending',
			value: 'invoiceDate DESC',
		},
		{
			title: 'Sort by date ascending',
			value: 'invoiceDate ASC',
		},
		{
			title: 'Sort by price ascending',
			value: 'totalAmount ASC',
		},
		{
			title: 'Sort by price descending',
			value: 'totalAmount DESC',
		},
	];
	const [searchQuery, setSearchQuery] = useState<string>();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [invoices, setInvoices] = useState<InvoiceInterface[]>([]);
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [assigneeId, setAssignee] = useState<string>();
	const [showPay, setShowPay] = useState<boolean>(false);
	const [showPaySuccess, setShowPaySuccess] = useState<boolean>(false);
	const [showPrevSuccess, setShowPrevSuccess] = useState<boolean>(false);
	const [selectedInvoice, setInvoice] = useState<InvoiceInterface>();
	const [payment, setPayment] = useState<ConfirmPaymentProps>();
	const [tabs, setTabs] = useState([
		{ label: 'All', color: 'white', count: '0', value: 'all' },
		{ label: 'Pending payment', color: 'yellow', count: '0', value: 'pending' },
		{ label: 'Payment overdue', color: 'red', count: '0', value: 'overdue' },
		{ label: 'Paid', color: 'green', count: '0', value: 'paid' },
	]);
	const [page, setPage] = useState<number>(0);

	const selectTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};
	const getStatistics = useCallback(async () => {
		try {
			const { data } = await BillingService.getInvoiceStatsAdmin(target);
			setTabs([
				{ label: 'All', color: 'white', count: data?.count?.all, value: 'all' },
				{
					label: 'Pending payment',
					color: 'yellow',
					count: data?.count?.pending,
					value: 'pending',
				},
				{
					label: 'Payment overdue',
					color: 'red',
					count: data?.count?.overdue,
					value: 'overdue',
				},
				{
					label: 'Paid',
					color: 'green',
					count: data?.count?.paid,
					value: 'paid',
				},
			]);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}, [target]);

	const getInvoiceList = useCallback(async () => {
		try {
			setLoading(true);
			const sort = sorting?.value.split(' ');
			const sortDirection = sort?.at(1);
			const sortBy = sort?.at(0);
			const { data } = await BillingService.getInvoiceListAdmin(target, {
				orderId: searchQuery ? parseInt(searchQuery) : undefined,
				statuses: [currentTab],
				limit: LIMIT,
				offset: page * LIMIT,
				sortDirection,
				sortBy,
				assigneeId,
			});

			if (!assigneeId) {
				const lines =
					data.items?.map((invoice) => invoice?.invoiceLines?.at(0)!) || [];

				const avatarsMap: Record<string, string> = {};
				lines?.forEach((line) => {
					avatarsMap[line?.assigneeUser?.uid] = line?.assigneeUser?.name;
				});
				const avatars: AvatarItem[] = Object.keys(avatarsMap)
					.map((key) => {
						return {
							id: key,
							fullName: avatarsMap[key],
						};
					})
					.filter((r) => r.fullName);
				setAvatars(avatars);
			}
			setInvoices(data.items);
			setCount(data.count);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [assigneeId, currentTab, page, searchQuery, sorting?.value, target]);

	const changeAssignee = (uid: string) => {
		const assignee = assigneeId === uid ? undefined : uid;
		setAssignee(assignee);
	};

	useEffect(() => {
		getStatistics();
		getInvoiceList();
	}, [getInvoiceList, getStatistics]);

	const onPay = async (value: InvoiceInterface | number) => {
		const invoice = value as InvoiceInterface;
		setPayment(undefined);
		const orderIds = invoice?.invoiceLines?.map((line) => line.orderId);
		if (orderIds?.length) {
			const orders = await OrdersService.getOrders({ ids: orderIds }, 'admin');
			const ordersMap: Record<number, Order> = {};
			orders.data?.items?.forEach((order) => {
				ordersMap[order.id] = order;
			});
			invoice.invoiceLines = invoice.invoiceLines?.map((line) => {
				line.order = ordersMap[line.orderId];
				return line;
			});
		}
		setInvoice(invoice);
		setShowPay(true);
	};

	const confirmPrevPayment = (values: ConfirmPaymentProps) => {
		setPayment(values);
		setShowPay(false);
		setShowPrevSuccess(true);
	};

	const confirmPayment = async () => {
		try {
			if (selectedInvoice?.id && payment) {
				await BillingService.acceptInvoice(target, selectedInvoice.id, {
					paymentAmount: parseFloat(payment?.amount)!,
					paymentDate: payment?.date!,
				});
				setShowPrevSuccess(false);
				setShowPaySuccess(true);
				setPayment(undefined);
				getStatistics();
				getInvoiceList();
			}
		} catch (e) {}
	};

	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex flex-row justify-between w-full">
				<div className="flex gap-7.5 w-full md:flex-col xl:flex-row">
					<div className="flex w-full max-w-[440px]">
						<SearchBar
							placeholder={t(
								'producerAndTransporterBilling.searchOrder',
								'Search order',
							)}
							onClick={setSearchQuery}
							classes={'w-full'}
						/>
					</div>
					<div className="flex w-full max-w-[540px]">
						<BillingTabs
							data={tabs}
							callback={selectTab}
							activeTab={currentTab}
						/>
					</div>
				</div>
				<div className="flex gap-7.5 flex-row  md:flex-col lg:flex-row">
					{avatars.length ? (
						<div className="flex flex-row w-fit">
							<AvatarDropDown items={avatars} onChange={changeAssignee} />
						</div>
					) : null}
					<Sorting options={sortOptions} action={(item) => setSorting(item)} />
				</div>
			</div>
			<div className="mt-8 h-full">
				{loading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="overflow-y-auto p-px h-[calc(100vh-250px)]">
						<PaymentsTable
							rows={invoices}
							rowClick={() => {}}
							target={'admin_producer'}
							aditionalClick={onPay}
						/>
					</div>
				)}
				<div className="flex justify-between bg-white-100 absolute bottom-8 py-4 px-7 rounded-br-10 rounded-bl-10 border border-gray-10 border-solid w-[calc(100vw-237px)] shadow-pagination">
					<Pagination
						showText={true}
						count={count}
						page={page}
						onClick={(item: any) => setPage(item)}
					/>
				</div>
			</div>
			{showPay ? (
				<ModalConfirmPayment
					data={{
						companyName: selectedInvoice?.company?.name! || 'Not set',
						companyId: selectedInvoice?.company?.id!,
						invoiceName: selectedInvoice?.id?.toString()!,
						amount: selectedInvoice?.balanceDue!,
						tableData:
							selectedInvoice?.invoiceLines?.map((line) => {
								return {
									order: line.orderId,
									product: line.description,
									total: 'string',
									quantity: line.quantity?.toString()!,
									price: line.lineTotal?.toString()!,
								};
							}) || [],
					}}
					isOpen={showPay}
					error={false}
					onClose={() => {
						setShowPay(false);
					}}
					onSubmit={confirmPrevPayment}
					realAmount={selectedInvoice?.balanceDue}
				/>
			) : null}
			<ModalConfirmPaymentStatus
				data={{
					companyName: selectedInvoice?.company?.name || 'Not set',
					companyId: selectedInvoice?.company?.id!,
					invoiceName: selectedInvoice?.id?.toString()!,
					amount: selectedInvoice?.balanceDue!,
					tableData:
						selectedInvoice?.invoiceLines?.map((line) => {
							return {
								order: line.orderId,
								product: line.description,
								total: 'string',
								quantity: line.quantity?.toString()!,
								price: line.lineTotal?.toString()!,
							};
						}) || [],
					paidData: payment!,
					invoice: selectedInvoice!,
				}}
				isOpen={showPrevSuccess}
				onClose={() => setShowPrevSuccess(false)}
				onSubmit={confirmPayment}
				companyType={target}
			/>
			<ModalSuccessPayment
				isOpen={showPaySuccess}
				onClose={() => setShowPaySuccess(false)}
			/>
		</div>
	);
};

export default ProducerAndTransporterBilling;
