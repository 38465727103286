import React from 'react';
import { SypacText, SypacBox } from '@sypac/component-library-react';
import { ClientsProductCardProps } from './ClientsProductCard.interface';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';

export const ClientsProductCard = (props: ClientsProductCardProps) => {
	const {
		name,
		availableQuantity,
		type,
		pricePerUnitWithProcent,
		pricePerUnitWithVat,
		photoUrl,
		size,
	} = props.data;
	const { onClick } = props;

	const getStatus = (quantity: number) => {
		if (quantity > 50) {
			return 'plenty';
		} else if (quantity > 10) {
			return 'medium';
		} else if (quantity > 0) {
			return 'low';
		} else {
			return 'none';
		}
	};

	return (
		<div className="relative">
			<SypacBox
				hover={true}
				className={`box-border w-[268px] h-[331px] flex gap-2 rounded-xl transition bg-white ${
					getStatus(availableQuantity!) === 'none' ? 'opacity-60' : ''
				}`}
				onClick={() => {
					onClick && onClick();
				}}
			>
				<div
					style={{ backgroundImage: `url(${photoUrl})` }}
					className="w-[calc(100%-22px)] h-[174px] m-2.5 bg-no-repeat bg-cover bg-center rounded-[10px] border border-solid border-gray-10"
				/>

				<div
					className={`flex flex-col gap-2 px-2.5 ${
						!type && !size ? 'mb-5' : ''
					}`}
				>
					<SypacText variant="body-regular-medium">
						<p className="text-base text-gray-80 truncate">{name}</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-40">
							{type || size ? `${type || ''}${size ? ` (${size})` : ''}` : null}
						</p>
					</SypacText>
				</div>
				<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

				<div className="flex flex-col gap-2 px-2.5">
					<div className="flex gap-1 [&_p]:text-sm text-gray-80">
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="clientsProductCard.pricePerTonExcl">
									Price per ton (excl. VAT):
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p>
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(pricePerUnitWithProcent?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>

					<div className="flex gap-1 [&_p]:text-xs text-gray-40">
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="clientsProductCard.pricePerTonIncl">
									Price per ton (incl. VAT):
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p>
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(pricePerUnitWithVat?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				</div>
			</SypacBox>
			{/* <div
				className={`absolute top-5 left-5 flex items-center justify-center w-fit h-[24px] rounded-full py-0.5 px-3 bg-${
					colorsTarget[getStatus(availableQuantity!)].bg
				}`}
			>
				<SypacText variant="body-regular-medium">
					<p
						className={`text-base text-${
							colorsTarget[getStatus(availableQuantity!)].text
						}`}
					>
						{availableQuantity! > 0 ? (
							<>
								<T keyName="clientsProductCard.available">Available:</T>{' '}
								{availableQuantity} {unit}
							</>
						) : (
							<>Out of stock</>
						)}
					</p>
				</SypacText>
			</div> */}
		</div>
	);
};
