import React from 'react';

import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import BoxComponent from '../../BoxComponent/BoxComponent';
import { DriverConfirmedProps } from './DriverConfirmed.interface';
import { LineStroke } from '../../../assets/LineStroke';
import { T } from '@tolgee/react';

const DriverConfirmed = ({
	notification,
	title,
	description,
	driver,
	truckPlates,
	truckType,
	loadingPlace,
	scheduledLoading,
	scheduledUnloading,
	unloadingPlace,
	onClick,
	loadingClick,
	unloadingClick,
	color,
}: DriverConfirmedProps) => {
	return (
		<BoxComponent color={color} notification={notification}>
			<div className="flex flex-col p-3 gap-5">
				<div className="flex flex-col gap-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{title}</p>
					</SypacText>
					<SypacText variant="overline-regular-large">
						<p className="text-gray-80 leading-3">{description}</p>
					</SypacText>
				</div>
				<div className="flex flex-col gap-3 max-w-[386px]">
					<SypacBox
						boxStyle="solid"
						borderWidth="sm"
						className="bg-gray-1 w-fit"
						px="sm"
						py="sm"
					>
						<SypacText className="mb-3" variant="overline-regular-large">
							<p className="text-gray-80">
								<T keyName="driverConfirmed.aboutDriver">About driver</T>
							</p>
						</SypacText>
						<LineStroke width={[360, 360, 386]} />
						<div className="grid grid-cols-12 gap-[9px] mt-3">
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.driverName">Driver Name:</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{driver}</p>
							</SypacText>
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.truckPlates">Truck plates:</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{truckPlates}</p>
							</SypacText>
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.truckType">Truck type:</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{truckType}</p>
							</SypacText>
						</div>
					</SypacBox>
					<SypacBox
						boxStyle="solid"
						borderWidth="sm"
						className="bg-gray-1 w-fit"
						px="sm"
						py="sm"
					>
						<SypacText className="mb-3" variant="overline-regular-large">
							<p className="text-gray-80">
								<T keyName="driverConfirmed.loadingPlace">Loading place:</T>
							</p>
						</SypacText>
						<LineStroke width={[360, 360, 386]} />
						<div className="grid grid-cols-12 gap-[9px] mt-3">
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.scheduledLoading">
										Scheduled loading:
									</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{scheduledLoading}</p>
							</SypacText>
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.loadingPlace">Loading place:</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7 overflow-hidden whitespace-nowrap text-ellipsis"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{loadingPlace}</p>
							</SypacText>
							{onClick ? (
								<SypacButton className="col-span-12 w-fit" variant="secondary">
									<button onClick={loadingClick} className="px-4 py-1.5">
										<SypacText variant="overline-regular-medium">
											<p>
												<T keyName="driverConfirmed.viewOnMap">View on map</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							) : null}
						</div>
					</SypacBox>
					<SypacBox
						boxStyle="solid"
						borderWidth="sm"
						className="bg-gray-1 w-fit"
						px="sm"
						py="sm"
					>
						<SypacText className="mb-3" variant="overline-regular-large">
							<p className="text-gray-80">
								<T keyName="driverConfirmed.unloadingPlace">Unloading place:</T>
							</p>
						</SypacText>
						<LineStroke width={[360, 360, 386]} />
						<div className="grid grid-cols-12 gap-[9px] mt-3">
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.scheduledUnloading">
										Scheduled unloading:
									</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{scheduledUnloading}</p>
							</SypacText>
							<SypacText
								className="col-span-5"
								variant="overline-regular-large"
							>
								<p className="text-gray-40">
									<T keyName="driverConfirmed.unloadingPlace">
										Unloading place:
									</T>
								</p>
							</SypacText>
							<SypacText
								className="col-span-7 overflow-hidden whitespace-nowrap text-ellipsis"
								variant="overline-regular-large"
							>
								<p className="text-gray-80">{unloadingPlace}</p>
							</SypacText>
							{onClick ? (
								<SypacButton className="col-span-12 w-fit" variant="secondary">
									<button onClick={unloadingClick} className="px-4 py-1.5">
										<SypacText variant="overline-regular-medium">
											<p>
												<T keyName="driverConfirmed.viewOnMap">View on map</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							) : null}
						</div>
					</SypacBox>
					{onClick ? (
						<SypacButton className="col-span-12 w-fit" variant="primary">
							<button onClick={onClick} className="px-4 py-1.5">
								<SypacText variant="overline-regular-medium">
									<p>
										<T keyName="driverConfirmed.viewTransportPartner">
											View transport partner
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					) : null}
				</div>
			</div>
		</BoxComponent>
	);
};

export default DriverConfirmed;
