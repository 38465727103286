import { Counter } from '../../components/QuantityPicker/QuantityPicker.interface';
export const QuantityTypes = [
	{
		id: '1',
		type: 'aluminium',
	},
	{
		id: '2',
		type: 'steel',
	},
	{
		id: '3',
		type: 'hooklifter',
	},
	// {
	// 	id: '4',
	// 	type: 'kempf_aluminium',
	// },
	// {
	// 	id: '5',
	// 	type: 'kempf_steel',
	// },
];
export const COUNTERS: Counter[] = [
	{
		id: '1',
		name: 'Aluminium 27 ton',
		payload: 27,
		car: 'aluminium',
	},
	{
		id: '2',
		name: 'Steel 25 tons',
		payload: 25,
		car: 'steel',
	},
	{
		id: '3',
		name: 'Hooklifter 21 tons',
		payload: 21,
		car: 'hooklifter',
	},
	// {
	// 	id: '4',
	// 	name: 'Kempf Aluminium 40 tons',
	// 	payload: 40,
	// },
	// {
	// 	id: '5',
	// 	name: 'Kempf  Steel 40 tons',
	// 	payload: 40,
	// },
];

export const QuantityType: any = {
	aluminium: 'Aluminium 27 tons',
	steel: 'Steel 25 tons',
	hooklifter: 'Hooklifter 21 tons',
	kempf_aluminium: 'Kempf Aluminium 40 tons',
	kempf_steel: 'Kempf  Steel 40 tons',
};
