import React from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';

import { T } from '@tolgee/react';

export interface TermsAndConditionsFirstStepProps {
	isOpen: boolean;
	nextStep: () => void;
}

const TermsAndConditionsFirstStep = (
	props: TermsAndConditionsFirstStepProps,
) => {
	const { isOpen, nextStep } = props;
	return (
		<Modal
			isOpen={!!isOpen}
			className="modal-inside max-w-[663px] outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="termsAndConditions.termsAndConditions">
								Terms and Conditions
							</T>
						</p>
					</SypacText>
				</div>
				<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-6 pt-5 pb-10 gap-4">
					<div className="rounded-10 flex flex-col bg-gray-10-opacity-50 w-full">
						<div className="p-5">
							<SypacText variant="heading-4">
								<p className="text-gray-80">
									<T keyName="termsAndConditions.congratulations">
										Congratulations!
									</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-large" className="mt-3">
								<p className="text-gray-80">
									<T keyName="termsAndConditions.accountIsVerified">
										Your Sypac account is verified!
									</T>
								</p>
							</SypacText>
						</div>
					</div>
					<SypacText variant="body-regular-medium" className="mt-10">
						<p className="text-gray-90">
							<T keyName="termsAndConditions.familiarizeTermsOfService">
								Before you get down to business, we'd like you to familiarize
								yourself with our Terms of Service.
							</T>
						</p>
					</SypacText>
				</div>

				<div className="flex justify-center py-5 mx-6">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className={`px-15 py-2.5 w-full border-primary-violet hover:border-kimberly bg-primary-violet hover:bg-kimberly`}
							onClick={nextStep}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="termsAndConditions.nextStep">Next step</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default TermsAndConditionsFirstStep;
