export const Checkmark = () => {
	return (
		<svg
			width="13"
			height="10"
			viewBox="0 0 13 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.00098 5.8L4.14383 9L12.001 1"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
