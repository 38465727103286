export const Expanded = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			viewBox="0 0 32 32"
		>
			<rect
				width="31"
				height="31"
				x="0.5"
				y="0.5"
				fill="#494C83"
				rx="5.5"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M11 16h10"
			></path>
			<rect
				width="31"
				height="31"
				x="0.5"
				y="0.5"
				stroke="#494C83"
				rx="5.5"
			></rect>
		</svg>
	);
};
