const CircleClose = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			fill="none"
			viewBox="0 0 18 18"
		>
			<circle
				cx="9.25"
				cy="9"
				r="8"
				stroke="#F44A77"
				strokeWidth="1.3"
			></circle>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				strokeWidth="1.3"
				d="M11.25 7l-4 4m0-4l4 4"
			></path>
		</svg>
	);
};

export default CircleClose;
