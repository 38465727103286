import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import Default from '../../../components/Notification/Default';
import { useTranslate } from '@tolgee/react';

interface NotificationDriverAcceptProps {
	notification: boolean;
	date: string;
}
const NotificationDriverAccept = ({
	notification,
	date,
}: NotificationDriverAcceptProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={98}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<Default
					notification={notification}
					title={t(
						'notificationDriverAccept.driverConfirmedOrder',
						'Driver confirmed the order',
					)}
					description={t(
						'notificationDriverAccept.awaitingPickupConfirmation.',
						'Awaiting pick-up confirmation.',
					)}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverAccept;
