import React, { useMemo } from 'react';
import TermsAndConditionsPartnersEn from './en/TermsAndConditionsPartners';
import TermsAndConditionsPartnersPl from './pl/TermsAndConditionsPartners';

const TermsAndConditionsPartners = () => {
	const lang = useMemo(() => localStorage.getItem('lang'), []);

	return lang === 'pl' ? (
		<TermsAndConditionsPartnersPl />
	) : (
		<TermsAndConditionsPartnersEn />
	);
};

export default TermsAndConditionsPartners;
