import React, { useState } from 'react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import TruckDetailsMap from '../../AdditionalDetails/TruckDetailsMap';
import { AdminOrderProps } from '../../../adminOrder.interface';

const FillerStep: React.FC<AdminOrderProps> = (props) => {
	const { selectedOrder, currentStep, isFinalStep = false } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={currentStep!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
					isFinalStep={isFinalStep}
				/>
			</div>

			{currentStep! >= 4 && currentStep! <= 8 ? (
				<div className="px-10 pt-7 pb-3.5">
					<TruckDetailsMap selectedOrder={selectedOrder} />
				</div>
			) : null}

			<div
				className={`px-10 ${
					currentStep! >= 8 ? 'pt-7 pb-3.5 py-3.5' : 'py-3.5'
				}`}
			>
				{currentStep === 6 ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : currentStep === 10 ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={currentStep!}
						deliveryIncluded={selectedOrder?.deliveryIncluded!}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{currentStep === 6 ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={currentStep}
						deliveryIncluded={selectedOrder?.deliveryIncluded!}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : currentStep! < 6 ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : currentStep === 10 ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={currentStep}
						deliveryIncluded={selectedOrder?.deliveryIncluded!}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{currentStep! <= 6 ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>
		</>
	);
};

export default FillerStep;
