import React, { useContext, useEffect, useRef } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalMapProps } from './ModalMap.interface';
import Map from '../../components/Map/Map';
import { OrderContext } from '../../context/OrderContext/order.context';
import Close from '../../assets/Close';
import { T } from '@tolgee/react';

const ModalMap = ({ isOpen, onClose, markers }: ModalMapProps) => {
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={() => onClose()}>
			<div
				className="absolute bg-white outline-none w-[1066px] h-[786px] rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex flex-col h-full w-full">
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalMap.mapOverview">Map overview</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

					<div className="box-border flex w-full h-full items-center justify-center p-3">
						<Map markers={markers} />
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalMap;
