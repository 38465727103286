const BankTransfer = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 15C1 8.40034 1 5.1005 3.05025 3.05025C5.1005 1 8.40034 1 15 1C21.5997 1 24.8995 1 26.9497 3.05025C29 5.1005 29 8.40034 29 15C29 21.5997 29 24.8995 26.9497 26.9497C24.8995 29 21.5997 29 15 29C8.40034 29 5.1005 29 3.05025 26.9497C1 24.8995 1 21.5997 1 15Z"
				stroke="#2F2F2F"
				strokeWidth="2"
			/>
			<path
				d="M6.60046 8L6.60046 22"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13.6005 14.9988C13.6005 12.6792 15.4809 10.7988 17.8005 10.7988C20.1201 10.7988 22.0005 12.6792 22.0005 14.9988C22.0005 17.3184 20.1201 19.1988 17.8005 19.1988C15.4809 19.1988 13.6005 17.3184 13.6005 14.9988Z"
				stroke="#2F2F2F"
				strokeWidth="2"
			/>
			<path
				d="M21.2998 11.4994L23.3998 9.39941"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M12.2002 20.6L14.3002 18.5"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M14.2998 11.4994L12.1998 9.39941"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M23.3995 20.6L21.2995 18.5"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default BankTransfer;
