import React, { useState } from 'react';
import {
	SypacButton,
	SypacText,
	SypacBookTabs,
	SypacTabBook,
	SypacIcon,
} from '@sypac/component-library-react';
import { useTranslate, T } from '@tolgee/react';
import ProducerAndTransporterBilling from './components/producerAndTransporterBilling';
import ClientBilling from './components/clientBilling';
import ModalDownload from '../../../components/ModalDownload/ModalDownload';
import { BillingService } from '../../../services/billing.service';
import { MutatingDots } from 'react-loader-spinner';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';

const Billing: React.FC = () => {
	const { t } = useTranslate();
	const [tab, setTab] = useState('client');
	const [downloadModal, setDownloadModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const checkDownloadInvoices = async () => {
		setDownloadModal(true);
	};

	const downloadInvoices = async (fromDate: string, toDate: string) => {
		try {
			setLoading(true);
			const response = await BillingService.downloadInvoices({
				fromDate,
				toDate,
			});
			if (response.data.type === 'text/html') {
				toastVariant(
					t(
						'billing.noInvoicesAvailable',
						'No invoices available for download during this period.',
					),
					true,
				);
				setLoading(false);
				setDownloadModal(false);
				return;
			}
			const url = window.URL.createObjectURL(new Blob([response.data]));

			const a = document.createElement('a');
			a.href = url;
			a.download = 'invoices.zip';
			a.click();

			window.URL.revokeObjectURL(url);
		} finally {
			setLoading(false);
			setDownloadModal(false);
		}
	};

	return (
		<>
			<div className="flex flex-col w-[calc(100vw-78px)] h-full ">
				<div className="flex-row grid grid-cols-12 border-bottom border-gray-10">
					<div className="col-span-4 ml-8">
						<SypacBookTabs className="mt-3.5">
							<SypacTabBook
								label={t('billing.client', 'Buyer')}
								button-id="client"
								onClick={() => setTab('client')}
							/>
							<SypacTabBook
								label={t('billing.producer', 'Seller')}
								button-id="producer"
								onClick={() => setTab('producer')}
							/>
							<SypacTabBook
								label={t('billing.transporter', 'Carrier')}
								button-id="transporter"
								onClick={() => setTab('transporter')}
							/>
						</SypacBookTabs>
					</div>
					<div className="col-span-4" />
					<div className="col-span-4">
						<div className="flex flex-row justify-end mr-4 mb-2">
							<SypacButton variant="secondary" size="small">
								<button
									className="px-5 py-[5px] border border-solid border-gray-10"
									onClick={checkDownloadInvoices}
								>
									<SypacIcon
										iconName="Download Minimalistic"
										className="text-gray-80 cursor-pointer"
										size="custom"
										width="14px"
										height="14px"
									/>
									<SypacText variant="body-regular-medium" className="ml-1">
										<p className="text-gray-80">
											<T keyName="productDetails.rejectProduct">
												Download invoices
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				</div>
				<div className="w-full h-full">
					<div className="flex flex-row h-full relative pt-8">
						{tab === 'transporter' ? (
							<ProducerAndTransporterBilling target="transporter" />
						) : tab === 'producer' ? (
							<ProducerAndTransporterBilling />
						) : (
							<ClientBilling />
						)}
					</div>
				</div>
			</div>
			{downloadModal ? (
				<ModalDownload
					isOpen={downloadModal}
					onClose={() => setDownloadModal(false)}
					onSuccess={downloadInvoices}
				/>
			) : null}
			{loading ? (
				<div className="flex w-full h-full items-center justify-center absolute top-0 left-0 bottom-0 right-0 z-[1000]">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : null}
		</>
	);
};

export default Billing;
