import { SypacText } from '@sypac/component-library-react';

import { DropDownItemInterface } from './MenuDropdown.interface';

const mainStyle =
	'no-underline flex hover:bg-gray-10-opacity-50 bg-white border-none pt-4 pb-[17px] px-5 rounded-md';

export const DropDownItem = (option: DropDownItemInterface) => {
	return <DropDownItemLink {...option} />;
};

const DropDownItemLink = (option: DropDownItemInterface) => {
	return (
		<div className={mainStyle} onClick={option.action}>
			<SypacText variant="body-regular-medium" className="mr-auto">
				<p className="text-gray-80 leading-[19px]">{option.title}</p>
			</SypacText>
		</div>
	);
};
