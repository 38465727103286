export const LineHorizontal = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="74"
			height="8"
			fill="none"
			viewBox="0 0 74 8"
		>
			<path
				fill="#000"
				d="M73.354 4.354a.5.5 0 000-.708L70.172.464a.5.5 0 10-.707.708L72.293 4l-2.829 2.828a.5.5 0 10.708.708l3.182-3.182zM0 4.5h73v-1H0v1z"
			></path>
		</svg>
	);
};
