import * as React from 'react';

export const Points = () => {
	return (
		<svg
			width="4"
			height="14"
			viewBox="0 0 4 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="2" cy="2" r="1.5" fill="#494C83" />
			<circle cx="2" cy="7" r="1.5" fill="#494C83" />
			<circle cx="2" cy="12" r="1.5" fill="#494C83" />
		</svg>
	);
};
