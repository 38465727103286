export const Telegram = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="20"
				height="20"
				rx="4"
				fill="url(#paint0_linear_5597_215843)"
			/>
			<g clipPath="url(#clip0_5597_215843)">
				<path
					d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
					fill="url(#paint1_linear_5597_215843)"
				/>
				<path
					d="M6.76172 10.7322L7.9469 14.0167C7.9469 14.0167 8.09505 14.3233 8.25505 14.3233C8.41505 14.3233 10.7736 11.8656 10.7736 11.8656L13.4076 6.79297L6.80764 9.8863L6.76172 10.7322Z"
					fill="#C8DAEA"
				/>
				<path
					d="M8.34013 11.5723L8.11346 13.993C8.11346 13.993 8.01717 14.7337 8.75939 13.993C9.50161 13.2523 10.2098 12.6804 10.2098 12.6804L8.34013 11.5723Z"
					fill="#A9C6D8"
				/>
				<path
					d="M6.79113 10.8471L4.35113 10.05C4.35113 10.05 4.05483 9.93153 4.1526 9.66338C4.17186 9.60857 4.21187 9.56116 4.32742 9.47968C4.86816 9.10339 14.3363 5.70042 14.3363 5.70042C14.4727 5.65236 14.6195 5.64162 14.7615 5.66932C14.8005 5.68182 14.8356 5.70421 14.8634 5.73433C14.8912 5.76445 14.9107 5.80125 14.92 5.84115C14.9366 5.9115 14.9436 5.98377 14.9408 6.05598C14.9408 6.11968 14.9319 6.17745 14.9259 6.2693C14.8682 7.19968 13.1482 14.1434 13.1482 14.1434C13.1482 14.1434 13.0445 14.5493 12.6741 14.5626C12.5833 14.5659 12.4928 14.5508 12.4081 14.5182C12.3233 14.4856 12.246 14.4362 12.1808 14.373C11.4548 13.7478 8.9452 12.0619 8.39113 11.6915C8.37865 11.6828 8.36811 11.6716 8.36021 11.6586C8.3523 11.6456 8.34719 11.6311 8.3452 11.616C8.33779 11.5774 8.38076 11.5286 8.38076 11.5286C8.38076 11.5286 12.7496 7.6456 12.8652 7.23227C12.8741 7.20116 12.84 7.18486 12.7941 7.1982C12.4978 7.30486 7.47409 10.4812 6.91853 10.8323C6.87795 10.8477 6.83417 10.8528 6.79113 10.8471Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_5597_215843"
					x1="10"
					y1="20"
					x2="10"
					y2="0"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1D93D2" />
					<stop offset="1" stopColor="#38B0E3" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_5597_215843"
					x1="10"
					y1="20"
					x2="10"
					y2="0"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1D93D2" />
					<stop offset="1" stopColor="#38B0E3" />
				</linearGradient>
				<clipPath id="clip0_5597_215843">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
