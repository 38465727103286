const OverdueInvoice = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="23"
			fill="none"
			viewBox="0 0 22 23"
		>
			<rect width="22" height="22" y="0.066" fill="#F44A77" rx="11"></rect>
			<path
				fill="#fff"
				d="M6.987 10.31C8.738 7.146 9.614 5.566 11 5.566c1.386 0 2.262 1.581 4.013 4.744l.218.394c1.455 2.627 2.183 3.941 1.525 4.902-.657.96-2.284.96-5.538.96h-.436c-3.254 0-4.88 0-5.538-.96-.658-.96.07-2.275 1.525-4.902l.218-.395z"
			></path>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				d="M11.008 8.625v3.056"
			></path>
			<mask id="path-4-inside-1_5511_211548" fill="#fff">
				<ellipse cx="10.998" cy="13.504" rx="0.6" ry="0.611"></ellipse>
			</mask>
			<ellipse
				cx="10.998"
				cy="13.504"
				fill="#F44A77"
				rx="0.6"
				ry="0.611"
			></ellipse>
			<path
				fill="#F44A77"
				d="M10.598 13.504c0-.198.162-.39.4-.39v2c.901 0 1.6-.738 1.6-1.61h-2zm.4-.39c.239 0 .4.192.4.39h-2c0 .872.7 1.61 1.6 1.61v-2zm.4.39a.395.395 0 01-.4.389v-2c-.9 0-1.6.738-1.6 1.61h2zm-.4.389a.395.395 0 01-.4-.39h2c0-.872-.699-1.61-1.6-1.61v2z"
				mask="url(#path-4-inside-1_5511_211548)"
			></path>
		</svg>
	);
};

export default OverdueInvoice;
