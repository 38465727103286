const DeleteDocument = () => {
	return (
		<svg
			width="61"
			height="64"
			viewBox="0 0 61 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M59 12H2"
				stroke="#171717"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M53.4131 20.3359L51.871 43.333C51.2776 52.1827 50.9808 56.6075 48.0805 59.3051C45.1802 62.0026 40.7195 62.0026 31.798 62.0026H29.205C20.2835 62.0026 15.8228 62.0026 12.9225 59.3051C10.0222 56.6075 9.72544 52.1827 9.13199 43.333L7.58984 20.3359"
				stroke="#171717"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M22.1172 28.6641L23.7937 45.3307"
				stroke="#171717"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M38.8835 28.6641L37.207 45.3307"
				stroke="#171717"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M12.0586 12C12.246 12 12.3396 12 12.4246 11.9979C15.1855 11.9283 17.6212 10.183 18.5606 7.60107C18.5895 7.52164 18.6192 7.43329 18.6784 7.25658L19.0039 6.28571C19.2818 5.45693 19.4208 5.04252 19.6051 4.69067C20.3404 3.28691 21.7007 2.31212 23.2728 2.06255C23.6669 2 24.1062 2 24.985 2H36.0144C36.8931 2 37.3325 2 37.7265 2.06255C39.2986 2.31212 40.659 3.28691 41.3943 4.69067C41.5786 5.04252 41.7175 5.45692 41.9954 6.28571L42.3209 7.25658C42.3801 7.43305 42.4098 7.5217 42.4387 7.60107C43.3782 10.183 45.8138 11.9283 48.5748 11.9979C48.6597 12 48.7534 12 48.9408 12"
				stroke="#171717"
				strokeWidth="3"
			/>
		</svg>
	);
};

export default DeleteDocument;
