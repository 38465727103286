import Api from '../axios/api';

export class AuthsService {
	static logout() {
		const lang = localStorage.getItem('lang') || 'pl';
		localStorage.clear();
		window.location.replace(
			`${process.env.REACT_APP_LANDING_URL}/${lang}/login`,
		);
	}
	static acceptTermsAndConditions(): Promise<void> {
		return Api.post(`/v1/auth/me/accept-terms`);
	}
}
