import { useNavigate, useParams } from 'react-router-dom';
import { SypacButton } from '@sypac/component-library-react';
import React from 'react';

import ProductGroup from '../../components/ProductGroup/ProductGroup';
import { useGetCategories } from '../../hooks/use-get-categories';
import { Category } from '../../interfaces/category.interface';

import './select-product-category.css';
import { T, useTranslate } from '@tolgee/react';

const SelectProductCategory = () => {
	const { groupId } = useParams();
	const { t } = useTranslate();
	const [groups] = useGetCategories({}, 'producer');
	const [categories] = useGetCategories({ parentIds: [groupId!] }, 'producer');
	const navigate = useNavigate();

	const onSelect = (category: Category): void => {
		navigate(`${category.id}`);
	};

	const back = () => {
		navigate(-1);
	};

	const getParentName = () => {
		if (groups?.items?.length) {
			return groups.items[0]?.name;
		}
		return '';
	};
	const breadcrumbs = [
		{
			name: getParentName(),
			link: '/products/new/groups',
		},
		{
			name: '',
			link: '',
		},
	];

	return (
		<div className="w-full h-full flex items-center">
			<div className="w-full my-15 lg:my-0 px-7.5 lg:px-0">
				<ProductGroup
					groups={categories?.items}
					onSelect={onSelect}
					title={t(
						'selectProductCategory.selectProductCategory',
						'Select product category',
					)}
					breadcrumbs={breadcrumbs}
					back={back}
				/>
				<div className="flex gap-6.25 items-center justify-center mt-8.25">
					<SypacButton variant="secondary" size="small">
						<button
							onClick={() => {
								navigate('/products/new/groups');
							}}
							className="border-[1px] border- text-primary-violet rounded-md py-[3px] px-11.5 justify-center"
						>
							<T keyName="selectProductCategory.back">Back</T>
						</button>
					</SypacButton>
					<SypacButton variant="primary" size="small">
						<a href="/products" className="select-product-category__cancel">
							<T keyName="selectProductCategory.cancel">Cancel</T>
						</a>
					</SypacButton>
				</div>
			</div>
		</div>
	);
};
export default SelectProductCategory;
