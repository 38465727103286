import {
	SypacAvatar,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { LeftSideMenuItemInterface } from './LeftSideMenuItem.interface';
import { Link, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/context';
import useDebounce from '../../../../hooks/useDebounce';

export const LeftSideMenuItem = ({
	icon,
	label,
	url,
	count,
	onClick = () => {},
	openProfile = () => {},
}: LeftSideMenuItemInterface) => {
	const { user } = useContext(AuthContext);
	const location = useLocation();
	const [active] = useState(!!location?.pathname?.includes(url || 'unknown'));
	const activeShow = useDebounce(active, 500);

	const content = () => {
		return (
			<>
				<div className="flex items-center gap-x-5">
					{icon !== 'profile_dropdown' ? (
						<SypacIcon
							color="currentColor"
							icon-name={icon}
							width="22px"
							height="22px"
							size="custom"
						/>
					) : (
						<SypacAvatar size="sm" initials={user?.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
					)}
					<SypacText variant="body-regular-medium">
						<p className="leading-5">{label}</p>
					</SypacText>
				</div>
				{count ? (
					<div className="ml-auto w-5 h-5 flex flex-row justify-center items-center bg-red rounded-[100px] text-xs font-medium text-gray-1 border-2 border-gray-1 border-solid">
						{count > 9 ? '9+' : count}
					</div>
				) : null}
			</>
		);
	};

	return (
		<>
			{url ? (
				<Link
					to={url}
					className={`flex no-underline items-center rounded-10 cursor-pointer py-3.75 px-5 hover:bg-gray-10 ${
						activeShow
							? 'bg-gradient-primary hover:text-white-100 text-white-100'
							: 'text-gray-80'
					}`}
				>
					{content()}
				</Link>
			) : (
				<div
					className={`flex no-underline items-center rounded-10 cursor-pointer py-3.75 px-5 hover:bg-gray-10 ${
						activeShow
							? 'bg-gradient-primary hover:text-white-100 text-white-100'
							: 'text-gray-80'
					}`}
					onClick={icon === 'profile_dropdown' ? openProfile : onClick}
				>
					{content()}
				</div>
			)}
		</>
	);
};
