const PreviewDocument = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="66"
			height="66"
			fill="none"
			viewBox="0 0 66 66"
		>
			<rect
				width="65"
				height="65"
				x="0.5"
				y="0.5"
				fill="#F3F5F8"
				stroke="#E8E8E8"
				rx="7.5"
			></rect>
			<path
				fill="#A2A2A2"
				fillRule="evenodd"
				d="M22 23.4c0-1.325 1.085-2.4 2.423-2.4H37.27a1 1 0 01.704.29l4.73 4.685a1 1 0 01.297.71V42.6c0 1.325-1.085 2.4-2.423 2.4H24.423C23.085 45 22 43.925 22 42.6V23.4z"
				clipRule="evenodd"
			></path>
			<path
				fill="#fff"
				d="M27.05 39.204c.31 0 .534-.073.67-.22.137-.147.205-.352.205-.616a.91.91 0 00-.053-.318.694.694 0 00-.434-.406 1.198 1.198 0 00-.388-.056h-.622v1.616h.622zm0-2.5c.362 0 .673.042.932.129.261.084.476.2.643.35.17.149.294.325.374.528.08.202.12.422.12.657 0 .254-.041.487-.123.7a1.404 1.404 0 01-.378.545c-.17.152-.385.27-.647.357a3.003 3.003 0 01-.921.126h-.622V41.8h-1.194v-5.097h1.816zM34.393 39.25c0 .368-.064.709-.194 1.021-.127.31-.307.58-.54.808a2.492 2.492 0 01-.844.532 3.017 3.017 0 01-1.091.189h-2v-5.097h2c.4 0 .763.064 1.09.192.33.129.612.306.845.532.233.226.413.496.54.808.13.31.194.648.194 1.015zm-1.221 0c0-.252-.033-.48-.1-.682a1.421 1.421 0 00-.285-.515 1.194 1.194 0 00-.453-.325 1.534 1.534 0 00-.61-.115h-.799v3.277h.799c.228 0 .432-.037.61-.112.18-.077.33-.185.453-.325.124-.142.22-.315.286-.518.066-.203.099-.431.099-.685zM36.299 37.613v1.29h1.758v.913H36.3V41.8h-1.201v-5.097h3.313v.91h-2.112z"
			></path>
		</svg>
	);
};

export default PreviewDocument;
