import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import AwaitingConfirmation from '../../../components/Notification/AwaitingConfirmation';
import { useTranslate } from '@tolgee/react';

interface NotificationSentProps {
	notification: boolean;
	date: string;
	count: number;
	onClick?: () => void;
}

const NotificationSent = ({
	notification,
	date,
	count,
	onClick,
}: NotificationSentProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-[25px]">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={140}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<AwaitingConfirmation
					notification={notification}
					disableButton={!!count}
					title={t(
						'notificationSent.notificationsSent',
						'Notifications sent — awaiting confirmation',
					)}
					description={t(
						'notificationSent.orderSuccessfullySend',
						`Your order has been successfully send to ${count} transport solutions`,
					).replace('solutions', count ? count.toString() : '0')}
					button={{
						text: t('notificationSent.viewAllSolution', 'View all solution'),
						onClick: onClick,
					}}
				/>
			</div>
		</div>
	);
};

export default NotificationSent;
