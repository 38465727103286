export const PickupProduct = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="40"
			fill="none"
			viewBox="0 0 32 40"
		>
			<path
				fill="#494C83"
				fillRule="evenodd"
				d="M16 0C7.163 0 0 7.292 0 16.287c0 8.924 5.107 19.338 13.074 23.062a6.9 6.9 0 005.852 0C26.893 35.625 32 25.21 32 16.287 32 7.292 24.837 0 16 0zm0 22a6 6 0 100-12 6 6 0 000 12z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
