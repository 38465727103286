export const titlesTarget: Record<string, string> = {
	customer: 'Buyer',
	producer: 'Seller',
	transporter: 'Carrier',
	admin: 'Admin',
	manager: 'Manager',
};
export const colorsTarget: Record<string, string> = {
	customer: 'violet',
	producer: 'green',
	transporter: 'yellow',
	admin: 'violet',
	manager: 'gray',
};
export const colorsCompanyState: Record<string, string> = {
	verified: 'green',
	verification_pending: 'red',
	registration_in_progress: 'red',
};
export const titlesCompanyState: Record<string, string> = {
	registration_in_progress: 'Not registered',
	verification_pending: 'Not verified',
	verified: 'Verified',
};
