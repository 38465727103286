import { components, StylesConfig } from 'react-select';
import React from 'react';
import { SypacText } from '@sypac/component-library-react';

import CarrierIcon from './assets/CarrierIcon';
import ClientIcon from './assets/ClientIcon';
import ProducerIcon from './assets/ProducerIcon';

const { Option } = components;

export const optionsCompanyDetails = [
	{
		value: 'transporter',
		label: (
			<div className="flex gap-2 items-center">
				<CarrierIcon />
				<SypacText variant="body-regular-small">
					<p className="text-gray-80">Carrier</p>
				</SypacText>
			</div>
		),
	},
	{
		value: 'customer',
		label: (
			<div className="flex gap-2 items-center">
				<ClientIcon />
				<SypacText variant="body-regular-small">
					<p className="text-gray-80">Buyer</p>
				</SypacText>
			</div>
		),
	},
	{
		value: 'producer',
		label: (
			<div className="flex gap-2 items-center">
				<ProducerIcon />
				<SypacText variant="body-regular-small">
					<p className="text-gray-80">Seller</p>
				</SypacText>
			</div>
		),
	},
];
export const customStylesCompanyDetails: StylesConfig<any> = {
	option: (provided) => ({
		...provided,
		backgroundColor: 'white',
		':focus': {
			backgroundColor: '#F2F2F2',
		},
		':hover': {
			backgroundColor: '#F2F2F2',
		},
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { ...provided, opacity, transition };
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: '41px',
		borderRadius: '6px',
		borderColor: state.isFocused ? 'rgb(86, 130, 250)' : 'rgb(172, 176, 181)',
		boxShadow: 'none',
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: 'white',
	}),
	menuList: (provided) => ({
		...provided,
		padding: 0,
	}),
	multiValueRemove: (provided) => ({
		...provided,
		borderRadius: '6px',
		borderWidth: '1px',
		borderStyle: 'solid',
		height: 'fit-content',
		alignSelf: 'center',
		marginLeft: '4px',
		padding: 0,
		borderColor: 'rgb(172, 176, 181)',
		':hover': { backgroundColor: 'white', cursor: 'pointer' },
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#d3d4d5',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '20px',
	}),
};

const CustomOption = (props: any) => {
	return <Option {...props}>{props.data.label}</Option>;
};

export default CustomOption;
