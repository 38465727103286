import { SypacText } from '@sypac/component-library-react';
import { PieChartProps } from './PieChart.interface';
import './pie_chart.css';
import { T } from '@tolgee/react';
import React from 'react';

const PieChart = (props: PieChartProps) => {
	return (
		<div className="relative w-fit">
			<div className="first-half-circle">
				<div className="flex flex-col justify-center items-center">
					<SypacText variant="overline-regular-large" className="mb-1">
						<p className="text-gray-40">
							<T keyName="pieChart.overdueOrders">Overdue orders:</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium" className="mb-1">
						<p className="text-gray-80">{props.overdueOrders}</p>
					</SypacText>
				</div>
			</div>
			<div className="second-half-circle">
				<div>
					<div className="flex flex-col justify-center items-center">
						<SypacText variant="overline-regular-large" className="mt-1">
							<p className="text-gray-40">
								<T keyName="pieChart.paidOrders">Paid orders:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium" className="mt-1">
							<p className="text-gray-80">{props.paidOrders}</p>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PieChart;
