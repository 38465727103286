import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { ThankYouProps } from './thank-you.interface';
import './thank-you.css';
import { T, useTranslate } from '@tolgee/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Logo } from '../../assets/Logo';

const ThankYou = (props: ThankYouProps) => {
	const { client } = props;
	const { t } = useTranslate();
	const { orderId } = useParams();
	const orderUrl = `/orders?orderId=${orderId}`;

	const renderProducerButtons = () => {
		return (
			<SypacButton
				variant="secondary"
				size="small"
				className="flex items-center"
			>
				<a href="/products" className="thank-you-secondary-button">
					<SypacText variant="body-regular-medium">
						<p className="text-primary-violet">
							<T keyName="thankYou.gotIt">Got it</T>
						</p>
					</SypacText>
				</a>
			</SypacButton>
		);
	};

	const renderClientButtons = () => {
		return (
			<div className="flex flex-col sm:flex-row gap-6.25">
				<SypacButton
					variant="secondary"
					size="small"
					className="flex items-center"
				>
					<a href="/store" className="thank-you-secondary-button">
						<SypacIcon
							size="custom"
							width="10px"
							height="12px"
							icon-name="Alt Arrow Left"
						/>
						<SypacText variant="body-regular-medium">
							<p className="text-primary-violet whitespace-nowrap">
								<T keyName="thankYou.backToStore">Back to store</T>
							</p>
						</SypacText>
					</a>
				</SypacButton>
				<SypacButton
					variant="secondary"
					size="small"
					className="flex items-center animate-tr-b-t-1"
				>
					<a href={orderUrl} className="thank-you-primary-button">
						<SypacText variant="body-regular-medium">
							<p className="text-white whitespace-nowrap">
								<T keyName="thankYou.viewOrder">View order</T>
							</p>
						</SypacText>
					</a>
				</SypacButton>
			</div>
		);
	};

	return (
		<div className="h-[calc(100vh-130px)] flex flex-col items-center justify-center mt-[68px] sm:mt-0 px-[12px] sm:px-0">
			<Logo />
			<div className="mb-6 text-center sm:text-left">
				<SypacText variant="heading-5">
					<p className="text-dark font-medium">
						{client
							? t(
									'thankYou.yourOrder',
									'Your order is currently being processed',
							  )
							: t('thankYou.thankYou', 'Thank you!')}
					</p>
				</SypacText>
			</div>
			<div className="max-w-[749px] mb-[120px]">
				<SypacText variant="body-normal-medium">
					<p className="text-dark-2 text-center">
						{client
							? t(
									'thankYou.weAreProcessingYourOrder',
									"Once processed, you'll receive a notification confirming the order acceptance and detailing the next steps. Thank you for choosing SYPAC.",
							  )
							: t(
									'thankYou.productPlacementIsNowUnderReview',
									"Your product placement is now under review. We will notify you once it's ready be published on Sypac.",
							  )}
					</p>
				</SypacText>
			</div>
			<div className="w-full sm:w-fit">
				{client ? renderClientButtons() : renderProducerButtons()}
			</div>
		</div>
	);
};

export default ThankYou;
