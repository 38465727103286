import Api from '../axios/api';
import { DriverDto } from './truck.services';

export interface UploadResponse {
	originalName?: string;
	name: string;
	path?: string;
	type?: string;
	url?: string;
}

export interface ResponnseUploadResponse {
	data: { count: number; items: UploadResponse[] };
}

export interface DocumentsDTO {
	id: number;
	orderId: number;
	quantity: number;
	loadedQuantity: number;
	payload: number;
	truckType: string;
	loadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	producerLoadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	unloadDetails: {
		comments: string;
		wight: number;
		documents: string[];
	};
	priceForTransporter: number;
	priceForTransporterWithVat: number;
	transportVat: number;
	priceForProducer: number;
	priceForProducerWithVat: number;
	deliveryFrom: string;
	deliveryTo: string;
	createdAt: string;
	updatedAt: string;
	deliveryTimeFrom: string;
	deliveryTimeTo: string;
	referenceId: string;
	distance: number;
	driver: DriverDto;
}

export interface GetFilesResponse {
	data: DocumentsDTO[];
}

export class FileService {
	static uploadFiles(
		files: FileList | File[],
	): Promise<ResponnseUploadResponse> {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append('files', files[i]);
		}
		formData.append('folder', 'dev');
		return Api.post('/v1/files/upload', formData);
	}

	static getFiles(orderId: number, target: string): Promise<GetFilesResponse> {
		return Api.get(`/v1/${target}/documents/${orderId}`);
	}
}
