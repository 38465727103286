// import Tracker from '@openreplay/tracker';
import React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AuthContext } from '../../context/context';
import AdminRouter from './admin.router';
import ProducerRouter from './producer.router';
import CustomerRouter from './customer.router';
import TransporterRouter from './transporter.router';
import Emitter, { EventType } from '../../services/events';
import { SocketService } from '../../services/socket.services';
import {
	NotificationCategoory,
	NotificationInterface,
	OrderInterface,
} from '../../components/OrdersTable/interfaces/Order.interface';
import { ToastContainer, toast } from 'react-toastify';
import useDebounce from '../../hooks/useDebounce';
import { OrdersService } from '../../services/orders.services';
import { OrderTarget } from '../../components/OrdersTable/interfaces/OrderStatus.interface';
import { useLocation } from 'react-router-dom';
import NewOrderToast from '../../components/Toasts/NewOrderToast';

const AppRouter: React.FC = () => {
	const { user } = useContext(AuthContext);
	const [connected, setConnected] = useState<boolean>(false);
	const [notify, setNotify] = useState<NotificationInterface | undefined>();
	const notifyShow = useDebounce(notify, 800);
	const location = useLocation();
	// useEffect(() => {
	// 	const tracker = new Tracker({
	// 		projectKey: 'AZCZOj6V020WhzAjtu9a',
	// 		ingestPoint: 'https://openreplay.fastcompany.dev/ingest',
	// 	});
	// 	tracker.setMetadata('user', user?.name!);
	// 	tracker.setMetadata('companyId', user?.company?.id?.toString()!);
	// 	tracker.setMetadata('companyName', user?.company?.name!);
	// 	tracker.setUserID(user?.email || 'anonymous');
	// 	tracker.start();
	// }, [user]);

	let targetCompany = window.location.host
		.split('.')[0]
		?.replace(/-dev|-staging/, '');

	const showNotify = useCallback(async () => {
		try {
			if (notifyShow) {
				if (notifyShow.category === EventType.UPDATE_EXPIRED_NOTIFICATION) {
					Emitter.emit(EventType.NOTIFICATION_COUNT);
				}
				const orderId = parseInt(notifyShow.groupId);
				let data: OrderInterface;
				if (notifyShow.category === NotificationCategoory.NEW_OFFER) {
					const respOffer = await OrdersService.getOffer(orderId);
					data = respOffer.data;
				} else {
					const respOrder = await OrdersService.getOrder(
						orderId,
						targetCompany as OrderTarget,
					);
					data = respOrder.data;
				}
				switch (notifyShow?.category) {
					case EventType.TRUCK_UPDATE_LOCATION:
						Emitter.emit(EventType.TRUCK_UPDATE_LOCATION, notify);
						break;
					default:
						Emitter.emit(EventType.ORDER_LIST_REFRESH, notifyShow);
						Emitter.emit(EventType.NOTIFICATION_COUNT);
				}
				if (
					notifyShow.category === NotificationCategoory.NEW_ORDER &&
					['admin'].includes(targetCompany)
				) {
					toast.info(<NewOrderToast order={data!} role={targetCompany} />, {
						className: 'notification_custom',
					});
				}
				if (
					notifyShow.category === NotificationCategoory.NEW_ORDER &&
					['producer'].includes(targetCompany)
				) {
					toast.info(<NewOrderToast order={data!} role={targetCompany} />, {
						className: 'notification_custom',
					});
				}
				if (
					notifyShow.category === NotificationCategoory.NEW_OFFER &&
					['transporter'].includes(targetCompany)
				) {
					toast.info(<NewOrderToast order={data!} role={targetCompany} />, {
						className: 'notification_custom',
					});
				}
				if (
					notifyShow.category ===
						NotificationCategoory.ORDER_ACCEPTED_BY_DRIVER &&
					['customer'].includes(targetCompany)
				) {
					toast.info(<NewOrderToast order={data} role={targetCompany} />, {
						className: 'notification_custom',
					});
				}
				setNotify(undefined);
			}
		} catch (e) {}
	}, [notify, notifyShow, targetCompany]);

	useEffect(() => {
		if (notifyShow) {
			showNotify();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifyShow]);

	const connectSocket = useCallback(() => {
		try {
			const socket = SocketService.getInstance();
			setConnected(true);
			socket.emit('identity', `room:${user?.uid}`);
			socket.on('notify', (notify: NotificationInterface) => {
				// console.log(notify, 'notify');
				setNotify(notify);
			});
		} catch (e) {
			console.log(e);
		}
	}, [user?.uid]);

	useEffect(() => {
		connectSocket();
	}, [connectSocket]);

	// useEffect(() => {
	// 	setNotify({
	// 		'id': '65d3583f94ecbfee7c0fbbe6',
	// 		'recipientId': 'b8e45568-4d9c-4f04-bc67-3ea3e55ee514',
	// 		'groupId': '738',
	// 		'category': 'new_order',
	// 		'createdAt':
	// 			'Mon Feb 19 2024 13:31:43 GMT+0000 (Coordinated Universal Time)',
	// 	});
	// }, [setNotify]);

	useEffect(() => {
		return () => {
			if (connected) {
				SocketService.disconnectSocket();
			}
		};
	}, [connected]);

	// Stop toast alerts reappearing when navigating.
	useEffect(() => {
		toast.dismiss();
	}, [location]);

	return (
		<HelmetProvider>
			{targetCompany === 'admin' && (
				<>
					<Helmet>
						<title>SYPAC for Admin</title>
					</Helmet>
					<AdminRouter />
				</>
			)}
			{targetCompany === 'producer' && (
				<>
					<Helmet>
						<title>SYPAC for Seller</title>
					</Helmet>
					<ProducerRouter />
				</>
			)}
			{targetCompany === 'customer' && (
				<>
					<Helmet>
						<title>SYPAC for Buyer</title>
					</Helmet>
					<CustomerRouter />
				</>
			)}
			{targetCompany === 'transporter' && (
				<>
					<Helmet>
						<title>SYPAC for Carrier</title>
					</Helmet>
					<TransporterRouter />
				</>
			)}
			<ToastContainer />
		</HelmetProvider>
	);
};

export default AppRouter;
