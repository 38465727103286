const CarrierCompanyTruck = () => {
	return (
		<svg
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 9V10.4995C1 11.0518 1.44772 11.4995 2 11.4995H2.5M1 9C1 8.44772 1.44772 8 2 8H11V2C11 1.44772 11.4477 1 12 1M1 9V3C1 1.89543 1.89543 1 3 1H12M12 1H14.1812C14.9813 1 15.7043 1.47679 16.0195 2.21216L16.8306 4.10481C16.9388 4.3571 17.1458 4.55386 17.4033 4.64896L18.3464 4.99727C18.7392 5.14232 19 5.51667 19 5.93534V9.88161C19 10.2603 18.7861 10.6065 18.4474 10.776L17 11.5M17 11.5C17 12.3284 16.3284 13 15.5 13C14.6716 13 14 12.3279 14 11.4995M17 11.5C17 10.6716 16.3284 10 15.5 10C14.6716 10 14 10.6711 14 11.4995M2.5 11.4995C2.5 12.3279 3.17157 13 4 13C4.82843 13 5.5 12.3279 5.5 11.4995M2.5 11.4995C2.5 10.6711 3.17157 10 4 10C4.82843 10 5.5 10.6711 5.5 11.4995M14 11.4995H5.5M15 7H14"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CarrierCompanyTruck;
