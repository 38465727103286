import {
	SypacBadge,
	SypacBox,
	SypacText,
} from '@sypac/component-library-react';
import dayjs from 'dayjs';

import { ProducerBillingStatisticProps } from './ProducerBillingStatistic.interface';
import { T } from '@tolgee/react';
import React from 'react';

const ProducerBillingStatistic = (props: ProducerBillingStatisticProps) => {
	const {
		amountPaid = '0',
		amountPending = '0',
		allOrders = '0',
		ordersLast30Days = '0',
		ordersLast60Days = '0',
		paidOrders = '0',
		orderInProgress = '0',
		ordersInDispute = '0',
		revenueLast30Days = '0',
		revenueLast60Days = '0',
		revenueLast90Days = '0',
		paymentTerm = 0,
		nextInvoicingDate = dayjs().toISOString(),
	} = props;

	const formatAmount = (value: string) => {
		const numberFormat = new Intl.NumberFormat('pl', {
			style: 'currency',
			currency: 'PLN',
		});
		return numberFormat.format(parseFloat(value));
	};

	return (
		<div className="relative w-full shadow-dropdown-avatar">
			<SypacBox hover={false} box-style="solid" box-color={'primary'}>
				<div className="p-5">
					<div className="flex sm:flex-col lg:flex-row sm:items-start max-lg:gap-2 lg:items-center">
						<SypacText variant="heading-4">
							<p className="text-gray-80">{formatAmount(amountPaid)} </p>
						</SypacText>
						<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center lg:ml-3">
							<SypacText variant="body-regular-small">
								<p className="text-gray-80">zł</p>
							</SypacText>
						</div>
						<div className="flex lg:ml-3">
							<SypacBadge color={'gray'}>
								<SypacText variant="overline-regular-medium">
									<p className="text-gray-80">
										<T keyName="producerBillingStatistic.paymentScheduledFor">
											Payment scheduled for
										</T>{' '}
										{dayjs(nextInvoicingDate).format('DD MMM')}
									</p>
								</SypacText>
							</SypacBadge>
						</div>
						<SypacText variant="heading-4" className="lg:ml-6">
							<p className="text-gray-80">{formatAmount(amountPending)} </p>
						</SypacText>
						<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center lg:ml-3">
							<SypacText variant="body-regular-small">
								<p className="text-gray-80">zł</p>
							</SypacText>
						</div>
					</div>
					<div className="flex mt-3 justify-between">
						{/* <SypacText variant="overline-regular-large">
							<p className="text-gray-40">
								<T keyName="producerBillingStatistic.eardnedFrom">
									Earned from
								</T>{' '}
								{dayjs(nextInvoicingDate)
									.subtract(paymentTerm, 'days')
									.format('DD MMM. YYYY')}{' '}
								<T keyName="producerBillingStatistic.to">to</T>{' '}
								{dayjs(nextInvoicingDate).format('DD MMM. YYYY')}
							</p>
						</SypacText> */}
						<SypacText variant="overline-regular-large" className="ml-auto">
							<p className="text-gray-40">
								<T keyName="producerBillingStatistic.bondBalance">
									Bond balance
								</T>
							</p>
						</SypacText>
					</div>
				</div>
				<div className="h-[1px] bg-gray-10"></div>
				<div className="p-5">
					<div className="grid grid-cols-3 gap-4">
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-mountain-meadow">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.paidOrders">
										Paid orders:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{paidOrders}</p>
							</SypacText>
						</div>
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-texas-rose">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.orderInProgress">
										Order in progress:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{orderInProgress}</p>
							</SypacText>
						</div>
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-red">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.ordersInDispute">
										Orders in dispute:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{ordersInDispute}</p>
							</SypacText>
						</div>
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.allOrders">
										All orders:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{allOrders}</p>
							</SypacText>
						</div>
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.orderLast30Days">
										Order last 30 days:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{ordersLast30Days}</p>
							</SypacText>
						</div>
						<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.orderLast60Days">
										Order last 60 days:
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="ml-3">
								<p className="text-gray-80 font-medium">{ordersLast60Days}</p>
							</SypacText>
						</div>

						<div className="flex sm:flex-col gap-y-2 2xl:flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.revenueLast30">
										Revenue last 30 d.:
									</T>
								</p>
							</SypacText>
							<div className="flex 2xl:flex-col 3xl:flex-row items-center">
								<SypacText variant="body-regular-large" className="ml-3">
									<p className="text-gray-80">{revenueLast30Days}</p>
								</SypacText>
								<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
									<SypacText variant="body-regular-small">
										<p className="text-gray-80">zł</p>
									</SypacText>
								</div>
							</div>
						</div>
						<div className="flex sm:flex-col gap-y-2 2xl:flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.revenueLast60">
										Revenue last 60 d.:
									</T>
								</p>
							</SypacText>
							<div className="flex 2xl:flex-col 3xl:flex-row items-center">
								<SypacText variant="body-regular-large" className="ml-3">
									<p className="text-gray-80">{revenueLast60Days}</p>
								</SypacText>
								<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
									<SypacText variant="body-regular-small">
										<p className="text-gray-80">zł</p>
									</SypacText>
								</div>
							</div>
						</div>
						<div className="flex sm:flex-col gap-y-2 2xl:flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="producerBillingStatistic.revenueLast90">
										Revenue last 90 d.:
									</T>
								</p>
							</SypacText>
							<div className="flex 2xl:flex-col 3xl:flex-row items-center">
								<SypacText variant="body-regular-large" className="ml-3">
									<p className="text-gray-80">{revenueLast90Days}</p>
								</SypacText>
								<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
									<SypacText variant="body-regular-small">
										<p className="text-gray-80">zł</p>
									</SypacText>
								</div>
							</div>
						</div>
					</div>
				</div>
			</SypacBox>
		</div>
	);
};

export default ProducerBillingStatistic;
