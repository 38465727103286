export const COLORS: {
	[key: string]: {
		[key: string]: string;
	};
} = {
	white: { en: 'White', pl: 'Biały' },
	silver: { en: 'Silver', pl: 'Srebrny' },
	black: { en: 'Black', pl: 'Czarny' },
	gray: { en: 'Gray', pl: 'Szary' },
	blue: { en: 'Blue', pl: 'Niebieski' },
	red: { en: 'Red', pl: 'Czerwony' },
	brown: { en: 'Brown', pl: 'Brązowy' },
	green: { en: 'Green', pl: 'Zielony' },
	yellow: { en: 'Yellow', pl: 'Zółty' },
	orange: { en: 'Orange', pl: 'Pomarańczowy' },
	purple: { en: 'Purple', pl: 'Fioletowy' },
	other: { en: 'Other', pl: 'Inny' },
};
