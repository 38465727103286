import React, { useContext, useEffect, useState } from 'react';
import { OrderContext } from '../../context/OrderContext/order.context';

import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '../../assets/SearchIcon';
import './search-input.css';

interface SearchInputProps {
	placeholder: string;
	onChange?: (search: string) => void;
}

const SearchInput = (props: SearchInputProps) => {
	const { placeholder, onChange } = props;
	const [search, setSearchLocal] = useState('');
	const { setSearch } = useContext(OrderContext);
	const searchQuery = useDebounce(search, 500);

	useEffect(() => {
		onChange && onChange(searchQuery);
		setSearch(searchQuery);
	}, [onChange, searchQuery, setSearch]);

	return (
		<div className="input-group">
			<input
				type="text"
				className="search-input"
				placeholder={placeholder}
				onChange={(event) => {
					setSearchLocal(event.target.value);
				}}
			/>
			<SearchIcon />
		</div>
	);
};

export default SearchInput;
