import {
	SypacButton,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import React, { useContext, useEffect, useState } from 'react';
import { OrderContext } from '../../context/OrderContext/order.context';
import useDebounce from '../../hooks/useDebounce';
import { SearchBarProps } from './search-bar.interface';
import { T } from '@tolgee/react';

const SearchBar = (props: SearchBarProps) => {
	const { placeholder, onChange, onClick, showButton = true, classes } = props;
	const [search, setSearchLocal] = useState('');
	const { setSearch } = useContext(OrderContext);
	const searchQuery = useDebounce(search, 500);

	useEffect(() => {
		onChange && onChange(searchQuery);
		setSearch(searchQuery);
	}, [onChange, searchQuery, setSearch]);

	return (
		<SypacInput
			className={`box-border flex flex-row gap-2 items-center w-[269px] h-[44px] border border-solid border-gray-10 transition hover:border-gray-40 focus-within:border-cornflower-blue py-2 px-3 rounded-[10px] bg-white ${classes}`}
		>
			<SypacIcon
				icon-name="Magnifer"
				className="text-gray-40"
				size="custom"
				width="20px"
				height="20px"
			/>
			<input
				type="text"
				className="text-base placeholder:text-gray-40 p-0 outline-none border-0 bg-transparent flex-1"
				placeholder={placeholder}
				onChange={(event) => {
					setSearchLocal(event.target.value);
				}}
			/>
			{showButton ? (
				<SypacButton variant="primary" size="small">
					<button
						className="search-button"
						onClick={() => (onClick ? onClick(search) : '')}
					>
						<SypacText variant="body-normal-small">
							<p className="leading-[22px]">
								<T keyName="saechBar.search">Search</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			) : null}
		</SypacInput>
	);
};

export default SearchBar;
