export const RejectBadge = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<rect width="40" height="40" fill="#F44A77" rx="8"></rect>
			<circle cx="20" cy="20" r="8" fill="#fff"></circle>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				strokeWidth="1.3"
				d="M22 18l-4 4m0-4l4 4"
			></path>
		</svg>
	);
};
