import React from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { ModalRemoveTruckProps } from './ModalRemoveTruck.interface';
import './modal-remove-truck.css';
import { Trash } from '../../assets/Trash';
import { T } from '@tolgee/react';

const ModalRemoveTruck = ({
	id,
	name,
	isOpen,
	onClose,
	onDelete,
}: ModalRemoveTruckProps) => {
	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[540px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between p-5">
					<SypacText variant="heading-5">
						<p className="text-gray-80 font-semibold">
							<T keyName="modalRemoveTruck.removeTruck">Remove truck</T>
						</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<div className="flex flex-col items-center justify-center modal-remove-truck-border px-6">
					<div className="flex py-10">
						<Trash />
					</div>
					<SypacText className="pb-5" variant="heading-5">
						<p className="text-gray-80 text-center font-semibold">
							<T keyName="modalRemoveTruck.reallyWantDeleteTruck">
								Do you really want to delete this truck:
							</T>{' '}
							“{name}”?
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-gray-40 text-center pb-10">
							<T keyName="modalRemoveTruck.removeTruckDescription">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							</T>
						</p>
					</SypacText>
				</div>
				<div className="flex gap-3 justify-center py-5">
					<SypacButton variant="secondary" size="small" onClick={onClose}>
						<button className="modal-reject-button">
							<SypacText variant="body-regular-medium">
								<p className="text-red -tracking-[0.01em]">
									<T keyName="modalRemoveTruck.reject">Reject</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => (onDelete ? onDelete(id) : null)}
					>
						<button className="modal-confirm-button">
							<SypacText variant="body-regular-medium">
								<p className="text-mountain-meadow -tracking-[0.01em]">
									<T keyName="modalRemoveTruck.confirm">Confirm</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalRemoveTruck;
