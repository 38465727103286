import { SypacText } from '@sypac/component-library-react';
import { SortingItemInterface } from './Sorting.interface';

const mainStyle =
	'no-underline flex hover:bg-gray-10-opacity-50 bg-white border-none pt-4 pb-[17px] px-5 rounded-md cursor-pointer';

export const SortingItem = (option: SortingItemInterface) => {
	return <SortingItems {...option} />;
};

const SortingItems = ({ title, action, value }: SortingItemInterface) => {
	return (
		<div
			className={mainStyle}
			onClick={() => (action ? action({ title, value }) : null)}
		>
			<SypacText variant="body-regular-medium" className="mr-auto">
				<p className="text-gray-80 leading-[19px]">{title}</p>
			</SypacText>
		</div>
	);
};
