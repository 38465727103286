const TooltipCircle = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="9"
				cy="9"
				r="8"
				fill="#F9F9F9"
				stroke="#686B6F"
				strokeOpacity="0.2"
			/>
			<path d="M9 13V8.2" stroke="#454545" strokeLinecap="round" />
			<circle
				cx="0.8"
				cy="0.8"
				r="0.8"
				transform="matrix(1 0 0 -1 8.2002 6.59961)"
				fill="#454545"
			/>
		</svg>
	);
};

export default TooltipCircle;
