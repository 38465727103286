import MenuItems from './MenuItems';

interface DropdownInterface {
	submenus: Record<string, string>[];
	dropdown: boolean;
	depthLevel: number;
	setSelected: any;
	selected: any[];
	selectedItems: any[];
	setChecked?: any;
	classDrop: string;
}

const Dropdown = ({
	submenus,
	dropdown,
	depthLevel,
	setSelected,
	selected,
	setChecked,
	selectedItems,
	classDrop,
}: DropdownInterface) => {
	depthLevel = depthLevel + 1;
	const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
	return (
		<div className={`${classDrop} absolute -top-[300px] left-full pl-2.5`}>
			<ul
				className={`dropdown w-[256px] ${dropdownClass} ${
					dropdown ? 'show' : ''
				}`}
			>
				{submenus.map((submenu: any, index: number) => (
					<MenuItems
						items={submenu}
						key={index}
						depthLevel={depthLevel}
						setSelected={setSelected}
						setChecked={setChecked}
						selectedItems={selectedItems}
						selected={selected}
					/>
				))}
			</ul>
		</div>
	);
};

export default Dropdown;
