import React from 'react';
import { SypacIcon } from '@sypac/component-library-react';

export const RenderIconByTarget = ({ target }: { target: string }) => {
	const Icons: Record<string, () => JSX.Element> = {
		customer: () => (
			<SypacIcon iconName="User" size="custom" width="15px" height="20px" />
		),
		transporter: () => (
			<SypacIcon iconName="Bus" size="custom" width="15px" height="20px" />
		),
		producer: () => (
			<SypacIcon
				iconName="Cart Large Minimalistic"
				size="custom"
				width="15px"
				height="20px"
			/>
		),
	};
	const Icon = Icons[target] || Icons['customer'];
	return <Icon />;
};
