import { IconCompleted } from '../../assets/IconCompleted';
import { IconRegistration } from '../../assets/IconRegistration';
import { IconVerification } from '../../assets/IconVerification';

export const RenderIconByStaus = ({ status }: { status: string }) => {
	const Icons: Record<string, () => JSX.Element> = {
		registrationInProgress: () => <IconRegistration />,
		registration_in_progress: () => <IconRegistration />,
		verificationPending: () => <IconVerification />,
		verification_pending: () => <IconVerification />,
		verified: () => <IconCompleted />,
	};
	const Icon = Icons[status] || Icons['verified'];
	return <Icon />;
};
