const COLUMNS = [
	{
		Header: 'Order',
		accessor: 'order',
	},
	{
		Header: 'Product',
		accessor: 'product',
	},
	{
		Header: 'Quantity',
		accessor: 'quantity',
	},
	{
		Header: 'Price',
		accessor: 'price',
	},
];

export default COLUMNS;
