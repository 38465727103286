import { SypacBox } from '@sypac/component-library-react';

import { BoxComponentProps } from './BoxComponent.interface';
import './box-component.css';

const BoxComponent = ({
	notification,
	disabled,
	children,
}: BoxComponentProps) => {
	return (
		<div
			className={`${notification ? 'box-notification-dot' : ''} ${
				disabled ? 'box-disabled' : ''
			}`}
		>
			<SypacBox
				hover={false}
				box-style="solid"
				box-color={`${!notification || disabled ? 'primary' : 'gradient'}`}
			>
				{children}
			</SypacBox>
		</div>
	);
};

export default BoxComponent;
