const PaidInvoice = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="23"
			fill="none"
			viewBox="0 0 22 23"
		>
			<rect width="22" height="22" y="0.336" fill="#20AC93" rx="11"></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M5.398 11.336l3.92 4 7.28-8"
			></path>
		</svg>
	);
};

export default PaidInvoice;
