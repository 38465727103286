import axios from 'axios';
import { memorizedRefreshToken } from '../services/refreshToken';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL!;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const parseJwt = (token: any) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

axios.interceptors.request.use(
	async (config) => {
		let accessToken = localStorage.getItem('access_token');
		const refreshToken = localStorage.getItem('refresh_token');

		if (accessToken && refreshToken) {
			const decodedJwt = parseJwt(accessToken);
			if (decodedJwt.exp * 1000 < Date.now()) {
				const result = await memorizedRefreshToken();
				accessToken = result?.accessToken;
			}
		}

		if (accessToken) {
			config.headers = {
				...config.headers,
				authorization: `Bearer ${accessToken}`,
			};
		}

		return config;
	},
	(error) => Promise.reject(error),
);

export default axios;
