import { SypacButton, SypacText } from '@sypac/component-library-react';
import React, { useEffect, useRef, useState } from 'react';
import { OrderInterface } from '../../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrdersService,
	ProducerOrderAction,
} from '../../../../../../services/orders.services';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { T } from '@tolgee/react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';

const InProgress: React.FC<ProducerOrderProps> = (props) => {
	const { selectedOrder, setOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);

	const handleClick = async () => {
		try {
			await OrdersService.actionOrder(
				{ wight: 1, documents: [] },
				OrderTarget.producer,
				ProducerOrderAction.CONFRIM_PICKUP,
				selectedOrder?.id!,
			);
			const { data } = await OrdersService.getOrder(
				selectedOrder?.id!,
				OrderTarget.producer,
			);
			setOrder?.(data as unknown as OrderInterface);
		} catch (e) {
			console.log(e);
		}
	};

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	return (
		<div style={{ marginBottom: `${boxHeight}px` }}>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={2}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				{selectedOrder?.deliveryIncluded ? (
					<PickUpSchedule selectedOrder={selectedOrder} step={2} />
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			<div
				className={`px-10 ${
					!selectedOrder?.deliveryIncluded ? 'py-3.5' : 'pb-7 pt-3.5'
				}`}
			>
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			{!selectedOrder?.deliveryIncluded ? (
				<div className="px-10 pb-7 pt-3.5">
					<PickUpSchedule selectedOrder={selectedOrder} step={2} />
				</div>
			) : null}

			{!selectedOrder?.deliveryIncluded ? (
				<div
					className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-gray-40 border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign"
					ref={boxRef}
				>
					<div className="flex flex-col justify-center items-start p-8 gap-5">
						<SypacText variant="overline-regular-large">
							<p className="text-base leading-6 tracking-tighter text-gray-40">
								<T keyName="boxInProgressStep.truckArrivalConfirmation">
									Truck arrival confirmation
								</T>
							</p>
						</SypacText>

						<SypacText variant="heading-5">
							<p className="tracking-tighter text-gray-90">
								<T keyName="boxInProgressStep.confirmTruckArrival">
									Please confirm truck arrival to proceed.
								</T>
							</p>
						</SypacText>

						<SypacButton variant="primary" size="large">
							<button className="w-fit transition" onClick={handleClick}>
								<p className="m-0">
									<T keyName="boxInProgressStep.excludedConfirmTruckArrival">
										Confirm truck arrival
									</T>
								</p>
							</button>
						</SypacButton>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default InProgress;
