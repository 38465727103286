import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	OrderContext,
	OrderProps,
} from '../../context/OrderContext/order.context';
import useDebounce from '../../hooks/useDebounce';
import { GeoService } from '../../services/geo.services';
import { Geo } from '../SearchLocation/locations.interface';
import SearchLocation from '../SearchLocation/SearchLocation';
import { T } from '@tolgee/react';
import UlpoadLocationPhoto from '../UlpoadLocationPhoto/UlpoadLocationPhoto';
import { FileService } from '../../services/file.service';
import { QuantityPickerContext } from '../../context/QuantityPickerContext/quantity-picker.context';
import Map from '../Map/Map';
import { OrdersService } from '../../services/orders.services';

const SelectLocation = () => {
	const navigate = useNavigate();
	const [search, setSearch] = useState('');
	const [locations, setLocations] = useState<Geo[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [showUpload, setUploadModal] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState<string>('orders');
	const searchQuery = useDebounce(search, 500);
	const { order, setOrder } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);

	const getRecentOrders = async () => {
		try {
			const query = {
				limit: 5,
				offset: 0,
			};
			const { data } = await OrdersService.getRecentOrders(query);
			if (!data.count) {
				return;
			}
			setLocalRecentOrders(data.items || []);
		} catch (e) {}
	};

	const selectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const saveLocationPhoto = async (file: File) => {
		try {
			const { data } = await FileService.uploadFiles([file]);
			const locationPhoto = data.items?.at(0)?.path;
			setOrder({ ...order, locationPhoto });
			setUploadModal(false);
			navigate('/store/groups');
		} catch (e) {}
	};

	const selectLocation = (location: Geo) => {
		setOrder({ ...order, location });
		setUploadModal(true);
	};

	const getResult = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch(console.error);
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	useEffect(() => {
		getRecentOrders();
		setOrder(undefined);
		setInputQuantity(0);
		setTotalQuantityCounter(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderRecentOrders = (order: OrderProps) => {
		return (
			<button
				className="w-full h-[68px] flex items-center bg-transparent border border-solid border-gray-10 rounded-[10px] px-3 transition hover:border-gray-40 cursor-pointer"
				onClick={() => selectRecentOrder(order)}
			>
				<div
					style={{
						backgroundImage: `url(${order?.product?.photoUrl})`,
					}}
					className="flex flex-none w-[44px] h-[44px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
				/>
				<div className="flex flex-col ml-5 items-start">
					<SypacText variant="body-normal-medium">
						<p
							className={`text-base text-gray-80 ${
								!order?.product?.type && !order?.product?.size
									? 'pb-[20px]'
									: ''
							}`}
						>
							{order?.product?.name}
						</p>
					</SypacText>
					<SypacText variant="body-normal-medium">
						<p className="text-sm text-gray-40">
							{order?.product?.type}{' '}
							{order?.product?.size && `(${order?.product?.size})`}
						</p>
					</SypacText>
				</div>
			</button>
		);
	};

	return (
		<>
			<div className="w-full flex gap-[20px] justify-center items-center">
				<div className="w-[556px] h-[738px] flex flex-col justify-between">
					<SypacText variant="body-regular-large">
						<p className="text-[40px] tracking-tighter text-gray-80">
							<T keyName="clientSelectLocation.welcomeToTheStore">
								Welcome to the Sypac store!
							</T>
						</p>
					</SypacText>

					<div className="flex flex-col gap-10">
						<div className="flex flex-col gap-6">
							<SypacText variant="body-regular-large">
								<p className="text-xl text-gray-80">
									<T keyName="clientSelectLocation.whereToDeliverYourGoods">
										Where to deliver your goods?
									</T>
								</p>
							</SypacText>
							<SearchLocation
								inputName="search_location"
								locations={locations}
								onChange={setSearch}
								selectLocation={selectLocation}
							/>
						</div>

						<div className="w-full flex flex-col h-[480px] border border-solid border-gray-10 rounded-[10px] bg-white overflow-hidden">
							<div className="w-[530px] fixed flex gap-[4px] p-3 rounded-t-[10px] border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 bg-white z-30">
								{/* <SypacButton
									variant="secondary"
									size="small"
									className="w-full"
								>
									<button
										className={`w-full h-[32px] rounded-lg flex gap-2 items-center border-none transition group ${
											currentTab === 'sites'
												? 'bg-primary-violet text-white-100'
												: 'hover:bg-gray-10 text-gray-80'
										}`}
										onClick={() => handleClick('sites')}
									>
										<SypacIcon
											iconName="Buildings"
											size="custom"
											width="24px"
											height="24px"
											className="mb-[2px]"
										/>
										<SypacText variant="body-regular-medium">
											<p>My construction sites</p>
										</SypacText>
									</button>
								</SypacButton> */}
								<SypacButton
									variant="secondary"
									size="small"
									className="w-full"
								>
									<button
										className={`w-full h-[32px] rounded-lg flex gap-2 items-center border-none transition group ${
											currentTab === 'orders'
												? 'bg-primary-violet text-white-100'
												: 'hover:bg-gray-10 text-gray-80'
										}`}
										onClick={() => setCurrentTab('orders')}
									>
										<SypacIcon
											iconName="Clock Circle"
											size="custom"
											width="24px"
											height="24px"
											className="mb-[2px]"
										/>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="clientSelectLocation.myRecentOrders">
													My recent orders
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>

							{currentTab === 'sites' ? (
								<div className="h-full flex justify-center items-center">
									<div className="w-[339px] h-[150px] flex flex-col justify-between text-center">
										<div className="flex flex-col gap-4">
											<SypacText variant="body-regular-large">
												<p className="text-xl text-gray-80">
													No construction sites
												</p>
											</SypacText>
											<SypacText variant="body-regular-large">
												<p className="text-base text-gray-40">
													No create construction sites yet. To add new
													construction sites click on button down below.
												</p>
											</SypacText>
										</div>
										<SypacButton
											variant="secondary"
											size="small"
											className="w-full"
										>
											<button
												className="w-[182px] h-[32px] rounded-lg flex gap-2 items-center transition group whitespace-nowrap"
												// onClick={}
											>
												<SypacText variant="body-regular-medium">
													<p className="text-sm">
														<T keyName="clientSelectLocation.createConstructionSites">
															Create construction sites
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</div>
								</div>
							) : null}

							{currentTab === 'orders' ? (
								localRecentOrders.length !== 0 ? (
									<div className="w-full h-full overflow-y-scroll scroll-smooth flex flex-col gap-3 p-3 mt-[57px]">
										{localRecentOrders.map((order: OrderProps) => (
											<div className="mr-[11px]" key={order?.product?.id}>
												{renderRecentOrders(order)}
											</div>
										))}
									</div>
								) : (
									<div className="h-full flex justify-center items-center">
										<div className="w-[339px] h-[150px] flex flex-col justify-between text-center">
											<div className="flex flex-col gap-4">
												<SypacText variant="body-regular-large">
													<p className="text-xl text-gray-80">
														<T keyName="clientSelectLocation.noRecentOrdersYet">
															No recent orders yet
														</T>
													</p>
												</SypacText>
												<SypacText variant="body-regular-large">
													<p className="text-base text-gray-40">
														<T keyName="selectLocation.youHaveNoRecentOrders">
															You have no recent orders. Your orders will appear
															here once placed.
														</T>
													</p>
												</SypacText>
											</div>

											{/* <SypacButton
												variant="secondary"
												size="small"
												className="w-full"
											>
												<button
													className="w-[182px] h-[32px] rounded-lg flex gap-2 items-center transition group whitespace-nowrap"
													onClick={}
												>
													<SypacText variant="body-regular-medium">
														<p className="text-sm">
															<T keyName="clientSelectLocation.createConstructionSites">
																Create construction sites
															</T>
														</p>
													</SypacText>
												</button>
											</SypacButton> */}
										</div>
									</div>
								)
							) : null}
						</div>
					</div>
				</div>

				<div className="w-[562px] h-[738px]">
					<Map poligons={[]} markers={[]} target="customer" />
				</div>
			</div>
			<UlpoadLocationPhoto isOpen={showUpload} onSave={saveLocationPhoto} />
		</>
	);
};

export default SelectLocation;
