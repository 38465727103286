import React from 'react';
import Flag from 'react-world-flags';
import {
	SypacBadge,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';

import { CompanyInformationProps } from './CompanyInformation.interface';
import {
	colorsCompanyState,
	colorsTarget,
	titlesCompanyState,
	titlesTarget,
} from '../../constants/company-target.constants';
import { RenderIconByTarget } from '../RenderIconByTarget/RenderIconByTarget';
import { RenderIconByState } from '../RenderIconByState/RenderIconByState';
import './company-information.css';
import { T } from '@tolgee/react';

const CompanyInformation = ({ data }: CompanyInformationProps) => {
	return (
		<div className="flex flex-col company-information-border px-10 py-5 gap-6">
			<SypacText variant="body-regular-medium">
				<p className="text-gray-90 -tracking-[0.01em]">
					<T keyName="companyInformation.generalCompanyInformation">
						General company information
					</T>
				</p>
			</SypacText>
			<div className="grid grid-cols-12 gap-[14px]">
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.companyName">Company name:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">
							{data.companyName}
						</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.established">Established:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">
							{data.established}
						</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.companyRegistryNumber">
								Company Registration Number:
							</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">
							{data.registryNumber}
						</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.companyTaxNumber">
								Tax Identification Number:
							</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">{data.taxNumber}</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.companyAddress">
								Company address:
							</T>
						</p>
					</SypacText>
				</div>
				<div className="flex col-span-7 gap-2.5 items-center">
					<span className="rounded-[3px] overflow-hidden">
						<Flag
							code={data.country.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">{data.address}</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.emailAddress">Email address:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">{data.email}</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.companyType">Company type:</T>
						</p>
					</SypacText>
				</div>
				<div className="flex col-span-7 gap-3 items-center">
					{data.targets.map((r) => {
						return (
							<div key={r} className="flex flex-row mr-3">
								<SypacBadge color={colorsTarget[r]} size="large">
									<div className="flex items-center justify-center gap-2 py-0 px-2.5">
										<RenderIconByTarget target={r} />
									</div>
								</SypacBadge>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 -tracking-[0.01em] ml-1">
										{titlesTarget[r]}
									</p>
								</SypacText>
							</div>
						);
					})}
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.website">Website</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacLink>
						<a href={data.website} target="_blank" rel="noreferrer">
							{data.website}
						</a>
					</SypacLink>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.phoneNumber">Phone number:</T>
						</p>
					</SypacText>
				</div>
				<div className="flex col-span-7 gap-2.5 items-center">
					<span className="rounded-[3px] overflow-hidden">
						<Flag
							code={data.country.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 -tracking-[0.01em]">
							{data.phoneNumber}
						</p>
					</SypacText>
				</div>
				<div className="col-span-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="companyInformation.accountStatus">Account status:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-7">
					<SypacBadge color={colorsCompanyState[data.status]} size="large">
						<div className="flex items-center justify-center gap-2 px-4">
							{data.status === 'verified' ? (
								<RenderIconByState state={`short_${data.status}`} />
							) : null}
							<SypacText variant="overline-normal-large">
								<p>{titlesCompanyState[data.status]}</p>
							</SypacText>
						</div>
					</SypacBadge>
				</div>
			</div>
		</div>
	);
};

export default CompanyInformation;
