export const Success = () => {
	return (
		<svg
			width="54"
			height="60"
			viewBox="0 0 54 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 25.25C0 15.6572 0 10.8608 1.13255 9.24723C2.26509 7.63362 6.77497 6.08987 15.7947 3.00238L17.5132 2.41415C22.2149 0.804717 24.5658 0 27 0C29.4342 0 31.7851 0.804717 36.4868 2.41415L38.2053 3.00238C47.225 6.08987 51.7349 7.63362 52.8675 9.24723C54 10.8608 54 15.6572 54 25.25C54 26.6989 54 28.2703 54 29.9741C54 46.8883 41.2831 55.0965 33.3043 58.5818C31.14 59.5273 30.0578 60 27 60C23.9422 60 22.86 59.5273 20.6957 58.5818C12.7169 55.0965 0 46.8883 0 29.9741C0 28.2703 0 26.6989 0 25.25Z"
				fill="#20AC93"
				fillOpacity="0.1"
			/>
			<path
				d="M19.5 31.2L23.7857 36L34.5 24"
				stroke="#20AC93"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
