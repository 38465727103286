const HideMap = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.73975 9.80036C7.00379 9.36838 6.50781 8.56842 6.50781 7.64847C6.50781 6.27253 7.61976 5.15259 9.00369 5.15259C9.92365 5.15259 10.7236 5.64856 11.1556 6.39253"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.1904 13.6482C2.71847 11.6403 1.64652 9.07247 2.30249 6.19261C3.62242 0.384897 11.0541 -0.551057 14.3419 3.38475"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.7048 6.20056C16.6248 10.2644 14.0969 13.7042 11.881 15.8321C10.2731 17.384 7.7292 17.384 6.11328 15.8321"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.9992 1.00098L1 17.0002"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default HideMap;
