import React from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { TruckAllocatedProps } from './TruckAllocated.interface';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import '../notification.css';
import { T } from '@tolgee/react';

const TruckAllocated = ({
	notification,
	title,
	description,
	driverName,
	truckName,
	truckPlates,
	truckId,
	truckType,
}: TruckAllocatedProps) => {
	return (
		<BoxComponent notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="overline-regular-large">
					<p className="text-gray-80 leading-3">{description}</p>
				</SypacText>
				<SypacBox className="bg-gray-1">
					<div className="border-bottom px-3 py-1.5">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80">
								<T keyName="truckAllocated.aboutTruck">About truck</T>
							</p>
						</SypacText>
					</div>
					<div className="grid grid-cols-3 p-3 gap-2.5">
						<div>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="truckAllocated.driverName">Driver Name:</T>
								</p>
							</SypacText>
						</div>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{driverName}</p>
							</SypacText>
						</div>
						<div>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="truckAllocated.truckName">Truck Name:</T>
								</p>
							</SypacText>
						</div>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{truckName}</p>
							</SypacText>
						</div>
						<div>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="truckAllocated.truckPlates">Truck plates:</T>
								</p>
							</SypacText>
						</div>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{truckPlates}</p>
							</SypacText>
						</div>
						<div>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="truckAllocated.truckId">Truck ID:</T>
								</p>
							</SypacText>
						</div>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{truckId}</p>
							</SypacText>
						</div>
						<div>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-40">
									<T keyName="truckAllocated.truckType">Truck type:</T>
								</p>
							</SypacText>
						</div>
						<div className="col-span-2">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{truckType}</p>
							</SypacText>
						</div>
					</div>
				</SypacBox>
			</div>
		</BoxComponent>
	);
};

export default TruckAllocated;
