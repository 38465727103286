import React, { ReactElement } from 'react';
import { SypacText } from '@sypac/component-library-react';
import './input-border.css';

interface InputBorderProps {
	currency?: string;
	children: ReactElement<any, any>;
	error: boolean;
}

const InputBorder = ({
	currency = 'PLN',
	children,
	error = false,
}: InputBorderProps) => {
	return (
		<div className={` ${error ? 'input-container-error' : 'input-container'}`}>
			{children}
			<SypacText variant="heading-5">
				<p className="input-currency">{currency}</p>
			</SypacText>
		</div>
	);
};

export default InputBorder;
