export const BlueBox = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.8622 2.10557L13.4622 2.94522C15.1835 3.84853 16.0442 4.30019 16.5221 5.11179C17 5.9234 17 6.93334 17 8.95321V9.04679C17 11.0667 17 12.0766 16.5221 12.8882C16.0442 13.6998 15.1835 14.1515 13.4622 15.0548L11.8622 15.8944C10.4577 16.6315 9.75542 17 9 17C8.24458 17 7.54233 16.6315 6.13783 15.8944L4.53783 15.0548C2.8165 14.1515 1.95583 13.6998 1.47792 12.8882C1 12.0766 1 11.0667 1 9.04679V8.95321C1 6.93334 1 5.9234 1.47792 5.11179C1.95583 4.30019 2.8165 3.84853 4.53783 2.94521L6.13783 2.10557C7.54233 1.36852 8.24458 1 9 1C9.75542 1 10.4577 1.36852 11.8622 2.10557Z"
				stroke="#5682FA"
				strokeLinecap="round"
			/>
			<path
				d="M16.1998 5.4L12.9998 7M8.9998 9L1.7998 5.4M8.9998 9V16.6M8.9998 9C8.9998 9 11.1939 7.90294 12.5998 7.2C12.756 7.1219 12.9998 7 12.9998 7M12.9998 7V9.8M12.9998 7L5.3998 3"
				stroke="#5682FA"
				strokeLinecap="round"
			/>
		</svg>
	);
};
