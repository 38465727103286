export const TruckNotApprove = () => {
	return (
		<svg
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15.0004" cy="11.7992" r="1.6" stroke="#A2A2A2" />
			<circle cx="7.00039" cy="11.7992" r="1.6" stroke="#A2A2A2" />
			<path
				d="M5.4 11.7776C4.52262 11.7338 3.97528 11.6033 3.58579 11.2138C3.19629 10.8243 3.02204 10.0013 3.02204 10.0013M8.6 11.7996H13.4M16.6 11.7776C17.4774 11.7338 18.0247 11.6033 18.4142 11.2138C19 10.628 19 9.68523 19 7.79961V6.19961H15.24C14.6444 6.19961 14.3466 6.19961 14.1056 6.1213C13.6185 5.96303 13.2366 5.58114 13.0783 5.09404C13 4.85302 13 4.55522 13 3.95961C13 3.06619 13 2.61949 12.8825 2.25797C12.6451 1.52732 12.0723 0.954477 11.3416 0.717074C10.9801 0.599609 10.5334 0.599609 9.64 0.599609H3"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 2.19922H14.457C15.6213 2.19922 16.2034 2.19922 16.6771 2.48219C17.1509 2.76515 17.4269 3.27771 17.9789 4.30282L19 6.19922"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.99998 3.43752L1 7.4375M0.999983 3.4375L4.99997 7.43748"
				stroke="#F44A77"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};
