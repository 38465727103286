import { SypacPagination, SypacText } from '@sypac/component-library-react';
import React from 'react';
import { LIMIT } from '../../constants';
import { T } from '@tolgee/react';

const Pagination = (props: any) => {
	const { page, count, onClick, showText = true } = props;

	return (
		<>
			{showText ? (
				<div>
					<SypacText variant="body-normal-medium">
						<p className="text-gray">
							<T keyName="pagination.showing">Showing</T>{' '}
							{count ? page * LIMIT + 1 : 0}-
							{(page + 1) * LIMIT > count ? count : (page + 1) * LIMIT}{' '}
							<T keyName="pagination.of">of</T> {count}
						</p>
					</SypacText>
				</div>
			) : (
				''
			)}
			<div>
				{count ? (
					<SypacPagination
						count={Math.ceil(count / LIMIT)}
						page={page + 1}
						view-page-range="6"
						onPageChanged={(e) => {
							onClick(e.detail.page - 1);
						}}
					/>
				) : null}
			</div>
		</>
	);
};

export default Pagination;
