import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { BlueBox } from '../../../assets/BlueBox';
import { TruckApprove } from '../../../assets/TruckApprove';
import dayjs from 'dayjs';
import { convertToTimeZone, formatTimeSince } from '../../../utils/time.util';
import { Checkmark } from '../../../assets/Checkmark';
import { OrderTarget } from '../../OrdersTable/interfaces/OrderStatus.interface';
import { Stack } from '../../../assets/Stack';
import { TruckNotApprove } from '../../../assets/TruckNotApprove';
import { Tooltip } from 'react-tooltip';
import {
	ADMIN_STEPS_MULTIPLE,
	ADMIN_STEPS_MULTIPLE_EXCLUDED,
	ADMIN_STEPS_SIMPLE,
	ADMIN_STEPS_SIMPLE_EXCLUDED,
	ADMIN_SUB_STATUS_MULTIPLE,
	ADMIN_SUB_STATUS_MULTIPLE_EXCLUDED,
	ADMIN_SUB_STATUS_SIMPLE,
	ADMIN_SUB_STATUS_SIMPLE_EXCLUDED,
	CUSTOMER_STEPS_MULTIPLE,
	CUSTOMER_STEPS_MULTIPLE_EXCLUDED,
	CUSTOMER_STEPS_SIMPLE,
	CUSTOMER_STEPS_SIMPLE_EXCLUDED,
	CUSTOMER_SUB_STATUS_MULTIPLE,
	CUSTOMER_SUB_STATUS_MULTIPLE_EXCLUDED,
	CUSTOMER_SUB_STATUS_SIMPLE,
	CUSTOMER_SUB_STATUS_SIMPLE_EXCLUDED,
	PRODUCER_STEPS,
	PRODUCER_STEPS_EXCLUDED,
	PRODUCER_SUB_STATUS,
	PRODUCER_SUB_STATUS_EXCLUDED,
	TRANSPORTER_OFFER_STEPS,
	TRANSPORTER_OFFER_SUB_STATUS,
	TRANSPORTER_STEPS,
	TRANSPORTER_SUB_STATUS,
} from './steps';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../OrdersTable/interfaces/Order.interface';
import ExpandedStepper from './ExpandedStepper';
import MultipleStepperStats from './MultipleStepperStats';

interface StepperProps {
	current: number;
	isFinalStep?: boolean;
	simpleOrder?: boolean;
	referenceId?: string;
	order: OrderInterface | undefined;
	onWidthChange?: any;
	isOffer?: boolean;
}

const Stepper: React.FC<StepperProps> = (props) => {
	const {
		current,
		isFinalStep,
		simpleOrder,
		referenceId,
		order,
		onWidthChange,
		isOffer,
	} = props;
	const { t } = useTranslate();
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [isExpandedOpen, setIsExpandedOpen] = useState<boolean>(false);
	const stepperRef = useRef<HTMLDivElement>(null);

	let currentStep;
	let currentSubStatus;
	const { target, createdAt, updatedAt, deliveryIncluded, notifications } =
		order || {};

	const notify = useMemo(
		() =>
			notifications?.items
				?.filter((r: any) => !r.readAt)
				?.map((r: any) => r.id),
		[notifications],
	);

	const subStatusIndex = useMemo(() => {
		return target === OrderTarget.admin &&
			!deliveryIncluded &&
			!simpleOrder &&
			current === 2
			? current - 2
			: current - 1;
	}, [target, deliveryIncluded, simpleOrder, current]);

	useEffect(() => {
		if (notify?.length! > 0) {
			setIsVisible(true);
			const timer = setTimeout(() => {
				setIsVisible(false);
			}, 5000);

			return () => clearTimeout(timer);
		}
	}, [notify]);

	switch (target) {
		case OrderTarget.transporter:
			currentStep = TRANSPORTER_STEPS;
			currentSubStatus = TRANSPORTER_SUB_STATUS;

			if (isOffer) {
				currentStep = TRANSPORTER_OFFER_STEPS;
				currentSubStatus = TRANSPORTER_OFFER_SUB_STATUS;
			}
			break;
		case OrderTarget.admin:
			if (simpleOrder) {
				if (deliveryIncluded) {
					currentStep = ADMIN_STEPS_SIMPLE;
					currentSubStatus = ADMIN_SUB_STATUS_SIMPLE;
				} else {
					currentStep = ADMIN_STEPS_SIMPLE_EXCLUDED;
					currentSubStatus = ADMIN_SUB_STATUS_SIMPLE_EXCLUDED;
				}
			} else {
				if (deliveryIncluded) {
					currentStep = ADMIN_STEPS_MULTIPLE;
					currentSubStatus = ADMIN_SUB_STATUS_MULTIPLE;
				} else {
					currentStep = ADMIN_STEPS_MULTIPLE_EXCLUDED;
					currentSubStatus = ADMIN_SUB_STATUS_MULTIPLE_EXCLUDED;
				}
			}
			break;
		case OrderTarget.customer:
			if (simpleOrder) {
				if (deliveryIncluded) {
					currentStep = CUSTOMER_STEPS_SIMPLE;
					currentSubStatus = CUSTOMER_SUB_STATUS_SIMPLE;
				} else {
					currentStep = CUSTOMER_STEPS_SIMPLE_EXCLUDED;
					currentSubStatus = CUSTOMER_SUB_STATUS_SIMPLE_EXCLUDED;
				}
			} else {
				if (deliveryIncluded) {
					currentStep = CUSTOMER_STEPS_MULTIPLE;
					currentSubStatus = CUSTOMER_SUB_STATUS_MULTIPLE;
				} else {
					currentStep = CUSTOMER_STEPS_MULTIPLE_EXCLUDED;
					currentSubStatus = CUSTOMER_SUB_STATUS_MULTIPLE_EXCLUDED;
				}
			}
			break;
		case OrderTarget.producer:
			if (!deliveryIncluded) {
				currentStep = PRODUCER_STEPS_EXCLUDED;
				currentSubStatus = PRODUCER_SUB_STATUS_EXCLUDED;
			} else {
				currentStep = PRODUCER_STEPS;
				currentSubStatus = PRODUCER_SUB_STATUS;
			}
			break;
		default:
			currentStep = undefined;
			currentSubStatus = undefined;
			break;
	}

	const renderTimeAgo = (
		target: string,
		current: number,
		additionalClasses = '',
	) => {
		if (isFinalStep) {
			return null;
		}

		return (
			<div className="flex items-center gap-2">
				<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
				<SypacText
					variant="overline-regular-large"
					className={`${additionalClasses}`}
				>
					<p className="text-gray-60">{formatTimeSince(updatedAt!)}</p>
				</SypacText>
			</div>
		);
	};

	const referenceFormatter = (id: string) => {
		return id.substring(0, 3) + ' ' + id.substring(3);
	};

	useEffect(() => {
		const element = stepperRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				onWidthChange(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, [onWidthChange]);

	return (
		<div
			className="fixed w-[702px] h-fit justify-center bg-white border border-solid border-gray-10 border-l-0 border-r-0 rounded-2xl mt-auto z-[1000]"
			ref={stepperRef}
		>
			<div className="flex flex-col justify-center items-start py-7 gap-5 [&_div]:box-border">
				{target !== OrderTarget.transporter ||
				(target === OrderTarget.transporter && !isOffer) ? (
					<>
						<div className="flex w-full justify-between items-center px-10">
							<div className="flex gap-5 [&_p]:text-gray-60">
								<SypacText variant="overline-regular-large">
									<p className="text-base leading-6 tracking-tighter">
										<T keyName="orderStepper.orderExecutionProgress">
											Order execution progress
										</T>
									</p>
								</SypacText>
								{!deliveryIncluded && referenceId ? (
									<>
										<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
										<SypacText variant="overline-regular-large">
											<p className="text-base leading-6 tracking-tighter">
												{`Reference ID ${referenceFormatter(referenceId)}`}
											</p>
										</SypacText>
									</>
								) : null}
							</div>

							<SypacButton variant="primary" size="small">
								<button
									className="p-0 m-0 border-none bg-transparent group"
									onClick={() => setIsExpandedOpen(!isExpandedOpen)}
								>
									<SypacText variant="overline-regular-large">
										<p className="text-base leading-6 tracking-tighter text-gray-60 transition group-hover:text-gray-80 group-hover:-translate-y-[3px]">
											{isExpandedOpen ? (
												<T keyName="orderStepper.viewLess">View less</T>
											) : (
												<T keyName="orderStepper.viewAllSteps">
													View all steps
												</T>
											)}
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
						<div className="flex w-full gap-[4px] px-10">
							{currentStep?.map((_, idx) => (
								<div
									key={idx}
									className={`w-full h-[10px] rounded ${
										isFinalStep
											? 'bg-shadow-blue'
											: idx <= current - 1
											? 'bg-texas-rose'
											: 'bg-light-grey'
									}`}
								/>
							))}
						</div>
					</>
				) : null}

				{isExpandedOpen ? (
					<ExpandedStepper currentStep={currentStep} current={current} />
				) : null}

				<div className="flex w-full gap-7 px-10">
					{isFinalStep ? (
						<div className="relative">
							<div className="w-[54px] h-[54px] bg-mountain-meadow rounded-lg flex justify-center items-center text-white-100">
								<Checkmark />
							</div>
						</div>
					) : (
						<div className="relative">
							<div className="w-[54px] h-[54px] bg-cornflower-blue rounded-lg flex justify-center items-center text-white-100">
								<SypacIcon
									iconName="Bell"
									size="custom"
									width="32px"
									height="32px"
								/>
							</div>

							{isVisible ? (
								<div className="absolute flex justify-center items-center w-[16px] h-[16px] bg-white rounded-full -top-[8px] -right-[8px]">
									<div className="w-[10px] h-[10px] bg-red rounded-full" />
									<div className="absolute w-[10px] h-[10px] bg-red rounded-full animate-ping" />
								</div>
							) : null}
						</div>
					)}

					<div className="w-full flex flex-col gap-1">
						<div className="flex justify-between">
							<SypacText variant="overline-regular-large">
								{currentStep?.[current - 1] && (
									<p className="max-w-[366px] text-xl leading-7 tracking-tighter text-gray-80">
										<T keyName={`orderStepper.${currentStep?.[current - 1]}`}>
											{currentStep?.[current - 1]}
										</T>
									</p>
								)}
							</SypacText>
							<div className="flex gap-4 justify-center items-center text-gray-40 [&_span]:scale-[1.2] mr-[6px]">
								{target === OrderTarget.admin ||
								target === OrderTarget.customer ? (
									<span
										data-tooltip-id="order-hint"
										data-tooltip-content={
											simpleOrder
												? t(
														'baseOrderDetails.singleDelivery',
														'Single delivery',
												  )
												: t(
														'baseOrderDetails.multipleDelivery',
														'Multiple delivery',
												  )
										}
									>
										{simpleOrder ? <BlueBox /> : <Stack />}
									</span>
								) : null}
								<span
									data-tooltip-id="order-hint"
									data-tooltip-content={
										deliveryIncluded
											? t(
													'baseOrderDetails.transportIncluded',
													'Transport included',
											  )
											: target !== OrderTarget.producer
											? t(
													'baseOrderDetails.transportExcluded',
													'Transport excluded',
											  )
											: t('baseOrderDetails.selfService', 'Self service')
									}
								>
									{deliveryIncluded ? <TruckApprove /> : <TruckNotApprove />}
								</span>
								<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
								<span
									data-tooltip-id="order-hint"
									data-tooltip-content={t(
										'orderStepper.orderCreatedAt',
										'Order created at',
									)}
								>
									<SypacText variant="overline-regular-large">
										<p className="text-sm leading-5 tracking-tighter mt-[2px]">
											{dayjs(createdAt).format('DD MMM. YYYY')}
										</p>
									</SypacText>
								</span>
							</div>
						</div>

						{target === OrderTarget.admin &&
						!simpleOrder &&
						current >= 2 &&
						current <= 3 ? (
							<MultipleStepperStats order={order} />
						) : null}

						{target === OrderTarget.customer &&
						!simpleOrder &&
						current >= 2 &&
						current <= 3 ? (
							<MultipleStepperStats order={order} />
						) : null}

						<div className="flex gap-[10px] items-center [&_p]:text-sm [&_p]:tracking-tighter [&_p]:mt-[4px] text-gray-40">
							{current >= 11 && target !== OrderTarget.transporter
								? null
								: currentSubStatus &&
								  currentSubStatus[subStatusIndex] && (
										<SypacText variant="overline-regular-large">
											<p className="text-gray-60">
												<T
													keyName={`orderStepper.${currentSubStatus[subStatusIndex]}`}
												>
													{currentSubStatus[subStatusIndex]}
												</T>
											</p>
										</SypacText>
								  )}
							{renderTimeAgo(target!, current)}
						</div>

						{target === OrderTarget.transporter &&
							[6, 7, 9].includes(current) && (
								<div className="flex flex-col gap-2">
									<div className="border-0 border-solid border-t-[1px] border-gray-10 mt-2" />
									<div className="flex gap-3 items-center [&_p]:text-sm [&_p]:tracking-tighter [&_p]:mt-[4px] text-gray-40">
										<SypacText variant="body-regular-medium">
											<p
												className={`text-gray-60 ${
													current > 6 ? 'max-w-[292px] truncate' : ''
												}`}
											>
												<T keyName="orderStepper.address">Address:</T>{' '}
												{current === 9
													? order?.deliveryAddress
													: order?.pickupAddress}
											</p>
										</SypacText>
										<div className="w-[2px] h-[2px] bg-gray-60 rounded-full mt-[2px]" />
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">
												<T
													keyName={
														current === 6
															? 'orderStepper.eta'
															: 'orderStepper.timeOfArrival'
													}
												>
													{current === 6 ? 'ETA:' : 'Time of arrival:'}
												</T>{' '}
												{current === 6 ? (
													' 18:00'
												) : (
													<>
														{dayjs(
															order?.notifications?.items?.find(
																(notify) =>
																	notify.category ===
																	(current === 7
																		? CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING
																		: CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING),
															)?.createdAt,
														).format('DD MMM. YYYY')}
														{' / '}
														{convertToTimeZone(
															order?.notifications?.items?.find(
																(notify) =>
																	notify.category ===
																	(current === 7
																		? CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING
																		: CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING),
															)?.createdAt!,
														)}
													</>
												)}
											</p>
										</SypacText>
									</div>
								</div>
							)}
					</div>
				</div>
			</div>
			<Tooltip
				place="top"
				id="order-hint"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
			/>
		</div>
	);
};

export default Stepper;
