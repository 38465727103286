import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import DriverMultipleNotification from '../../../components/Notification/DriverMultipleNotification';
import { useTranslate } from '@tolgee/react';

interface NotificationPendingArrivingUnloadingProps {
	notification: boolean;
	date?: string;
	loadingPlace?: string;
}
const NotificationPendingArrivingUnloading = ({
	notification,
	date,
	loadingPlace,
}: NotificationPendingArrivingUnloadingProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={150}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'notificationPendingArrivingUnloading.pendingArrivingUnloadingPlace',
						'Pending arriving at the unloading place',
					)}
					description={t(
						'notificationPendingArrivingUnloading.confirmArrivalUnloadingGoods',
						'Confirm his arrival and unloading of goods',
					)}
					loadingPlace={loadingPlace}
				/>
			</div>
		</div>
	);
};

export default NotificationPendingArrivingUnloading;
