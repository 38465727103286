import React from 'react';
import { SypacText } from '@sypac/component-library-react';

const TermsAndConditionsPartnersEn = () => {
	return (
		<div>
			<div>
				<SypacText variant="body-regular-medium">
					<span className="text-gray-90">
						Pursuant to these general terms of use and legal notices
						(hereinafter referred to as "
					</span>

					<span className="text-gray-90 font-bold">Terms for Partners</span>
					<span className="text-gray-90">
						"), Sypac Spółka z ograniczoną odpowiedzialnością with its
						registered office in Warsaw (hereinafter referred to as "
					</span>
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						") makes it available to Partners (hereinafter referred to as "
					</span>
					<span className="text-gray-90 font-bold">PARTNER</span>
					<span className="text-gray-90">" or "</span>
					<span className="text-gray-90 font-bold">PARTNERS</span>
					<span className="text-gray-90">
						"") website with the domain www.sypac.app and the Sypac mobile app
						(hereinafter collectively referred to as "
					</span>
					<span className="text-gray-90 font-bold">APPLICATION</span>
					<span className="text-gray-90">" or "</span>
					<span className="text-gray-90 font-bold">SYPAC APPLICATION</span>
					<span className="text-gray-90">").</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						These General Terms and Conditions for Partners apply to the use by
						each
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNER</span>{' '}
					<span className="text-gray-90">of</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>
					<span className="text-gray-90">
						, as well as all related sites or pages to which
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">links via the links provided in</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>
					<span className="text-gray-90">
						. General terms and conditions for Partners apply to all users of
					</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
					<span className="text-gray-90">acting on behalf of</span>
					<span className="text-gray-90 font-bold">PARTNER</span>{' '}
					<span className="text-gray-90">
						(in particular, employees, contractors, subcontractors and those
						providing services under other civil law contracts),
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNER</span>{' '}
					<span className="text-gray-90">
						assumes responsibility for the actions of such persons, on the same
						basis as if they were its own actions.
					</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
					<span className="text-gray-90">is owned by</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						. Further use of it constitutes your agreement to these terms of
						use. Users (PARTNERS) who do not agree with the above should refrain
						from using
					</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>
					<span className="text-gray-90">.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<p className="text-gray-90">
						For purposes of these Partner Terms, the following capitalized terms
						have the meanings given to them below:
					</p>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold"> 1. TRANSPORTERS:</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
					<span className="text-gray-90">
						responsible for carrying the goods ordered by the consignee from
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
					<span className="text-gray-90">
						engaged in the production of goods, for the purpose of interpreting
						these Terms and Conditions for Partners, the understanding of the
						term
					</span>{' '}
					<span className="text-gray-90 font-bold">SUPPLIER</span>{' '}
					<span className="text-gray-90">
						under the Civil Code is excluded.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90 font-bold"> 2. SELLERS:</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
					<span className="text-gray-90">
						engaged in the production or supply of the goods offered, whether
						they produce them themselves or merely resell the goods obtained by
						them from other entities in
					</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>
					<span className="text-gray-90">.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90 font-bold">
						{' '}
						3. Services in the APPLICATION (hereinafter also as "BUY & SELL
						SERVICES"):
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Technological services dedicated to "
					</span>
					<span className="text-gray-90 font-bold">Buy & Sell</span>
					<span className="text-gray-90">
						" as well as to make available products or services offered by
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNER</span>{' '}
					<span className="text-gray-90">on the</span>{' '}
					<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
					<span className="text-gray-90">
						and delivered to Customers of the{' '}
					</span>
					<span className="text-gray-90 font-bold">SYPAC APPLICATION</span>{' '}
					<span className="text-gray-90">
						through carriers who also provide services through the{' '}
					</span>
					<span className="text-gray-90 font-bold">SYPAC - TRANSPORTERS.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90">Company business name:</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<p className="text-gray-90">
						Sypac Limited Liability Company (Sypac Spółka z ograniczoną
						odpowiedzialnością);
					</p>
					<p className="text-gray-90">Headquarters: Warsaw, Poland;</p>
					<p className="text-gray-90">
						Address: 65 Filtrowa Street premises 45, 02-055 Warsaw;
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						SYPAC and PARTNERS (including both SELLERS, and SUPPLIERS) are
						hereinafter referred to in the content of these Terms for Partners
						as "
					</span>
					<span className="text-gray-90 font-bold">Parties</span>
					<span className="text-gray-90">" or individually as "</span>
					<span className="text-gray-90 font-bold">Party</span>
					<span className="text-gray-90">".</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Registration data in the Register of Entrepreneurs: the company is
						registered in the Register of Entrepreneurs of the National Court
						Register kept by the District Court for the capital city of Warsaw,
						XII Commercial Division of the National Court Register, KRS number
						0001014419, share capital PLN 5,000.00, REGON: 524266784.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Tax Identification Number (NIP): 7011126139
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						These Terms for Partners shall apply both to SYPAC's relationship
						with all PARTNERS responsible for the manufacture of the goods,
						their delivery, as well as responsible for its delivery to final
						customers, unless the application of specific provisions to
					</span>{' '}
					<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
					<span className="text-gray-90">or</span>{' '}
					<span className="text-gray-90 font-bold">SELLERS</span>{' '}
					<span className="text-gray-90">
						is expressly excluded, or it is expressly indicated that a provision
						applies only to{' '}
					</span>{' '}
					<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
					<span className="text-gray-90">or</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERS.</span>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">I. PURPOSE:</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The general purpose of these Terms for Partners is to create a
								legal framework (in terms of the obligatory legal relationship
								between{' '}
							</span>
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">, and the </span>
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">
								) for the provision of products or services provided by{' '}
							</span>
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">to</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">, offered by </span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">through the </span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90"> Application, which:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">Provides services in “</span>{' '}
									<span className="text-gray-90 font-bold">Buy & Sell</span>
									<span className="text-gray-90">
										” procedure for the acquisition of potential customers, the
										conclusion of contracts between these customers and{' '}
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, and the processing of payments and other services related
										to the sale of and delivery of products;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Deals with procurement incidents.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">In relation to</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERS</span>
							<span className="text-gray-90">, who use</span>{' '}
							<span className="text-gray-90 font-bold">
								BUY & SELL SERVICES, SYPAC:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">
										In no event shall be held liable for the carriage services
										provided by{' '}
									</span>{' '}
									<span className="text-gray-90 font-bold">
										PARTNER - TRANSPORTER, and all such liability shall be fully
										borne by PARTNER;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Undertakes to allow ordering - Customers of the APPLICATION
										to establish contact with TRANSPORTERS, providing a chat
										module in the APPLICATION for this purpose.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								The relationship arising from these Terms for Partners is
								strictly a business relationship between independent persons who
								agree to always present themselves to the market as independent
								entities, without allowing confusion between the services
								provided by each of them.
							</p>
							<p className="text-gray-90">
								Both Parties agree to cooperate in good faith on certain
								projects requiring such cooperation. The parties may amend these
								Terms for Partners by mutual agreement at any time, by signing
								an addendum in writing. In the event of a change to these Terms
								for Partners, the provisions introduced thereafter shall precede
								those in the addendum.
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								Each Party shall be solely responsible for its employees in
								connection with the implementation of these Partner Terms and
								Conditions and the fulfillment of its own obligations under
								these Terms and Conditions. For the purposes of these Terms for
								Partners, the employee referred to in the preceding sentence
								shall be understood to include contractors, subcontractors and
								those providing services under other civil law contracts.
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
							<span className="text-gray-90">undertake to indemnify</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								in full for damages caused by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								's sale of goods or services purchased or ordered from
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								that do not have the assured qualities or are not adequately
								performed by that
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">
								. The above is also applicable to the cost of warranty claims by
								Customers - individuals.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								provides a warranty disclaimer in relations with Customers -
								entrepreneurs.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						II. DATE AND DURATION OF THE TERMS OF SERVICE
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								agrees to maintain its profile on the
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
							<span className="text-gray-90">
								at the times agreed upon with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">for</span>{' '}
							<span className="text-gray-90 font-bold">TWENTY</span>{' '}
							<span className="text-gray-90">
								(12) months from the date of acceptance of these Terms for
								Partners, which period shall be extended indefinitely for
								successive periods of
							</span>{' '}
							<span className="text-gray-90 font-bold">TWENTY MONTHS</span>
							<span className="text-gray-90">
								. The expiration of the periods referred to in the preceding
								sentence has effect at the end of the month.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								If either Party wishes to terminate the mutual relations, it
								shall be obligated to give the other Party at least
							</span>{' '}
							<span className="text-gray-90 font-bold">ONE MONTH'S</span>{' '}
							<span className="text-gray-90">
								written notice of such termination.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">If</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								intends to change these Terms for Partners,
							</span>
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">is required to notify</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">by email or by means of the</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
							<span className="text-gray-90">of any change at least</span>{' '}
							<span className="text-gray-90 font-bold">ONE MONTH</span>{' '}
							<span className="text-gray-90">
								prior to the date on which the change is to take effect. In the
								event that the aforementioned period expires without any
								objection from
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								, the content of the notified modification shall be binding on
								both Parties and shall remain in force for the duration of the
								contract. Continued access or use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
							<span className="text-gray-90">by</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								after the modifications become effective will be considered
								acceptance of the modifications by
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">. Any amendments that</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								may wish to make to these Terms for Partners shall in no event
								be retroactive unless
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								is required to comply with laws or regulations applicable to it
								or such amendments are beneficial to
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">A PARTNER</span>{' '}
							<span className="text-gray-90">
								has the right to file a complaint through
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, if it disagrees with the reasons that caused
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								to restrict, suspend or eliminate its products or services. Upon
								receipt of a complaint, an internal procedure will be initiated,
								which will be monitored, investigated and resolved by a team
								appointed by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, assuming the responsibility of investigating, resolving and
								communicating a decision within a reasonable period of time, in
								an individualized and simple manner.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">III. FINANCIAL TERMS</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">buys goods from</span>{' '}
							<span className="text-gray-90 font-bold">SELLERS</span>{' '}
							<span className="text-gray-90">
								at agreed price (calculated as product of price x quantity). As
								of reselling those goods on
							</span>{' '}
							<span className="text-gray-90 font-bold">
								SYPAC Platform, SELLERS
							</span>{' '}
							<span className="text-gray-90">
								while making their goods available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								also agree to determine their price and availability by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC. SYPAC</span>{' '}
							<span className="text-gray-90">
								freely (unless there is an agreement referred to in paragraph. 3
								below) determines the margin on the goods and makes the goods
								available at the margin price on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform.</span>{' '}
							<span className="text-gray-90">
								When a Customer places an order on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								, a contract for the sale of the quantity of goods specified by
								that Customer is concluded between the
							</span>{' '}
							<span className="text-gray-90 font-bold">PRODUCER</span>{' '}
							<span className="text-gray-90">and</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								, at the price current in the
							</span>{' '}
							<span className="text-gray-90 font-bold">PRODUCER</span>{' '}
							<span className="text-gray-90">
								's profile at the time the order is placed. Payment for the
								goods shall be made in accordance with the provisions of
								paragraph. 5 below.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Payment for the use of transportation services provided by
							</span>{' '}
							<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
							<span className="text-gray-90">
								consists of a variable amount calculated based on various
								factors, such as presence in the territory, the price of fuel,
								distance or order volume, it is calculated as in following
								equation: fix price + variable per km fee * distance in
								kilometers (hereinafter referred to as "Transportation Fee").
								Variable per kilometer and fix price are set out by
							</span>{' '}
							<span className="text-gray-90 font-bold">TRANSPORTER</span>{' '}
							<span className="text-gray-90">in his profile at</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The parties may agree to establish this relationship on an
								exclusive basis to the extent permitted by applicable law. In
								the absence of such an agreement, it is assumed that the
								principle of exclusivity has not been reserved.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								As part of the exclusivity arrangement
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">and</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								may agree on special terms and conditions for the sale of goods
								or services of this
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								, in particular, reduce the margin in order to increase the
								popularity of the goods of this
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								and provide for contractual penalties for failure to implement
								the exclusivity principle.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								conducts all settlements with Customers - orders exclusively
								using the payment system provided in
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION, SYPAC</span>{' '}
							<span className="text-gray-90">transfers the proceeds</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
							<span className="text-gray-90">
								(hereinafter referred to as "Payable Amount") as stated below:
								(i) for the total amount of sales generated less refunds during
								the period from the 1st up to and including the 15th day of each
								month,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								shall pay the Amount Payable within a maximum of three (3)
								business days from the 5th day of the following month, and (ii)
								for the total amount of sales generated less refunds during the
								period from the 16th up to and including the last day of the
								month,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								shall pay the Amount Payable within a maximum of three (3)
								business days from the 20th day of the following month.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								In the event of an incomplete delivery of any product or service
								for reasons attributable to
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER, PARTNER</span>{' '}
							<span className="text-gray-90">
								will be required to pay the full cost (including tax)
								corresponding to the price of the incomplete product or service,
								plus tax for the incomplete product or service, which
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">is required to pay</span>
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						IV. PARTNER'S RESPONSIBILITIES
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">PARTNER undertakes:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">Provide</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										all necessary information as well as a fact sheet for each
										of its products or services in the form required by
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">and keep them updated.</span>
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										agrees to be solely responsible for providing this
										information to
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										Customers and to indemnify
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										against any liability it may incur for any damages. In any
										case, the descriptions of such products or services
										appearing in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										will always strictly conform to the information provided by
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER.</span>
									<span className="text-gray-90">
										Any discrepancy between the description appearing in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										and the product or service actually offered by
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										(such as, for example, m.in. the difference between the
										price quoted in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										and the price offered at the point) shall be charged to
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>
									<span className="text-gray-90">
										, who shall therefore be obliged to bear any additional
										costs that may result therefrom;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">Report</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">using the</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										{' '}
										any case of unavailability, changes in the information
										referred to in letter. (a) or lack of stock of products or
										services placed in the
									</span>
									<span className="text-gray-90 font-bold">
										APPLICATION
									</span>{' '}
									<span className="text-gray-90">immediately. If</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										fails to adequately inform
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										about the goods or services it has in stock, change the
										information provided in accordance with letter. (a), about
										the opening hours or closing times of its or outlets, or
										about other important matters resulting in
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										providing inaccurate information to its Customers as a
										result of the negligence of
									</span>{' '}
									<span className="text-gray-90 font-bold">
										PARTNER, PARTNER
									</span>
									<span className="text-gray-90">
										the latter shall be liable in full for the damage caused to
										the Customer as a result;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Keep the prices and availability of goods disclosed in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										up to date, especially if
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										does not have the necessary inventory to fulfill the order.
										Failure to update prices creates an obligation to fulfill
										orders at the prices disclosed to date;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">Accept that</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">makes payments to</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										only by bank transfer executed as split payment, as
										described in paragraph III.5 above;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Remain active and maintain service availability for
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										Customers for at least 95% of the normal business hours of
										its stores or
									</span>{' '}
									<span className="text-gray-90 font-bold">FILES;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Take orders on the terminal (application) of the point of
										sale at the time indicated in the order, after receiving
										notification, and prepare orders in full;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Prepare and deliver (in the case of
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>
									<span className="text-gray-90">
										) orders processed through the
									</span>{' '}
									<span className="text-gray-90 font-bold">
										SYPAC APPLICATION
									</span>{' '}
									<span className="text-gray-90">
										exclusively in its own enterprise in order to fulfill the
										hygiene and public health obligations set forth in these
										Terms and Conditions for Partners, as well as to meet the
										efficiency and logistics criteria agreed between the
										Parties.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Provide truthful information about your bank account number
										and bear the costs that may arise as a result of providing
										false information with respect to that account number;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">may offer, and</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										may agree, to participate in promotions and campaigns to
										support
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>
									<span className="text-gray-90">
										's positioning and visibility in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION.</span>{' '}
									<span className="text-gray-90">
										These activities willbe governed by and subject to the terms
										and conditions agreed upon by the Parties;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">Respond via the</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										to complaints and notifications of Customers - ordering
										parties within 2 working days of their notification, failure
										to do so will result in withholding of payment for the order
										to which the complaint relates, unless the amount has
										already been transferred to the
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Accept that acceptance of orders is automatic (this
										provision applies only to
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLERS</span>
									<span className="text-gray-90">
										), with the exempt that
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLER</span>{' '}
									<span className="text-gray-90">
										may denied delivery if chosen product is out of stock,
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										in such case is also obliged to keep up to date information
										about goods availability;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Accept or reject the delivery offer, whereby rejection
										requires completion of the rejection form (indication of
										objective reason) in the application, and after acceptance
										of the order, perform delivery always at the indicated time
										(this provision applies only to
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>
									<span className="text-gray-90">).</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>

				<div className="flex flex-row mt-5 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">With respect to</span>{' '}
							<span className="text-gray-90 font-bold">
								BUY & SELL, PARTNER
							</span>{' '}
							<span className="text-gray-90">is additionally required to:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										Prepare the order as quickly, carefully and efficiently as
										possible;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Prepare the goods specified in the order within the time
										disclosed in the order tab of the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">(applies only to</span>{' '}
									<span className="text-gray-90 font-bold">SELLERS</span>
									<span className="text-gray-90">
										). Deliver the goods within the time disclosed in the order
										tab of the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										, after confirmation by the
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">(applies only to</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>
									<span className="text-gray-90">). </span>
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										will evaluate the delivery time, as well as the information
										provided regarding the time and manner of
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">TRANSPORTER</span>{' '}
									<span className="text-gray-90">
										is solely responsible for setting delivery charges, and
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLER</span>{' '}
									<span className="text-gray-90">
										is solely responsible for setting prices for its products.
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">guarantees</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										, that the products sold through the
									</span>{' '}
									<span className="text-gray-90 font-bold">
										SYPAC APPLICATION
									</span>{' '}
									<span className="text-gray-90">
										and the delivery fee applied by
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										will not be offered to Customers of
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										at prices higher than those offered by
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										on other platforms, at its own outlets or on its own
										platform, if applicable (hereinafter "Parity Model").
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTER</span>{' '}
									<span className="text-gray-90">
										is required to enable tracking of delivery via
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">Application;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">SELLER</span>{' '}
									<span className="text-gray-90">
										is obliged to provide (if a particular commodity requires
										special transport conditions, e.g. appropriate temperature
										or humidity)
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
									<span className="text-gray-90">
										requirements for handling goods, so that the products are
										delivered to Customers in proper condition and in accordance
										with the necessary conditions;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">TRANSPORTER</span>{' '}
									<span className="text-gray-90">
										will ensure that its employees, associates, or
										subcontractors providing delivery service to a specific
										address:
									</span>
									<p className="text-gray-90">
										a) have the necessary driver's license;
									</p>
									<p className="text-gray-90">
										b) have a current motor vehicle liability insurance policy,
										if applicable, as well as an insurance policy covering the
										transportation of goods;
									</p>
									<p className="text-gray-90">
										c) are covered by{' '}
										<span className="text-gray-90 font-bold">PARTNER</span>{' '}
										minimum security protection (e.g. accident insurance);
									</p>
									<p className="text-gray-90">
										d) have up-to-date tax and social security payments and
										obligations, and will be equipped or required to have a
										device (e.g. smartphone) enabling the installation of the
										APP.;
									</p>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Responsibility for all events related to the delivery of the
										order rests with the
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTER</span>
									<span className="text-gray-90">
										, and for those related to the quality of the product rests
										with the
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLER;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">Carefully use the</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										and refrain from directly or indirectly promoting the supply
										of products or the provision of services that are not
										permitted by law.
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										shall not be liable for any loss or damage that may arise as
										a result of
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>
									<span className="text-gray-90">'s use of the</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										contrary to law or the Partner Terms and Conditions. In
										particular,
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										does not assume any responsibility for the use of the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										or for the processing of orders containing materials with
										limited delivery options. Notwithstanding the foregoing,
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										reserves the right to terminate the agreement and seek
										appropriate compensation from
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>
									<span className="text-gray-90">
										, if it learns that the activities of
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										are unlawful or violate the Partner Terms and Conditions and
										contractual terms. Accordingly,
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										may set the necessary filters to prevent certain products
										with limited delivery options from being delivered to
										Customers via the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION;</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Use of the cancellation mechanism described below and
										undertakes to apply it:
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										will not receive the amount of the order placed via the
										Application
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, if it is canceled by the Customer before the
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTER</span>
									<span className="text-gray-90">
										's employee sets out to the
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLER</span>{' '}
									<span className="text-gray-90">
										to pick up the order or within 24 hours after the order is
										placed (unless the lead time is less than 24 hours). The
										Customer (who first ordered and then cancelled delivery)
										will be refunded in this case, within 48h after the
										cancellation of the order. Cancellation of an order has the
										effect of terminating the sales and delivery contracts
										concluded on this account;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">The</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										provides separate roles for
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
									<span className="text-gray-90">and</span>{' '}
									<span className="text-gray-90 font-bold">SELLERS</span>
									<span className="text-gray-90">, in case one</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										expresses the desire to act in a dual role, it is obliged to
										maintain activity in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
									<span className="text-gray-90">
										in two roles for this purpose.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Due to the nature of the market in which
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">and</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERS</span>{' '}
									<span className="text-gray-90">operate,</span>{' '}
									<span className="text-gray-90 font-bold">SELLERS</span>{' '}
									<span className="text-gray-90">
										accept orders received in advance, with the exempt that
									</span>{' '}
									<span className="text-gray-90 font-bold">SELLER</span>{' '}
									<span className="text-gray-90">
										may denied to accept order if chosen product is unavailable
										(this provision does not apply to sand and gravel), while
									</span>{' '}
									<span className="text-gray-90 font-bold">TRANSPORTERS</span>{' '}
									<span className="text-gray-90">
										are required to accept the order in the
									</span>{' '}
									<span className="text-gray-90 font-bold">APPLICATION.</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">V. OTHER PROVISIONS</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								By agreeing to these Terms and Conditions,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								agrees to carry a liability insurance policy. Such a policy will
								be issued by a recognized insurance company. In addition, it
								cannot be canceled and its sum cannot be significantly reduced.
								Upon request,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC PARTNER</span>{' '}
							<span className="text-gray-90">
								will provide evidence of insurance coverage required hereunder.
								In no event shall the limits set forth in the insurance policy
								be deemed a limitation of liability
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								under these Partner Terms and Conditions.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								agrees not to harm the image of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								or damage its reputation in any way, and may use the brand
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">or</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								to publicly identify itself as an affiliate through any means
								used by
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">and</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								for its own promotion and to attract Customers.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">undertakes to inform</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								of any changes in the terms and conditions of use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
							<span className="text-gray-90">
								concerning Customers, who are ordering goods on
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">Platform. In the event that</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">
								, directly or indirectly, takes any action that could damage the
								image and reputation of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								(such as. discloses to competitors
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">information about</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								activities or makes negative comments about
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								, promotes other platforms using the brand
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								e.g.. during promotions, on flyers and will use any other
								informational materials of any kind for the benefit of its own
								platform or third-party platforms in the framework of orders
								carried out through the
							</span>{' '}
							<span className="text-gray-90 font-bold">
								APPLICATION SYPAC), SYPAC
							</span>{' '}
							<span className="text-gray-90">
								reserves the right to discontinue these Terms and Conditions for
								Partners, as well as the set of agreements concluded with t,
								take legal action and seek damages from
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								will refrain from disclosing any trade secrets or confidential
								information to which it may have had access as a result of its
								business relationship with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								In addition, by agreeing to apply these Terms and Conditions to
								Partners during the term of the contractual relationship,
							</span>
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								agrees not to develop or market any application that directly
								competes with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC's APP.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								All brands, domain names, software and other works that are
								subject to industrial and intellectual property rights with
								respect to the
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>
							<span className="text-gray-90">
								, including any possible future changes, are the property of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC. PARTNER</span>{' '}
							<span className="text-gray-90">
								agrees not to register or apply for registration of these or
								similar works wherever they may be, nor to alter, modify or
								delete them, and expressly agrees that nothing in these Partner
								Terms grants it any such rights in the future.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Except as expressly set forth in these Partner Terms or in a
								subsequent attachment, without the prior written consent of
							</span>{' '}
							<span className="text-gray-90 font-bold">
								SYPAC PARTNER, SYPAC PARTNER
							</span>{' '}
							<span className="text-gray-90">
								may not issue press releases or refer to
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								in any way in connection with these Partner Terms or otherwise.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">8.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								For the term of these Partner Terms,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">hereby grants</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								a royalty-free license that is non-exclusive and valid
								worldwide, as well as the right to use its Intellectual
								Property, including, m.in.: copyrights, trade secrets, know-how,
								trademarks, images, text and in general all content published on
								its website ("
							</span>
							<span className="text-gray-90 font-bold">
								PARTNER's Intellectual Property
							</span>{' '}
							<span className="text-gray-90">") provided by</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								for the purposes of i) performing these Partner Terms and
								Conditions and ii) conducting marketing activities in connection
								with online orders and iii) for any other related purposes. - In
								no way does the above provision imply that
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								transfers its intellectual property to
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								(unless otherwise agreed in a separate agreement).
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								represents, warrants and agrees that it owns the ownership
								rights in the
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								Intellectual Property (being the beneficial owner or licensee
								thereof) and
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								has the right to license or sublicense the use of such
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								Intellectual Property for purposes related to these Terms, and
								is not aware of any third party infringing its intellectual
								property rights.
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								will not enter into anysubsequent agreements that would restrict
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">'s right to use</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">'s Intellectual Property.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">9.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								declares that it is aware of Polish and European data protection
								regulations and that it complies with these regulations when
								conducting its own business.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">10.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								acknowledges that the processing of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								Customers' personal data under the agreement set forth in these
								Partner Terms is at the sole discretion of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">, and that</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								may not access it at any time except with the prior direct
								consent of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">11.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">In order to enable</span>{' '}
							<span className="text-gray-90 font-bold">
								PARTNER - TRANSPORTER
							</span>
							<span className="text-gray-90">, which uses</span>{' '}
							<span className="text-gray-90 font-bold">
								BUY & SELL SERVICES
							</span>
							<span className="text-gray-90">
								, to provide the delivery service using its own fleet of
								suppliers
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">will provide via the</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>{' '}
							<span className="text-gray-90">
								the Customer data that is necessary to complete the order (i.e.
								username and cell phone number and order data), provided that
								such Customer has placed an order at this
							</span>{' '}
							<span className="text-gray-90 font-bold">FILE</span>{' '}
							<span className="text-gray-90">or with a franchisee in the</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>
							<span className="text-gray-90">
								. The basis for such processing is the legitimate interests of
								the Parties, as well as the provision of services, since it is
								the data subjects themselves who actively perform the action in
								placing the order. Before submitting the data,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will inform the Customer in question that his/her order will be
								processed directly by
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">12.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Whenever required by applicable law,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								is required to sign a Data Processing Agreement (hereinafter
								referred to as the "
							</span>
							<span className="text-gray-90 font-bold">
								Data Processing Agreement
							</span>
							<span className="text-gray-90">
								") setting forth the duties and responsibilities described in
								applicable law that may apply to these Partner Terms.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">13.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								In order to fulfill its obligations under these Partner Terms
								and Conditions
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								and its employees, agents and representatives shall fully comply
								with all applicable local anti corruption, anti-money laundering
								and financial terrorism laws, anti-trust laws and other laws
								that may apply to the business. In addition, he is obliged to
								conduct his business in accordance with the strictest principles
								of ethics, honesty and good faith, avoiding, either directly or
								through third parties, any involvement in illegal business
								activities.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">14.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								will have access to the following information generated by the
							</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>
							<span className="text-gray-90">
								: invoices, orders, Customer ratings, number of orders
								generated, as well as any other information agreed between the
								parties.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">15.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Under no circumstances will</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								have access to information from other partners with whom
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">has an agreement. However,</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								may have access to information in aggregate form on incomplete
								order volume, preparation time, order size or other relevant
								information relating to the city or radius where
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								offers its products or services.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">16.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								represents that it has complied with the
							</span>{' '}
							<span className="text-gray-90 font-bold">
								Standards of Business Ethics and Conduct SYPAC
							</span>{' '}
							<span className="text-gray-90">
								intended for third parties (the "Guidelines") and that it has
								read and warrants that it will read and refrain from violating
								the provisions contained therein in the future.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">17.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Whenever</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								is asked to do so, it undertakes to provide
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">within no more than a</span>{' '}
							<span className="text-gray-90 font-bold">MONTH</span>{' '}
							<span className="text-gray-90">
								with documents proving the legality of its activities in a valid
								and organized manner, as well as any additional explanations of
								its activities that may be necessary for audit purposes. Failure
								to provide these documents will result in the suspension of all
								transfers until the documents required by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">. are provided.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">18.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								reserves the right to use parameters it deems convenient to
								classify and position
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">within</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>
							<span className="text-gray-90">
								. The main ranking parameters are Customer preferences and
								search history
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, Customer ratings, product or service availability, preparation
								time, incomplete orders, radius, delivery area and estimated
								delivery time.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">19.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								reserves the right to transfer this reciprocal relationship to
								any entity that directly or indirectly belongs to
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">(group companies</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, its parent companies and its subsidiaries). In the event
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">or any</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								group company or its subsidiary is involved in a merger,
								consolidation, change of corporate control, material transfer of
								assets, restructuring or liquidation,
							</span>
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								may, at its sole discretion, transfer or assign this reciprocal
								relationship to a related party or any of its subsidiaries.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">20.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">If</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								has any incident, problem, complaint or claim regarding the
								services provided by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">, it may contact</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">free of charge via the</span>{' '}
							<span className="text-gray-90 font-bold">APPLICATION</span>
							<span className="text-gray-90">
								. Upon receipt of a complaint or claim from
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">
								, an internal complaint procedure will be launched so that the
								support team can address the matter and proceed to investigate
								and resolve it.
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								assumes the obligation to consider the complaint within a
								reasonable time and to communicate the results of the complaint
								individually and simply.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						VI. LOCAL JURISDICTION AND APPLICABLE LAW
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								These Terms and Conditions for Partners are subject to the
								applicable provisions of Polish law.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The parties undertake to reach an amicable agreement in the
								event of a dispute between them. If such proceedings are
								unsuccessful, the Parties may submit the dispute to the
								settlement of the common court of competent subject matter
								jurisdiction, for the seat of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								In the event of any conflict between these Partner Terms and
								Conditions and any other terms and conditions, these terms and
								conditions shall prevail unless otherwise agreed in writing
							</span>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditionsPartnersEn;
