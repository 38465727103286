const PaymentCard = () => {
	return (
		<svg
			width="37"
			height="30"
			viewBox="0 0 37 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 15C1 8.40034 1 5.10051 3.05025 3.05025C5.10051 1 8.40034 1 15 1H22C28.5997 1 31.8995 1 33.9497 3.05025C36 5.10051 36 8.40034 36 15C36 21.5997 36 24.8995 33.9497 26.9497C31.8995 29 28.5997 29 22 29H15C8.40034 29 5.10051 29 3.05025 26.9497C1 24.8995 1 21.5997 1 15Z"
				stroke="#2F2F2F"
				strokeWidth="2"
			/>
			<path
				d="M15 22H8"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M22 22H19.375"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1 11.5L36 11.5"
				stroke="#2F2F2F"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default PaymentCard;
