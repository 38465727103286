import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import {
	InvoiceList,
	RequestInvoiceListDto,
} from '../interfaces/invoice.interface';

type UseGetInvoiceStats = [InvoiceList | undefined, Error | undefined, boolean];

export const useGetInvoicesByCompnayId = (
	companyId: number,
	{
		limit,
		offset,
		orderId,
		status,
		assigneeId,
		fromDate,
		toDate,
		sorting,
		refresh = 0,
	}: Partial<RequestInvoiceListDto>,
): UseGetInvoiceStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	let statuses = [status];

	const params: Record<string, any> = {
		limit,
		orderId,
		statuses,
		offset,
		assigneeId,
		fromDate,
		toDate,
	};
	if (sorting) {
		const sortBy = sorting.split(' ')[0];
		const sortDirection = sorting.split(' ')[1];
		params['sortBy'] = sortBy;
		params['sortDirection'] = sortDirection;
	}

	useEffect(() => {
		setIsLoading(true);
		if (!companyId) {
			return;
		}
		facade.client
			.get(`/v1/admin/invoices/companies/${companyId}`, {
				params,
			})
			.then((response) => {
				setData(response.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		facade,
		limit,
		orderId,
		status,
		offset,
		assigneeId,
		toDate,
		fromDate,
		sorting,
		refresh,
	]);

	return [data, error, isLoading];
};
