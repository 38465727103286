import React, { useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacAvatar,
	SypacBadge,
	SypacButton,
	SypacIcon,
	SypacTabButton,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';
import dayjs from 'dayjs';

import {
	ProductInterface,
	ProductStatusEnum,
} from '../../../interfaces/product.interface';
import { ProductStatusColors } from '../../../constants/product-status.constants';
import Flag from 'react-world-flags';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { RejectBadge } from '../../../assets/RejectBadge';
import { PendingBadge } from '../../../assets/PendingBadge';
import CircleValid from '../../../assets/CircleValid';
import CircleClose from '../../../assets/CircleClose';
import { Future } from '../../../assets/Future';
import Map from '../../../components/Map/Map';
import { ViewStore } from '../../../assets/ViewStore';
import { RejectButton } from '../../../assets/RejectButton';
import ProductEdit from '../ProductEdit';
import { EditProduct } from '../../../services/product.services';

interface Props {
	onClose: () => void;
	approveProduct: () => void;
	rejectProduct: () => void;
	viewOnStore: () => void;
	editProduct: (data: EditProduct) => void;
	product: ProductInterface;
}

const ProductDetails: React.FC<Props> = (props) => {
	const { t } = useTranslate();
	let {
		onClose,
		product,
		rejectProduct,
		approveProduct,
		viewOnStore,
		editProduct,
	} = props;
	const [currentTab, setCurrentTab] = useState<string>('overview');
	const [readOnly, setReadOnly] = useState(true);
	const [openedMap, setOpenMap] = useState(false);

	const status: string = useMemo(
		() => product?.status || ProductStatusEnum.PENDING,
		[product?.status],
	);
	const bgColor = useMemo(
		() =>
			ProductStatusColors[status].color || ProductStatusColors.created.color,
		[status],
	);
	const label = useMemo(
		() =>
			ProductStatusColors[status]
				? t(ProductStatusColors[status].label)
				: t(ProductStatusColors.created.label),
		[status, t],
	);

	const openMap = () => {
		setOpenMap(true);
	};

	return (
		<div className="fixed min-h-full top-0 right-0 w-[716px] bg-white-100 shadow-order-details z-[100] h-screen overflow-y-auto scroll-smooth">
			<div className="pb-10">
				<div className="h-full">
					<div className="sticky top-0 z-[120] bg-white pt-10">
						<div className="px-10 flex flex-row justify-between">
							<div className="flex-1">
								{['rejected', 'approved'].includes(product.status!) ? (
									<div className="flex flex-row justify-between items-center">
										<div className="flex flex-row justify-start items-center">
											<div>
												<SypacAvatar
													initials={product.verifiedByUser?.name}
													size="md"
												>
													{product.verifiedByUser?.avatarUrl ? (
														<img
															src={product.verifiedByUser?.avatarUrl}
															alt={product.verifiedByUser?.name}
														/>
													) : null}
												</SypacAvatar>
											</div>
											<div className="flex flex-col justify-start items-start ml-5">
												<SypacText variant="overline-regular-large">
													<p className="text-gray-80">
														{product.status === 'approved' ? (
															<T keyName="productDetails.approvedBy">
																Approved by:
															</T>
														) : (
															<T keyName="productDetails.rejectedBy">
																Rejected by:
															</T>
														)}{' '}
														{dayjs(product.updatedAt).format('DD MMM. YYYY')}
													</p>
												</SypacText>
												<SypacText
													variant="body-regular-medium"
													className="mt-1"
												>
													<p className="text-primary-dark-gray">
														{product.verifiedByUser?.name}
													</p>
												</SypacText>
											</div>
										</div>
									</div>
								) : null}
							</div>
							<div className="flex flex-row items-center gap-3">
								{product.status !== 'rejected' ? (
									<div onClick={viewOnStore} className="cursor-pointer">
										<ViewStore />
									</div>
								) : null}
								{product.status === 'pending' ? (
									<div onClick={rejectProduct} className="cursor-pointer">
										<RejectButton />
									</div>
								) : null}
								<SypacIcon
									iconName="Close Square"
									className="text-gray-10 cursor-pointer"
									size="custom"
									width="32px"
									height="32px"
									onClick={onClose}
								/>
							</div>
						</div>

						<div className="px-10 py-6">
							<SypacTabs variant="gray">
								<SypacTabButton
									variant="violet"
									size="large"
									label={t('productDetails.overview', 'Overview')}
									button-id="overview"
									onClick={() => setCurrentTab('overview')}
									isActive={currentTab === 'overview'}
								/>
								<SypacTabButton
									variant="violet"
									size="large"
									label={t('productDetails.files', 'Files')}
									button-id="files"
									onClick={() => setCurrentTab('files')}
									isActive={currentTab === 'files'}
								/>
								<SypacTabButton
									variant="violet"
									size="large"
									label={t('productDetails.history', 'Order history')}
									button-id="history"
									onClick={() => setCurrentTab('history')}
									isActive={currentTab === 'history'}
								/>
								<SypacTabButton
									variant="violet"
									size="large"
									label={t('productDetails.notes', 'Notes')}
									button-id="notes"
									onClick={() => setCurrentTab('notes')}
									isActive={currentTab === 'notes'}
								/>
							</SypacTabs>
						</div>
					</div>

					<div className="px-10">
						{currentTab === 'overview' ? (
							<>
								{product.status === 'pending' && readOnly ? (
									<div className="border border-solid border-texas-rose bg-texas-rose-10 rounded-10 mb-5">
										<div className="flex flex-row p-5">
											<div>
												<PendingBadge />
											</div>
											<div className="flex flex-col ml-6">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-90">
														<T keyName="productDetails.productVerificationPending">
															Product verification pending
														</T>
													</p>
												</SypacText>
												<SypacText variant="body-regular-smal" className="mt-2">
													<p className="text-gray-40">
														<T keyName="productDetails.productVerificationPendingDescription">
															Click "Approve product" to publish it in the SYPAC
															Store or click "Reject product" to remove this
															product from the "Pending approval" list.
														</T>
													</p>
												</SypacText>
												<div className="gap-3 mt-4 flex flex-row">
													<SypacButton variant="secondary" size="small">
														<button
															className="px-5 py-[5px] border border-solid border-mountain-meadow"
															onClick={approveProduct}
														>
															<CircleValid />
															<SypacText
																variant="body-regular-medium"
																className="ml-1"
															>
																<p className="text-mountain-meadow">
																	<T keyName="productDetails.approveProduct">
																		Approve product
																	</T>
																</p>
															</SypacText>
														</button>
													</SypacButton>
													<SypacButton variant="secondary" size="small">
														<button
															className="px-5 py-[5px] border border-solid border-red"
															onClick={rejectProduct}
														>
															<CircleClose />
															<SypacText
																variant="body-regular-medium"
																className="ml-1"
															>
																<p className="text-red">
																	<T keyName="productDetails.rejectProduct">
																		Reject product
																	</T>
																</p>
															</SypacText>
														</button>
													</SypacButton>
												</div>
											</div>
										</div>
									</div>
								) : null}
								{product.status === 'rejected' && readOnly ? (
									<div className="border border-solid border-red bg-red-10 rounded-10 mb-5">
										<div className="flex flex-row p-5">
											<div>
												<RejectBadge />
											</div>
											<div className="flex flex-col ml-6">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-90">
														<T keyName="productDetails.productVerificationRejected">
															Product verification rejected
														</T>
													</p>
												</SypacText>
												<SypacText variant="body-regular-smal" className="mt-2">
													<p className="text-gray-40">
														<T keyName="productDetails.productVerificationRejectedDescription">
															Lorem ipsum dolor sit amet, consectetur adipiscing
															elit, sed do eiusmod tempor incididunt ut labore
															et dolore magna aliqua.
														</T>
													</p>
												</SypacText>
											</div>
										</div>
									</div>
								) : null}

								{readOnly ? (
									<>
										<div
											className={classNames(
												'border-0 border-gray-10 border-solid',
												{
													'border-b pb-6': !openedMap,
												},
											)}
										>
											<div className="flex flex-row items-center justify-between">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-90 -tracking-[0.01em]">
														<T keyName="productDetails.generalProductInformation">
															General product info
														</T>
													</p>
												</SypacText>
												<SypacButton variant="secondary" size="small">
													<button
														className="px-5 py-[5px] border-0"
														onClick={() => setReadOnly(false)}
													>
														<SypacIcon
															iconName="Document Add"
															size="custom"
															width="24px"
															height="24px"
														/>
														<SypacText variant="body-regular-medium">
															<p>
																<T keyName="productDetails.edit">
																	Edit product
																</T>
															</p>
														</SypacText>
													</button>
												</SypacButton>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 mt-6">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.productName">
																Product name:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">{product.name}</p>
													</SypacText>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.productType">
																Product type:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{product?.type || ''}{' '}
															{product?.size ? `(${product?.size})` : ''}
														</p>
													</SypacText>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.status">Status:</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													{bgColor && label ? (
														<SypacBadge color={bgColor} outlined={false}>
															<div className="text-xs font-medium whitespace-nowrap min-w-[113px] flex justify-center">
																{label}
															</div>
														</SypacBadge>
													) : null}
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.postedBy">
																Posted by:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<div className="flex flex-row items-center">
														<div className="flex">
															<SypacAvatar
																initials={product?.company?.name}
																size="sm"
															/>
														</div>
														<div className="flex flex-row ml-5">
															<SypacText variant="body-regular-medium">
																<p className="text-gray-80">
																	{product?.company?.name}
																</p>
															</SypacText>
														</div>
													</div>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.createdDate">
																Created date:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{dayjs(product.createdAt).format('DD MMM. YYYY')}
														</p>
													</SypacText>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.productId">
																Product ID:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">P{product.id}</p>
													</SypacText>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.storeLocation">
																Store location:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<div className="flex flex-row justify-between items-center">
														<div className="flex flex-row">
															{product.countryCode ? (
																<Flag
																	className="rounded-[3px]"
																	code={product.countryCode}
																	width={22}
																	height={16}
																/>
															) : null}
															<SypacText
																variant="body-regular-medium"
																className={product.countryCode ? 'ml-5' : ''}
															>
																<p className="text-gray-80">
																	{product.address}
																</p>
															</SypacText>
														</div>
														<div>
															{!openedMap ? (
																<SypacButton variant="secondary" size="small">
																	<button
																		className="px-5 py-[5px] border-0 w-max"
																		onClick={openMap}
																	>
																		<SypacIcon
																			iconName="Eye"
																			size="custom"
																			width="24px"
																			height="24px"
																		/>
																		<SypacText variant="body-regular-medium">
																			<p>
																				<T keyName="productDetails.viewOnMap">
																					View on map
																				</T>
																			</p>
																		</SypacText>
																	</button>
																</SypacButton>
															) : null}
														</div>
													</div>
												</div>
											</div>
											{openedMap ? (
												<div className="flex flex-col mt-6 border border-solid border-gray-10 rounded-10 relative h-96">
													<SypacIcon
														iconName="Close Square"
														className="text-gray-10 cursor-pointer absolute right-5 top-5 z-[500] bg-white-100 flex rounded-10"
														size="custom"
														width="32px"
														height="32px"
														onClick={() => setOpenMap(false)}
													/>
													<Map
														markers={[
															{
																latitude: product.location?.coordinates[1]!,
																longitude: product.location?.coordinates[0]!,
																variant: 'product',
															},
														]}
													/>
													<div className="flex flex-row px-5 py-3 border-0 border-t border-solid border-gray-10">
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																Delivery area: 100km
															</p>
														</SypacText>
													</div>
												</div>
											) : null}
										</div>
										<div className="mt-6">
											<div className="flex flex-row items-center justify-between">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-90 -tracking-[0.01em]">
														<T keyName="productDetails.priceAvailability">
															Price & availability
														</T>
													</p>
												</SypacText>
												<div />
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 mt-6">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.originalPrice">
																Original price (per ton):
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<div className="flex flex-row items-center">
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																<NumericFormat
																	type="text"
																	displayType="text"
																	thousandSeparator="."
																	decimalSeparator=","
																	value={parseFloat(
																		product?.pricePerUnit?.toFixed(2) || '0',
																	)}
																	suffix={' zł'}
																/>
															</p>
														</SypacText>
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																<T keyName="productDetails.per"> — </T>
															</p>
														</SypacText>
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																<T keyName="productDetails.perTon">per ton</T>
															</p>
														</SypacText>
													</div>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.productStock">
																Product stock:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<div className="flex flex-row items-center">
														<div
															className={classNames('h-2 w-2 mr-1 rounded-sm', {
																'bg-mountain-meadow': product.unlimited,
																'bg-red': !product.unlimited,
															})}
														/>
														{product.unlimited ? (
															<SypacText variant="body-regular-medium">
																<p className="text-gray-80">
																	<T keyName="productDetails.available">
																		Available
																	</T>
																</p>
															</SypacText>
														) : (
															<SypacText variant="body-regular-medium">
																<p className="text-gray-80"> -</p>
															</SypacText>
														)}
													</div>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.availableQuantity">
																Available quantity:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													{product.availableQuantity ? (
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">
																{product.availableQuantity}{' '}
																<T keyName="productDetails.tons">tons</T>
															</p>
														</SypacText>
													) : null}
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.markup">Markup:</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													{product.markup ? (
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">{product.markup} %</p>
														</SypacText>
													) : null}
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.availabilityRadius">
																Availability radius:
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">100 km</p>
													</SypacText>
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.vat">VAT %</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													{product.vat ? (
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">{product.vat} %</p>
														</SypacText>
													) : null}
												</div>
											</div>
											<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
												<div className="col-span-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-40">
															<T keyName="productDetails.priceInStore">
																Price in store (per ton):
															</T>
														</p>
													</SypacText>
												</div>
												<div className="col-span-8">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															<NumericFormat
																type="text"
																displayType="text"
																thousandSeparator="."
																decimalSeparator=","
																value={
																	product.pricePerUnitWithProcent
																		? parseFloat(
																				product.pricePerUnitWithProcent.toFixed(
																					2,
																				),
																		  )
																		: 0
																}
																suffix={' zł'}
															/>
														</p>
													</SypacText>
												</div>
											</div>
										</div>
									</>
								) : (
									<ProductEdit
										onClose={() => setReadOnly(true)}
										product={product}
										editProduct={(data) => {
											editProduct(data);
											setReadOnly(true);
										}}
									/>
								)}
							</>
						) : (
							<div className="flex flex-col justify-center items-center pt-8">
								<Future />
								<SypacText variant="heading-5" className="mt-20">
									<p className="text-gray-90 font-medium">
										<T keyName="productDetails.future">
											Feature is not available at the moment
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium" className="mt-6">
									<p className="text-gray-40">
										<T keyName="productDetails.futureDescription">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt
										</T>
									</p>
								</SypacText>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductDetails;
