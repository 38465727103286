import { UsersInterface } from '../interfaces/Users.interface';
import API from '../axios/api';
import { PhoneInterface } from './company.services';

interface GetUsersResponse {
	data: {
		count: number;
		items: UsersInterface[];
	};
}

interface QueryParams {
	limit: number;
	skip: number;
	companyId?: string;
}

export interface UpdateProfile {
	firstName?: string;
	lastName?: string;
	email?: string;
	secondaryEmail?: string;
	phoneNumber?: PhoneInterface;
	secondaryPhoneNumber?: PhoneInterface;
}

export class UsersService {
	static getUsers(query?: QueryParams): Promise<GetUsersResponse> {
		return API.get('/v1/admin/users', {
			params: query,
		});
	}

	static changeUserStatus(action: string, uid: string): Promise<any> {
		return API.post(`/v1/admin/users/${uid}/${action}`);
	}

	static updateProfile(uid: string, body: UpdateProfile): Promise<void> {
		return API.patch(`/v1/admin/users/${uid}`, {
			...body,
		});
	}
}
