import { StatusTruckPinColors } from '../components/Map/Map.interface';

interface PinProps {
	status: string | undefined;
}

const paths = (color: string) => [
	<path
		d="M23.3575 24.4146L23.6896 19.6457C23.8993 16.635 24.0041 15.1297 23.1263 14.6481C22.2486 14.1664 21.0352 15.0635 18.6084 16.8575L14.7644 19.6993C13.2908 20.7887 12.554 21.3334 12.3533 21.8206C12.0632 22.5246 12.2298 23.3132 12.7656 23.7724C13.1364 24.0903 14.003 24.1597 15.736 24.2986C16.3772 24.35 16.6978 24.3757 16.9937 24.4613C17.4195 24.5845 17.8107 24.7992 18.1433 25.0921C18.3745 25.2958 18.5684 25.5524 18.9561 26.0656C20.0042 27.4528 20.5283 28.1463 20.9956 28.2884C21.6707 28.4936 22.4253 28.2105 22.8634 27.5878C23.1665 27.1567 23.2302 26.2427 23.3575 24.4146Z"
		fill={color}
	/>,
	<path
		fill={color}
		d="M24.07 16.895l-4.768-.332c-3.01-.21-4.516-.314-4.998.564-.481.877.416 2.09 2.21 4.518l2.842 3.844c1.089 1.473 1.634 2.21 2.12 2.41.705.29 1.494.124 1.953-.412.318-.37.387-1.237.526-2.97.051-.641.077-.962.163-1.258.123-.426.337-.817.63-1.15.204-.23.46-.424.974-.812 1.387-1.048 2.08-1.572 2.223-2.04.205-.675-.078-1.43-.701-1.867-.431-.304-1.345-.367-3.173-.495z"
	></path>,
];

export const TruckPin = (props: PinProps) => {
	const { status = 'available' } = props;

	const path = () => {
		const random = Math.random() >= 0.5 ? 1 : 0;
		return paths(StatusTruckPinColors[status])[random];
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<circle
				cx="20.002"
				cy="20.253"
				r="15.5"
				fill="#fff"
				stroke={StatusTruckPinColors[status]}
				transform="rotate(165 20.002 20.253)"
			></circle>
			{path()}
		</svg>
	);
};
