import React from 'react';
import { SypacTabButton } from '@sypac/component-library-react';
import { TabButtonNotificationInterface } from './TabButtonNotification.interface';

export const TabButtonNotification = ({
	label,
	buttonId,
	onClick,
	isSelected,
	handleSelectedTab,
	notification,
}: TabButtonNotificationInterface) => {
	return (
		<div
			className="w-full relative flex items-center group z-10"
			onClick={handleSelectedTab}
			tabIndex={0}
		>
			<SypacTabButton
				variant="gradient"
				label={label}
				button-id={buttonId}
				onClick={onClick}
				className="w-full"
			>
				{notification ? (
					<div
						className={`w-5 h-5 flex justify-center rounded-md text-gray-80 transition ${
							isSelected ? 'bg-white' : 'bg-red text-white'
						}`}
					>
						{notification}
					</div>
				) : null}
			</SypacTabButton>
		</div>
	);
};
