import ProductGroup from '../../components/ProductGroup/ProductGroup';
import { useGetCategories } from '../../hooks/use-get-categories';
import { Category } from '../../interfaces/category.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';

const SelectProductGroup = () => {
	const { t } = useTranslate();
	const [groups] = useGetCategories({}, 'producer');
	const navigate = useNavigate();

	const onSelect = (category: Category): void => {
		navigate(`${category.id}/categories`);
	};

	const back = () => {
		navigate(-1);
	};

	return (
		<div className="w-full h-full flex flex-col">
			<div className="h-full w-full my-[60px] lg:my-0 px-[30px] lg:px-0">
				<ProductGroup
					groups={groups?.items}
					onSelect={onSelect}
					title={t(
						'selectProductGroup.selectProductGroup',
						'Select product group',
					)}
					description={t(
						'selectProductGroup.selectProductGroupDescription',
						'Lorem ipsum dolor sit amet.',
					)}
					showClose={true}
					back={back}
				/>
			</div>
		</div>
	);
};
export default SelectProductGroup;
