import { SypacCheckbox, SypacText } from '@sypac/component-library-react';
import { useState, useRef, useEffect } from 'react';

import Dropdown from './Dropdown';
import classNames from 'classnames';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';
import { useTranslate } from '@tolgee/react';

interface MenuItemsInterface {
	items: Record<string, any>;
	depthLevel: number;
	setSelected: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
	selected: any[];
	handleMouseLeave: any;
	checkeds: string[];
}

const MenuItems = ({
	items,
	depthLevel,
	setSelected,
	selected,
	handleMouseLeave,
	checkeds,
}: MenuItemsInterface) => {
	const { t } = useTranslate();
	const [dropdown, setDropdown] = useState<boolean>(false);
	const [classDrop, setClass] = useState<string>('left-full');
	const [checked, setChecked] = useState<boolean>(
		selected.find((el) => el.variant === items.variant),
	);
	let ref = useRef<any>(null);

	const submenu = [
		{
			title: t('productLocation.monday', 'Monday'),
			variant: 'monday',
		},
		{
			title: t('productLocation.tuesday', 'Tuesday'),
			variant: 'tuesday',
		},
		{
			title: t('productLocation.wednesday', 'Wednesday'),
			variant: 'wednesday',
		},
		{
			title: t('productLocation.thursday', 'Thursday'),
			variant: 'thursday',
		},
		{
			title: t('productLocation.friday', 'Friday'),
			variant: 'friday',
		},
		{
			title: t('productLocation.saturday', 'Saturday'),
			variant: 'saturday',
		},
		{
			title: t('productLocation.sunday', 'Sunday'),
			variant: 'sunday',
		},
	];

	const onMouseEnter = () => {
		window.innerWidth > 960 && setDropdown(true);
	};

	const handleCheckboxChange = () => {
		setChecked((prev) => !prev);
	};

	const skipCheckBox = ['full_time', 'working_hours'];

	const variants = [...skipCheckBox, 'hours'];

	const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		handleCheckboxChange();
		setSelected((prev) =>
			items.variant === prev[0].variant
				? prev
				: variants.includes(items.variant)
				? [items]
				: [],
		);
		handleMouseLeave();
	};

	useEffect(() => {
		const points = ref.current.getBoundingClientRect();
		if (points.right) {
			const distance = window.innerWidth - points.right;
			if (distance < 440) {
				setClass('right-[105%]');
			}
		}
	}, []);

	const openDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setDropdown((prev) => !prev);
	};

	const handleDataFromChild = (data: any) => {
		setSelected([{ title: 'Custom', variant: 'custom' }, data]);
		handleMouseLeave();
	};

	return (
		<li
			className={`menu-items relative min-w-max w-full`}
			ref={ref}
			onMouseEnter={onMouseEnter}
		>
			{items.variant !== 'custom' ? (
				<button
					className={`cursor-pointer flex items-center gap-2.5 ${
						depthLevel > 0 ? 'hover:bg-transparent' : 'hover:bg-gray-2'
					}`}
					onClick={onClick}
				>
					{!skipCheckBox.includes(items.variant) ? (
						<SypacCheckbox size="md">
							<input
								className={classNames('pointer-events-none')}
								type="checkbox"
								onChange={handleCheckboxChange}
								checked={checked}
							/>
						</SypacCheckbox>
					) : null}
					<SypacText variant="body-normal-medium">
						<div>{items.title}</div>
					</SypacText>
				</button>
			) : (
				<>
					<button
						type="button"
						aria-haspopup="menu"
						className={
							'hover:bg-gray-2 text-black flex justify-between text-center'
						}
						aria-expanded={dropdown ? 'true' : 'false'}
						onClick={openDropDown}
					>
						{depthLevel === 0 ? (
							<SypacText variant="body-normal-medium">
								<div>{items.title}</div>
							</SypacText>
						) : (
							<SypacText>
								<div>{items.title}</div>
							</SypacText>
						)}
						<span
							className={classNames(
								'flex my-auto transform mr-1 -rotate-90 transition-all',
								{
									'rotate-0': dropdown,
								},
							)}
						>
							<ChevronRightIcon />
						</span>
					</button>
					<Dropdown
						depthLevel={(depthLevel += 1)}
						submenus={submenu}
						dropdown={dropdown}
						classDrop={classDrop}
						handleDataFromChild={handleDataFromChild}
						checkeds={checkeds}
					/>
				</>
			)}
		</li>
	);
};

export default MenuItems;
