import { IconInterface } from '../interfaces/icon.interface';

export const Stack = (props: IconInterface) => {
	const { color } = props;

	return (
		<svg
			width="18"
			height="14"
			viewBox="0 0 18 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.38306 5.14767C1.79435 4.51219 1 4.19445 1 3.79961C1 3.40477 1.79435 3.08703 3.38306 2.45154L5.62984 1.55284C7.21855 0.917351 8.0129 0.599609 9 0.599609C9.9871 0.599609 10.7815 0.917351 12.3702 1.55284L14.6169 2.45154C16.2056 3.08703 17 3.40477 17 3.79961C17 4.19445 16.2056 4.51219 14.6169 5.14767L12.3702 6.04638C10.7815 6.68187 9.9871 6.99961 9 6.99961C8.0129 6.99961 7.21855 6.68187 5.62984 6.04638L3.38306 5.14767Z"
				stroke={color || '#494C83'}
			/>
			<path
				d="M17 7C17 7 16.2056 7.71258 14.6169 8.34806L12.3702 9.24677C10.7815 9.88226 9.9871 10.2 9 10.2C8.0129 10.2 7.21855 9.88226 5.62984 9.24677L3.38306 8.34806C1.79435 7.71258 1 7 1 7"
				stroke={color || '#494C83'}
				strokeLinecap="round"
			/>
			<path
				d="M17 10.1992C17 10.1992 16.2056 10.9118 14.6169 11.5473L12.3702 12.446C10.7815 13.0815 9.9871 13.3992 9 13.3992C8.0129 13.3992 7.21855 13.0815 5.62984 12.446L3.38306 11.5473C1.79435 10.9118 1 10.1992 1 10.1992"
				stroke={color || '#494C83'}
				strokeLinecap="round"
			/>
		</svg>
	);
};
