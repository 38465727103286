const Arrows = () => {
	return (
		<svg
			width="7"
			height="14"
			viewBox="0 0 7 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.85355 10.1464C7.04882 10.3417 7.04882 10.6583 6.85355 10.8536L3.85355 13.8536C3.65829 14.0488 3.34171 14.0488 3.14645 13.8536L0.146447 10.8536C-0.0488158 10.6583 -0.0488157 10.3417 0.146447 10.1464C0.341709 9.95118 0.658291 9.95118 0.853553 10.1464L3.5 12.7929L6.14645 10.1464C6.34171 9.95118 6.65829 9.95118 6.85355 10.1464Z"
				fill="#A2A2A2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.146447 3.85355C-0.0488155 3.65829 -0.0488155 3.34171 0.146447 3.14645L3.14645 0.146446C3.34171 -0.0488162 3.65829 -0.0488162 3.85355 0.146446L6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355C6.65829 4.04882 6.34171 4.04882 6.14645 3.85355L3.5 1.20711L0.853553 3.85355C0.658291 4.04882 0.341709 4.04882 0.146447 3.85355Z"
				fill="#A2A2A2"
			/>
		</svg>
	);
};

export default Arrows;
