export const DeliveryClient = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="25"
			fill="none"
			viewBox="0 0 25 25"
		>
			<circle
				cx="12.009"
				cy="12.01"
				r="12"
				fill="#373A77"
				fillOpacity="0.13"
				transform="rotate(-.044 12.01 12.01)"
			></circle>
			<circle
				cx="12.007"
				cy="12.008"
				r="4.421"
				fill="#373A77"
				transform="rotate(-.044 12.007 12.008)"
			></circle>
		</svg>
	);
};
