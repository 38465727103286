import { ReactElement } from 'react';

export interface ExpandedBoxProps {
	children?: ReactElement<any, any>;
	title?: string;
	isOpen?: boolean;
	boxType?: string;
	onToggle?: (newState: boolean) => void;
}

export enum ExpandedBoxType {
	SIMPLE_ORDER = 'simple_order',
	MULTIPLE_ORDER = 'multiple_order',
	EXCLUDED_ORDER = 'excluded_order',
}
