export const LogoShort = () => {
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.7778 42H13.2222C5.91111 42 0 36.0733 0 28.7778V13.2222C0 5.91111 5.92667 0 13.2222 0H28.7778C36.0889 0 42 5.92667 42 13.2222V28.7778C42 36.0733 36.0733 42 28.7778 42Z"
				fill="url(#paint0_linear_296_9576)"
			/>
			<path
				d="M17.1269 15.6493C17.3758 15.2137 17.8114 14.9648 18.3091 14.9648L29.0425 14.9959C29.5714 14.9959 29.9914 15.2915 30.2247 15.6804C30.4425 16.0693 30.4891 16.5826 30.2247 17.0493L24.8269 26.3359C24.578 26.7715 24.1425 27.0204 23.6447 27.0204C23.1314 27.0204 22.7269 26.7715 22.478 26.3359L19.8025 21.6848L17.1269 17.0337C16.8625 16.567 16.8625 16.1004 17.1269 15.6493ZM32.5269 14.3737C32.1847 13.7826 31.6869 13.2693 31.0647 12.8959C30.4736 12.5537 29.7891 12.367 29.058 12.367C25.4802 12.3515 21.9025 12.3359 18.3247 12.3359C16.0225 12.3359 14.3114 14.187 14.2958 16.3337C14.2958 16.6759 14.3425 17.0182 14.4202 17.3448C13.7514 17.5782 13.1914 18.0448 12.818 18.6826L9.36469 24.6248C8.77357 25.6359 8.85135 26.7715 9.34913 27.6582C9.86246 28.5448 10.8114 29.1826 11.978 29.1826L18.8536 29.1982C19.6002 29.1982 20.2691 28.9648 20.8136 28.4982C21.0625 28.7315 21.3269 28.9493 21.6225 29.1204C23.4736 30.1937 25.9469 29.6493 27.098 27.6582C28.9025 24.5626 30.6914 21.467 32.4958 18.387C32.8691 17.7493 33.0558 17.0804 33.0558 16.3804C33.0558 15.6493 32.8691 14.9648 32.5269 14.3737ZM11.6358 26.3359C11.5736 26.2271 11.558 26.087 11.6358 25.9315C12.7869 23.9559 13.938 21.9959 15.1202 20.0359C15.2914 19.7404 15.5869 19.6626 15.7736 20.0048L17.4847 22.9915L19.1958 25.9782C19.3825 26.3048 19.1647 26.5226 18.838 26.5226C16.5514 26.5537 14.2802 26.5537 11.9936 26.5537C11.8225 26.5382 11.698 26.4448 11.6358 26.3359Z"
				fill="white"
			/>
			<mask
				id="mask0_296_9576"
				type="alpha"
				maskUnits="userSpaceOnUse"
				x="13"
				y="9"
				width="12"
				height="12"
			>
				<mask
					id="mask1_296_9576"
					type="alpha"
					maskUnits="userSpaceOnUse"
					x="13"
					y="9"
					width="12"
					height="12"
				>
					<path
						d="M17.6088 9.42676L24.8577 13.6423L20.5799 20.9845L13.3311 16.769L17.6088 9.42676Z"
						fill="url(#paint1_linear_296_9576)"
					/>
				</mask>
				<g mask="url(#mask1_296_9576)">
					<path
						d="M17.6088 9.42676L24.8577 13.6423L20.5799 20.9845L13.3311 16.769L17.6088 9.42676Z"
						fill="url(#paint2_linear_296_9576)"
					/>
				</g>
			</mask>
			<g mask="url(#mask0_296_9576)">
				<path
					d="M24.0019 14.995L22.4775 12.3505L18.293 12.335C15.9908 12.335 14.2797 14.1861 14.2642 16.3327C14.2642 16.675 14.3108 17.0172 14.3886 17.3438C15.773 16.8616 17.3286 17.4216 18.0597 18.6972L17.0953 17.0172C16.5353 16.0527 17.2664 14.9794 18.2775 14.9794L24.0019 14.995Z"
					fill="url(#paint3_linear_296_9576)"
				/>
			</g>
			<mask
				id="mask2_296_9576"
				type="alpha"
				maskUnits="userSpaceOnUse"
				x="12"
				y="23"
				width="10"
				height="9"
			>
				<mask
					id="mask3_296_9576"
					type="alpha"
					maskUnits="userSpaceOnUse"
					x="12"
					y="23"
					width="10"
					height="9"
				>
					<path
						d="M14.9176 23.4111L21.5754 27.2845L18.8687 31.9511L12.2109 28.0778L14.9176 23.4111Z"
						fill="url(#paint4_linear_296_9576)"
					/>
				</mask>
				<g mask="url(#mask3_296_9576)">
					<path
						d="M14.9176 23.4111L21.5754 27.2845L18.8687 31.9511L12.2109 28.0778L14.9176 23.4111Z"
						fill="url(#paint5_linear_296_9576)"
					/>
				</g>
			</mask>
			<g mask="url(#mask2_296_9576)">
				<path
					d="M20.1597 27.6429L19.1953 25.9629C19.382 26.2896 19.1642 26.5073 18.8375 26.5073C16.9397 26.5229 15.0575 26.5384 13.1753 26.5384L14.2486 29.1829L18.8531 29.1984C19.5997 29.1984 20.2686 28.9651 20.8131 28.4984C20.5642 28.2496 20.3464 27.9696 20.1597 27.6429Z"
					fill="url(#paint6_linear_296_9576)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_296_9576"
					x1="0"
					y1="21"
					x2="42"
					y2="21"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.0220187" stopColor="#494C83" />
					<stop offset="1" stopColor="#7693F4" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_296_9576"
					x1="12.3628"
					y1="19.0716"
					x2="20.7486"
					y2="14.2661"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_296_9576"
					x1="12.3628"
					y1="19.0716"
					x2="20.7486"
					y2="14.2661"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_296_9576"
					x1="14.4092"
					y1="17.3508"
					x2="22.7063"
					y2="12.6956"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_296_9576"
					x1="20.0395"
					y1="25.8831"
					x2="15.6144"
					y2="28.4185"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_296_9576"
					x1="20.0395"
					y1="25.8831"
					x2="15.6144"
					y2="28.4185"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_296_9576"
					x1="19.4983"
					y1="26.86"
					x2="13.7135"
					y2="28.7003"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
