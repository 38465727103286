export const DecreaseIcon = ({ isActive }: any) => {
	return (
		<svg
			width="8"
			height="2"
			viewBox="0 0 8 2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.2 1.79922C7.64183 1.79922 8 1.44105 8 0.999219C8 0.557391 7.64183 0.199219 7.2 0.199219H0.8C0.358172 0.199219 0 0.557391 0 0.999219C0 1.44105 0.358172 1.79922 0.8 1.79922H7.2Z"
				fill={isActive ? 'white' : '#a2a2a2'}
			/>
		</svg>
	);
};
