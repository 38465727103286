import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	SypacButton,
	SypacText,
	SypacBreadcrumbs,
	SypacLink,
} from '@sypac/component-library-react';

import Close from '../../assets/Close';
import { ProductGroupProps } from './ProductGroup.interface';
import './product-group.css';
import { Category } from '../../interfaces/category.interface';
import { T } from '@tolgee/react';

const ProductGroup = ({
	groups,
	onSelect,
	back,
	title,
	breadcrumbs,
	description,
	showClose,
}: ProductGroupProps) => {
	const navigate = useNavigate();

	const renderGroup = (item: Category) => {
		return (
			<div
				className="flex flex-col items-center"
				key={item.id}
				onClick={() => onSelect(item)}
			>
				<div
					style={{ backgroundImage: `url(${item.imageUrl})` }}
					className="product-group-bg-image"
				/>
				<SypacText variant="body-normal-large">
					<p className="-tracking-[0.01] text-primary-dark-gray">{item.name}</p>
				</SypacText>
			</div>
		);
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<div className="max-w-[690px] w-full flex flex-col">
				{showClose && (
					<div className="flex justify-between items-center mb-15">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-80">
								<T keyName="productGroup.addNewProduct">Add new product</T>
							</p>
						</SypacText>
						<SypacButton variant="secondary" size="medium">
							<button
								onClick={() => navigate('/products/')}
								className="p-[9.5px] border-[1px] border-gray-10 hover:border-primary-violet"
							>
								<Close />
							</button>
						</SypacButton>
					</div>
				)}
				<SypacText variant="heading-5" className="mb-4">
					<p className="-tracking-[0.01] text-primary-dark-gray font-semibold">
						{title}
					</p>
				</SypacText>
				{!!description && (
					<SypacText variant="body-regular-medium">
						<p className="-tracking-[0.01] text-gray-80">{description}</p>
					</SypacText>
				)}
				{!!breadcrumbs?.length && (
					<SypacBreadcrumbs>
						{breadcrumbs.map((item, i) => (
							<SypacLink variant="breadcrumbs" key={i}>
								<a href={item.link}>{item.name}</a>
							</SypacLink>
						))}
					</SypacBreadcrumbs>
				)}

				<div className="border-card">
					{groups?.map((item) => renderGroup(item))}
				</div>
			</div>
		</div>
	);
};

export default ProductGroup;
