import React from 'react';
import { SypacText } from '@sypac/component-library-react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { DefaultProps } from './Default.interface';

const Default = ({ notification, title, description, tag }: DefaultProps) => {
	return (
		<BoxComponent notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="overline-regular-large">
					<p className="text-gray-80 leading-3">{description}</p>
				</SypacText>
				{tag ? (
					<SypacText variant="overline-regular-large">
						<p className="text-gray-80 leading-3">{tag}</p>
					</SypacText>
				) : null}
			</div>
		</BoxComponent>
	);
};

export default Default;
