import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';

interface Props {
	optionType: string;
	brand?: string;
	model?: string;
	type?: string;
	year?: number;
	limit?: number;
	offset?: number;
}

export interface TruckOptions {
	count: number;
	items: [string | number] | [];
}

type UseGetTruckOptions = [
	TruckOptions | undefined,
	Error | undefined,
	boolean,
];

export const useGetTruckOptions = ({
	optionType,
	brand,
	model,
	type,
	year,
	limit = 1000,
	offset = 0,
}: Props): UseGetTruckOptions => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!['types'].includes(optionType) && !(brand || model || type || year)) {
			setData({
				items: [],
				count: 0,
			});
			return;
		}
		setIsLoading(true);
		facade.client
			.get(`/v1/transporter/fleets/${optionType}/options`, {
				params: {
					limit,
					offset,
					brand,
					model,
					type,
					year,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, optionType, limit, offset, brand, model, year, type]);

	return [data, error, isLoading];
};
