import React, {
	useState,
	DragEvent,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import Modal from 'react-modal';
import { UlpoadDocumentsProps } from './UlpoadDocuments.interface';
import {
	SypacButton,
	SypacDropdown,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import CircleClose from '../../assets/CircleClose';
import CircleValid from '../../assets/CircleValid';
import PreviewDocument from '../../assets/PreviewDocument';
import ModalDeleteDocument from '../ModalDeleteDocument/ModalDeleteDocument';
import { FileInterface } from '../CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { T, useTranslate } from '@tolgee/react';

export const DOCEMENT_TYPES = [
	{ value: 'Registration certificate', label: 'Registration certificate' },
	{ value: 'Power of attorney', label: 'Power of attorney' },
	{ value: 'Identification card', label: 'Identification card' },
	{ value: 'Passport', label: 'Passport' },
];

const UlpoadDocuments = (props: UlpoadDocumentsProps) => {
	const { t } = useTranslate();
	const {
		isOpen,
		onClose,
		onSave,
		files,
		docsLoading,
		saveLocalDocuments,
		updateType,
	} = props;
	const [type, setType] = useState<string>();
	const [isSave, setSave] = useState<boolean>(false);
	const [deleteIndex, setDeleteIndex] = useState<number>();
	const [openDelete, setDelete] = useState<boolean>(false);
	const [deletedFiles, setDeletedFiles] = useState<FileInterface[]>([]);
	const [localFiles, setLocalFiles] = useState<FileInterface[]>([]);
	const [progress, setProgress] = useState(1);

	const previousTypeRef = useRef<string | undefined>(undefined);

	useEffect(() => {
		if (files?.length !== 0) {
			const newType = files?.length ? files[0].type : '';
			setType(newType);
			setLocalFiles(files?.length ? files : []);
		}
		setSave(!!files?.length);
	}, [files]);

	useEffect(() => {
		if (!docsLoading) {
			setSave(true);
		}
	}, [docsLoading]);

	const save = () => {
		if (type !== previousTypeRef.current) {
			previousTypeRef.current = type;
			updateType(localFiles.map((r) => ({ ...r, type })));
		}
		saveLocalDocuments();
		setLocalFiles([]);
		setSave(false);
		setDeleteIndex(undefined);
		setType(undefined);
	};

	const close = () => {
		setLocalFiles([]);
		setSave(false);
		setType(undefined);
		onClose();
	};

	const loadingBar = () => {
		setProgress(1);
		const increment = 1;
		const totalTime = 10 * 1000;
		const intervalTime = totalTime / 100;
		let currentProgress = 0;

		const interval = setInterval(() => {
			currentProgress += increment;
			setProgress(currentProgress);
			if (currentProgress >= 100) {
				clearInterval(interval);
			}
		}, intervalTime);
	};

	const getDataFiles = async (files: FileList) => {
		const dataFiles = [];
		for (let file of files) {
			const values = file.name.split('.');
			const name = values[0];
			let mimeType = values[1]?.toUpperCase() || '';
			if (file.type) {
				mimeType = file.type.split('/').pop()?.toUpperCase() || mimeType;
			}
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;
			dataFiles.push({ type, name, mimeType, size, file });
		}
		setLocalFiles(dataFiles);
		onSave(
			dataFiles.map((r) => ({ ...r, type })),
			deletedFiles,
		);
		loadingBar();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			getDataFiles(e.dataTransfer.files);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			getDataFiles(e.target.files);
		}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const closeDeleteModal = () => {
		setDeleteIndex(undefined);
		setDelete(false);
	};

	const chekDeleteFile = (index: number) => {
		setDeleteIndex(index);
		setDelete(true);
	};

	const deleteFile = () => {
		const dataFiles = JSON.parse(JSON.stringify(localFiles));
		const file = localFiles.find((r, i) => i === deleteIndex);
		dataFiles.splice(deleteIndex, 1);
		setLocalFiles(dataFiles);
		if (!dataFiles.length) {
			setSave(false);
		}
		if (file && file.id) {
			setDeletedFiles([...deletedFiles, file]);
		}
		setDeleteIndex(undefined);
		setDelete(false);
	};

	const handleDropdownChange = useCallback(
		(e: any) => {
			setType(e?.detail?.value);
		},
		[setType],
	);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={close}
				className="modal-inside outline-none w-[358px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
			>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between px-6 py-4">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="uploadDocuments.uploadCompanyDocuments">
									Upload company documents
								</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={close}
						/>
					</div>

					<div className="flex flex-col items-start justify-center upload-docs-top-border p-6 gap-4">
						<div className="flex flex-col justify-start w-full">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="uploadDocuments.selectFileType">
										Select file type
									</T>
								</p>
							</SypacText>
							<SypacDropdown
								name="type"
								placeholder={t(
									'uploadDocuments.enter',
									'Company registry certificate',
								)}
								callback={handleDropdownChange}
								className="w-full mt-1"
							>
								{DOCEMENT_TYPES.map((option) => {
									return (
										// @ts-ignore
										<sypac-dropdown-option
											key={option.value}
											value={option.value}
											label={option.label}
											selected={option.value === type}
										/>
									);
								})}
							</SypacDropdown>
						</div>
						<div className="flex flex-col justify-start w-full">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									{isSave
										? t(
												'uploadDocuments.uploadedDocuments',
												'Uploaded documents',
										  )
										: t('uploadDocuments.uploadDocument', 'Upload document')}
								</p>
							</SypacText>

							{isSave ? (
								<div className="flex flex-col justify-start w-full">
									{localFiles.map((file, index) => {
										return (
											<div
												className="flex flex-row mt-4"
												key={file?.name || Math.random() + index}
											>
												<div>
													<PreviewDocument />
												</div>
												<div className="flex flex-col ml-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">{file.name}</p>
													</SypacText>
													<div className="flex flex-row justify-start items-center mt-1">
														<SypacText variant="overline-regular-large">
															<p className="text-gray-20">{file.mimeType} </p>
														</SypacText>
														<div className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
														<SypacText variant="overline-regular-large">
															<p className="text-gray-20">{file.size} </p>
														</SypacText>
													</div>
													<SypacText
														variant="overline-normal-large"
														className="mt-3 cursor-pointer"
														onClick={() => chekDeleteFile(index)}
													>
														<p className="text-red">
															<T keyName="uploadDocuments.deleteFile">
																Delete file
															</T>
														</p>
													</SypacText>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<>
									{docsLoading ? (
										<div className="flex items-center justify-center border border-gray-90 border-dashed rounded-10 h-24 w-full mt-4">
											<div className="flex flex-col gap-1.5">
												<div
													className="w-[270px] bg-gray-200 rounded-lg p-[3px]"
													style={{ backgroundColor: '#e8e8e8' }}
												>
													<div className="w-full h-[6px] bg-gray-200 rounded-lg overflow-hidden">
														<div
															className="h-full bg-gradient-primary transition-all duration-300 ease-in-out"
															style={{ width: `${progress}%` }}
														></div>
													</div>
												</div>
												<SypacText variant="overline-regular-large">
													<p className="font-medium text-sm text-gray-40">{`${progress}%`}</p>
												</SypacText>
											</div>
										</div>
									) : (
										<div
											onDragEnter={handleDrag}
											onDragLeave={handleDrag}
											onDragOver={handleDrag}
											onDrop={handleDrop}
											className="border border-gray-90 border-dashed rounded-10 h-24 w-full mt-4"
										>
											<input
												type="file"
												id="input-file-upload"
												className="hidden"
												multiple={true}
												accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
												onChange={handleChange}
											/>
											<label
												htmlFor="input-file-upload"
												className="flex flex-col justify-center items-center"
											>
												<SypacText variant="body-regular-medium">
													<div className="flex">
														<p className="text-gray-80">
															<T keyName="uploadDocuments.dragAndDrop">
																Drag and drop or
															</T>{' '}
															<span className="text-cornflower-blue cursor-pointer">
																<T keyName="uploadDocuments.browseFile">
																	Browse File
																</T>
															</span>
														</p>
													</div>
												</SypacText>

												<SypacText
													variant="overline-regular-medium"
													className="mt-3"
												>
													<p className="text-gray-40">
														<T keyName="uploadDocuments.noteSupportedFiles">
															Note: We support JPG, PDF or PNG files.
														</T>
													</p>
												</SypacText>
											</label>
										</div>
									)}
								</>
							)}
						</div>
					</div>
					{isSave ? (
						<div className="flex justify-center p-6 upload-docs-top-border">
							<div className="flex flex-row gap-x-4">
								<SypacButton variant="secondary" size="small" onClick={close}>
									<button
										className="px-8 py-2.5 border-red flex flex-row justify-center items-center"
										type="reset"
									>
										<CircleClose />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-red">
												<T keyName="uploadDocuments.cancel">Cancel</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="secondary" size="small" onClick={save}>
									<button
										className="px-8 py-2.5 border-mountain-meadow flex flex-row justify-center items-center"
										type="reset"
										disabled={!(type && localFiles.length)}
									>
										<CircleValid />
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-mountain-meadow">
												<T keyName="uploadDocuments.save">Save</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
					) : (
						<></>
						// <div className="w-full">
						// 	<SypacButton
						// 		variant="secondary"
						// 		size="small"
						// 		onClick={upload}
						// 		className="w-full"
						// 	>
						// 		<button
						// 			className="w-full px-10 py-2.5 border-mountain-meadow"
						// 			type="reset"
						// 			disabled={!(type && localFiles.length)}
						// 		>
						// 			<SypacText variant="body-regular-medium">
						// 				<p className="text-mountain-meadow">
						// 					<T keyName="uploadDocuments.uploadDocument">
						// 						Upload document
						// 					</T>
						// 				</p>
						// 			</SypacText>
						// 		</button>
						// 	</SypacButton>
						// </div>
					)}
				</div>
			</Modal>
			<ModalDeleteDocument
				isOpen={openDelete}
				onClose={closeDeleteModal}
				onSubmit={deleteFile}
			/>
		</>
	);
};

export default UlpoadDocuments;
