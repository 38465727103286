import { useEffect, useState } from 'react';
import { useApiFacade } from './use-api-facade';
import { CompanyResponse } from '../services/company.services';

interface Props {
	ids: number[];
	refresh?: number;
}

type UseGetCompanies = [
	CompanyResponse[] | undefined,
	Error | undefined,
	boolean,
];

export const useGetCompanies = ({
	ids,
	refresh = 0,
}: Props): UseGetCompanies => {
	const facade = useApiFacade();
	const [data, setData] = useState<CompanyResponse[]>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		if (!ids || ids.length === 0) {
			return;
		}
		Promise.all(
			ids.map((id) => facade.client.get(`/v1/admin/companies/${id}`, {})),
		)
			.then((responses) => {
				setData(responses.map((response) => response.data));
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, refresh, ids]);

	return [data, error, isLoading];
};
