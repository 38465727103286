export const Trash = () => {
	return (
		<svg
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle opacity="0.5" cx="40" cy="40" r="40" fill="#D3D4D5" />
			<path
				d="M51 33H28"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M48.7479 36.332L48.1256 45.5308C47.8862 49.0707 47.7665 50.8407 46.5962 51.9197C45.4259 52.9987 43.6259 52.9987 40.026 52.9987H38.9797C35.3798 52.9987 33.5799 52.9987 32.4096 51.9197C31.2393 50.8407 31.1195 49.0707 30.8801 45.5308L30.2578 36.332"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M36.1172 39.668L36.7937 46.3346"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M42.8796 39.668L42.2031 46.3346"
				stroke="#171717"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M32.0625 33C32.1381 33 32.1759 33 32.2102 32.9991C33.3242 32.9713 34.3071 32.2732 34.6861 31.2404C34.6978 31.2087 34.7097 31.1733 34.7337 31.1026L34.865 30.7143C34.9771 30.3828 35.0332 30.217 35.1076 30.0763C35.4043 29.5148 35.9532 29.1248 36.5875 29.025C36.7465 29 36.9238 29 37.2784 29H41.7289C42.0835 29 42.2607 29 42.4197 29.025C43.0541 29.1248 43.603 29.5148 43.8997 30.0763C43.9741 30.217 44.0301 30.3828 44.1423 30.7143L44.2736 31.1026C44.2975 31.1732 44.3095 31.2087 44.3211 31.2404C44.7002 32.2732 45.683 32.9713 46.7971 32.9991C46.8314 33 46.8692 33 46.9448 33"
				stroke="#171717"
				strokeWidth="1.5"
			/>
		</svg>
	);
};
