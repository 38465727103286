import React, { useState } from 'react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import TruckDetailsMap from '../../AdditionalDetails/TruckDetailsMap';
import { AdminOrderProps } from '../../../adminOrder.interface';

const ToLoading: React.FC<AdminOrderProps> = (props) => {
	const { selectedOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={5}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				<TruckDetailsMap selectedOrder={selectedOrder} />
			</div>

			<div className="px-10 py-3.5">
				<FulfilmentSchedule
					selectedOrder={selectedOrder}
					step={5}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={false}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>
		</>
	);
};

export default ToLoading;
