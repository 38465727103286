const CarrierIcon = () => {
	return (
		<svg
			width="34"
			height="20"
			viewBox="0 0 34 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="34" height="20" rx="10" fill="#FFB352" />
			<path
				d="M11.9102 8.72763C11.9102 6.32775 11.9102 5.12781 12.6557 4.38227C13.4012 3.63672 14.6012 3.63672 17.0011 3.63672C19.4009 3.63672 20.6009 3.63672 21.3464 4.38227C22.092 5.12781 22.092 6.32775 22.092 8.72763V10.0004C22.092 12.4002 22.092 13.6002 21.3464 14.3457C20.6009 15.0913 19.4009 15.0913 17.0011 15.0913C14.6012 15.0913 13.4012 15.0913 12.6557 14.3457C11.9102 13.6002 11.9102 12.4002 11.9102 10.0004V8.72763Z"
				stroke="#454545"
			/>
			<path
				d="M11.9102 10.6367H22.092"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.2285 12.5469H20.1831"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.8184 12.5469H14.7729"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.1816 14.7734V15.728C13.1816 16.0794 13.4666 16.3643 13.818 16.3643H14.7725C15.124 16.3643 15.4089 16.0794 15.4089 15.728V15.0916"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.8191 14.7734V15.728C20.8191 16.0794 20.5342 16.3643 20.1827 16.3643H19.2282C18.8767 16.3643 18.5918 16.0794 18.5918 15.728V15.0916"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.0918 8.08984H22.7282C23.0796 8.08984 23.3645 8.37475 23.3645 8.72621V9.36257C23.3645 9.56287 23.2702 9.75148 23.11 9.87166L22.0918 10.6353"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.9094 8.08984H11.2731C10.9216 8.08984 10.6367 8.37475 10.6367 8.72621V9.36257C10.6367 9.56287 10.731 9.75148 10.8913 9.87166L11.9094 10.6353"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.774 5.54688H12.2285"
				stroke="#454545"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default CarrierIcon;
