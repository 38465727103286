import React from 'react';
import { SypacIcon } from '@sypac/component-library-react';

import { PaymentStatusColor } from './PaymentStatus.constants';
import './payment_status_icon.css';

interface PaymentStatusIconProps {
	state: string;
}

const PaymentStatusIcon = (props: PaymentStatusIconProps) => {
	const { state } = props;

	return (
		<div className="flex">
			<div
				className={`flex items-center justify-center w-[22px] h-[22px] ${
					// eslint-disable-next-line eqeqeq
					PaymentStatusColor[state] == 'green' ? 'text-white' : ''
				} rounded-full ${PaymentStatusColor[state]}`}
			>
				{state === 'pending' ? (
					<SypacIcon
						iconName="Clock White"
						size="custom"
						width="12px"
						height="12px"
					/>
				) : state === 'overdue' ? (
					<SypacIcon
						iconName="Danger Filled"
						size="custom"
						width="12px"
						height="11px"
					/>
				) : state === 'paid' ? (
					<SypacIcon
						iconName="Unread"
						size="custom"
						width="19px"
						height="16px"
					/>
				) : null}
			</div>
		</div>
	);
};

export default PaymentStatusIcon;
