import React, { useState } from 'react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import TruckSchedule from '../../AdditionalDetails/TruckSchedule';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import TruckDetailsMap from '../../../../../Admin/OrderDetails/components/AdditionalDetails/TruckDetailsMap';
import { TransporterOrderProps } from '../../../transporterOrder.interface';

const CommonStep: React.FC<TransporterOrderProps> = (props) => {
	const { selectedOrder, currentStep } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={currentStep!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{currentStep! > 3 && currentStep! < 10 && (
				<div className="px-10 pt-7 pb-3.5">
					<TruckDetailsMap selectedOrder={selectedOrder} />
				</div>
			)}

			<div
				className={`px-10 ${
					currentStep! > 3 && currentStep! < 10 ? 'py-3.5' : 'pt-7 pb-3.5'
				}`}
			>
				{currentStep === 7 ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				) : (
					<TruckSchedule selectedOrder={selectedOrder} />
				)}
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={![8, 9, 10].includes(currentStep!)}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={[8, 9, 10].includes(currentStep!)}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>
		</>
	);
};

export default CommonStep;
