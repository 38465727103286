export const NewNotification = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.5"
				d="M17.7491 16V16.7041C17.7491 17.5491 17.9903 18.3752 18.4422 19.0782L19.5496 20.8012C20.5612 22.3749 19.789 24.5139 18.0296 25.0116C13.4273 26.3134 8.57274 26.3134 3.97036 25.0116C2.21105 24.5139 1.43882 22.3749 2.45036 20.8012L3.5578 19.0782C4.00972 18.3752 4.25087 17.5491 4.25087 16.7041V16C4.25087 12.134 7.27256 9 11 9C14.7274 9 17.7491 12.134 17.7491 16Z"
				fill="#BABABA"
			/>
			<path
				d="M6.24219 25.5449C6.89313 27.5501 8.7767 28.9997 10.9988 28.9997C13.221 28.9997 15.1046 27.5501 15.7555 25.5449C12.61 26.1352 9.38763 26.1352 6.24219 25.5449Z"
				fill="#BABABA"
			/>
			<circle
				cx="22"
				cy="8"
				r="6.5"
				fill="#F44A77"
				stroke="white"
				strokeWidth="3"
			/>
		</svg>
	);
};
