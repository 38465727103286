export const Clock = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="9" cy="9" r="8" stroke="#8B8B8B" />
			<path
				d="M9 5.80078V9.00078L11 11.0008"
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
