import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SypacButton } from '@sypac/component-library-react';

import { DropDownItem } from './DropdownItem';
import { Points } from '../../assets/Points';
import { MenuDropdownProps } from './MenuDropdown.interface';

export const MenuDropdown = (props: MenuDropdownProps) => {
	const { options } = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const closeDropdown = () => {
		setIsOpen(false);
	};

	const ref = useDetectClickOutside({ onTriggered: closeDropdown });

	return (
		<div ref={ref} className="flex gap-x-3 relative">
			<SypacButton variant="subtitle" size="medium">
				<button
					className={`flex items-center p-0 justify-center w-8 h-8 bg-transparent ${
						isOpen
							? 'bg-white outline outline-1 outline-darkViolet-100'
							: 'hover:bg-white hover:outline hover:outline-1 hover:outline-gray-10'
					}`}
					onClick={() => setIsOpen(!isOpen)}
				>
					<Points />
				</button>
			</SypacButton>
			{isOpen && (
				<div className="flex flex-col absolute top-0 right-10 p-3 rounded-lg shadow-dropdown bg-white min-w-[145px] w-max z-30">
					{options.map((option) => {
						return <DropDownItem key={Math.random()} {...option} />;
					})}
				</div>
			)}
		</div>
	);
};
