import Api from '../axios/api';
import {
	OrderTarget,
	PaymentMethod,
} from '../components/OrdersTable/interfaces/OrderStatus.interface';
import {
	DriverLoadDetails,
	NotificationInterface,
	ProductOrderInterface,
	Quantity,
} from '../components/OrdersTable/interfaces/Order.interface';
import { UsersInterface } from '../interfaces/Users.interface';
import { FleetDto } from '../components/Map/Map.interface';
import { TruckDto } from './truck.services';
import { OrderProps } from '../context/OrderContext/order.context';

interface OrderResponse {
	count: number;
	items: Order[];
}

export interface Order {
	id: number;
	target: OrderTarget;
	status: OrderStatusesEnum & AdminOrderStatusesEnum;
	state: OrderStatusesEnum & AdminOrderStatusesEnum;
	paymentMethod: PaymentMethod;
	quantity: number;
	product: ProductOrderInterface;
	pickupLocation: Point;
	pickupAddress?: string;
	deliveryLocation: Point;
	deliveryAddress?: string;
	createdBy: string;
	createdByUser: UsersInterface;
	assigneeId: string;
	assignee?: UsersInterface;
	assigneeUser?: UsersInterface;
	companyId: number;
	sourceId?: number;
	notificationCount?: number;
	source?: Order;
	deliveryFrom: string;
	deliveryTo: string;
	createdAt: string;
	updatedAt: string;
	pickupCountryCode?: string;
	deliveryCountryCode?: string;
	notifications: { items: NotificationInterface[]; count: number };
	loadDetails?: DriverLoadDetails;
	unloadDetails?: DriverLoadDetails;
	truck?: FleetDto;
	trucks?: Array<TruckDto>;
	priceForProducer?: number;
	priceForTransporter?: number;
	transportPriceForClient?: number;
	productPriceForClient?: number;
	locationPhoto?: string;
	offerId?: number;
	deliveryIncluded?: boolean;
}

interface Point {
	type: 'Point';
	coordinates: number[];
}

interface QueryParams {
	limit?: number;
	offset?: number;
	clientIds?: string[];
	assigneeIds?: string[];
	ids?: number[];
	statuses?: string[];
	searchQuery?: string;
}

export interface CreateOrder {
	paymentMethod: string;
	quantity?: Quantity[];
	productId: number;
	latPickup: number;
	longPickup: number;
	pickupAddress?: string;
	latDelivery: number;
	longDelivery: number;
	deliveryAddress?: string;
	deliveryFrom: string;
	deliveryTo: string;
	isAssigned?: boolean;
	pickupCountryCode?: string;
	deliveryCountryCode?: string;
	locationPhoto?: string;
	language: string;
}

export interface AssigneeToMeOrder {
	orderId?: number;
}

export interface AcceptOrder {
	orderId?: number;
}

export interface AcceptOffer {
	offerId?: number;
}

export interface CreateOrderResponse {
	data: Order;
}

export interface UpdateOrderResponse {
	data: Order;
}

export enum ProducerOrderAction {
	ASSIGNN = 'assign',
	CONFRIM_PICKUP = 'confirm-pick-up',
	START_LOADING_GOODS = 'start-loading-goods',
	CONFIRM_LOADING_GOODS = 'confirm-loading-goods',
}

export interface ResponnseOrderListResponse {
	data: OrderResponse;
}

export interface OrderDriverActionDto {
	comments?: string;
	wight: number;
	documents: string[];
}

export enum OrderStatusesEnum {
	CREATED = 'created',
	TO_LOADING = 'to_loading',
	TO_UNLOADING = 'to_unloading',
	ARRIVED_TO_LOADING = 'arrived_to_loading',
	ARRIVED_TO_UNLOADING = 'arrived_to_unloading',
	LOADING = 'loading',
	UNLOADING = 'unloading',
	LOADED = 'loaded',
	UNLOADED = 'unloaded',
	COMPLETED = 'completed',
	DELIVERED = 'delivered',
	TO_DO = 'to_do',
	IN_PROGRESS = 'in_progress',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	REQUESTED = 'requested',
	EXPIRED = 'expired',
	SENT_TO_DRIVER = 'sent_to_driver',
	ACCEPTED = 'accepted',
	CONFRIM_PICKUP = 'confirm_pick_up',
	PREPARING = 'preparing',
	DELIVERING = 'delivering',
	PENDING_PAYMENT = 'pending_payment',
	PAID = 'paid',
	SHIPPED = 'shipped',
}

export enum OrderStatesEnum {
	TO_DO = 'to_do',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	TRANSPORTER_CONFIRMED = 'transporter_confirmed',
	TRUCK_CONFIRMED = 'truck_confirmed',
	TO_LOADING = 'to_loading',
	TO_UNLOADING = 'to_unloading',
	DELIVERED = 'delivered',
	IN_PROGRESS = 'in_progress',
	ARRIVED_TO_LOADING = 'arrived_to_loading',
	LOADING = 'loading',
	LOADED = 'loaded',
	ARRIVED_TO_UNLOADING = 'arrived_to_unloading',
	UNLOADING = 'unloading',
	UNLOADED = 'unloaded',
	COMPLETED = 'completed',
}

export enum AdminOrderStatusesEnum {
	TO_DO = 'to_do',
	IN_PROGRESS = 'in_progress',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	TRANSPORTER_CONFIRMED = 'transporter_confirmed',
	TRUCK_CONFIRMED = 'truck_confirmed',
	TO_LOADING = 'to_loading',
	TO_UNLOADING = 'to_unloading',
	DELIVERED = 'delivered',
}

export interface CalculatePrice {
	quantity: number;
	productId: number;
	latPickup: number;
	longPickup: number;
	latDelivery: number;
	longDelivery: number;
	trucksNr: number;
}

export interface Prices {
	productPriceForClient: number;
	transportPriceForClient: number;
	productPriceForClientWithVat: number;
	transportPriceForClientWithVat: number;
}

export interface ResponseCalculatePrice {
	data: Prices;
}

export interface UpdateTruckBody {
	id: number;
	type: string;
	licensePlates: string;
	driverFullName: string;
}

export class OrdersService {
	static getOrders(
		query?: QueryParams,
		target = 'customer',
	): Promise<ResponnseOrderListResponse> {
		return Api.get(`/v1/${target}/orders`, {
			params: query,
		});
	}

	static getRecentOrders(
		query?: QueryParams,
		target = 'customer',
	): Promise<{ data: { items: OrderProps[]; count: number } }> {
		return Api.get(`/v1/${target}/orders/recent`, {
			params: query,
		});
	}

	static getOffers(query?: QueryParams): Promise<ResponnseOrderListResponse> {
		return Api.get(`/v1/transporter/offers`, {
			params: query,
		});
	}

	static createOrder(body: CreateOrder): Promise<CreateOrderResponse> {
		return Api.post(`/v1/customer/orders`, body);
	}

	static actionOrder(
		body: OrderDriverActionDto,
		target: OrderTarget,
		action: string,
		id: number,
	): Promise<UpdateOrderResponse> {
		return Api.post(`/v1/${target}/orders/${id}/${action}`, body);
	}

	static acceptOrder(
		body: AcceptOrder,
		target: OrderTarget,
	): Promise<UpdateOrderResponse> {
		return Api.post(`/v1/${target}/orders/${body.orderId}/accept/`);
	}

	static acceptOffer(body: {}, id: number): Promise<UpdateOrderResponse> {
		return Api.post(`/v1/transporter/offers/${id}/accept/`, body);
	}

	static confirmOrder(
		id: number,
		target: OrderTarget,
	): Promise<UpdateOrderResponse> {
		return Api.post(`/v1/${target}/orders/${id}/confirm`);
	}

	static getOrder(
		id: number,
		target: OrderTarget,
	): Promise<UpdateOrderResponse> {
		return Api.get(`/v1/${target}/orders/${id}`);
	}

	static getOffer(id: number): Promise<UpdateOrderResponse> {
		return Api.get(`/v1/transporter/offers/${id}`);
	}

	static calculatePrice(body: CalculatePrice): Promise<ResponseCalculatePrice> {
		return Api.post(`/v1/customer/orders/price`, body);
	}

	static updateTruckDetails(id: number, body: UpdateTruckBody): Promise<any> {
		return Api.post(`/v1/customer/orders/${id}/truck`, body);
	}
}
