import React from 'react';
import Modal from 'react-modal';
import {
	SypacAvatar,
	SypacBox,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { LineStroke } from '../../../../../assets/LineStroke';
import { T } from '@tolgee/react';

export interface AwaitingTransportProps {
	isShown: boolean;
	count: number;
	solutions: {
		id: number;
		name: string;
		email: string;
		phone?: string;
		secondaryPhone?: string;
	}[];
	onClose: () => void;
}

const TransportSolution: React.FC<AwaitingTransportProps> = (props) => {
	const { isShown, onClose, solutions, count } = props;

	return (
		<Modal
			isOpen={isShown}
			contentLabel="onRequestClose Example"
			onRequestClose={onClose}
			className="modal-inside outline-none px-6"
			overlayClassName="modal-overlay"
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between py-2.5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="transportSolution.transportSolutions">
								Transport solutions
							</T>
						</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<LineStroke width={[520, 520, 556]} />
				<div className="flex flex-col py-[25px] gap-[25px]">
					<div className="flex flex-col gap-3">
						<SypacText variant="body-regular-large">
							<p className="text-gray-90 -tracking-[0.01em]">
								<T keyName="transportSolution.successfullySend">
									Your order has been successfully send to:
								</T>
								<br />
								{count}{' '}
								<T keyName="transportSolution.transportSolutions">
									transport solutions
								</T>
							</p>
						</SypacText>
						<SypacText variant="overline-regular-large ">
							<p className="text-gray-80">
								<T keyName="transportSolution.awaitingConfirmation">
									Awaiting confirmation
								</T>
							</p>
						</SypacText>
					</div>
					<div className="flex flex-col gap-3">
						{solutions.map(({ name, phone, secondaryPhone, email }) => {
							return (
								<SypacBox key={Math.random()} boxStyle="solid" boxShadow="xs">
									<div className="flex justify-between p-4 gap-2.5">
										<SypacAvatar size="sm" initials={name}>
											<img src="" alt={name} />
										</SypacAvatar>
										<div className="flex flex-col gap-3 flex-1">
											<SypacText variant="heading-5">
												<p className="text-gray-80 font-bold">{name}</p>
											</SypacText>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-30 flex">
													<T keyName="transportSolution.phone">Phone:</T>&nbsp;
													<div className="flex gap-[30px] text-cornflower-blue">
														{[phone, secondaryPhone].map((item) => {
															return <span key={Math.random()}>{item}</span>;
														})}
													</div>
												</p>
											</SypacText>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-30 flex">
													<T keyName="transportSolution.emailAddress">
														Email address:
													</T>
													<span className="text-cornflower-blue">
														&nbsp;{email}
													</span>
												</p>
											</SypacText>
										</div>
									</div>
								</SypacBox>
							);
						})}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default TransportSolution;
