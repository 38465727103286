import React from 'react';
import {
	SypacBadge,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { PaymentStatusProps } from './PaymentStatus.interface';
import { PaymentStatusColor } from './PaymentStatus.constants';
import { T } from '@tolgee/react';

const PaymentStatus = (props: PaymentStatusProps) => {
	const { state, days, isPartial } = props;

	return (
		<div className="flex gap-2.5 items-center w-full">
			<SypacBadge
				color={PaymentStatusColor[state]}
				size="large"
				className="w-full"
			>
				{state === 'pending' ? (
					<>
						<div className="flex gap-1 items-center w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Clock White"
								size="custom"
								width="12px"
								height="12px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="paymentStatus.pending">Pending</T>
								</p>
							</SypacText>
						</div>
						{days ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80">{days}d.</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
						{isPartial ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 w-fit"
							>
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80 min-w-max">
										<T keyName="paymentStatus.partialPayment">
											Partial payment
										</T>
									</p>
								</SypacText>
							</SypacBadge>
						) : null}
					</>
				) : state === 'overdue' ? (
					<>
						<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Danger Filled"
								size="custom"
								width="12px"
								height="11px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-white">
									<T keyName="paymentStatus.overdue">Overdue</T>
								</p>
							</SypacText>
						</div>
						{days ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-white">{days}d.</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
					</>
				) : state === 'paid' ? (
					<>
						<div className="flex gap-1 text-white w-fit justify-center cursor-auto">
							<SypacIcon
								iconName="Unread"
								size="custom"
								width="19px"
								height="16px"
							/>
							<SypacText variant="overline-regular-large">
								<p className="text-white">
									<T keyName="paymentStatus.paid">Paid</T>
								</p>
							</SypacText>
						</div>
						{isPartial ? (
							<SypacBadge
								color={PaymentStatusColor[state]}
								size="small"
								className="ml-3 w-fit cursor-auto"
							>
								<span className="cursor-auto">
									<SypacText variant="overline-regular-large">
										<p className="text-white min-w-max">
											<T keyName="paymentStatus.inInstalments">
												In instalments
											</T>
										</p>
									</SypacText>
								</span>
							</SypacBadge>
						) : null}
					</>
				) : null}
			</SypacBadge>
		</div>
	);
};

export default PaymentStatus;
