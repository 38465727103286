import React from 'react';

import { AllIcon } from '../../assets/AllIcon';
import { IconCompleted } from '../../assets/IconCompleted';
import { IconRegistration } from '../../assets/IconRegistration';
import { IconVerification } from '../../assets/IconVerification';
import { AssignedOrder } from '../../assets/AssignedOrder';
import { UnassignedOrder } from '../../assets/UnassignedOrder';

export const RenderIconByState = ({
	state,
	isActive,
}: {
	state: string;
	isActive?: boolean;
}) => {
	const Icons: Record<string, () => JSX.Element> = {
		all: () => <AllIcon isActive={isActive} />,
		rejected: () => <IconRegistration isActive={isActive} />,
		awaitEmailConfirmation: () => <IconVerification isActive={isActive} />,
		approved: () => <IconCompleted isActive={isActive} />,
		verified: () => <IconCompleted />,
		short_verified: () => <IconCompleted isActive={true} />,
		assigned: () => <AssignedOrder />,
		unassigned: () => <UnassignedOrder />,
		verificationPending: () => <IconVerification />,
		verification_pending: () => <IconVerification />,
		registrationInProgress: () => <IconRegistration />,
		registration_in_progress: () => <IconRegistration />,
	};
	const Icon = Icons[state] || Icons['awaitEmailConfirmation'];
	return <Icon />;
};
