import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { AssignAccountManagerProps } from './AssignAccountManager.interface';
import './assign-account-manager.css';
import AvatarDropDown from '../AvatarDropDown/AvatarDropDown';
import { AvatarItem } from '../AvatarDropDown/AvatarDropDown.interface';
import { T } from '@tolgee/react';

const AssignAccountManager = ({
	isOpen,
	onClose,
	onSubmit,
	users,
	assignees,
}: AssignAccountManagerProps) => {
	const [items, setItems] = useState<AvatarItem[]>([]);
	const [selectedValue, setSelectedUser] = useState<string | null>();

	const onChange = (value: string) => {
		setSelectedUser(value);
	};

	const onCloseModal = () => {
		setSelectedUser(null);
		onClose && onClose();
	};
	const callSubmint = () => {
		const user = users.find((r) => r.uid === selectedValue);
		onSubmit && onSubmit(user);
	};

	useEffect(() => {
		const newItems = users?.map((r) => ({
			id: r.uid,
			avatarUrl: r.avatarUrl,
			fullName: r.name,
		}));
		setItems(newItems);
	}, [users]);

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[663px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="assignAccountManager.assignAccountManager">
								Assign account manager
							</T>
						</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onCloseModal}
					/>
				</div>
				<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-6 py-4 gap-4">
					<AvatarDropDown
						items={items}
						onChange={onChange}
						userIsAssigned={users.some(
							(obj) => obj.uid === (assignees?.[0]?.assigneeUid ?? null),
						)}
					/>
				</div>

				<div className="flex justify-center py-5 mx-6 gap-5 w-fit">
					<SypacButton variant="secondary" size="small" onClick={onClose}>
						<button className="px-10 py-2.5 hover:border-gray-2" type="reset">
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="assignAccountManager.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small">
						<button
							className={`px-15 py-2.5 border-primary-violet hover:border-kimberly`}
							onClick={callSubmint}
							disabled={
								!selectedValue &&
								users.some(
									(obj) => obj.uid !== (assignees?.[0]?.assigneeUid ?? null),
								)
							}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="assignAccountManager.confirm">Confirm</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default AssignAccountManager;
