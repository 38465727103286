import { io } from 'socket.io-client';

export class SocketService {
	static socket: any;
	static connect() {
		let accessToken = localStorage.getItem('access_token');
		this.socket = io(process.env.REACT_APP_NOTIFY_URL!, {
			reconnection: true,
			extraHeaders: {
				'autorisation': `Bearer ${accessToken}`,
			},
		});
		this.socket.on('connect', () => {
			console.log(`connected`);
		});
		this.socket.on('disconnect', () => {
			console.log('disconnect');
		});
		return this.socket;
	}

	static getInstance() {
		if (!this.socket) {
			return this.connect();
		}
		return this.socket;
	}

	static disconnectSocket() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}
}
