import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	CommonNotificationsEnums,
	NotificationInterface,
	OrderInterface,
	TransporterNotificationsEnums,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import NotificationOrderAccepted from '../Notification/NotificationOrderAccepted';
import NotificationAssignedTo from '../Notification/NotificationAssignedTo';
import NotificationTruckAllocated from '../Notification/NotificationTruckAllocated';
import NotificationDriverLoaded from '../Notification/NotificationDriverLoaded';
import NotificationPendingArrivingUnloading from '../Notification/NotificationPendingArrivingUnloading';
import NotificationDriverArrivedUnloading from '../Notification/NotificationDriverArrivedUnloading';
import NotificationDriverUnloaded from '../Notification/NotificationDriverUnloaded';
import NotificationUnloadingSuccessful from '../Notification/NotificationUnloadingSuccessful';
import NotificationPeddingPayment from '../Notification/NotificationPeddingPayment';
import NotificationDriverAccept from '../Notification/NotificationDriverAccept';
import DriverMultipleNotification from '../../../components/Notification/DriverMultipleNotification';
import {
	SypacText,
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import NotificationPaid from '../Notification/NotificationPaid';
import { useTranslate } from '@tolgee/react';

interface TimelineProps {
	selectedOrder: OrderInterface | undefined;
}

const Timeline: React.FC<TimelineProps> = (props) => {
	let { selectedOrder } = props;
	const { t } = useTranslate();
	const [notifications, setNotifications] = useState<NotificationInterface[]>(
		[],
	);

	useEffect(() => {
		const notificationList = selectedOrder?.notifications?.items?.length
			? JSON.parse(JSON.stringify(selectedOrder?.notifications?.items))
			: [];
		setNotifications(notificationList);
	}, [selectedOrder]);

	return (
		<>
			{notifications.length ? (
				<div className="flex flex-col gap-8 px-10 py-7 border-0 border-solid border-t-[1px] border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-base leading-6 tracking-tighter text-gray-40">
							{t('awaitingTransport.timeline', 'Timeline')}
						</p>
					</SypacText>
					<div className="flex flex-col">
						{notifications.map((item, index) => {
							const date = dayjs(item.createdAt).format('DD MMM, YYYY — HH:mm');

							return (
								<div key={item.id}>
									{item.category === CommonNotificationsEnums.ORDER_PAID ? (
										<NotificationPaid notification={index === 0} date={date} />
									) : item.category ===
									  CommonNotificationsEnums.ORDER_COMPLETE ? (
										<NotificationPeddingPayment
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_CONFIRM_UNLOADING_GOODS ? (
										<NotificationUnloadingSuccessful
											notification={index === 0}
											client={selectedOrder?.createdByUser?.name}
											clientSigned={item.createdAt}
											clientDocuments={[]}
											driver={selectedOrder?.truck?.driverFullName}
											driverSigned={item.createdAt}
											driverDocuments={selectedOrder?.unloadDetails?.documents?.map(
												(src) => ({ src }),
											)}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS ? (
										<NotificationDriverUnloaded
											notification={index === 0}
											unloadingPlace={selectedOrder?.deliveryAddress}
											product={selectedOrder?.product}
											quantity={selectedOrder?.product.quantity}
											unloadingDate={item.createdAt}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_ARRIVED_TO_UNLOADING ? (
										<NotificationDriverArrivedUnloading
											notification={index === 0}
											loadingPlace={selectedOrder?.deliveryAddress}
											date={date}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_TRIP_TO_UNLOADING ? (
										<NotificationPendingArrivingUnloading
											notification={index === 0}
											date={date}
											loadingPlace={selectedOrder?.deliveryAddress}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS ? (
										<NotificationDriverLoaded
											notification={index === 0}
											date={date}
											loadingPlace={selectedOrder?.pickupAddress}
											product={selectedOrder?.product}
											quantity={selectedOrder?.product.quantity}
											loadingDate={item.createdAt}
										/>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING ? (
										<div className="flex flex-row gap-6">
											<SypacVerticalTimeline>
												<SypacVerticalTimelineStep
													isActive={index === 0}
													minWidth={120}
												>
													{date}
												</SypacVerticalTimelineStep>
											</SypacVerticalTimeline>
											<div className="h-fit w-full">
												<DriverMultipleNotification
													notification={index === 0}
													title={t(
														'timeline.driverArrivedLoadingPlace',
														'The driver arrived at the loading place',
													)}
													description={t(
														'timeline.pendingLoadingGoods',
														'Pending loading of goods.',
													)}
													loadingPlace={selectedOrder?.pickupAddress}
												/>
											</div>
										</div>
									) : item.category ===
									  CommonNotificationsEnums.ORDER_START_TRIP_TO_LOADING ? (
										<div className="flex flex-row gap-6">
											<SypacVerticalTimeline>
												<SypacVerticalTimelineStep
													isActive={index === 0}
													minWidth={260}
												>
													{date}
												</SypacVerticalTimelineStep>
											</SypacVerticalTimeline>
											<div className="h-fit w-full">
												<DriverMultipleNotification
													notification={index === 0}
													title={t(
														'timeline.pendingArrivingLoadingPlace',
														'Pending arriving at the loading place',
													)}
													description={t(
														'timeline.pendingHisArrivalLoadingGoods',
														'Pending his arrival and loading of goods.',
													)}
													eta={dayjs(selectedOrder?.deliveryFrom)
														.hour(16)
														.minute(0)
														.format('DD MMM, YYYY — HH:mm')}
													truckType={selectedOrder?.truck?.type}
													truckPlates={selectedOrder?.truck?.licensePlates}
													driverName={selectedOrder?.truck?.driverFullName}
													loadingPlace={selectedOrder?.pickupAddress}
												/>
											</div>
										</div>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ACCEPTED_BY_DRIVER ? (
										<NotificationDriverAccept
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ACCEPTED ? (
										<NotificationOrderAccepted
											notification={index === 0}
											date={date}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.ORDER_ASSIGNED ? (
										<NotificationAssignedTo
											notification={index === 0}
											date={date}
											avatar={selectedOrder?.assigneeUser?.avatarUrl || ''}
											username={
												(selectedOrder?.assigneeUser?.profile?.firstName
													? `${selectedOrder?.assigneeUser?.profile?.firstName} ${selectedOrder?.assigneeUser?.profile?.lastName}`
													: selectedOrder?.assigneeUser?.name)!
											}
										/>
									) : item.category ===
									  TransporterNotificationsEnums.DRIVER_ALLOCATED ? (
										<NotificationTruckAllocated
											notification={index === 0}
											date={date}
											driverName={selectedOrder?.truck?.driverFullName!}
											truckName={selectedOrder?.truck?.name!}
											truckType={selectedOrder?.truck?.type!}
											truckPlates={selectedOrder?.truck?.licensePlates!}
											truckId={selectedOrder?.truck?.id.toString()!}
										/>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</>
	);
};

export default Timeline;
