export const Future = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="64"
			height="64"
			fill="none"
			viewBox="0 0 64 64"
		>
			<path
				stroke="#454545"
				strokeLinecap="round"
				strokeWidth="3"
				d="M2 2l.875.294c4.361 1.467 6.542 2.2 7.79 3.946 1.246 1.746 1.246 4.065 1.246 8.704v8.723c0 9.428 0 14.142 2.903 17.07 2.903 2.93 7.576 2.93 16.92 2.93h26.43"
			></path>
			<path
				stroke="#454545"
				strokeWidth="3"
				d="M20.182 52c2.737 0 4.956 2.239 4.956 5s-2.219 5-4.956 5c-2.737 0-4.955-2.239-4.955-5s2.218-5 4.955-5zM49.901 52c2.737 0 4.956 2.239 4.956 5 0 2.762-2.219 5-4.956 5-2.737 0-4.956-2.238-4.956-5 0-2.761 2.22-5 4.956-5zM11.898 12h37.83c6.79 0 10.184 0 11.653 2.248 1.469 2.247.132 5.395-2.543 11.691l-1.416 3.334c-1.248 2.939-1.873 4.409-3.114 5.235-1.241.825-2.826.825-5.996.825H11.898"
			></path>
		</svg>
	);
};
