/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacCheckbox,
	SypacInput,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { StylesConfig } from 'react-select';

import { useGetCategories } from '../../hooks/use-get-categories';
import { useGetProductsTmpl } from '../../hooks/use-get-products-tmpl';
import {
	CreateProductParams,
	ProductService,
} from '../../services/product.services';
import './select-product.css';
import { ProductLocation } from '../ProductLocation/ProductLocation';
import { GeoService } from '../../services/geo.services';
import { Geo } from '../SearchLocation/locations.interface';
import { T, useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { ProductInterface } from '../../interfaces/product.interface';

export const colourErrorStyles: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		':focus': { borderColor: 'rgb(86, 130, 250)' },
		':hover': { borderColor: 'rgb(172, 176, 181)' },
		':focus-visible': { borderColor: 'rgb(86, 130, 250)' },
		':focus-within': { borderColor: 'rgb(86, 130, 250)' },
		boxShadow: 'none',
		borderColor: 'rgb(247, 72, 39)',
		padding: '0px 2px',
		maxHeight: '41px',
		height: '42px',
		borderRadius: '6px',
	}),
};

const colourStyles: StylesConfig<any, true> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		boxShadow: 'none',
		padding: '0px 2px',
		maxHeight: '42px',
		height: '42px',
		borderRadius: '6px',
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: data.background,
			borderRadius: '6px',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.background,
			color: 'white',
		},
	}),
};

const SelectProduct = () => {
	const { t } = useTranslate();
	const { groupId, categoryId } = useParams();
	const [categories] = useGetCategories(
		{ ids: [categoryId!, groupId!] },
		'producer',
	);
	const [groups] = useGetCategories({}, 'producer');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [products, _, isLoading] = useGetProductsTmpl(categoryId);
	const [address, setAddress] = useState<Geo | undefined>();
	const [maxStockAvailability, setStockAvailability] =
		useState<number>(1000000);
	const [disableSend, setDisableSend] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductInterface>();
	const navigate = useNavigate();

	const backUrl = `/products/new/groups/${groupId}/categories`;

	const getGroupName = (id: string) => {
		return categories?.items?.find((item) => item.id === id)?.name || 'No name';
	};

	const getParentName = () => {
		if (groups?.items?.length) {
			return groups.items[0]?.name;
		}
		return '';
	};

	const formik = useFormik({
		initialValues: {
			sourceProductId: undefined,
			available: undefined,
			availableQuantity: undefined,
			unlimited: false,
			minOrderQuantity: undefined,
			maxOrderCapacity: undefined,
			pricePerUnit: undefined,
			locationId: undefined,
			workingHours: undefined,
			pickupAvailability: undefined,
		},
		onSubmit: async (values) => {
			setDisableSend(true);
			let lat = address?.lat;
			let long = address?.long;
			let countryCode = address?.countryCode;
			if (values.locationId && values.locationId !== 'default') {
				const resp = await GeoService.getLocationDetails({
					locationid: values.locationId ? values.locationId : '',
				});
				lat = resp.data.Latitude;
				long = resp.data.Longitude;
				countryCode = resp?.data?.Country;
			}
			const addressLabel = address?.lat
				? address.label
				: address?.address
				? [
						[address?.address?.street, address?.address?.houseNumber].join(
							' ',
						) || '',
						address?.address.postalCode || '',
						address?.address.city || '',
						address?.address.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '';
			await ProductService.createProduct({
				...values,
				pricePerUnit: values.pricePerUnit
					? parseFloat(values.pricePerUnit)
					: undefined,
				minOrderQuantity: values.minOrderQuantity
					? parseFloat(values.minOrderQuantity)
					: undefined,
				availableQuantity: values.available
					? parseFloat(values.available)
					: undefined,
				sourceProductId: values.sourceProductId
					? parseInt(values.sourceProductId)
					: null,
				maxOrderCapacity: values.maxOrderCapacity
					? parseInt(values.maxOrderCapacity)
					: null,
				lat,
				long,
				address: addressLabel,
				countryCode,
			} as CreateProductParams);
			navigate('/products/new/thank-you');
			setDisableSend(false);
		},
		validateOnChange: true,
		validationSchema: Yup.object({
			locationId: Yup.string().required(
				t('selectProduct.locationIsRequired', 'Location is required'),
			),
			workingHours: Yup.bool()
				.oneOf(
					[true],
					t(
						'selectProduct.workingHoursIsRequired',
						'Working Hours is required',
					),
				)
				.required(
					t(
						'selectProduct.workingHoursIsRequired',
						'Working Hours is required',
					),
				),
			pickupAvailability: Yup.bool()
				.oneOf(
					[true],
					t(
						'selectProduct.pickupAvailabilityIsRequired',
						'Product pickup availability is required',
					),
				)
				.required(
					t(
						'selectProduct.pickupAvailabilityIsRequired',
						'Product pickup availability is required',
					),
				),
			sourceProductId: Yup.number().required(
				t('selectProduct.productTypeIsRequired', 'Product type is required'),
			),
			available: Yup.number()
				.positive(
					t(
						'selectProduct.minimumProductStock',
						'Minimum product stock is 0.1',
					),
				)
				.when(['availableQuantity', 'unlimited'], {
					is: (a: number, b: boolean) => {
						return a !== undefined || !b;
					},
					then: Yup.number().required(
						t(
							'selectProduct.productStockIsRequired',
							'Product stock is required',
						),
					),
				}),
			minOrderQuantity: Yup.number()
				.positive(
					t('selectProduct.minimumCapacityValue', 'Minimum capacity is 0.1'),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maxMinimumCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.minimumCapacityIsRequired',
						'Minimum capacity is required',
					),
				),
			maxOrderCapacity: Yup.number()
				.positive(
					t(
						'selectProduct.minimumMaxCapacityValue',
						'Minimum max order capacity is 0.1',
					),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maximumMaxCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.maximumCapacityIsRequired',
						'Maximum capacity is required',
					),
				),
			pricePerUnit: Yup.number()
				.positive(
					t('selectProduct.netPriceMinimumValue', 'Minimum Net price is 0.1'),
				)
				.required(
					t('selectProduct.netPriceIsRequired', 'Net price is required'),
				),
		}),
	});
	//TODO:SYPAC-336 add sypac-box when will be implemented
	//TODO:change dropdown like in design

	const getAddress = useCallback(async () => {
		if (!formik.values.locationId) {
			return;
		}
		if (address) {
			return;
		}
		try {
			const { data } = await GeoService.getLocationDetails({
				locationid: formik.values.locationId ? formik.values.locationId : '',
			});
			if (data?.address) {
				setAddress({
					label: data?.address?.label!,
					language: 'en',
					countryCode: data?.address?.country!,
					locationId: formik.values.locationId,
					matchLevel: '1',
					address: data.address!,
				});
			}
		} catch (e) {}
	}, [address, formik.values.locationId]);

	useEffect(() => {
		getAddress();
	}, [getAddress]);

	const setLocation = (value: Geo | undefined) => {
		setAddress(value);
		const locationId = value?.locationId
			? value?.locationId
			: value?.lat
			? 'default'
			: '';
		formik.setFieldValue('locationId', locationId, true);
		if (formik.values.locationId) {
			formik.setTouched({ 'locationId': true });
		}
	};

	const changeAvailableStock = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			formik.values.unlimited ? 1000000 : parseFloat(e.target.value) || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeUnlimited = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			e.target.checked ? 1000000 : formik.values.available || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeMaxOrderCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (
			!(value && value > 0.1 && value < maxStockAvailability) &&
			!isNaN(value)
		) {
			formik.setFieldValue('maxOrderCapacity', value, false);
			formik.setFieldTouched('maxOrderCapacity', true, false);
			formik.validateField('maxOrderCapacity');
		}
	};

	const changeMinOrderQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	if (isLoading) {
		return (
			<div className="w-full">
				<SypacText className="m-auto w-max italic">
					<span>Loading...</span>
				</SypacText>
			</div>
		);
	}

	const changeWorkHours = (value: boolean) => {
		formik.setFieldValue('workingHours', value, true);
		formik.setTouched({ 'workingHours': !value });
	};

	const changePickupAvailability = (value: boolean) => {
		formik.setFieldValue('pickupAvailability', value, true);
		formik.setTouched({ 'pickupAvailability': !value });
	};

	const customOption = (props: any) => {
		const { innerProps, innerRef, data, isSelected } = props;
		const type = [data.type, data.size]?.filter((r) => !!r)?.join(': ');
		return (
			<div
				ref={innerRef}
				{...innerProps}
				className={classNames(
					'm-1 p-2 hover:bg-gray-10-opacity-50 rounded-md border border-solid flex flex-row',
					{
						'bg-gray-10-opacity-50 border-gray-10': isSelected,
						'bg-white border-white': !isSelected,
					},
				)}
			>
				<div
					style={{ backgroundImage: `url(${data.photoUrl})` }}
					className="h-11 w-11 rounded-lg bg-cover"
				/>
				<div className="flex flex-col ml-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{data.name}</p>
					</SypacText>
					<SypacText variant="overline-regular-large" className="mt-1">
						<p className="text-gray-60">{type}</p>
					</SypacText>
				</div>
			</div>
		);
	};

	return (
		<div className="max-w-235 w-full h-full flex justify-center flex-col">
			<SypacText variant="heading-5">
				<p className="text-primary-dark-gray font-semibold -tracking-[0.01em]">
					<T keyName="selectProduct.selectProduct">Select product</T>
				</p>
			</SypacText>
			<div className="pt-3 pb-7.5">
				<SypacBreadcrumbs>
					<SypacLink variant="breadcrumbs">
						<a href="/products/new/groups">{getParentName()}</a>
					</SypacLink>
					<SypacLink variant="breadcrumbs">
						<a href={`/products/new/groups/${groupId}/categories`}>
							{getGroupName(categoryId!)}
						</a>
					</SypacLink>
					<SypacLink variant="breadcrumbs">
						<a href="#">
							<T keyName="selectProduct.product">Product</T>
						</a>
					</SypacLink>
				</SypacBreadcrumbs>
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className="border-card-product p-6.25 flex flex-col">
					<ProductLocation
						setLocation={setLocation}
						addWorkHours={changeWorkHours}
						addPickupDays={changePickupAvailability}
						errorLocation={
							formik.touched.locationId ? formik.errors.locationId : undefined
						}
						errorWorkingHours={
							formik.touched.workingHours &&
							typeof formik.values.workingHours === 'boolean'
								? formik.errors.workingHours
								: undefined
						}
						errorPickupAvailability={
							formik.touched.pickupAvailability &&
							typeof formik.values.pickupAvailability === 'boolean'
								? formik.errors.pickupAvailability
								: undefined
						}
						defaultValue={
							address?.address
								? [
										[
											address?.address?.street,
											address?.address?.houseNumber,
										].join(' ') || '',
										address?.address.postalCode || '',
										address?.address.city || '',
										address?.address.country || '',
								  ]
										.filter((r) => r)
										.join(', ')
								: undefined
						}
					/>
					<SypacText variant="body-regular-medium" className="mt-6.25">
						<p className="text-gray-80 font-medium pb-6.25">
							<T keyName="selectProduct.typeAndAvailability">
								2. Type & availability
							</T>
						</p>
					</SypacText>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-6.25 pb-2.5">
						<div className="w-full">
							<SypacInput
								error={
									!!(
										formik.touched.sourceProductId &&
										formik.errors.sourceProductId
									)
								}
								className="mb-1"
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="selectProduct.productType">Product type</T>
									<span className="text-red">*</span>
								</label>
								<Select
									closeMenuOnSelect={true}
									value={
										formik.values.sourceProductId
											? {
													value: formik.values.sourceProductId,
													label: selectedProduct?.name,
											  }
											: ''
									}
									options={products?.items.map((r) => ({
										...r,
										value: r.id,
										label: r.name,
									}))}
									onChange={(value: any) => {
										setSelectedProduct(value);
										formik.setFieldValue('sourceProductId', value.id);
									}}
									placeholder={t(
										'selectProduct.selectProductType',
										'Select Product Type',
									)}
									components={{ Option: customOption }}
									name="sourceProductId"
									onBlur={formik.handleBlur}
									menuPlacement={'auto'}
									classNamePrefix="dropdown"
									styles={
										formik.touched.sourceProductId &&
										formik.errors.sourceProductId
											? { ...colourStyles, ...colourErrorStyles }
											: colourStyles
									}
								/>
								{formik.touched.sourceProductId &&
								formik.errors.sourceProductId ? (
									<span className="input-error">
										<T keyName="selectProduct.errorProductType">
											{formik.errors.sourceProductId}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
						<div className="w-full">
							<SypacInput
								error={!!(formik.touched.available && formik.errors.available)}
								className="mb-1"
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="selectProduct.productStockAvailability">
										Product stock availability (tons)
									</T>
									<span className="text-red">*</span>
								</label>
								<input
									className="py-2.5 pl-3 border-[1px] transition-colors hover:border-gray-30 rounded-md placeholder:text-gray-22"
									name="available"
									type="number"
									placeholder={t(
										'selectProduct.enterAvailableProductStock',
										'Enter available product stock',
									)}
									value={formik?.values.available}
									onChange={changeAvailableStock}
									disabled={formik?.values.unlimited}
								/>
								{formik.touched.available && formik.errors.available ? (
									<span className="bottom-helper">
										<T keyName="selectProduct.errorAvailable">
											{formik.errors.available}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>
					<div className="pb-6.25 grid grid-cols-1 lg:grid-cols-2 gap-6.25">
						<div className="col-start-2 flex items-center gap-4">
							<SypacCheckbox size="md">
								<input
									type="checkbox"
									name="unlimited"
									checked={formik?.values.unlimited}
									onChange={changeUnlimited}
								/>
								{formik.touched.unlimited && formik.errors.unlimited ? (
									<span className="bottom-helper">
										<T keyName="selectProduct.errorUnlimited">
											{formik.errors.unlimited}
										</T>
									</span>
								) : null}
							</SypacCheckbox>
							<SypacText variant="overline-normal-large">
								<p className="text-gray-hover-2 pt-1">
									<T keyName="selectProduct.unlimitedQuantity">
										Unlimited quantity
									</T>
								</p>
							</SypacText>
						</div>
					</div>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 font-medium pb-6.25">
							<T keyName="selectProduct.priceAndOrder">3. Price and order</T>
						</p>
					</SypacText>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-6.25">
						<div className="w-full">
							<SypacInput
								error={
									!!(
										formik.touched.minOrderQuantity &&
										formik.errors.minOrderQuantity
									)
								}
							>
								<SypacText variant="overline-normal-large" className="mb-1">
									<p className="-tracking-[0.01em] text-primary-dark-gray">
										<T keyName="selectProduct.minimumOrderCapacity">
											Minimum order capacity per day (tons)
										</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									className="py-2.5 pl-3 border-[1px] transition-colors hover:border-gray-30 rounded-md placeholder:text-gray-22"
									type="number"
									name="minOrderQuantity"
									placeholder={t(
										'selectProduct.enterMinimumPerTon',
										'Enter minimum per ton',
									)}
									value={formik?.values.minOrderQuantity}
									onChange={changeMinOrderQuantity}
								/>
								{formik.touched.minOrderQuantity &&
								formik.errors.minOrderQuantity ? (
									<span className="bottom-helper">
										<T keyName="selectProduct.errorMinOrderQuantity">
											{formik.errors.minOrderQuantity}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
						<div className="w-full">
							<SypacInput
								error={
									!!(
										formik.touched.maxOrderCapacity &&
										formik.errors.maxOrderCapacity
									)
								}
							>
								<SypacText variant="overline-normal-large" className="mb-1">
									<p className="-tracking-[0.01em] text-primary-dark-gray">
										<T keyName="selectProduct.maximumOrderCapacity">
											Maximum order capacity per day (tons)
										</T>
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									className="py-2.5 pl-3 border-[1px] transition-colors hover:border-gray-30 rounded-md placeholder:text-gray-22"
									type="number"
									name="maxOrderCapacity"
									placeholder={t(
										'selectProduct.enterMaximumPerTon',
										'Enter maximum per ton',
									)}
									value={formik?.values.maxOrderCapacity}
									onChange={changeMaxOrderCapacity}
								/>
								{formik.touched.maxOrderCapacity &&
								formik.errors.maxOrderCapacity ? (
									<span className="bottom-helper">
										<T keyName="selectProduct.errorMaxOrderCapacity">
											{formik.errors.maxOrderCapacity}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
						<div className="w-full">
							<SypacText variant="overline-normal-large" className="mb-1">
								<p className="-tracking-[0.01em] text-primary-dark-gray">
									<T keyName="selectProduct.netPricePerTon">
										Net price per ton (excluding VAT)
									</T>
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacInput
								error={
									!!(formik.touched.pricePerUnit && formik.errors.pricePerUnit)
								}
							>
								<div
									className={`flex border-solid border-[1px] border-gray-22 transition-colors hover:border-gray-30 rounded-md h-[40px] 
								${
									!!(formik.touched.pricePerUnit && formik.errors.pricePerUnit)
										? 'border-red-orange'
										: ''
								}`}
								>
									<input
										type="number"
										step=".01"
										name="pricePerUnit"
										className="block border-0 py-2.5 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										placeholder={t(
											'selectProduct.enterProductPricePerTon',
											'Enter product price per ton',
										)}
										value={formik?.values.pricePerUnit}
										onChange={(event) => formik?.handleChange(event)}
									/>
									<div className="py-2.5 px-5 text-gray-22 border-left content-center">
										<SypacText variant="overline-normal-large" className="flex">
											<p>PLN</p>
										</SypacText>
									</div>
								</div>
								{formik.touched.pricePerUnit && formik.errors.pricePerUnit ? (
									<span className="bottom-helper">
										<T keyName="selectProduct.errorPricePerUnit">
											{formik.errors.pricePerUnit}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>
				</div>
				<div className="flex gap-6.25 items-center justify-center mt-6.25">
					<SypacButton variant="secondary" size="small">
						<a
							href={backUrl}
							className="border-[1px] border- text-primary-violet rounded-md py-[3px] px-11.5 justify-center"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="selectProduct.back">Back</T>
								</p>
							</SypacText>
						</a>
					</SypacButton>
					<SypacButton variant="primary" size="small">
						<button
							type="submit"
							className="py-[4px] px-8 justify-center"
							disabled={disableSend}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="selectProduct.sendForApproval">
										Send for approval
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</div>
	);
};

export default SelectProduct;
