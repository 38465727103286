import {
	CompanyResponse,
	CompanyService,
} from '../../../services/company.services';
import React, { memo, useState } from 'react';
import dayjs from 'dayjs';
import { CompanyAllStats } from '../../../components/CompanyStats/CompanyStats.interface';
import CompanyWallet from '../../../components/CompanyWallet/CompanyWallet';
import { CompanyDetailsEdit } from '../../../components/CompanyWallet/CompanyWallet.interface';
import { useGetInvoicesByCompnayId } from '../../../hooks/use-get-invoices-by-company';
import { useGetInvoiceStatsByCompnayId } from '../../../hooks/use-get-stats';

interface Props {
	company: CompanyResponse;
	updateCompany: () => void;
}

export const WalletTAB: React.FC<Props> = memo(
	({ company, updateCompany }: Props) => {
		// TODO replace type with company ID when while implemented
		const [page, changePage] = useState(0);
		const [stats] = useGetInvoiceStatsByCompnayId(company.id!, {});
		const [stats30] = useGetInvoiceStatsByCompnayId(company.id!, {
			fromDate: dayjs().subtract(30, 'days').format(),
		});
		const [stats60] = useGetInvoiceStatsByCompnayId(company.id!, {
			fromDate: dayjs().subtract(60, 'days').format(),
		});
		const [stats90] = useGetInvoiceStatsByCompnayId(company.id!, {
			fromDate: dayjs().subtract(90, 'days').format(),
		});

		const [invoices] = useGetInvoicesByCompnayId(company.id!, {
			limit: 10,
			offset: page * 10,
		});
		const payload = {
			...stats,
			revenueLast30Days: stats30?.amountTotal?.all || '0',
			revenueLast60Days: stats60?.amountTotal?.all || '0',
			revenueLast90Days: stats90?.amountTotal?.all || '0',
			ordersLast30Days: stats30?.countOrders?.all || '0',
			ordersLast60Days: stats60?.countOrders?.all || '0',
			paymentTerm: company.paymentTerm,
			nextInvoicingDate: company.nextInvoicingDate,
			amount: stats?.amountTotal.pending,
		} as CompanyAllStats;

		const submitCompanyDetails = async (data: CompanyDetailsEdit) => {
			try {
				await CompanyService.updateCompany(company.id!, {
					bankAccountNumber: data.bankAccountNumber,
					swift: data.swift,
					bankName: data.bankName,
					iban: data.iban,
					paymentTerm: data.paymentTerm,
					creditLimit: data.creditLimit,
				});
				updateCompany();
			} catch (e) {}
		};

		return (
			<>
				<CompanyWallet
					invoicesData={invoices || { items: [], count: 0 }}
					submitCompanyDetails={submitCompanyDetails}
					company={company}
					stats={payload}
					page={page}
					changePage={changePage}
				/>
			</>
		);
	},
);
