import React, { useCallback, useEffect, useState } from 'react';
import { T } from '@tolgee/react';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { Calendar } from '../../../../../assets/Calendar';
import dayjs from 'dayjs';
import Flag from 'react-world-flags';
import { MapIcon } from '../../../../../assets/MapIcon';
import { Clock } from '../../../../../assets/Clock';
import classNames from 'classnames';
import DocumentsViewer from '../../../../../components/DocumentsViewer/DocumentsViewer';
import {
	convertToTimeZone,
	deliveryDates,
} from '../../../../../utils/time.util';
import {
	DocumentsDTO,
	FileService,
} from '../../../../../services/file.service';
import ModalMap from '../../../../../components/ModalMap/ModalMap';

interface ClientDeliveryDetailsProps {
	selectedOrder: OrderInterface | undefined;
	simpleOrder?: boolean;
}

const ClientDeliveryDetails: React.FC<ClientDeliveryDetailsProps> = (props) => {
	const { selectedOrder, simpleOrder } = props;
	const { deliveryIncluded } = selectedOrder!;
	const [openView, setShowView] = useState<boolean>(false);
	const [openMap, setOpenMap] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const [docs, setDocs] = useState<DocumentsDTO[]>();

	const getDocsCallback = useCallback(async () => {
		try {
			if (selectedOrder?.id && selectedOrder?.target) {
				const { data } = await FileService.getFiles(
					selectedOrder.id!,
					selectedOrder.target!,
				);
				setDocs(data as any);
			}
		} catch (e) {
			console.error(e);
		}
	}, [selectedOrder?.id, selectedOrder?.target]);

	useEffect(() => {
		getDocsCallback();
	}, [getDocsCallback]);

	const openFile = (url: string) => {
		setUrl(url);
		setShowView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const ImageCard = ({ imageUrl }: any) => (
		<div
			style={{ backgroundImage: `url(${imageUrl})` }}
			className={`relative flex flex-none w-[105px] bg-no-repeat bg-cover bg-center rounded-[12px] border border-solid border-gray-10 cursor-pointer ${
				showBlur === imageUrl ? 'hover:border-kimberly' : ''
			}`}
			onClick={() => openFile(imageUrl)}
			onMouseEnter={() => handleBoxToggle(imageUrl)}
			onMouseLeave={() => handleBoxToggle(undefined)}
		>
			{showBlur === imageUrl ? (
				<div
					className={classNames(
						'bg-black-50 absolute rounded-[11px] backdrop-blur-sm h-full w-full flex justify-center items-center',
						{},
					)}
				>
					<SypacIcon
						size="custom"
						width="32px"
						height="32px"
						icon-name="Magnifer"
						className="text-white"
					/>
				</div>
			) : null}
		</div>
	);

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 py-4">
					<p className="text-base text-gray-60">
						{deliveryIncluded ? (
							<T keyName="clientDeliveryDetails.delivery">Delivery</T>
						) : (
							<T keyName="clientDeliveryDetails.pickUp">Pick-up</T>
						)}
					</p>
				</SypacText>

				<div className="flex flex-col gap-4">
					<div className="grid grid-cols-[250px,auto] items-start px-5 mb-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								{deliveryIncluded ? (
									<T keyName="baseOrder.deliveryAddress">Delivery address:</T>
								) : (
									<T keyName="clientDeliveryDetails.loadingAddress">
										Loading address:
									</T>
								)}
							</p>
						</SypacText>
						<div className="flex flex-col gap-3">
							<div className="flex items-center gap-2">
								<span className="-ml-[2px] mt-[2px]">
									<Flag
										className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
										code={
											deliveryIncluded
												? selectedOrder?.deliveryCountryCode
												: selectedOrder?.pickupCountryCode
										}
										width={24}
										height={16}
									/>
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 w-[296px] truncate">
										{deliveryIncluded
											? selectedOrder?.deliveryAddress
											: selectedOrder?.pickupAddress}
									</p>
								</SypacText>
							</div>
							<div className="flex items-center gap-2">
								<span className="-ml-[2px] mr-[2px] scale-[1.2]">
									<MapIcon />
								</span>
								<SypacButton variant="secondary" size="small">
									<button
										className="w-full border-none bg-transparent p-0 group"
										onClick={() => setOpenMap(!openMap)}
										type="button"
									>
										<SypacText variant="body-normal-medium">
											<p className="text-cornflower-blue transition group-hover:text-gray-80">
												<T keyName="orderDetails.viewLocationOnMap">
													View location on the map
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-[250px,auto] items-start px-5 mb-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								{deliveryIncluded ? (
									<T keyName="clientDeliveryDetails.deliveryDate">
										Scheduled delivery date:
									</T>
								) : (
									<T keyName="clientDeliveryDetails.scheduledPickUpDate">
										Scheduled pick-up date:
									</T>
								)}
							</p>
						</SypacText>
						<div className="flex flex-col gap-3">
							<div className="flex gap-3 [&_p]:text-gray-80">
								<span className="scale-[1.1]">
									<Calendar />
								</span>
								{deliveryIncluded
									? deliveryDates(selectedOrder?.trucks!, true, 'deliveryTo')
									: deliveryDates(selectedOrder?.trucks!, true, 'deliveryFrom')}
							</div>
							<div className="flex gap-3 [&_p]:text-gray-80">
								<span className="scale-[1.1]">
									<Clock />
								</span>
								<div className="flex gap-5 items-center">
									{selectedOrder?.trucks ? (
										convertToTimeZone(
											selectedOrder.trucks[0].deliveryTimeFrom,
										) === '0:00' &&
										convertToTimeZone(
											selectedOrder.trucks[0].deliveryTimeTo,
										) === '23:59' ? (
											<SypacText variant="body-regular-medium">
												<p>Full Time</p>
											</SypacText>
										) : (
											<>
												<SypacText variant="body-regular-medium">
													<p>
														{convertToTimeZone(
															selectedOrder.trucks[0].deliveryTimeFrom,
														)}
													</p>
												</SypacText>
												<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
												<SypacText variant="body-regular-medium">
													<p>
														{convertToTimeZone(
															selectedOrder.trucks[0].deliveryTimeTo,
														)}
													</p>
												</SypacText>
											</>
										)
									) : null}
								</div>
							</div>
						</div>
					</div>

					{deliveryIncluded && simpleOrder ? (
						selectedOrder?.status === 'paid' ||
						selectedOrder?.status === 'delivered' ? (
							<>
								{selectedOrder?.notifications?.items?.find(
									(obj) =>
										obj.category ===
										CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
								) ? (
									<div className="grid grid-cols-[250px,auto] items-start px-5">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-40">
												<T keyName="deliverySchedule.truckArrivalConfirmation">
													Truck arrival confirmation:
												</T>
											</p>
										</SypacText>

										<div className="flex flex-col gap-3">
											<div className="flex gap-6 [&_p]:text-gray-80">
												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Calendar />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{dayjs(
																selectedOrder?.notifications?.items?.find(
																	(obj) =>
																		obj.category ===
																		CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
																)?.createdAt,
															).format('DD MMM. YYYY')}
														</p>
													</SypacText>
												</div>

												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Clock />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{convertToTimeZone(
																selectedOrder?.notifications?.items?.find(
																	(obj) =>
																		obj.category ===
																		CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS,
																)?.createdAt!,
															)}
														</p>
													</SypacText>
												</div>
											</div>
										</div>
									</div>
								) : null}

								{selectedOrder?.notifications?.items?.find(
									(obj) =>
										obj.category === CommonNotificationsEnums.ORDER_COMPLETE,
								) ? (
									<div className="grid grid-cols-[250px,auto] items-start px-5">
										<SypacText variant="body-regular-medium">
											{deliveryIncluded ? (
												<p className="text-gray-40">
													<T keyName="clientDeliveryDetails.deliveryConfirmation">
														Delivery confirmation:
													</T>
												</p>
											) : (
												<p className="text-gray-40">
													<T keyName="clientDeliveryDetails.loadingConfirmation">
														Loading confirmation:
													</T>
												</p>
											)}
										</SypacText>

										<div className="flex flex-col gap-3">
											<div className="flex gap-6 [&_p]:text-gray-80">
												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Calendar />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{dayjs(
																selectedOrder?.notifications?.items?.find(
																	(obj) =>
																		obj.category ===
																		CommonNotificationsEnums.ORDER_COMPLETE,
																)?.createdAt,
															).format('DD MMM. YYYY')}
														</p>
													</SypacText>
												</div>

												<div className="flex gap-3">
													<span className="scale-[1.1]">
														<Clock />
													</span>
													<SypacText variant="body-regular-medium">
														<p>
															{convertToTimeZone(
																selectedOrder?.notifications?.items?.find(
																	(obj) =>
																		obj.category ===
																		CommonNotificationsEnums.ORDER_COMPLETE,
																)?.createdAt!,
															)}
														</p>
													</SypacText>
												</div>
											</div>
										</div>
									</div>
								) : null}
							</>
						) : null
					) : (
						<>
							{(selectedOrder?.status === 'paid' ||
								selectedOrder?.status === 'delivered') &&
							deliveryIncluded ? (
								<div className="flex flex-col gap-2">
									{selectedOrder?.trucks &&
										selectedOrder.trucks.map((orderTruck, index) => (
											<div key={orderTruck?.id}>
												<div className="border-0 border-solid border-t-[1px] border-gray-10" />

												<div className="flex flex-col gap-5 px-5">
													<div className="flex gap-4 mt-5">
														<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
															<SypacText variant="body-regular-medium">
																<p className="text-sm text-gray-90">
																	<T keyName="requestedStep.payloadTruck">
																		Truck
																	</T>{' '}
																	{index + 1}
																</p>
															</SypacText>
														</div>
														<SypacText variant="body-regular-medium">
															<p className="text-gray-60">
																{orderTruck?.driver?.name}
															</p>
														</SypacText>
													</div>

													<div className="flex flex-col gap-3">
														{selectedOrder?.notifications?.items?.find(
															(notify) =>
																notify.category ===
																	CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS &&
																notify.content?.driverId ===
																	orderTruck.driver.uid,
														) ? (
															<div className="grid grid-cols-[250px,auto] items-start">
																<SypacText variant="body-regular-medium">
																	<p className="text-gray-40">
																		<T keyName="deliverySchedule.truckArrivalConfirmation">
																			Truck arrival confirmation:
																		</T>
																	</p>
																</SypacText>

																<div className="flex flex-col gap-3">
																	<div className="flex gap-6 [&_p]:text-gray-80">
																		<div className="flex gap-3">
																			<span className="scale-[1.1]">
																				<Calendar />
																			</span>
																			<SypacText variant="body-regular-medium">
																				<p>
																					{dayjs(
																						selectedOrder?.notifications?.items?.find(
																							(notify) =>
																								notify.category ===
																									CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS &&
																								notify.content?.driverId ===
																									orderTruck.driver.uid,
																						)?.createdAt,
																					).format('DD MMM. YYYY')}
																				</p>
																			</SypacText>
																		</div>

																		<div className="flex gap-3">
																			<span className="scale-[1.1]">
																				<Clock />
																			</span>
																			<SypacText variant="body-regular-medium">
																				<p>
																					{convertToTimeZone(
																						selectedOrder?.notifications?.items?.find(
																							(notify) =>
																								notify.category ===
																									CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS &&
																								notify.content?.driverId ===
																									orderTruck.driver.uid,
																						)?.createdAt!,
																					)}
																				</p>
																			</SypacText>
																		</div>
																	</div>
																</div>
															</div>
														) : null}

														{selectedOrder?.notifications?.items?.find(
															(notify) =>
																notify.category ===
																	CommonNotificationsEnums.ORDER_COMPLETE &&
																notify.content?.driverId ===
																	orderTruck.driver.uid,
														) ? (
															<div className="grid grid-cols-[250px,auto] items-start">
																<SypacText variant="body-regular-medium">
																	<p className="text-gray-40">
																		<T keyName="clientDeliveryDetails.deliveryConfirmation">
																			Delivery confirmation:
																		</T>
																	</p>
																</SypacText>

																<div className="flex flex-col gap-3">
																	<div className="flex gap-6 [&_p]:text-gray-80">
																		<div className="flex gap-3">
																			<span className="scale-[1.1]">
																				<Calendar />
																			</span>
																			<SypacText variant="body-regular-medium">
																				<p>
																					{dayjs(
																						selectedOrder?.notifications?.items?.find(
																							(notify) =>
																								notify.category ===
																									CommonNotificationsEnums.ORDER_COMPLETE &&
																								notify.content?.driverId ===
																									orderTruck.driver.uid,
																						)?.createdAt,
																					).format('DD MMM. YYYY')}
																				</p>
																			</SypacText>
																		</div>

																		<div className="flex gap-3">
																			<span className="scale-[1.1]">
																				<Clock />
																			</span>
																			<SypacText variant="body-regular-medium">
																				<p>
																					{convertToTimeZone(
																						selectedOrder?.notifications?.items?.find(
																							(notify) =>
																								notify.category ===
																									CommonNotificationsEnums.ORDER_COMPLETE &&
																								notify.content?.driverId ===
																									orderTruck.driver.uid,
																						)?.createdAt!,
																					)}
																				</p>
																			</SypacText>
																		</div>
																	</div>
																</div>
															</div>
														) : null}
													</div>

													<>
														<div className="border-0 border-solid border-t-[1px] border-gray-10" />
														<div className="flex flex-col gap-5">
															<SypacText variant="body-regular-medium">
																<p className="text-gray-60">
																	<T keyName="documentsTab.proofOfDelivery">
																		Proof of delivery
																	</T>
																</p>
															</SypacText>

															<div className="flex gap-3 h-[105px] mb-4">
																{docs?.[index].loadDetails?.documents?.map(
																	(url: string, index: number) => (
																		<ImageCard key={index} imageUrl={url} />
																	),
																)}
																{docs?.[index].unloadDetails?.documents?.map(
																	(url: string, index: number) => (
																		<ImageCard key={index} imageUrl={url} />
																	),
																)}
															</div>

															{/* <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-2">
																{localFiles.map((file, index) => (
																	<div
																		className="flex flex-row"
																		key={file?.name || Math.random() + index}
																	>
																		<div className="w-[66px] h-[66px] flex justify-center items-center border border-solid border-gray-10 rounded-lg">
																			<PdfIcon />
																		</div>
																		<div className="flex flex-col ml-4">
																			<SypacText variant="body-regular-medium">
																				<p className="text-gray-80">
																					{file.name}
																				</p>
																			</SypacText>
																			<div className="flex flex-row justify-start items-center mt-1">
																				<SypacText variant="overline-regular-large">
																					<p className="text-gray-20">
																						{file.mimeType}{' '}
																					</p>
																				</SypacText>
																				<div className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
																				<SypacText variant="overline-regular-large">
																					<p className="text-gray-20">
																						{file.size}{' '}
																					</p>
																				</SypacText>
																			</div>
																			<SypacText
																				variant="overline-normal-large"
																				className="mt-2 cursor-pointer"
																				// onClick={() => handleRemoveFile(index)}
																			>
																				<p className="text-red">
																					<T keyName="uploadDocuments.deleteFile">
																						Delete file
																					</T>
																				</p>
																			</SypacText>
																		</div>
																	</div>
																))}
															</div> */}
														</div>
													</>
												</div>
											</div>
										))}
								</div>
							) : null}
						</>
					)}

					{!deliveryIncluded && !simpleOrder ? (
						<div className="flex flex-col gap-2">
							{docs
								?.filter(
									(orderTruck) =>
										orderTruck?.producerLoadDetails &&
										Object.keys(orderTruck.producerLoadDetails).length > 0,
								)
								.map((orderTruck) => {
									const originalIndex = docs.findIndex(
										(doc) => doc.id === orderTruck.id,
									);

									return (
										<div key={orderTruck?.id}>
											<div className="border-0 border-solid border-t-[1px] border-gray-10" />

											<div className="flex flex-col gap-5 px-5">
												<div className="flex gap-4 mt-5">
													<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
														<SypacText variant="body-regular-medium">
															<p className="text-sm text-gray-90">
																<T keyName="requestedStep.payloadTruck">
																	Truck
																</T>{' '}
																{originalIndex + 1}
															</p>
														</SypacText>
													</div>
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															{orderTruck?.driver?.licensePlates}
														</p>
													</SypacText>
												</div>

												<div className="flex flex-col gap-5">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-60">
															<T keyName="documentsTab.proofOfDelivery">
																Proof of delivery
															</T>
														</p>
													</SypacText>

													<div className="flex gap-3 h-[105px] mb-4">
														{orderTruck.producerLoadDetails?.documents?.map(
															(url: string, index: number) => (
																<ImageCard key={index} imageUrl={url} />
															),
														)}
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					) : null}

					{simpleOrder &&
					deliveryIncluded &&
					docs?.some((item) => item.loadDetails !== undefined) &&
					docs?.some((item) => item.unloadDetails !== undefined) ? (
						<>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							<div className="flex flex-col gap-5 px-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-60">
										<T keyName="documentsTab.proofOfDelivery">
											Proof of delivery
										</T>
									</p>
								</SypacText>

								<div className="flex gap-3 h-[105px] mb-4">
									{docs[0].loadDetails?.documents?.map(
										(url: string, index: number) => (
											<ImageCard key={index} imageUrl={url} />
										),
									)}
									{docs[0].unloadDetails?.documents?.map(
										(url: string, index: number) => (
											<ImageCard key={index} imageUrl={url} />
										),
									)}
								</div>

								{/* <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-2">
									{localFiles.map((file, index) => (
										<div
											className="flex flex-row"
											key={file?.name || Math.random() + index}
										>
											<div className="w-[66px] h-[66px] flex justify-center items-center border border-solid border-gray-10 rounded-lg">
												<PdfIcon />
											</div>
											<div className="flex flex-col ml-4">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">{file.name}</p>
												</SypacText>
												<div className="flex flex-row justify-start items-center mt-1">
													<SypacText variant="overline-regular-large">
														<p className="text-gray-20">{file.mimeType} </p>
													</SypacText>
													<div className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
													<SypacText variant="overline-regular-large">
														<p className="text-gray-20">{file.size} </p>
													</SypacText>
												</div>
												<SypacText
													variant="overline-normal-large"
													className="mt-2 cursor-pointer"
													// onClick={() => handleRemoveFile(index)}
												>
													<p className="text-red">
														<T keyName="uploadDocuments.deleteFile">
															Delete file
														</T>
													</p>
												</SypacText>
											</div>
										</div>
									))}
								</div> */}
							</div>
						</>
					) : null}

					{simpleOrder &&
					!deliveryIncluded &&
					docs?.some((item) => item.producerLoadDetails !== undefined) ? (
						<>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							<div className="flex flex-col gap-5 px-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-60">
										<T keyName="documentsTab.proofOfLoading">
											Proof of loading
										</T>
									</p>
								</SypacText>

								<div className="flex gap-3 h-[105px] mb-4">
									{docs[0].producerLoadDetails?.documents?.map(
										(url: string, index: number) => (
											<ImageCard key={index} imageUrl={url} />
										),
									)}
								</div>
							</div>
						</>
					) : null}
				</div>
			</div>

			{openView ? (
				<DocumentsViewer
					url={url!}
					isPdf={false}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}

			{openMap ? (
				<ModalMap
					isOpen={openMap}
					onClose={() => setOpenMap(false)}
					markers={[
						{
							latitude: !deliveryIncluded
								? selectedOrder?.pickupLocation?.coordinates?.at(1)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(1)!,
							longitude: !deliveryIncluded
								? selectedOrder?.pickupLocation?.coordinates?.at(0)!
								: selectedOrder?.deliveryLocation?.coordinates?.at(0)!,
							variant: !deliveryIncluded ? 'pickup' : 'delivery',
							...(!deliveryIncluded
								? {
										pickup: {
											pickupAddress: selectedOrder?.pickupAddress!,
											pickupCountryCode: selectedOrder?.pickupCountryCode!,
										},
								  }
								: {
										delivery: {
											deliveryAddress: selectedOrder?.deliveryAddress!,
											deliveryCountryCode: selectedOrder?.deliveryCountryCode!,
										},
								  }),
						},
					]}
				/>
			) : null}
		</>
	);
};

export default ClientDeliveryDetails;
