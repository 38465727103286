export const ViewStore = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="42"
			height="42"
			fill="none"
			viewBox="0 0 42 42"
		>
			<rect width="42" height="42" fill="#494C83" rx="8"></rect>
			<path
				stroke="#fff"
				strokeWidth="1.3"
				d="M12.275 24.296C11.425 23.192 11 22.639 11 21c0-1.64.425-2.192 1.275-3.296C13.972 15.5 16.818 13 21 13c4.182 0 7.028 2.5 8.725 4.704C30.575 18.808 31 19.361 31 21c0 1.64-.425 2.192-1.275 3.296C28.028 26.5 25.182 29 21 29c-4.182 0-7.028-2.5-8.725-4.704z"
			></path>
			<path
				stroke="#fff"
				strokeWidth="1.3"
				d="M24 21a3 3 0 11-6 0 3 3 0 016 0z"
			></path>
		</svg>
	);
};
