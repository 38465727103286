const PendingInvoice = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="23"
			fill="none"
			viewBox="0 0 22 23"
		>
			<rect width="22" height="22" y="0.336" fill="#FFB352" rx="11"></rect>
			<circle cx="11" cy="11.336" r="6" fill="#fff" stroke="#454545"></circle>
			<path
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M11 8.936v2.4l1.5 1.5"
			></path>
		</svg>
	);
};

export default PendingInvoice;
