import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import { UsersList } from '../interfaces/Users.interface';

interface Props {
	companyId: number | 'self';
	refresh?: number;
}

type UseGetCompanyUsers = [UsersList | undefined, Error | undefined, boolean];

export const useGetCompanyUsers = ({
	companyId,
	refresh = 0,
}: Props): UseGetCompanyUsers => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/admin/users`, {
				params: {
					companyId,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, refresh, companyId]);

	return [data, error, isLoading];
};
