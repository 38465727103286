const OverdueBadge = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<rect width="40" height="40" fill="#F44A77" rx="8"></rect>
			<path
				fill="#fff"
				d="M14.164 18.9C16.71 14.3 17.984 12 20 12c2.016 0 3.29 2.3 5.837 6.9l.317.572c2.117 3.822 3.175 5.733 2.218 7.13C27.417 28 25.051 28 20.318 28h-.635c-4.732 0-7.099 0-8.055-1.397-.956-1.398.102-3.309 2.218-7.13l.318-.574z"
			></path>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				d="M20.012 16.447v4.445"
			></path>
			<mask id="path-4-inside-1_5515_209330" fill="#fff">
				<ellipse cx="19.998" cy="23.545" rx="0.873" ry="0.889"></ellipse>
			</mask>
			<ellipse
				cx="19.998"
				cy="23.545"
				fill="#F44A77"
				rx="0.873"
				ry="0.889"
			></ellipse>
			<path
				fill="#F44A77"
				d="M19.87 23.545c0-.044.04-.111.128-.111v2a1.88 1.88 0 001.872-1.889h-2zm.128-.111c.087 0 .127.067.127.111h-2a1.88 1.88 0 001.873 1.889v-2zm.127.111c0 .044-.04.111-.127.111v-2a1.88 1.88 0 00-1.873 1.89h2zm-.127.111c-.088 0-.128-.067-.128-.11h2a1.881 1.881 0 00-1.872-1.89v2z"
				mask="url(#path-4-inside-1_5515_209330)"
			></path>
		</svg>
	);
};

export default OverdueBadge;
