import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	SypacBox,
	SypacCheckbox,
	SypacText,
} from '@sypac/component-library-react';
import { NumericFormat } from 'react-number-format';
import { ClientOrderSummaryProps } from './ClientOrderSummary.interface';
import { OrderContext } from '../../context/OrderContext/order.context';
import ModalSelfPickup from './components/ModalSelfPickup';
import {
	CompanyResponse,
	CompanyService,
} from '../../services/company.services';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import { T, useTranslate } from '@tolgee/react';

const ClientOrderSummary = (props: ClientOrderSummaryProps) => {
	const { t } = useTranslate();
	const { currentProduct, prices, quantity, step } = props;
	const { order, setOrder } = useContext(OrderContext);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [dontShowAnymore, setDontShowAnymore] = useState<boolean>(false);
	const [isDeliveryIncluded, setIsDeliveryIncluded] = useState<boolean>(
		order?.deliveryIncluded ?? true,
	);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const ProductDetail = ({ label, value, isNumeric = false }: any) => (
		<div className="flex justify-between mb-3 [&_p]:text-gray-40">
			<SypacText variant="body-normal-medium">
				<p>{label}</p>
			</SypacText>
			<SypacText variant="body-normal-medium">
				{isNumeric ? (
					<p>
						<FormattedNumber value={value} />
					</p>
				) : (
					<p>{value}</p>
				)}
			</SypacText>
		</div>
	);

	const FormattedNumber = ({ value }: any) => (
		<NumericFormat
			type="text"
			displayType="text"
			thousandSeparator=" "
			decimalSeparator="."
			value={parseFloat(value || 0).toFixed(2)}
			suffix={' zł'}
		/>
	);

	const handleCheck = () => {
		setOrder({
			...order,
			deliveryIncluded: !isDeliveryIncluded,
		});
		setIsDeliveryIncluded(!isDeliveryIncluded);
		setIsModalOpen(false);
	};

	const handleDontShowAnymore = () => {
		setDontShowAnymore(true);
		handleCheck();
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<SypacBox
				className="w-full md:flex-row lg:flex-col py-6 bg-white"
				boxShadow="sm"
			>
				<div className="min-w-[240px] px-[14px] sm:px-6">
					<SypacText variant="body-normal-medium" className="mb-6">
						<p>
							<T keyName="clientOrderSummary.orderSummary">Order summary</T>
						</p>
					</SypacText>
					<ProductDetail
						label={t('clientOrderSummary.productName', 'Product name')}
						value={currentProduct?.name}
					/>

					{currentProduct?.type ? (
						<ProductDetail
							label={t('clientOrderSummary.productType', 'Product type')}
							value={`${
								currentProduct?.type ? `${currentProduct?.type}` : ''
							} ${currentProduct?.size ? `(${currentProduct?.size})` : ''}`}
						/>
					) : null}
					{!currentProduct?.type && currentProduct?.size ? (
						<ProductDetail
							label={t('clientOrderSummary.productSize', 'Product size')}
							value={`(${currentProduct?.size})`}
						/>
					) : null}

					<ProductDetail
						label={t(
							'clientOrderSummary.pricePerTonExcl',
							'Price per ton (VAT excl.)',
						)}
						value={currentProduct?.pricePerUnitWithProcent}
						isNumeric
					/>
					<ProductDetail
						label={t('clientOrderSummary.quantity', 'Quantity')}
						value={`${quantity} ${currentProduct?.unit}`}
					/>
					{/* <ProductDetail
						label={t(
							'clientOrderSummary.productVatExcl',
							'Product (VAT excl.)',
						)}
						value={prices.productPriceForClient}
						isNumeric
					/> */}
					<ProductDetail
						label={
							<T
								keyName="clientOrderSummary.productVatIncl"
								params={{
									vat: `${currentProduct?.vat && currentProduct.vat}`,
								}}
							>
								Product (VAT incl.)
							</T>
						}
						value={prices.productPriceForClientWithVat}
						isNumeric
					/>
				</div>

				{isDeliveryIncluded ? (
					<>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 mb-6" />
						<div className="min-w-[240px] px-[14px] sm:px-6">
							<SypacText variant="body-normal-medium" className="mb-6">
								<p>
									<T keyName="clientOrderSummary.deliveryCosts">
										Delivery costs
									</T>
								</p>
							</SypacText>
							{/* <ProductDetail
								label={t(
									'clientOrderSummary.transportationVatExcl',
									'Transportation (VAT excl.)',
								)}
								value={prices.transportPriceForClient}
								isNumeric
							/> */}
							<ProductDetail
								label={t(
									'clientOrderSummary.transportationVatIncl',
									'Transportation (VAT incl.)',
								)}
								value={prices.transportPriceForClientWithVat}
								isNumeric
							/>
						</div>
					</>
				) : null}

				<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

				<div className="md:ml-8 lg:ml-0 min-w-[240px] px-[14px] sm:px-6">
					<div className="flex justify-between mt-6 mb-4 md:mt-0 lg:mt-6">
						<SypacText
							variant="body-normal-medium"
							className="mb-0 md:mb-3 lg:mb-0"
						>
							<p>
								<T keyName="clientOrderSummary.total">Total</T>
							</p>
						</SypacText>
					</div>
					<div className="flex justify-between mt-6 mb-4 md:mt-0 lg:mt-6">
						<SypacText
							variant="body-normal-medium"
							className="mb-0 md:mb-3 lg:mb-0"
						>
							<p>
								<T keyName="clientOrderSummary.totalPriceExcl">
									Total price (VAT excl.)
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-normal-medium">
							<p>
								<FormattedNumber
									value={
										isDeliveryIncluded
											? parseFloat(
													String(
														prices.productPriceForClient +
															prices.transportPriceForClient || 0,
													),
											  )
											: parseFloat(String(prices.productPriceForClient))
									}
								/>
							</p>
						</SypacText>
					</div>
					<div className="flex justify-between my-2 md:mt-0 lg:mt-2">
						<SypacText
							variant="body-normal-medium"
							className="mb-0 md:mb-3 lg:mb-0"
						>
							<p>
								<T keyName="clientOrderSummary.totalPriceIncl">
									Total price (VAT incl.)
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-normal-medium">
							<p>
								<FormattedNumber
									value={
										isDeliveryIncluded
											? parseFloat(
													String(
														prices.productPriceForClientWithVat +
															prices.transportPriceForClientWithVat || 0,
													),
											  )
											: parseFloat(String(prices.productPriceForClientWithVat))
									}
								/>
							</p>
						</SypacText>
					</div>
				</div>

				{step === 1 && myCompany?.alowExcludedTransporter ? (
					<>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
						<div className="md:ml-8 lg:ml-0 min-w-[240px] px-[14px] sm:px-6 mt-6">
							<div className="col-start-2 flex items-center gap-4">
								<SypacCheckbox size="md">
									<input
										type="checkbox"
										name="unlimited"
										checked={isDeliveryIncluded}
										onChange={
											dontShowAnymore
												? handleCheck
												: isDeliveryIncluded
												? openModal
												: handleCheck
										}
										className="cursor-pointer"
									/>
								</SypacCheckbox>
								<SypacText variant="overline-normal-large">
									<p className="text-base">
										{isDeliveryIncluded ? (
											<T keyName="clientOrderSummary.deliveryIncluded">
												Delivery included
											</T>
										) : (
											<T keyName="clientOrderSummary.deliveryExcluded">
												Delivery excluded
											</T>
										)}
									</p>
								</SypacText>
							</div>
						</div>
					</>
				) : null}
			</SypacBox>
			<ModalSelfPickup
				isOpen={isModalOpen}
				onClose={closeModal}
				onSave={handleCheck}
				dontShowAnymore={handleDontShowAnymore}
				isChecked={dontShowAnymore}
			/>
		</>
	);
};

export default ClientOrderSummary;
