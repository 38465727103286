export const NoResults = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="64"
			height="64"
			fill="none"
			viewBox="0 0 64 64"
		>
			<path
				stroke="#454545"
				strokeWidth="3"
				d="M11 20c0-8.485 0-12.728 2.636-15.364C16.272 2 20.515 2 29 2h6c8.485 0 12.728 0 15.364 2.636C53 7.272 53 11.515 53 20v24c0 8.485 0 12.728-2.636 15.364C47.728 62 43.485 62 35 62h-6c-8.485 0-12.728 0-15.364-2.636C11 56.728 11 52.485 11 44V20z"
			></path>
			<path
				stroke="#454545"
				strokeLinecap="round"
				strokeWidth="3"
				d="M23 35h18M23 23h18M23 47h9M2 53V11M62 53V11"
			></path>
		</svg>
	);
};
