import { SypacBox } from '@sypac/component-library-react';
import React, { useState } from 'react';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import { Counter, QuantityPickerProps } from './QuantityPicker.interface';

const QuantityPicker = (props: QuantityPickerProps) => {
	const { counters, availableQuantity, unlimited } = props;

	const [activeTab] = useState('truck-loading');

	const renderCounter = (counter: Counter) => {
		return (
			<QuantityCounter
				key={counter.id}
				id={counter.id}
				car={counter.car}
				name={counter.name}
				payload={counter.payload}
				availableQuantity={availableQuantity}
				unlimited={unlimited}
			/>
		);
	};

	return (
		<SypacBox boxStyle="none" hover={false} className="h-fit">
			<div className="">
				{/*<SypacTabs className="mb-6.25 bg-[#F3F5F8]">
					<SypacTabButton
						variant="white"
						label={t('quantityPicker.fullTruckLoading', 'Full truck loading')}
						button-id="truck-loading"
						onClick={() => setActiveTab('truck-loading')}
					/>
					<SypacTabButton
						variant="white"
						label={t('quantityPicker.customQuantity', 'Custom quantity')}
						button-id="custom-quantity"
						onClick={() => setActiveTab('custom-quantity')}
					/>
				</SypacTabs>*/}

				{
					activeTab === 'truck-loading' ? (
						<div className="flex flex-col gap-6.25">
							{counters.map((counter) => renderCounter(counter))}
						</div>
					) : null
					// (
					// 	<div>
					// 		<SypacInput className="mb-[23px]">
					// 			<SypacText variant="overline-normal-large" className="mb-1">
					// 				<p className="-tracking-[0.01em]">
					// 					<T keyName="quantityPicker.quantity">Quantity</T>
					// 				</p>
					// 			</SypacText>
					// 			<div className="flex">
					// 				<input
					// 					type="number"
					// 					className="block py-2.5 px-3 border-[1px] border-solid border-gray-22 rounded-l-md placeholder:text-gray-22 outline-none flex-1"
					// 					placeholder={t(
					// 						'quantityPicker.enterQuantity',
					// 						'Enter quantity',
					// 					)}
					// 					value={inputQuantity ? inputQuantity : ''}
					// 					onChange={(event) => {
					// 						setTotalQuantityCounter([]);
					// 						changeInputQuantity(Number(event.target.value));
					// 					}}
					// 				/>
					// 				<div className="py-2.5 px-5 border border-solid border-l-transparent border-gray-22 rounded-r-md text-gray-22">
					// 					<span>
					// 						<T keyName="quantityPicker.tons">Tons</T>
					// 					</span>
					// 				</div>
					// 			</div>
					// 		</SypacInput>
					// 		<SypacText
					// 			variant="overline-normal-medium"
					// 			className="max-w-[485px] w-full"
					// 		>
					// 			<p className="text-gray-40">
					// 				<T keyName="quantityPicker.description">
					// 					Inputed custom parameters related to quantity will be
					// 					automatically calculated and distributed accordingly to the
					// 					number of trucks needed for the delivery.
					// 				</T>
					// 			</p>
					// 		</SypacText>
					// 	</div>
					// )
				}
			</div>
		</SypacBox>
	);
};

export default QuantityPicker;
