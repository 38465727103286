import React from 'react';
import ThankYou from '../../ThankYou';

const ClientThankYou = () => {
	return (
		<section className="client-select-product">
			<ThankYou client={true} />
		</section>
	);
};

export default ClientThankYou;
