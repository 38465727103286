export const IncreaseIcon = ({ isActive }: any) => {
	return (
		<svg
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 0C4.44183 0 4.8 0.358172 4.8 0.8V3.20003H7.2C7.64183 3.20003 8 3.5582 8 4.00003C8 4.44185 7.64183 4.80003 7.2 4.80003H4.8L4.8 7.2C4.8 7.64183 4.44183 8 4 8C3.55817 8 3.2 7.64183 3.2 7.2V4.80003H0.8C0.358172 4.80003 0 4.44185 0 4.00003C0 3.5582 0.358172 3.20003 0.8 3.20003H3.2L3.2 0.8C3.2 0.358172 3.55817 0 4 0Z"
				fill={isActive ? 'white' : '#a2a2a2'}
			/>
		</svg>
	);
};
