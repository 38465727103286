export const Radio = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="26"
			fill="none"
			viewBox="0 0 26 26"
		>
			<g filter="url(#filter0_d_6645_242281)">
				<rect width="20" height="20" x="3" y="2" fill="#fff" rx="10"></rect>
				<rect
					width="18.75"
					height="18.75"
					x="3.625"
					y="2.625"
					stroke="#D1D1D1"
					strokeWidth="1.25"
					rx="9.375"
				></rect>
			</g>
			<defs>
				<filter
					id="filter0_d_6645_242281"
					width="25"
					height="25"
					x="0.5"
					y="0.75"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="1.25"></feOffset>
					<feGaussianBlur stdDeviation="1.25"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_6645_242281"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_6645_242281"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	);
};
