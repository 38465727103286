export const useGetRating = () => {
	const number = Number(Math.random() * (5 - 1));
	const label = number.toFixed(2);
	let color;
	if (number >= 4) {
		color = 'green';
	}
	if (number >= 2 && number <= 3.99) {
		color = 'yellow';
	}
	if (number <= 1.99) {
		color = 'red';
	}
	return { color, label };
};

export default useGetRating;
