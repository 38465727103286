import mem from 'mem';
import axios from 'axios';

export const refreshTokenFn = async () => {
	const refreshToken = localStorage.getItem('refresh_token');
	try {
		const response = await axios.create().post(`/v1/auth/refresh-token`, {
			token: refreshToken,
		});

		if (!response.data?.accessToken) {
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('has_overdue_payments');
		}

		localStorage.setItem('access_token', response.data.accessToken);
		localStorage.setItem('refresh_token', response.data.refreshToken);
		localStorage.setItem(
			'has_overdue_payments',
			response.data.hasOverduePayments,
		);

		return response.data;
	} catch (error) {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('has_overdue_payments');
	}
};

const maxAge = 300;

export const memorizedRefreshToken = mem(refreshTokenFn, {
	maxAge,
});
