export const Viber = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="20" height="20" rx="4" fill="#7A4D9A" />
			<circle cx="10" cy="10" r="8" fill="white" />
			<path
				d="M10.01 0C15.53 0 20 4.48 20 10.01C20 15.54 15.52 20.01 9.99002 20C4.46002 20 -0.00998326 15.51 1.67458e-05 9.98C0.0100167 4.47 4.49002 0 10.01 0ZM7.02002 16.59C7.03002 16.6 7.04002 16.6 7.05002 16.61C7.09002 16.57 7.12002 16.52 7.16002 16.48C7.76002 15.84 8.37002 15.21 8.97002 14.57C9.05002 14.48 9.13002 14.44 9.25002 14.45C9.82002 14.47 10.38 14.47 10.95 14.43C11.71 14.38 12.46 14.26 13.19 14.03C14.28 13.69 15 12.98 15.33 11.88C15.56 11.11 15.66 10.32 15.68 9.53C15.7 8.68 15.6 7.84 15.43 7C15.29 6.32 15 5.72 14.48 5.24C14.03 4.83 13.5 4.58 12.92 4.43C11.47 4.04 10.01 3.92 8.52002 4.1C7.78002 4.18 7.06002 4.37 6.38002 4.72C5.41002 5.22 4.77002 5.99 4.55002 7.08C4.35002 8.06 4.28002 9.04 4.33002 10.04C4.36002 10.59 4.43002 11.13 4.58002 11.66C4.81002 12.5 5.25002 13.19 6.00002 13.66C6.26002 13.82 6.55002 13.93 6.83002 14.06C6.98002 14.13 7.03002 14.22 7.03002 14.38C7.02002 15.08 7.03002 15.79 7.03002 16.49C7.02002 16.51 7.02002 16.55 7.02002 16.59Z"
				fill="#7A4D9A"
			/>
			<path
				d="M6.23047 7.30934C6.23047 6.67934 6.76047 6.05934 7.35047 6.01934C7.57047 5.99934 7.76047 6.05934 7.92047 6.21934C8.32047 6.60934 8.65047 7.04934 8.89047 7.54934C8.92047 7.61934 8.96047 7.68934 8.97047 7.76934C9.01047 7.92934 8.97047 8.07934 8.85047 8.19934C8.76047 8.27934 8.66047 8.35934 8.56047 8.43934C8.28047 8.67934 8.22047 8.89934 8.36047 9.25934C8.57047 9.77934 8.90047 10.2193 9.34047 10.5693C9.59047 10.7693 9.86047 10.9393 10.1505 11.0593C10.5005 11.2093 10.6905 11.1493 10.9205 10.8493C10.9405 10.8293 10.9605 10.7993 10.9805 10.7793C11.2805 10.4193 11.5905 10.3593 12.0005 10.5993C12.4305 10.8593 12.8205 11.1493 13.2005 11.4793C13.4105 11.6593 13.4605 11.8993 13.3605 12.1493C13.1305 12.6893 12.7405 13.0393 12.1705 13.1893C11.9905 13.2393 11.8205 13.1993 11.6605 13.1293C9.17047 12.0793 7.37047 10.3393 6.35047 7.81934C6.27047 7.65934 6.24047 7.48934 6.23047 7.30934Z"
				fill="#7A4D9A"
			/>
			<path
				d="M13.33 9.20272C13.33 9.30272 13.34 9.39272 13.33 9.49272C13.32 9.59272 13.27 9.65272 13.16 9.65272C13.06 9.65272 12.99 9.61272 12.97 9.51272C12.94 9.29272 12.92 9.07272 12.88 8.85272C12.76 8.20272 12.53 7.60272 12.04 7.14272C11.63 6.75272 11.14 6.54272 10.6 6.41272C10.38 6.36272 10.15 6.31272 9.92 6.29272C9.8 6.27272 9.75 6.22272 9.75 6.11272C9.75 5.99272 9.82 5.91272 9.94 5.92272C10.79 5.95272 11.59 6.14272 12.24 6.73272C12.82 7.25272 13.15 7.90272 13.28 8.65272C13.32 8.83272 13.34 9.01272 13.33 9.20272Z"
				fill="#7A4D9A"
			/>
			<path
				d="M12.4599 8.87876C12.4599 8.97876 12.4599 9.07876 12.4299 9.17876C12.4099 9.26876 12.3499 9.30876 12.2599 9.30876C12.1699 9.30876 12.0999 9.26876 12.0899 9.16876C12.0599 8.96876 12.0399 8.76876 11.9999 8.57876C11.8199 7.79876 11.3099 7.38876 10.5499 7.22876C10.4299 7.19876 10.3099 7.18876 10.1899 7.15876C10.0499 7.12876 9.99985 7.06876 10.0199 6.93876C10.0299 6.81876 10.1099 6.74876 10.2299 6.75876C10.7299 6.80876 11.2099 6.92876 11.6099 7.24876C12.1399 7.65876 12.4099 8.20876 12.4599 8.87876Z"
				fill="#7A4D9A"
			/>
			<path
				d="M10.5295 7.66016C11.0295 7.67016 11.4995 8.08016 11.5995 8.58016C11.6195 8.68016 11.6295 8.78016 11.5995 8.88016C11.5795 8.98016 11.5295 9.03016 11.4295 9.04016C11.3295 9.04016 11.2695 8.98016 11.2395 8.88016C11.2195 8.81016 11.2095 8.74016 11.1995 8.67016C11.1295 8.34016 10.9295 8.14016 10.5995 8.06016C10.5395 8.05016 10.4795 8.03016 10.4195 8.02016C10.3295 8.00016 10.2795 7.96016 10.2695 7.86016C10.2695 7.76016 10.3195 7.70016 10.4095 7.67016C10.4595 7.66016 10.5095 7.66016 10.5295 7.66016Z"
				fill="#7A4D9A"
			/>
		</svg>
	);
};
