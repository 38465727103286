const PdfIcon = () => {
	return (
		<svg
			width="21"
			height="24"
			viewBox="0 0 21 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 2.4C0 1.07452 1.08485 0 2.42308 0H15.2693C15.5329 0 15.7858 0.104048 15.973 0.289518L20.7037 4.97517C20.8933 5.16297 21 5.41877 21 5.68565V21.6C21 22.9255 19.9152 24 18.5769 24H2.42308C1.08485 24 0 22.9255 0 21.6V2.4Z"
				fill="#A2A2A2"
			/>
			<path
				d="M5.04959 18.2041C5.36037 18.2041 5.58404 18.1307 5.72059 17.9838C5.85714 17.8369 5.92542 17.6316 5.92542 17.3681C5.92542 17.2515 5.90776 17.1454 5.87245 17.0498C5.83713 16.9542 5.78298 16.8726 5.71 16.805C5.63936 16.735 5.54872 16.6814 5.43807 16.6441C5.32976 16.6068 5.20027 16.5881 5.04959 16.5881H4.42804V18.2041H5.04959ZM5.04959 15.7031C5.41217 15.7031 5.72294 15.7463 5.98193 15.8325C6.24326 15.9165 6.45751 16.0331 6.62467 16.1823C6.79418 16.3316 6.91896 16.5076 6.99901 16.7105C7.07906 16.9134 7.11909 17.1326 7.11909 17.3681C7.11909 17.6223 7.07788 17.8555 6.99548 18.0677C6.91308 18.2799 6.78712 18.4618 6.61761 18.6134C6.44809 18.765 6.23267 18.8839 5.97133 18.9702C5.71235 19.0541 5.4051 19.0961 5.04959 19.0961H4.42804V20.7996H3.23438V15.7031H5.04959Z"
				fill="white"
			/>
			<path
				d="M12.3934 18.2496C12.3934 18.6181 12.3287 18.9585 12.1992 19.271C12.072 19.5812 11.8919 19.8505 11.6588 20.079C11.4258 20.3052 11.1444 20.4825 10.8148 20.6107C10.4875 20.7366 10.1238 20.7996 9.72355 20.7996H7.72469V15.7031H9.72355C10.1238 15.7031 10.4875 15.7673 10.8148 15.8955C11.1444 16.0238 11.4258 16.201 11.6588 16.4272C11.8919 16.6534 12.072 16.9227 12.1992 17.2352C12.3287 17.5454 12.3934 17.8835 12.3934 18.2496ZM11.1715 18.2496C11.1715 17.9978 11.1385 17.7704 11.0726 17.5675C11.0067 17.3646 10.9113 17.1932 10.7866 17.0533C10.6641 16.9111 10.5134 16.8026 10.3345 16.728C10.1556 16.6511 9.95193 16.6126 9.72355 16.6126H8.92542V19.8901H9.72355C9.95193 19.8901 10.1556 19.8528 10.3345 19.7782C10.5134 19.7012 10.6641 19.5928 10.7866 19.4529C10.9113 19.3106 11.0067 19.1381 11.0726 18.9352C11.1385 18.7323 11.1715 18.5038 11.1715 18.2496Z"
				fill="white"
			/>
			<path
				d="M14.2986 16.6126V17.9033H16.0573V18.8163H14.2986V20.7996H13.0979V15.7031H16.4105V16.6126H14.2986Z"
				fill="white"
			/>
		</svg>
	);
};

export default PdfIcon;
