import React, { useContext } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { Link, useLocation } from 'react-router-dom';
import { OrderInterface } from '../OrdersTable/interfaces/Order.interface';
import { OrderContext } from '../../context/OrderContext/order.context';
import { AcceptedOrder } from '../../assets/AcceptedOrder';
import { NewOrder } from '../../assets/NewOrder';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';
import { Truck } from '../../assets/Truck';

export interface NewOrderToastProps {
	order: OrderInterface;
	role: string;
}

const NewOrderToast = ({ order, role }: NewOrderToastProps) => {
	const { setAdditional } = useContext(OrderContext);
	const location = useLocation();

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (location.pathname === '/orders') {
			event.preventDefault();
			setAdditional((prev) => ({
				...prev,
				order: order,
			}));
		}
	};

	const newOrderMessageKey =
		role === OrderTarget.customer
			? 'customerToast.newTransportOrderAvailable'
			: role === OrderTarget.producer
			? 'producerToast.newOrderAvailable'
			: role === OrderTarget.admin
			? 'adminToast.descriptionNewOrder'
			: 'transporterToast.newTransportOrderAvailable';

	return (
		<Link
			to={
				location.pathname === '/orders'
					? '#'
					: `/orders?orderId=${
							role === OrderTarget.transporter ? order.id : order.orderId
					  }`
			}
			className="no-underline bg-transparent"
			onClick={handleClick}
			state={{
				orderId: role === OrderTarget.transporter ? order.offerId : order.id,
			}}
		>
			<div className="flex flex-row w-[545px] h-[110px]">
				<div className="flex flex-row justify-center items-center h-12 w-12 min-w-[48px] rounded-10 bg-mountain-meadow-20">
					{role === OrderTarget.customer ? <AcceptedOrder /> : <NewOrder />}
				</div>

				<div className="flex flex-col ml-4 mr-5">
					<div className="flex flex-row justify-start items-center flex-nowrap">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40 whitespace-nowrap">
								<T keyName={`${role}Toast.orderId`}>Order ID:</T>{' '}
								{role === OrderTarget.transporter ? order.id : order.orderId}
							</p>
						</SypacText>

						{role === OrderTarget.customer ? (
							<>
								{order?.truck?.licensePlates ? (
									<div className="w-1 h-1 rounded-sm bg-gray-40 mx-2" />
								) : null}
								{order?.truck?.licensePlates ? (
									<SypacText variant="overline-regular-large">
										<p className="text-gray-40 whitespace-nowrap">
											{order?.truck?.licensePlates || ''}
										</p>
									</SypacText>
								) : null}
								<div className="w-1 h-1 rounded-sm bg-gray-40 mx-2" />
								<div>
									<Truck />
								</div>
							</>
						) : null}
					</div>

					<SypacText variant="body-regular-large" className="mt-2">
						<p className="text-gray-80 whitespace-nowrap">
							<T keyName={newOrderMessageKey}>
								{role === OrderTarget.customer
									? 'Your order has been accepted'
									: 'New order available'}
							</T>
						</p>
					</SypacText>
					<div className="flex flex-row justify-start flex-nowrap mt-2">
						<div className="flex flex-col justify-start flex-nowrap">
							<SypacText variant="body-regular-small">
								<p className="text-gray-60 whitespace-nowrap">
									<T keyName={`${role}Toast.product`}>Product:</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-small" className="mt-2">
								<p className="text-gray-60 whitespace-nowrap">
									<T keyName={`${role}Toast.deliveryAddress:`}>
										Delivery address:
									</T>
								</p>
							</SypacText>
						</div>
						<div className="flex flex-col justify-start flex-nowrap ml-3">
							<SypacText variant="body-regular-small">
								<p className="text-gray-60 truncate">
									{[order.product.name, order.product.size].join(', ')}
								</p>
							</SypacText>
							<SypacText variant="body-regular-small" className="mt-2">
								<p className="text-gray-60 truncate">{order.deliveryAddress}</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default NewOrderToast;
