import React, { useState } from 'react';
import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { ExpandedBoxProps, ExpandedBoxType } from './ExpandedBox.interface';
import './expanded-box.css';
import { T } from '@tolgee/react';
import { ChevronDownIcon } from '../../assets/ChevronDownIcon';
import classNames from 'classnames';

const ExpandedBox = ({
	title,
	isOpen,
	children,
	boxType,
	onToggle,
}: ExpandedBoxProps) => {
	const [showMore, setShowMore] = useState<boolean>(isOpen!);

	const renderShowLess = () => {
		const childElements = children?.props.children;

		if (boxType === ExpandedBoxType.SIMPLE_ORDER) {
			return [...childElements.slice(0, 6), childElements.at(8)];
		} else if (boxType === ExpandedBoxType.MULTIPLE_ORDER) {
			return [
				...childElements.slice(0, 6),
				childElements.at(8),
				childElements.at(9),
			];
		} else if (boxType === ExpandedBoxType.EXCLUDED_ORDER) {
			return [...childElements.slice(0, 5)];
		}
	};

	const toggleShowMore = () => {
		setShowMore((prev) => {
			const newState = !prev;
			if (onToggle) {
				onToggle(newState);
			}
			return newState;
		});
	};

	return (
		<SypacBox
			hover={false}
			boxStyle="solid"
			boxColor="primary"
			className="bg-white transition hover:border-gray-80"
		>
			<div className="flex flex-col">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-60 px-4 pt-4">{title}</p>
				</SypacText>
				<div>
					{showMore ? (
						<div className="px-4 pt-4">{children}</div>
					) : (
						<div className="px-4 pt-4">{renderShowLess()}</div>
					)}
				</div>

				<div className="flex justify-center px-4 py-1 border-0 border-t border-solid border-gray-10">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => toggleShowMore()}
					>
						<button className="px-4 py-2 border-0 bg-transparent" type="button">
							<SypacText variant="body-regular-medium" className="mr-3">
								<p className="text-primary-violet">
									{showMore ? (
										<T keyName="expandedBox.showLess">Show less</T>
									) : (
										<T keyName="expandedBox.showMore">Show more</T>
									)}
								</p>
							</SypacText>
							<span
								className={classNames(
									'flex my-auto transform mr-1 transition-all duration-300 ease-in-out',
									{
										'-rotate-180': showMore,
									},
								)}
							>
								<ChevronDownIcon />
							</span>
						</button>
					</SypacButton>
				</div>
			</div>
		</SypacBox>
	);
};

export default ExpandedBox;
