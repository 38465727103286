export const NewOrder = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#20AC93"
				d="M3.979 8.685C1.993 7.891 1 7.494 1 7s.993-.89 2.979-1.685l2.808-1.123C8.773 3.397 9.767 3 11 3c1.234 0 2.227.397 4.213 1.192l2.808 1.123C20.007 6.109 21 6.506 21 7s-.993.89-2.979 1.685l-2.808 1.123C13.227 10.603 12.233 11 11 11c-1.234 0-2.227-.397-4.213-1.192L3.98 8.685z"
			></path>
			<path
				stroke="#20AC93"
				strokeLinecap="round"
				d="M21 11s-.993.89-2.979 1.685l-2.808 1.123C13.227 14.604 12.233 15 11 15c-1.234 0-2.227-.397-4.213-1.191L3.98 12.684C1.993 11.891 1 11 1 11M21 15s-.993.89-2.979 1.685l-2.808 1.123C13.227 18.604 12.233 19 11 19c-1.234 0-2.227-.397-4.213-1.192L3.98 16.685C1.993 15.891 1 15 1 15"
			></path>
		</svg>
	);
};
