import React from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { SypacText } from '@sypac/component-library-react';
import { DriverConfirmationProps } from './DriverConfirmation.interface';
import Flag from 'react-world-flags';
import '../notification.css';
import { T } from '@tolgee/react';

const DriverConfirmation = ({
	notification,
	title,
	description,
	driver,
	countryCode,
	truckPlates,
	truckType,
	address,
	color,
}: DriverConfirmationProps) => {
	return (
		<BoxComponent color={color} notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="overline-regular-large">
					<p className="text-gray-80 leading-3">{description}</p>
				</SypacText>
				<div className="grid grid-cols-12 gap-[9px]">
					<SypacText className="col-span-3" variant="overline-regular-large">
						<p className="text-gray-40">
							<T keyName="driverConfirmation.driverName">Driver Name:</T>
						</p>
					</SypacText>
					<SypacText className="col-span-9" variant="overline-regular-large">
						<p className="text-gray-80">{driver}</p>
					</SypacText>
					<SypacText className="col-span-3" variant="overline-regular-large">
						<p className="text-gray-40">
							<T keyName="driverConfirmation.truckPlates">Truck plates:</T>
						</p>
					</SypacText>
					<SypacText className="col-span-9" variant="overline-regular-large">
						<p className="text-gray-80">{truckPlates}</p>
					</SypacText>
					<SypacText className="col-span-3" variant="overline-regular-large">
						<p className="text-gray-40">
							<T keyName="driverConfirmation.truckType">Truck type:</T>
						</p>
					</SypacText>
					<SypacText className="col-span-9" variant="overline-regular-large">
						<p className="text-gray-80">{truckType}</p>
					</SypacText>
					<SypacText className="col-span-3" variant="overline-regular-large">
						<p className="text-gray-40">
							<T keyName="driverConfirmation.loadingPlace">Loading place:</T>
						</p>
					</SypacText>
					<SypacText className="col-span-9" variant="overline-regular-large">
						<p className="text-gray-80">
							<span className="flag mr-[20px]">
								<Flag
									code={countryCode?.toLocaleUpperCase()}
									width={24}
									height={16}
								/>
							</span>
							{address}
						</p>
					</SypacText>
				</div>
			</div>
		</BoxComponent>
	);
};

export default DriverConfirmation;
