import React, { useCallback, useEffect, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import {
	SypacAvatar,
	SypacBadge,
	SypacText,
} from '@sypac/component-library-react';

import './products_table.css';
import { ProductsTableInterface } from './interfaces/ProductsTable.interface';
import dayjs from 'dayjs';
import { T, useTranslate } from '@tolgee/react';
import {
	ProductInterface,
	ProductStatusEnum,
} from '../../interfaces/product.interface';
import { ProductStatusColors } from '../../constants/product-status.constants';
import Flag from 'react-world-flags';
import { NoResults } from '../../assets/NoResult';

export const ProductsTable = ({
	rows,
	rowClick,
	search,
	openCompany,
}: ProductsTableInterface) => {
	const [keyRows, setRows] = useState<ProductInterface[]>([]);
	const { t } = useTranslate();

	const columns = (search?: string) => [
		{
			title: t('productTable.status', 'Status'),
			dataIndex: '',
			className: 'text-right',
			key: 'status',
			width: 100,
			render(value: ProductInterface) {
				const status: string = value?.status || ProductStatusEnum.PENDING;
				const bgColor =
					ProductStatusColors[status].color ||
					ProductStatusColors.created.color;
				const label = ProductStatusColors[status]
					? t(ProductStatusColors[status].label)
					: t(ProductStatusColors.created.label);

				return (
					<div className="flex justify-end">
						{bgColor && label ? (
							<SypacBadge color={bgColor} outlined={false} className="ml-5">
								<div className="text-xs font-medium whitespace-nowrap min-w-[113px] flex justify-center">
									{label}
								</div>
							</SypacBadge>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('productTable.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			width: 100,
			render(value: ProductInterface) {
				const html = search
					? value.id
							.toString()
							.replace(search, `<span class="text-kimberly">${search}</span>`)
					: value.id.toString();

				return (
					<SypacText variant="body-regular-medium">
						<p
							className="text-gray"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			width: 250,
			render(value: ProductInterface) {
				const productDescription = `${value?.name || ''}`;
				const html = search
					? productDescription?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-kimberly">${match}</span>`,
					  )
					: productDescription;

				return (
					<div className="flex flex-row">
						<div
							style={{
								backgroundImage: `url(${value?.photoUrl})`,
							}}
							className="flex w-11 h-11 min-w-[44px] rounded-10 border border-solid border-gray-10 bg-cover"
						/>
						<div className="flex flex-col ml-3">
							<SypacText variant="body-regular-medium">
								<p
									className="text-gray-80"
									dangerouslySetInnerHTML={{ __html: html }}
								/>
							</SypacText>

							<SypacText variant="overline-regular-large" className="mt-1">
								<p className="text-gray-40">
									{value?.type || ''} {value?.size ? `(${value?.size})` : ''}
								</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.location', 'Location'),
			dataIndex: '',
			className: 'text-left',
			key: 'country',
			width: 250,
			render(value: ProductInterface) {
				return (
					<div className="flex flex-row">
						{value.countryCode ? (
							<Flag
								className="rounded-[3px]"
								code={value.countryCode}
								width={22}
								height={16}
							/>
						) : null}
						<SypacText
							variant="body-regular-medium"
							className={value.countryCode ? 'ml-3' : ''}
						>
							<p className="text-shark">{value.address}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('productTable.addedBY', 'Added by'),
			dataIndex: '',
			className: 'text-left',
			key: 'addedBY',
			width: 250,
			render(value: ProductInterface) {
				const companyName = value?.company?.name;
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div className="flex flex-row">
						<div className="flex w-11 h-11">
							<SypacAvatar initials={companyName} size="md" />
						</div>
						<div className="flex flex-col ml-5">
							<SypacText
								variant="body-regular-medium"
								onClick={() => openCompany(value?.company?.id!)}
							>
								<p className="text-cornflower-blue">{companyName}</p>
							</SypacText>

							<SypacText variant="overline-regular-large" className="mt-1">
								<p className="text-gray-50">{date}</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.approvedRejected', 'Approved / Rejected'),
			dataIndex: '',
			className: 'text-left',
			key: 'approvedRejected',
			width: 300,
			render(value: ProductInterface) {
				const status = value.status || ProductStatusEnum.PENDING;
				const date = dayjs(value.updatedAt).format('DD MMM. YYYY');
				const fullName =
					[
						value.verifiedByUser?.firstName || '',
						value.verifiedByUser?.lastName || '',
					]
						.join(' ')
						.trim() || value.verifiedByUser?.name;
				return [
					ProductStatusEnum.APPROVED.toString(),
					ProductStatusEnum.REJECTED.toString(),
				].includes(status) ? (
					<div className="flex flex-row items-center">
						<SypacAvatar initials={fullName} size="sm">
							{value.verifiedByUser?.avatarUrl ? (
								<img src={value.verifiedByUser?.avatarUrl} alt={fullName} />
							) : null}
						</SypacAvatar>
						<div className="flex flex-row items-center ml-3">
							<SypacText variant="overline-regular-large" className="mr-1">
								<p className="text-gray-80">{date}</p>
							</SypacText>
							<div className="w-1 h-1 rounded-sm bg-gray-80" />
							<SypacText variant="overline-regular-large" className="ml-1">
								<p className="text-gray-80">{date}</p>
							</SypacText>
						</div>
					</div>
				) : null;
			},
		},
	];

	useEffect(() => {
		const newRows = rows.map((row) => ({ ...row, key: row?.id }));
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: ProductInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass = `product-table-row-${record.id}`;
		const bgClass = ` product-table-row-${record.status}`;

		return `${baseClass}${bgClass}`;
	};

	const handleClick = useCallback(
		(event: Event, row: any) => {
			event.stopImmediatePropagation();
			const className = row.className;
			const regexes = className.match(/product-table-row-[0-9]+/gm);
			const regex = regexes?.length && regexes[0];
			if (!regex) {
				return;
			}
			const query = regex.replace('product-table-row-', '');
			if (!query) {
				return;
			}
			const productId = parseInt(query);
			if (!productId) {
				return;
			}
			const product = rows.find((r) => r.id === productId);
			if (product) {
				rowClick(product);
			}
		},
		[rowClick, rows],
	);

	useEffect(() => {
		const rows = document.querySelectorAll('.product-table .rc-table-row');
		for (let i = 0; i < rows.length; i++) {
			rows[i].addEventListener('click', (e) => handleClick(e, rows[i]));
		}

		return () => {
			const rows = document.querySelectorAll('.product-table .rc-table-row');
			for (let i = 0; i < rows.length; i++) {
				rows[i].removeEventListener('click', (e) => handleClick(e, rows[i]));
			}
		};
	}, [handleClick, keyRows]);

	return (
		<>
			<Table
				className={`product-table ${rows.length === 0 ? 'h-full' : ''}`}
				columns={columns(search)}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={() => {
					return (
						<div className="flex flex-col justify-center items-center h-full gap-8">
							<NoResults />
							<SypacText variant="heading-3">
								<p className="text-gray-80">
									{search ? (
										<T keyName="productTable.noResultFoundSearch">
											Feature is not available at the moment
										</T>
									) : (
										<T keyName="productTable.noResultFound">No products yet.</T>
									)}
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									{search ? (
										<T keyName="productTable.noResultFoundDescription">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt
										</T>
									) : null}
								</p>
							</SypacText>
						</div>
					);
				}}
			/>
		</>
	);
};
