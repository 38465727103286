import { SypacInput, SypacText } from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface DayTimeValues {
	from: string;
	to: string;
}

interface FormValues {
	[title: string]: DayTimeValues;
}

interface DropdownInterface {
	submenus: { title: string; variant: string }[];
	dropdown: boolean;
	depthLevel: number;
	classDrop: string;
	handleDataFromChild: any;
	checkeds: string[];
}

const Dropdown = ({
	submenus,
	dropdown,
	depthLevel,
	classDrop,
	handleDataFromChild,
	checkeds,
}: DropdownInterface) => {
	const { t } = useTranslate();
	const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
	const timeFormatRegex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

	const timeEntrySchema = (menuTitle: any) =>
		Yup.object({
			from: Yup.string()
				.required("'From' Time is required")
				.matches(timeFormatRegex, 'Invalid time format, must contain :'),
			to: Yup.string()
				.required("'To' Time is required")
				.matches(timeFormatRegex, 'Invalid time format, must contain :')
				.test(
					'to-after-from',
					"Time must be later than 'From' time",
					function (value, context) {
						const { from } = context.parent;
						return compareTimes(from, value)
							? true
							: this.createError({
									message: "Time must be later than 'From' time",
									path: `${menuTitle}.to`,
							  });
					},
				),
		});

	const validationSchema = Yup.object().shape(
		submenus.reduce((acc, menu) => {
			if (checkeds.includes(menu.variant)) {
				// Only include if the submenu is enabled
				// @ts-ignore
				acc[menu.title] = timeEntrySchema(menu.title);
			}
			return acc;
		}, {}),
	);

	const compareTimes = (from: any, to: any) => {
		if (!from || !to) return true; // Skip if either field is empty
		const [fromHour, fromMinute] = from.split(':').map(Number);
		const [toHour, toMinute] = to.split(':').map(Number);
		return fromHour < toHour || (fromHour === toHour && fromMinute < toMinute);
	};

	const formik = useFormik<FormValues>({
		initialValues: submenus.reduce(
			(acc, menu) => ({
				...acc,
				[menu.title]: { from: '', to: '' },
			}),
			{},
		),
		validationSchema,
		validateOnBlur: false,
		validateOnChange: true,
		onSubmit: (values) => {
			handleDataFromChild(values);
		},
	});

	const handleFormValidation = () => {
		const touchedFields = submenus.reduce(
			(acc, menu) => ({
				...acc,
				[menu.title]: { from: true, to: true },
			}),
			{},
		);

		formik.setTouched(touchedFields);
		formik.validateForm().then((errors) => {
			const filteredErrors = Object.keys(errors).filter((key) =>
				checkeds.includes(
					submenus.find((menu) => menu.title === key)?.variant as string,
				),
			);
			if (filteredErrors.length === 0) {
				formik.submitForm();
			}
		});
	};

	return (
		<div className={`${classDrop} absolute -bottom-[600%] pl-2.5`}>
			<ul
				className={`dropdown flex min-w-[402px] ${dropdownClass} ${
					depthLevel > 0 ? 'gap-y-3' : ''
				} ${dropdown ? 'show' : ''}`}
			>
				<form
					className="flex flex-col gap-y-4 ml-auto w-[436px]"
					onSubmit={formik.handleSubmit}
				>
					{submenus.map(({ title, variant }) => {
						if (checkeds.includes('business_days')) {
							checkeds.length = 0;
							checkeds.push(...submenus.slice(0, 5).map((obj) => obj.variant));
						} else if (checkeds.includes('full_week')) {
							checkeds.length = 0;
							checkeds.push(...submenus.slice(0, 7).map((obj) => obj.variant));
						}

						const isDisabled = !checkeds.includes(variant);
						return (
							<li
								key={title}
								className={`flex gap-x-4 border border-solid border-gray-1 transition-colors ${
									isDisabled
										? 'cursor-not-allowed text-gray-10'
										: 'hover:border-gray-20'
								} rounded-md p-5`}
							>
								<div className="flex flex-1 min-w-0">
									<SypacText
										variant="body-normal-medium"
										className="content-center"
									>
										<div className="truncate">{title}</div>
									</SypacText>
								</div>
								<div className="flex-1">
									<SypacInput
										error={
											!!(
												formik.touched[title]?.from &&
												formik.errors[title]?.from
											)
										}
										size="sm"
									>
										<label>
											<T keyName="menuItems.from">From</T>
										</label>
										<input
											className={`border px-2.5 py-1.5 w-full ${
												formik.touched[title]?.from &&
												formik.errors[title]?.from
													? 'border-red-orange'
													: 'border-gray-2'
											}`}
											type="text"
											value={formik.values[title].from}
											placeholder={t('menuItems.enterTime', 'Enter Time')}
											onChange={(e) =>
												formik.setFieldValue(`${title}.from`, e.target.value)
											}
											disabled={isDisabled}
										/>
										{formik.touched[title]?.from &&
										formik.errors[title]?.from ? (
											<span className="bottom-helper">
												{formik.errors[title]?.from}
											</span>
										) : null}
									</SypacInput>
								</div>
								<div className="flex-1">
									<SypacInput
										error={
											!!(formik.touched[title]?.to && formik.errors[title]?.to)
										}
										size="sm"
									>
										<label>
											<T keyName="menuItems.to">To</T>
										</label>
										<input
											className={`border px-2.5 py-1.5 w-full ${
												formik.touched[title]?.to && formik.errors[title]?.to
													? 'border-red-orange'
													: 'border-gray-2'
											}`}
											type="text"
											value={formik.values[title].to}
											placeholder={t('menuItems.enterTime', 'Enter Time')}
											onChange={(e) =>
												formik.setFieldValue(`${title}.to`, e.target.value)
											}
											disabled={isDisabled}
										/>
										{formik.touched[title]?.to && formik.errors[title]?.to ? (
											<span className="bottom-helper">
												{formik.errors[title]?.to}
											</span>
										) : null}
									</SypacInput>
								</div>
							</li>
						);
					})}
					<div className="flex gap-6.25 items-center justify-center">
						<div className="border border-solid border-gray-10 rounded-md hover:border-violet-900">
							<button
								type="button"
								onClick={() => formik.resetForm()}
								style={{ height: '30px', color: '#494c83' }}
							>
								<SypacText variant="body-regular-medium">
									<p>Discard</p>
								</SypacText>
							</button>
						</div>
						<div className="bg-primary-violet rounded-md hover:bg-primary-violet/70">
							<button
								type="button"
								onClick={() => handleFormValidation()}
								style={{ height: '30px', color: 'white' }}
							>
								<SypacText variant="body-regular-medium">
									<p>Apply</p>
								</SypacText>
							</button>
						</div>
					</div>
				</form>
			</ul>
		</div>
	);
};

export default Dropdown;
