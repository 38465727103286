export const Logo = () => {
	return (
		<svg
			width="164"
			height="165"
			viewBox="0 0 164 165"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_12296_88202)">
				<rect
					x="42"
					y="28"
					width="80"
					height="80"
					rx="18.1818"
					fill="url(#paint0_linear_12296_88202)"
				/>
			</g>
			<path
				d="M74.963 59.2363C75.4128 58.4491 76.2 57.9993 77.0996 57.9993L96.4981 58.0555C97.454 58.0555 98.213 58.5896 98.6347 59.2925C99.0283 59.9953 99.1127 60.9231 98.6347 61.7665L88.8793 78.5504C88.4295 79.3375 87.6423 79.7874 86.7426 79.7874C85.8149 79.7874 85.0839 79.3375 84.6341 78.5504L79.7986 70.1444L74.963 61.7384C74.4851 60.895 74.4851 60.0516 74.963 59.2363ZM102.796 56.9309C102.177 55.8626 101.277 54.9349 100.153 54.2601C99.0846 53.6416 97.8476 53.3043 96.5262 53.3043C90.0601 53.2762 83.5939 53.248 77.1278 53.248C72.9669 53.248 69.8744 56.5936 69.8463 60.4733C69.8463 61.0918 69.9307 61.7103 70.0712 62.3007C68.8623 62.7224 67.8502 63.5658 67.1755 64.7184L60.9343 75.4579C59.866 77.2852 60.0065 79.3375 60.9062 80.94C61.8339 82.5425 63.5489 83.6952 65.6574 83.6952L78.0836 83.7233C79.4331 83.7233 80.642 83.3016 81.626 82.4582C82.0758 82.8799 82.5537 83.2735 83.0879 83.5827C86.4334 85.5226 90.9035 84.5386 92.9839 80.94C96.2451 75.3454 99.4781 69.7508 102.739 64.1843C103.414 63.0316 103.751 61.8227 103.751 60.5576C103.751 59.2363 103.414 57.9993 102.796 56.9309ZM65.0389 78.5504C64.9264 78.3536 64.8983 78.1005 65.0389 77.8194C67.1193 74.249 69.1997 70.7066 71.3363 67.1643C71.6456 66.6302 72.1798 66.4896 72.5171 67.1081L75.6096 72.5059L78.7021 77.9037C79.0395 78.4941 78.6459 78.8877 78.0555 78.8877C73.9228 78.944 69.8182 78.944 65.6855 78.944C65.3762 78.9158 65.1513 78.7472 65.0389 78.5504Z"
				fill="white"
			/>
			<mask
				id="mask0_12296_88202"
				maskUnits="userSpaceOnUse"
				x="68"
				y="48"
				width="21"
				height="21"
			>
				<mask
					id="mask1_12296_88202"
					maskUnits="userSpaceOnUse"
					x="68"
					y="48"
					width="21"
					height="21"
				>
					<path
						d="M75.8319 48L88.9328 55.6188L81.2016 68.8885L68.1006 61.2697L75.8319 48Z"
						fill="url(#paint1_linear_12296_88202)"
					/>
				</mask>
				<g mask="url(#mask1_12296_88202)">
					<path
						d="M75.8319 47.9922L88.9328 55.611L81.2016 68.8807L68.1006 61.2618L75.8319 47.9922Z"
						fill="url(#paint2_linear_12296_88202)"
					/>
				</g>
			</mask>
			<g mask="url(#mask0_12296_88202)">
				<path
					d="M87.3902 58.0555L84.635 53.2762L77.0725 53.248C72.9116 53.248 69.8191 56.5936 69.791 60.4733C69.791 61.0918 69.8754 61.7103 70.0159 62.3007C72.518 61.4291 75.3294 62.4412 76.6508 64.7465L74.9077 61.7103C73.8956 59.9672 75.217 58.0274 77.0443 58.0274L87.3902 58.0555Z"
					fill="url(#paint3_linear_12296_88202)"
				/>
			</g>
			<mask
				id="mask2_12296_88202"
				maskUnits="userSpaceOnUse"
				x="66"
				y="73"
				width="18"
				height="16"
			>
				<mask
					id="mask3_12296_88202"
					maskUnits="userSpaceOnUse"
					x="66"
					y="73"
					width="18"
					height="16"
				>
					<path
						d="M70.968 73.2715L83.0006 80.2718L78.1088 88.7059L66.0762 81.7056L70.968 73.2715Z"
						fill="url(#paint4_linear_12296_88202)"
					/>
				</mask>
				<g mask="url(#mask3_12296_88202)">
					<path
						d="M70.968 73.2715L83.0006 80.2718L78.1088 88.7059L66.0762 81.7056L70.968 73.2715Z"
						fill="url(#paint5_linear_12296_88202)"
					/>
				</g>
			</mask>
			<g mask="url(#mask2_12296_88202)">
				<path
					d="M80.4463 80.9113L78.7032 77.875C79.0406 78.4654 78.647 78.859 78.0566 78.859C74.6268 78.8871 71.225 78.9152 67.8232 78.9152L69.7631 83.6945L78.0847 83.7227C79.4342 83.7227 80.6431 83.3009 81.6271 82.4575C81.1772 82.0077 80.7837 81.5017 80.4463 80.9113Z"
					fill="url(#paint6_linear_12296_88202)"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_12296_88202"
					x="0.181818"
					y="0.727273"
					width="163.636"
					height="163.636"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feMorphology
						radius="21.8182"
						operator="erode"
						in="SourceAlpha"
						result="effect1_dropShadow_12296_88202"
					/>
					<feOffset dy="14.5455" />
					<feGaussianBlur stdDeviation="31.8182" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_12296_88202"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_12296_88202"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_12296_88202"
					x1="42"
					y1="68"
					x2="122"
					y2="68"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.0220187" stopColor="#494C83" />
					<stop offset="1" stopColor="#7693F4" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_12296_88202"
					x1="66.3506"
					y1="65.4312"
					x2="81.5064"
					y2="56.7462"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_12296_88202"
					x1="66.3506"
					y1="65.4234"
					x2="81.5064"
					y2="56.7384"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_12296_88202"
					x1="70.0531"
					y1="62.3133"
					x2="85.0486"
					y2="53.8999"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_12296_88202"
					x1="80.2248"
					y1="77.739"
					x2="72.2273"
					y2="82.3213"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_12296_88202"
					x1="80.2248"
					y1="77.739"
					x2="72.2273"
					y2="82.3213"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_12296_88202"
					x1="79.2509"
					y1="79.4963"
					x2="68.7959"
					y2="82.8223"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
