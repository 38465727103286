export const RadioCheked = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="26"
			fill="none"
			viewBox="0 0 26 26"
		>
			<g filter="url(#filter0_d_6645_242267)">
				<g clipPath="url(#clip0_6645_242267)">
					<rect
						width="20"
						height="20"
						x="3"
						y="2"
						fill="#DCFCE7"
						rx="10"
					></rect>
					<circle
						cx="13"
						cy="12"
						r="9.375"
						stroke="#16A34A"
						strokeWidth="1.25"
					></circle>
					<circle cx="13" cy="12" r="5" fill="#22C55E"></circle>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_6645_242267"
					width="25"
					height="25"
					x="0.5"
					y="0.75"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="1.25"></feOffset>
					<feGaussianBlur stdDeviation="1.25"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_6645_242267"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_6645_242267"
						result="shape"
					></feBlend>
				</filter>
				<clipPath id="clip0_6645_242267">
					<rect width="20" height="20" x="3" y="2" fill="#fff" rx="10"></rect>
				</clipPath>
			</defs>
		</svg>
	);
};
