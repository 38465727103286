import React from 'react';
import {
	SypacBadge,
	SypacTabButton,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';

import { useTranslate } from '@tolgee/react';
import { ProductTab, ProductTabsProps } from './ProductTabs.interface';

const ProductTabs = ({ data, callback, activeTab }: ProductTabsProps) => {
	const { t } = useTranslate();

	return (
		<SypacTabs className="box-border">
			{data
				? data.map(({ label, value, color, count }: ProductTab) => {
						return (
							<SypacTabButton
								key={Math.random()}
								size="large"
								variant="gradient"
								label={t(`productTabs.${label.toLowerCase()}`, label)}
								button-id={value ? value : label.toLowerCase()}
								onClick={() => callback(value ? value : label.toLowerCase())}
								is-active={
									activeTab === value || activeTab === label.toLowerCase()
								}
							>
								<SypacBadge color={color}>
									<SypacText variant="overline-regular-large">
										<p>{count}</p>
									</SypacText>
								</SypacBadge>
							</SypacTabButton>
						);
				  })
				: null}
		</SypacTabs>
	);
};

export default ProductTabs;
