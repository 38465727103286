import React from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';

import { ModalSuccessPaymentProps } from './ModalSuccessPayment.interface';
import './modal-success-payment.css';
import PaymentSuccess from '../../assets/PaymentSuccess';
import { T } from '@tolgee/react';

const ModalSuccessPayment = ({ isOpen, onClose }: ModalSuccessPaymentProps) => {
	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[563px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Payment processed</p>
					</SypacText>
					<SypacIcon
						iconName="Close Square"
						className="text-gray-10 ml-6 cursor-pointer"
						size="custom"
						width="32px"
						height="32px"
						onClick={onClose}
					/>
				</div>
				<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-6 py-6 gap-4">
					<div className="pt-10">
						<PaymentSuccess />
					</div>
					<SypacText variant="heading-5">
						<p className="text-gray-80">Payment successfully processed</p>
					</SypacText>
					<SypacText variant="body-regular-small" className="pt-3">
						<p className="text-gray-40 text-left">
							Payment confirmed and successfully processed.
						</p>
					</SypacText>
				</div>

				<div className="flex justify-center w-full py-5">
					<SypacButton variant="secondary" size="small" onClick={onClose}>
						<button className="px-10 py-2.5 hover:border-gray-2" type="reset">
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalSuccessPayment.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSuccessPayment;
