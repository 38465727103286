export const WhatsUp = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="20" height="20" rx="4" fill="#0CD461" />
			<path
				d="M17 10C17 13.866 13.866 17 10 17C4.2392 17.5665 1.84825 16.4499 4.25469 14C3.97153 13.594 3.73029 13.1567 3.53725 12.6942C3.19114 11.8649 3 10.9548 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
				fill="white"
			/>
			<path
				d="M15.1885 9.97093C15.1885 12.9409 12.6485 15.3109 9.68851 15.1209C8.83851 15.0609 8.05851 14.8109 7.32851 14.3809C7.22851 14.3209 7.14851 14.3109 7.03851 14.3409C6.50851 14.4809 5.97851 14.6209 5.45851 14.7609C5.24851 14.8209 5.24851 14.8209 5.29851 14.6109C5.43851 14.1109 5.56851 13.6109 5.70851 13.1109C5.74851 12.9709 5.73851 12.8709 5.65851 12.7509C4.07851 10.1809 4.88851 6.95093 7.47851 5.48093C10.5685 3.72094 14.5385 5.61093 15.1185 9.11093C15.1685 9.39093 15.1985 9.68093 15.1885 9.97093ZM11.6585 12.9409C11.7385 12.9409 11.8185 12.9509 11.8885 12.9409C12.5185 12.8409 13.1885 12.4609 13.1785 11.6309C13.1785 11.5209 13.1785 11.4209 13.0585 11.3709C12.6285 11.1709 12.1985 10.9609 11.7785 10.7609C11.6385 10.7009 11.5285 10.7309 11.4385 10.8509C11.2985 11.0409 11.1385 11.2209 10.9885 11.4109C10.8985 11.5309 10.7885 11.5609 10.6485 11.5009C9.73851 11.1309 9.04851 10.5309 8.53851 9.69093C8.44851 9.54093 8.45851 9.43093 8.57851 9.31093C8.71851 9.16093 8.84851 9.02093 8.93851 8.83093C8.98851 8.74093 8.99851 8.65093 8.95851 8.55093C8.78851 8.14093 8.61851 7.72093 8.43851 7.31093C8.40851 7.23093 8.35851 7.13093 8.25851 7.12093C7.96851 7.08093 7.65851 7.03093 7.40851 7.27093C6.94851 7.73093 6.77851 8.28093 6.89851 8.92093C6.99851 9.49093 7.30851 9.95093 7.64851 10.4009C8.30851 11.2709 9.05851 12.0309 10.0785 12.4909C10.5785 12.7209 11.0785 12.9509 11.6585 12.9409Z"
				fill="#0CD461"
			/>
			<path
				d="M19.9993 9.2388C19.9993 9.7688 19.9993 10.3088 19.9993 10.8388C19.9293 10.8688 19.9593 10.9288 19.9593 10.9788C19.8793 11.6788 19.7693 12.3688 19.5393 13.0288C18.1693 16.8888 15.4693 19.1888 11.4193 19.8988C11.2093 19.9388 10.9793 19.9188 10.7793 19.9988C10.2493 19.9988 9.70928 19.9988 9.17928 19.9988C8.92928 19.9088 8.65928 19.9188 8.39928 19.8788C6.32928 19.5288 4.50928 18.6488 3.01928 17.1688C0.749277 14.9188 -0.240723 12.1888 0.0492773 9.0188C0.259277 6.7188 1.17928 4.7088 2.81928 3.0888C5.69928 0.2388 9.16928 -0.6912 13.0193 0.5388C16.9093 1.7788 19.1793 4.5688 19.8893 8.5988C19.9293 8.8088 19.9093 9.0288 19.9993 9.2388ZM10.0193 16.1788C10.4493 16.1788 10.8793 16.1388 11.2993 16.0488C14.5593 15.3388 16.6493 12.3088 16.1693 9.0188C15.6393 5.3888 11.9393 2.9788 8.40928 3.9588C4.49928 5.0388 2.64928 9.3388 4.54928 12.9188C4.60928 13.0288 4.60928 13.1188 4.57928 13.2288C4.30928 14.1788 4.05928 15.1288 3.79928 16.0788C3.74928 16.2788 3.74928 16.2788 3.94928 16.2288C4.91928 15.9788 5.88928 15.7288 6.84928 15.4688C6.98928 15.4288 7.08928 15.4388 7.21928 15.5088C8.09928 15.9588 9.03928 16.1688 10.0193 16.1788Z"
				fill="#0CD461"
			/>
		</svg>
	);
};
