export const Pickup = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="33"
			fill="none"
			viewBox="0 0 32 33"
		>
			<g stroke="#FFB352" clipPath="url(#clip0_4423_162181)">
				<circle cx="16" cy="16.25" r="15.5" fill="#FFF7EE"></circle>
				<path
					strokeWidth="1.5"
					d="M10 14.857c0-3.373 2.686-6.107 6-6.107s6 2.734 6 6.107c0 3.347-1.915 7.252-4.903 8.649a2.587 2.587 0 01-2.194 0C11.915 22.109 10 18.204 10 14.857z"
				></path>
				<circle cx="16" cy="14.75" r="2.25" strokeWidth="1.5"></circle>
			</g>
			<defs>
				<clipPath id="clip0_4423_162181">
					<path
						fill="#fff"
						d="M0 0H32V32H0z"
						transform="translate(0 .25)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
};
