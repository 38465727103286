export const NotExpanded = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			viewBox="0 0 32 32"
		>
			<rect width="31" height="31" x="0.5" y="0.5" fill="#fff" rx="5.5"></rect>
			<path
				stroke="#454545"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M11 16h5m0 0h5m-5 0v5m0-5v-5"
			></path>
			<rect
				width="31"
				height="31"
				x="0.5"
				y="0.5"
				stroke="#E8E8E8"
				rx="5.5"
			></rect>
		</svg>
	);
};
