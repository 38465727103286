import React, { ReactElement, useContext } from 'react';
import { AuthContext } from '../../context/context';

interface GuardProps {
	children: ReactElement<any, any>;
}

const AdminGuardedRoute: React.FC<GuardProps> = ({ children }) => {
	const { user } = useContext(AuthContext);

	if (!(user && user.isAdmin)) {
		window.location.replace(`${process.env.REACT_APP_LANDING_URL}/en/login`);
	}
	return <>{children}</>;
};

export default AdminGuardedRoute;
