export const SearchIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.976707 6.67416C0.976707 3.52754 3.52754 0.976707 6.67416 0.976707C9.82078 0.976707 12.3716 3.52754 12.3716 6.67416C12.3716 9.82078 9.82078 12.3716 6.67416 12.3716C3.52754 12.3716 0.976707 9.82078 0.976707 6.67416ZM6.67416 0C2.98812 0 0 2.98812 0 6.67416C0 10.3602 2.98812 13.3483 6.67416 13.3483C8.34123 13.3483 9.86555 12.7371 11.0352 11.7266L13.1656 13.857C13.3564 14.0477 13.6656 14.0477 13.8563 13.857C14.047 13.6663 14.047 13.357 13.8563 13.1663L11.7259 11.036C12.7369 9.86619 13.3483 8.34158 13.3483 6.67416C13.3483 2.98812 10.3602 0 6.67416 0Z"
				fill="#A2A2A2"
			/>
		</svg>
	);
};
