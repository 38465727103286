import React, { useState } from 'react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import DeliverySchedule from '../../AdditionalDetails/DeliverySchedule';
import ClientDeliveryDetails from '../../AdditionalDetails/ClientDeliveryDetails';
import { ClientOrderProps } from '../../../clientOrder.interface';

const CreatedStep: React.FC<ClientOrderProps> = (props) => {
	const { selectedOrder } = props;
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={1}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			<div className="px-10 pt-7 pb-3.5">
				<ClientDeliveryDetails
					selectedOrder={selectedOrder}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<DeliverySchedule
					selectedOrder={selectedOrder}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					step={1}
				/>
			</div>
		</>
	);
};

export default CreatedStep;
