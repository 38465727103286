import { SypacText } from '@sypac/component-library-react';
import React, { useEffect, useState } from 'react';

import './styles.css';
import { MultipleSelect } from './Selectors/Days/MultipleSelect';
import { MultipleSelectHours } from './Selectors/Hours/MultipleSelect';
import { LocationInput } from './Selectors/LocationInput';
import { Geo } from '../SearchLocation/locations.interface';
import { T, useTranslate } from '@tolgee/react';

export interface VariantInterface {
	title: string;
	variant: string;
	submenu?: VariantInterface[];
}

interface ProductLocationProps {
	setLocation?: (location: Geo | undefined) => void;
	errorLocation?: string;
	errorPickupAvailability?: string;
	errorWorkingHours?: string;
	defaultValue?: string;
	addWorkHours?: (selected: boolean) => void;
	addPickupDays?: (selected: boolean) => void;
}

export const ProductLocation = ({
	setLocation,
	errorLocation,
	errorPickupAvailability,
	errorWorkingHours,
	defaultValue,
	addWorkHours,
	addPickupDays,
}: ProductLocationProps) => {
	const { t } = useTranslate();
	const menuItems = [
		{
			title: t('productLocation.businessDays', 'Business days'),
			variant: 'business_days',
		},
		{
			title: t('productLocation.FullWeek', 'Full week'),
			variant: 'full_week',
		},
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: [
				{
					title: t('productLocation.monday', 'Monday'),
					variant: 'monday',
				},
				{
					title: t('productLocation.tuesday', 'Tuesday'),
					variant: 'tuesday',
				},
				{
					title: t('productLocation.wednesday', 'Wednesday'),
					variant: 'wednesday',
				},
				{
					title: t('productLocation.thursday', 'Thursday'),
					variant: 'thursday',
				},
				{
					title: t('productLocation.friday', 'Friday'),
					variant: 'friday',
				},
				{
					title: t('productLocation.saturday', 'Saturday'),
					variant: 'saturday',
				},
				{
					title: t('productLocation.sunday', 'Sunday'),
					variant: 'sunday',
				},
			],
		},
	];
	const menuItemsHours = [
		{
			title: t('productLocation.fullTime', 'Full Time'),
			variant: 'full_time',
		},
		{
			title: t('productLocation.fromHoursToHours', 'From 9:00 to 18:00'),
			variant: 'working_hours',
		},
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: [
				{
					title: t('productLocation.monday', 'Monday'),
					variant: 'monday',
				},
				{
					title: t('productLocation.tuesday', 'Tuesday'),
					variant: 'tuesday',
				},
				{
					title: t('productLocation.wednesday', 'Wednesday'),
					variant: 'wednesday',
				},
				{
					title: t('productLocation.thursday', 'Thursday'),
					variant: 'thursday',
				},
				{
					title: t('productLocation.friday', 'Friday'),
					variant: 'friday',
				},
				{
					title: t('productLocation.saturday', 'Saturday'),
					variant: 'saturday',
				},
				{
					title: t('productLocation.sunday', 'Sunday'),
					variant: 'sunday',
				},
			],
		},
	];

	const [pickupDays, setPickupDays] = useState<VariantInterface[]>([
		{ title: t('productLocation.days', 'Days'), variant: 'days' },
	]);
	const [workHours, setWorkHours] = useState<VariantInterface[]>([
		{ title: t('productLocation.hours', 'Hours'), variant: 'hours' },
	]);
	const [checkeds, setChecked] = useState<string[]>([]);

	useEffect(() => {
		const exist = pickupDays.some((r) => r.variant !== 'days');
		if (exist) {
			addPickupDays && addPickupDays(exist);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickupDays]);

	useEffect(() => {
		const exist = workHours.some((r) => r.variant !== 'hours');
		if (exist) {
			addWorkHours && addWorkHours(exist);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workHours]);

	const setNewItem = (data: any) => {
		if (data.variant === 'business_days' || data.variant === 'full_week') {
			setChecked([data.variant]);
		} else {
			setChecked([]);
			const exist = checkeds.includes(data.variant);
			if (exist) {
				const newCheckeds = checkeds.filter((r) => r !== data.variant);
				setChecked(newCheckeds);
			} else {
				setChecked([...checkeds, data.variant]);
			}
		}
	};

	return (
		<div className="flex flex-col p-5 pt-[25px] border border-dashed border-gray rounded-xl m-1 font-sans">
			<div>
				<SypacText variant="body-regular-medium">
					<p>
						<T keyName="productLocation.location">1. Location</T>
					</p>
				</SypacText>
			</div>
			<div className="mt-6 gap-6 grid grid-cols-3">
				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.selectLocationProduct">
								Select location for the product
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<LocationInput
						setLocation={(item) => setLocation && setLocation(item)}
						defaultValue={defaultValue}
						role={'producer'}
						errorLocation={errorLocation}
					/>
					{errorLocation ? (
						<span className="input-error">
							<T keyName="productLocation.errorLocation">{errorLocation}</T>
						</span>
					) : null}
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.productPickupAvailability">
								Product pickup availability
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<MultipleSelect
						items={menuItems}
						selected={pickupDays}
						setSelected={setPickupDays}
						setChecked={setNewItem}
						checkeds={checkeds}
					/>
					{errorPickupAvailability ? (
						<span className="input-error">
							<T keyName="productLocation.errorProductPickupAvailability">
								{errorPickupAvailability}
							</T>
						</span>
					) : null}
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.workingHours">Working hours</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<MultipleSelectHours
						items={menuItemsHours}
						selected={workHours}
						setSelected={setWorkHours}
						checkeds={checkeds}
					/>
					{errorWorkingHours ? (
						<span className="input-error">
							<T keyName="productLocation.errorProductWorkingHours">
								{errorWorkingHours}
							</T>
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
};
