export const CompletedPin = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="33"
			fill="none"
			viewBox="0 0 32 33"
		>
			<circle
				cx="16"
				cy="16.625"
				r="15.5"
				fill="#D7D7E4"
				stroke="#494C83"
			></circle>
			<path
				stroke="#494C83"
				strokeWidth="1.5"
				d="M10 15.232c0-3.373 2.686-6.107 6-6.107s6 2.734 6 6.107c0 3.347-1.915 7.252-4.903 8.649a2.587 2.587 0 01-2.194 0C11.915 22.484 10 18.579 10 15.232z"
			></path>
			<circle
				cx="16"
				cy="15.125"
				r="2.25"
				stroke="#494C83"
				strokeWidth="1.5"
			></circle>
		</svg>
	);
};
