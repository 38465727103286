export const Calendar = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 8.79844C1 5.78145 1 4.27295 1.93726 3.3357C2.87452 2.39844 4.38301 2.39844 7.4 2.39844H10.6C13.617 2.39844 15.1255 2.39844 16.0627 3.3357C17 4.27295 17 5.78145 17 8.79844V10.3984C17 13.4154 17 14.9239 16.0627 15.8612C15.1255 16.7984 13.617 16.7984 10.6 16.7984H7.4C4.38301 16.7984 2.87452 16.7984 1.93726 15.8612C1 14.9239 1 13.4154 1 10.3984V8.79844Z"
				stroke="#8B8B8B"
			/>
			<path d="M5 2.39922V1.19922" stroke="#8B8B8B" strokeLinecap="round" />
			<path d="M13 2.39922V1.19922" stroke="#8B8B8B" strokeLinecap="round" />
			<path
				d="M1.40039 6.39844H16.6004"
				stroke="#8B8B8B"
				strokeLinecap="round"
			/>
			<path
				d="M13.7992 12.7988C13.7992 13.2407 13.441 13.5988 12.9992 13.5988C12.5574 13.5988 12.1992 13.2407 12.1992 12.7988C12.1992 12.357 12.5574 11.9988 12.9992 11.9988C13.441 11.9988 13.7992 12.357 13.7992 12.7988Z"
				fill="#8B8B8B"
			/>
			<path
				d="M13.7992 9.59883C13.7992 10.0407 13.441 10.3988 12.9992 10.3988C12.5574 10.3988 12.1992 10.0407 12.1992 9.59883C12.1992 9.157 12.5574 8.79883 12.9992 8.79883C13.441 8.79883 13.7992 9.157 13.7992 9.59883Z"
				fill="#8B8B8B"
			/>
			<path
				d="M9.79922 12.7988C9.79922 13.2407 9.44105 13.5988 8.99922 13.5988C8.55739 13.5988 8.19922 13.2407 8.19922 12.7988C8.19922 12.357 8.55739 11.9988 8.99922 11.9988C9.44105 11.9988 9.79922 12.357 9.79922 12.7988Z"
				fill="#8B8B8B"
			/>
			<path
				d="M9.79922 9.59883C9.79922 10.0407 9.44105 10.3988 8.99922 10.3988C8.55739 10.3988 8.19922 10.0407 8.19922 9.59883C8.19922 9.157 8.55739 8.79883 8.99922 8.79883C9.44105 8.79883 9.79922 9.157 9.79922 9.59883Z"
				fill="#8B8B8B"
			/>
			<path
				d="M5.79922 12.7988C5.79922 13.2407 5.44105 13.5988 4.99922 13.5988C4.55739 13.5988 4.19922 13.2407 4.19922 12.7988C4.19922 12.357 4.55739 11.9988 4.99922 11.9988C5.44105 11.9988 5.79922 12.357 5.79922 12.7988Z"
				fill="#8B8B8B"
			/>
			<path
				d="M5.79922 9.59883C5.79922 10.0407 5.44105 10.3988 4.99922 10.3988C4.55739 10.3988 4.19922 10.0407 4.19922 9.59883C4.19922 9.157 4.55739 8.79883 4.99922 8.79883C5.44105 8.79883 5.79922 9.157 5.79922 9.59883Z"
				fill="#8B8B8B"
			/>
		</svg>
	);
};
