import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const Clock: React.FC = () => {
	const [curDate, setCurDate] = useState(dayjs());

	useEffect(() => {
		setCurDate(dayjs());
	}, []);

	return <>{curDate.format('HH:mm')}</>;
};

export default Clock;
