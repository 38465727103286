export const AcceptedOrder = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				fill="#20AC93"
				d="M18.326 4.416a.5.5 0 01.712-.702l-.712.702zM14.6 2.777l.356-.35a.5.5 0 010 .701l-.356-.35zm-1.394 2.128a.5.5 0 01-.713-.702l.713.702zm-.713-3.554a.5.5 0 11.713-.702l-.713.702zM11 20.5c2.136 0 3.686-.001 4.87-.163 1.169-.16 1.905-.467 2.456-1.026l.712.702c-.767.779-1.75 1.14-3.033 1.315-1.267.173-2.898.172-5.005.172v-1zm9.5-8.636c0 2.139.001 3.793-.17 5.078-.171 1.298-.526 2.293-1.292 3.07l-.712-.7c.552-.561.856-1.312 1.014-2.502.159-1.203.16-2.78.16-4.946h1zm-18 0c0 2.167.001 3.743.16 4.946.158 1.19.462 1.94 1.014 2.501l-.712.702c-.766-.778-1.121-1.773-1.293-3.071-.17-1.285-.169-2.939-.169-5.079h1zM11 21.5c-2.107 0-3.738.001-5.005-.172-1.283-.175-2.266-.536-3.033-1.315l.712-.702c.551.56 1.287.867 2.456 1.026 1.184.162 2.734.163 4.87.163v1zm0-18.273c-2.136 0-3.686.001-4.87.163-1.169.159-1.905.466-2.456 1.026l-.712-.702c.767-.779 1.75-1.14 3.033-1.315 1.267-.173 2.898-.172 5.005-.172v1zm-9.5 8.636c0-2.14-.001-3.793.17-5.078.171-1.298.526-2.293 1.292-3.071l.712.702c-.552.56-.856 1.31-1.014 2.5-.159 1.204-.16 2.78-.16 4.947h-1zm18 0c0-2.167-.001-3.743-.16-4.947-.158-1.19-.462-1.94-1.014-2.5l.712-.702c.766.778 1.121 1.773 1.293 3.071.17 1.285.169 2.939.169 5.079h-1zM11 2.228c1.417 0 2.615 0 3.625.05l-.05 1c-.98-.05-2.15-.05-3.575-.05v-1zm3.956.901l-1.75 1.777-.713-.702 1.75-1.777.713.702zm-.712 0l-1.75-1.777.712-.702 1.75 1.777-.712.702z"
			></path>
			<path
				stroke="#20AC93"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M7.5 12.5l2 2 5-5"
			></path>
		</svg>
	);
};
