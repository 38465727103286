import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	SypacText,
	SypacTabs,
	SypacButton,
	SypacTabButton,
} from '@sypac/component-library-react';
import { Link } from 'react-router-dom';
import { ProducersProductCard } from '../../components/Producers/ProducersProductCard/ProducersProductCard';
import {
	ProductResponse,
	ProductService,
} from '../../services/product.services';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { OrderTarget } from '../../components/OrdersTable/interfaces/OrderStatus.interface';

const Products: React.FC = () => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [products, setProducts] = useState<ProductResponse>({
		items: [],
		count: 0,
	});
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const getProducts = useCallback(async () => {
		try {
			setIsLoading(true);
			const resp = await ProductService.getList({
				status: currentTab,
				limit: 100,
				offset: 0,
				role: OrderTarget.producer,
			});
			setProducts(resp.data);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	}, [currentTab]);

	useEffect(() => {
		getProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const noProductsText = useMemo(() => {
		const tabTextMap = {
			all: 'noProductsInStoreYet',
			approved: 'noProductsInApproved',
			pending: 'noProductsInPending',
			rejected: 'noProductsInRejected',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	return (
		<div className="h-full w-full flex flex-col gap-4">
			<div className="sm:flex flex-col gap-y-2 lg:grid grid-cols-12">
				<div className="col-span-9">
					<SypacTabs className="box-border">
						<SypacTabButton
							variant="gradient"
							label={t('products.all', 'All')}
							button-id="all"
							onClick={() => setCurrentTab('all')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.approved', 'Approved')}
							button-id="approved"
							onClick={() => setCurrentTab('approved')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.pendingApproval', 'Pending approval')}
							button-id="pending"
							onClick={() => setCurrentTab('pending')}
						/>
						<SypacTabButton
							variant="gradient"
							label={t('products.rejected', 'Rejected')}
							button-id="rejected"
							onClick={() => setCurrentTab('rejected')}
						/>
					</SypacTabs>
				</div>
				<div className="flex justify-end col-start-11 col-span-2">
					<SypacButton>
						<Link
							to="/products/new/groups"
							className="bg-gradient-primary text-white-100 hover:text-white-100 rounded-lg px-6 py-1.25"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="products.addNewProduct">Add new product</T>
								</p>
							</SypacText>
						</Link>
					</SypacButton>
				</div>
			</div>
			<div
				className={`flex flex-col gap-7 ${
					products?.items?.length === 0 ? 'h-full' : ''
				}`}
			>
				{products?.items?.map((item) => {
					return <ProducersProductCard key={Math.random()} data={item} />;
				})}
				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					products?.items?.length === 0 && (
						<div className="flex flex-col justify-center items-center h-full gap-8">
							<SypacText variant="heading-5">
								<p className="text-gray-80">
									<T keyName={`products.${noProductsText}`}>{noProductsText}</T>
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									{currentTab === 'all' ? (
										<T keyName="ordersTable.noProductsInStoreYetDescription">
											Once you add a product, it will be sent for approval
										</T>
									) : null}
								</p>
							</SypacText>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default Products;
