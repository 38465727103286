export const LineStrokeVertical = () => {
	return (
		<svg
			width="1"
			height="19"
			viewBox="0 0 1 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.5 19C0.223857 19 1.37369e-08 18.7761 2.58074e-08 18.5L8.12609e-07 0.5C8.2468e-07 0.223857 0.223858 -3.39261e-08 0.5 -2.18556e-08C0.776143 -9.78508e-09 1 0.223858 1 0.5L0.999999 18.5C0.999999 18.7761 0.776142 19 0.5 19Z"
				fill="url(#paint0_linear_1696_9374)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1696_9374"
					x1="1"
					y1="0"
					x2="0.999999"
					y2="19.3654"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#E8E8E8" stopOpacity="0" />
					<stop offset="0.489583" stopColor="#D1D1D1" />
					<stop offset="1" stopColor="#E8E8E8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
