import React from 'react';
import { SideBarMenu } from './SideBarMenu';
import { SideMenuProps } from './SideBarMenuProps';

export const SideMenu = ({ groups, children }: SideMenuProps) => {
	return (
		<section className="flex flex-col sm:flex-row h-full sm:h-[calc(100vh-60px)] sm:pl-7.5 sm:pt-7.5 sm:pb-[26px] bg-white-200">
			<SideBarMenu groups={groups} />
			{children}
		</section>
	);
};
