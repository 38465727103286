import React from 'react';
import { T } from '@tolgee/react';

const NotFound: React.FC = () => {
	return (
		<div>
			<T keyName="notFound.notFound">NotFound</T>
		</div>
	);
};

export default NotFound;
