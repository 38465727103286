import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacAvatar,
	SypacButton,
	SypacDropdown,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import dayjs from 'dayjs';

import { ProductInterface } from '../../../interfaces/product.interface';
import { EditProduct } from '../../../services/product.services';
import { Geo } from '../../../components/SearchLocation/locations.interface';
import { LocationInput } from '../../../components/ProductLocation/Selectors/LocationInput';
import { useGetProductsTmpl } from '../../../hooks/use-get-products-tmpl';
import { NumericFormat } from 'react-number-format';
import { GeoService } from '../../../services/geo.services';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
	onClose: () => void;
	editProduct: (data: EditProduct) => void;
	product: ProductInterface;
}

const ProductEdit: React.FC<Props> = (props) => {
	const { t } = useTranslate();
	let { onClose, product, editProduct } = props;
	const [address, setAddress] = useState<Geo | undefined>();
	const [products] = useGetProductsTmpl(product.categoryId, 100, 0, 'admin');
	const dropDownRef = useDetectClickOutside({
		onTriggered: () => {
			const elem = document.querySelector('.choices__list--dropdown');
			elem?.classList.add('hidden');
		},
	});

	const isPoland = useMemo(() => {
		return product.countryCode === 'POL';
	}, [product.countryCode]);

	const removeClass = () => {
		const elem = document.querySelector('.choices__list--dropdown');
		elem?.classList.remove('hidden');
	};

	const formik = useFormik({
		initialValues: {
			vat: product.vat,
			markup: product.markup,
			availableQuantity: product.availableQuantity,
			pricePerUnit: product.pricePerUnit,
			name: product.name,
			address: product.address,
			countryCode: product.countryCode,
			sourceProductId: product.sourceProductId,
			lat: product.location?.coordinates[1]!,
			long: product.location?.coordinates[0]!,
			locationId: undefined,
		},
		enableReinitialize: true,
		onSubmit: async (
			values: EditProduct & { locationId?: string; sourceProductId?: number },
		) => {
			let data = {
				pricePerUnit: values.pricePerUnit,
				availableQuantity: values.availableQuantity,
				sourceProductId: values.sourceProductId,
				name: values.name,
				vat: values.vat,
				markup: values.markup,
				address: values.address,
				countryCode: values.countryCode,
				lat: values.lat,
				long: values.long,
			};
			if (values.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: values.locationId,
				});
				data = {
					...data,
					countryCode: resp?.data?.Country,
					lat: resp.data.Latitude,
					long: resp.data.Longitude,
				};
			}
			if (address?.address) {
				const addressLabel = address?.address
					? [
							[address?.address?.street, address?.address?.houseNumber].join(
								' ',
							) || '',
							address?.address.postalCode || '',
							address?.address.city || '',
							address?.address.country || '',
					  ]
							.filter((r) => r)
							.join(', ')
					: '';
				data = {
					...data,
					address: addressLabel,
				};
			}
			editProduct(data);
		},
		validationSchema: Yup.object({
			vat: Yup.number().required('VAT number is required'),
			markup: Yup.number().required('Markup name is required'),
			availableQuantity: Yup.number().required('AvailbleQuantity is required'),
			pricePerUnit: Yup.number().required('Price per unit is required'),
			name: Yup.string().required('Name is required'),
			locationId: Yup.string().required('Address is required'),
			sourceProductId: Yup.number().required('Type is required'),
		}),
	});

	const setLocation = (value: Geo | undefined) => {
		setAddress(value);
		formik.setFieldValue('locationId', value?.locationId || '', true);
		if (!value?.locationId) {
			formik.setTouched({ 'locationId': true });
		}
	};

	const pricePerUnitWithProcent = useMemo(
		() =>
			(
				(formik?.values?.pricePerUnit || 0) +
				((formik?.values?.pricePerUnit || 0) * (formik?.values?.markup || 0) ||
					0) /
					100
			)?.toFixed(2),

		[formik?.values?.markup, formik?.values?.pricePerUnit],
	);

	const getLocationId = useCallback(async () => {
		if (product.address) {
			const { data } = await GeoService.getLocations({
				phrase: product.address,
				maxResults: '10',
			});
			const result = data?.at(0);
			if (result) {
				setAddress(result);
				formik.setFieldValue('locationId', result?.locationId || '', true);
				if (!result?.locationId) {
					formik.setTouched({ 'locationId': true });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product.address]);

	useEffect(() => {
		getLocationId();
	}, [getLocationId]);

	return (
		<div>
			<div className="flex flex-row items-center justify-start pb-6 border-b border-0 border-gray-10 border-solid">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90">
						<T keyName="productDetails.generalProductInformation">
							General product info
						</T>
					</p>
				</SypacText>
			</div>
			<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-6">
				<div className="col-span-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="productDetails.postedBy">Posted by:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-8">
					<div className="flex flex-row items-center">
						<div className="flex">
							<SypacAvatar initials={product?.company?.name} size="sm" />
						</div>
						<div className="flex flex-row ml-5">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">{product?.company?.name}</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
			<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
				<div className="col-span-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="productDetails.createdDate">Created date:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-8">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">
							{dayjs(product.createdAt).format('DD MMM. YYYY')}
						</p>
					</SypacText>
				</div>
			</div>
			<div className="col-span-12 grid grid-cols-12 gap-3 items-center mt-3">
				<div className="col-span-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="productDetails.productId">Product ID:</T>
						</p>
					</SypacText>
				</div>
				<div className="col-span-8">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">P{product.id}</p>
					</SypacText>
				</div>
			</div>

			<form onSubmit={formik.handleSubmit} className="flex flex-col mt-6">
				<div className="pb-6 border-b border-0 border-gray-10 border-solid">
					<div className="col-span-12 grid grid-cols-12 gap-4">
						<div className="col-span-6">
							<SypacInput error={!!(formik.touched.name && formik.errors.name)}>
								<label className="text-xs text-gray-80">
									<T keyName="productEdit.productName">Product name</T>{' '}
									<span className="text-red">*</span>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md text-gray-80 placeholder:text-gray-22"
									name="name"
									type="text"
									placeholder={t(
										'productEdit.enterProductName',
										'Enter Product name',
									)}
									value={formik.values.name}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
						</div>
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.productType">Product type</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacDropdown
								name="sourceProductId"
								placeholder={t(
									'productEdit.selectProductType',
									'Select Product Type',
								)}
								callback={(e) => {
									formik.setFieldValue('sourceProductId', e?.detail?.value);
								}}
								ref={dropDownRef}
								onClick={removeClass}
							>
								{products?.items?.map((product) => {
									const formikValue =
										(typeof formik.values.sourceProductId === 'string'
											? parseInt(formik.values.sourceProductId)
											: formik.values.sourceProductId) || undefined;

									const label = `${product.name || ''}${
										product.type ? `, ${product.type}` : ''
									}${product.size ? `: ${product.size}` : ''}`;
									return (
										// @ts-ignore
										<sypac-dropdown-option
											key={product.id}
											value={product.id}
											label={label}
											selected={product.id === formikValue}
										/>
									);
								})}
							</SypacDropdown>
							{formik.touched.sourceProductId &&
							formik.errors.sourceProductId ? (
								<span className="input-error">
									<T keyName="productEdit.errorProductType">
										{formik.errors.sourceProductId}
									</T>
								</span>
							) : null}
						</div>
					</div>

					<div className="col-span-12 mt-6">
						<SypacText variant="overline-regular-large">
							<p>
								<T keyName="productEdit.selectLocationProduct">
									Store location
								</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<div className="mt-1">
							<LocationInput
								defaultValue={formik.values.address}
								className="py-2.5 pl-3 border rounded-md text-gray-80 placeholder:text-gray-22"
								setLocation={(item) => setLocation && setLocation(item)}
								placeholder={'Enter store location'}
								role={'admin'}
							/>
						</div>
						{formik.touched.locationId && !formik.values.locationId ? (
							<span className="input-error">
								<T keyName="productEdit.errorLocation">
									{formik.errors.locationId}
								</T>
							</span>
						) : null}
					</div>
				</div>

				<div className="py-6 border-b border-0 border-gray-10 border-solid">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 ">
							<T keyName="productDetails.priceAvailability">
								Price & availability
							</T>
						</p>
					</SypacText>
					<div className="col-span-12 grid grid-cols-12 gap-4 mt-6">
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.netPricePerTon">
										Original price (per ton) (excluding VAT)
									</T>
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacInput
								error={
									!!(formik.touched.pricePerUnit && formik.errors.pricePerUnit)
								}
							>
								<div className="flex border-solid border border-gray-22 rounded-md">
									<input
										type="number"
										name="pricePerUnit"
										className="block border-0 py-2.5 text-gray-80 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										placeholder={t(
											'productEdit.enterProductPricePerTon',
											'Enter product price per ton',
										)}
										value={formik?.values.pricePerUnit}
										onChange={(event) => formik?.handleChange(event)}
									/>
									<div className="py-2.5 px-5 text-gray-80 border-left">
										<SypacText
											variant="overline-regular-large"
											className="flex"
										>
											<p>zł</p>
										</SypacText>
									</div>
								</div>
								{formik.touched.pricePerUnit && formik.errors.pricePerUnit ? (
									<span className="bottom-helper">
										<T keyName="productEdit.errorPricePerUnit">
											{formik.errors.pricePerUnit}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.productStock">Product stock</T>
								</p>
							</SypacText>
							<SypacInput>
								<div className="flex border-solid border border-gray-22 rounded-md flex-row items-center bg-gray-10">
									<div className="bg-mountain-meadow h-2 w-2 rounded-sm ml-3" />
									<input
										type="text"
										className="block border-0 py-2.5 text-gray-40 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										value={'Unlimited'}
										disabled={true}
									/>
								</div>
							</SypacInput>
						</div>
					</div>
					<div className="col-span-12 grid grid-cols-12 gap-4 mt-6">
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.availableQuantity">
										Available quantity (tons)
									</T>
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacInput
								error={
									!!(
										formik.touched.availableQuantity &&
										formik.errors.availableQuantity
									)
								}
							>
								<div className="flex border-solid border border-gray-22 rounded-md">
									<input
										type="number"
										name="availableQuantity"
										className="block border-0 py-2.5 text-gray-80 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										placeholder={t(
											'productEdit.enterAvailableQuantity',
											'Enter available quantity',
										)}
										value={formik?.values.availableQuantity}
										onChange={(event) => formik?.handleChange(event)}
									/>
								</div>
								{formik.touched.availableQuantity &&
								formik.errors.availableQuantity ? (
									<span className="bottom-helper">
										<T keyName="productEdit.errorAvailableQuantity">
											{formik.errors.availableQuantity}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.markup">Mark up (%)</T>
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacInput
								error={!!(formik.touched.markup && formik.errors.markup)}
							>
								<div className="flex border-solid border border-gray-22 rounded-md">
									<input
										type="number"
										name="markup"
										className="block border-0 py-2.5 text-gray-80 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										placeholder={t('productEdit.enterMarkup', 'Enter mark up')}
										value={formik?.values.markup}
										onChange={(event) => formik?.handleChange(event)}
									/>
								</div>
								{formik.touched.markup && formik.errors.markup ? (
									<span className="bottom-helper">
										<T keyName="productEdit.errorMarkup">
											{formik.errors.markup}
										</T>
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>
					<div className="col-span-12 grid grid-cols-12 gap-4 mt-6">
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.availableRadius">
										Available radius (km)
									</T>
								</p>
							</SypacText>
							<SypacInput>
								<div className="flex border-solid border border-gray-22 rounded-md bg-gray-10">
									<input
										type="number"
										className="block border-0 py-2.5 text-gray-40 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
										value={100}
										disabled={true}
									/>
								</div>
							</SypacInput>
						</div>
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.vat">VAT (%)</T>
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<SypacInput error={!!(formik.touched.vat && formik.errors.vat)}>
								<div
									className={`flex border-solid border border-gray-22 rounded-md ${
										isPoland ? 'bg-gray-10' : ''
									}`}
								>
									<input
										type="number"
										name="vat"
										className={`block border-0 py-2.5 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1 ${
											isPoland ? 'text-gray-40' : 'text-gray-80'
										}`}
										placeholder={t(
											'productEdit.enterVat',
											'Apply VAT for this product',
										)}
										value={formik?.values.vat}
										disabled={isPoland}
										onChange={(event) => formik?.handleChange(event)}
									/>
								</div>
								{formik.touched.vat && formik.errors.vat ? (
									<span className="bottom-helper">
										<T keyName="productEdit.errorVat">{formik.errors.vat}</T>
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>
					<div className="col-span-12 grid grid-cols-12 gap-4 mt-6">
						<div className="col-span-6">
							<SypacText variant="overline-regular-large" className="mb-1">
								<p className="text-gray-80">
									<T keyName="productEdit.displayPriceInStore">
										Display price in the store (VAT excluded)
									</T>
								</p>
							</SypacText>
							<SypacText variant="heading-5" className="mt-3">
								<p className="text-gray-80 font-medium">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(
											pricePerUnitWithProcent.replace(',', '.') || '0',
										)}
										suffix={' zł'}
									/>
								</p>
							</SypacText>
						</div>
						<div className="col-span-6"></div>
					</div>
				</div>

				<div className="flex gap-4 mt-6 flex-row justify-center">
					<SypacButton variant="secondary" size="small">
						<button
							className="border-red text-red px-5 py-[5px]"
							onClick={onClose}
						>
							<SypacIcon
								iconName="Close Circle"
								size="custom"
								width="24px"
								height="24px"
							/>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="productDetails.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small">
						<button
							type="submit"
							className="border-mountain-meadow text-mountain-meadow px-5 py-[5px]"
						>
							<SypacIcon
								iconName="Check Circle"
								size="custom"
								width="24px"
								height="24px"
							/>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="productDetails.save">Save</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</div>
	);
};

export default ProductEdit;
