import React, { memo } from 'react';
import CompanyFleet from '../../../components/CompanyFleet/CompanyFleet';
import { useGetFeet } from '../../../hooks/use-get-fleet';
import { MutatingDots } from 'react-loader-spinner';

interface Props {
	companyId: number | undefined;
}

export const FleetTab: React.FC<Props> = memo(({ companyId }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [data, _, isLoading] = useGetFeet({
		companyId: companyId,
		target: 'admin',
	});

	return !isLoading ? (
		<CompanyFleet data={data} />
	) : (
		<div className="flex w-full h-full items-center justify-center">
			<MutatingDots
				height="100"
				width="100"
				color="#7693F4"
				secondaryColor="#494C83"
				radius="12.5"
				ariaLabel="mutating-dots-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</div>
	);
});
