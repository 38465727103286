import React from 'react';
import { SypacText } from '@sypac/component-library-react';

const TermsAndConditionsPartnersPl = () => {
	return (
		<div>
			<div>
				<SypacText variant="body-regular-medium">
					<span className="text-gray-90">
						Zgodnie z niniejszym ogólnymi warunkami używania i notami prawnymi
						(dalej zwanymi „
					</span>
					<span className="text-gray-90 font-bold">
						Warunkami Współpracy Partnerskiej
					</span>
					<span className="text-gray-90">
						”), spółka Sypac Spółka z ograniczoną odpowiedzialnością, z siedzibą
						w Warszawie (dalej zwana with „
					</span>
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						”) udostępnia Partnerom (dalej zwanym „
					</span>
					<span className="text-gray-90 font-bold">PARTNEREM</span>
					<span className="text-gray-90">” lub „</span>
					<span className="text-gray-90 font-bold">PARTNERAMI</span>
					<span className="text-gray-90">
						”) stronę internetową o nazwie domeny www.sypac.app wraz z aplikacją
						mobilną Sypac (dalej łącznie zwanymi „
					</span>
					<span className="text-gray-90 font-bold">APLIKACJĄ</span>
					<span className="text-gray-90">” lub „</span>
					<span className="text-gray-90 font-bold">APLIKACJĄ SYPAC</span>
					<span className="text-gray-90">").</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Niniejsze Ogólne Warunki Współpracy Partnerskiej odnoszą się do
						używania APLIKACJI przez każdego PARTNERA, a także wszystkich
						powiązanych witryn lub stron, do których
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">
						przekierowuje za pośrednictwem linków udostępnionych w{' '}
					</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI</span>
					<span className="text-gray-90">
						. Ogólne Warunki Współpracy Partnerskiej mają zastosowanie do
						wszystkich użytkowników
					</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI</span>{' '}
					<span className="text-gray-90">działających w imieniu</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
					<span className="text-gray-90">
						(w szczególności pracowników, wykonawców, podwykonawców oraz osób
						świadczących usługi na mocy umów cywilnoprawnych),
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNER</span>{' '}
					<span className="text-gray-90">
						przyjmuje odpowiedzialność za działania wskazanych osób na takich
						samych warunkach, jak gdyby to były działania podejmowane przez
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERA. APLIKACJA</span>{' '}
					<span className="text-gray-90">stanowi własność</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						{' '}
						Jakiekolwiek jej dalsze używanie stanowi akceptację niniejszych
						warunków używania. Użytkownicy (
					</span>
					<span className="text-gray-90 font-bold">PARTNERZY</span>
					<span className="text-gray-90">
						), którzy nie dokonają akceptacji powyżej wspomnianych warunków,
						powinni powstrzymać się od używania
					</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI</span>
					<span className="text-gray-90">.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<p className="text-gray-90">
						Na potrzeby niniejszych Warunków Współpracy Partnerskiej, poniżej
						wymienione wyrażenia pisane dużą literą mają przypisane im poniżej
						znaczenie:
					</p>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						{' '}
						1. DOSTAWCY USŁUG TRANSPORTOWYCH:
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90 font-bold">PARTNERZY</span>{' '}
					<span className="text-gray-90">
						odpowiedzialni za przewóz towarów zamówionych przez odbiorcę od
						PARTNERÓW będących producentami towarów; na potrzeby interpretacji
						niniejszych Warunków Współpracy Partnerskiej, niniejszym wyklucza
						się interpretację pojęcia DOSTAWCA w rozumieniu postanowień Kodeksu
						Cywilnego,
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90 font-bold"> 2. SPRZEDAJĄCY:</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90 font-bold">PARTNERZY</span>
					<span className="text-gray-90">
						, których działalność obejmuje wytwarzanie lub dostawę oferowanych
						towarów, bez względu na to czy takie towary są wytwarzane
						bezpośrednio przez nich czy prowadzą dalszą sprzedaż towarów
						otrzymanych od innych podmiotów za pośrednictwem
					</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI</span>
					<span className="text-gray-90">.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90 font-bold">
						3. Usługi oferowane w APLIKACJI:
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">(dalej zwane „</span>
					<span className="text-gray-90 font-bold">
						USŁUGAMI KUP I SPRZEDAJ
					</span>
					<span className="text-gray-90">
						"): usługi technologiczne przeznaczone do oferowania w ramach „Kup i
						Sprzedaj”, a także do udostępniania produktów i/lub usług
						oferowanych przez
					</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
					<span className="text-gray-90"> za pośrednictwem</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI SYPAC</span>{' '}
					<span className="text-gray-90">oraz dostarczane Klientom</span>{' '}
					<span className="text-gray-90 font-bold">APLIKACJI SYPAC</span>{' '}
					<span className="text-gray-90">
						za pośrednictwem przewoźników, którzy również świadczą usługi za
						pośrednictwem
					</span>{' '}
					<span className="text-gray-90 font-bold">
						SYPAC - DOSTAWCÓW USŁUG TRANSPORTOWYCH.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90">Firma:</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<p className="text-gray-90">
						Sypac Spółka z ograniczoną odpowiedzialnością.
					</p>
					<p className="text-gray-90">Siedziba: Warszawa, Polska. </p>
					<p className="text-gray-90">
						Adres: ul. Filtrowa 65 lokal 45, 02-055 Warszawa
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						SYPAC i PARTNERZY (w tym również SPRZEDAJĄCY i DOSTAWCY) są w
						dalszej części niniejszych Warunków Współpracy Partnerskiej zwani „
					</span>
					<span className="text-gray-90 font-bold">Stronami</span>
					<span className="text-gray-90">” lub osobno „</span>
					<span className="text-gray-90 font-bold">Stroną</span>
					<span className="text-gray-90">”.</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Dane rejestracyjne zawarte w rejestrze przedsiębiorców: spółka
						została wpisana do rejestru przedsiębiorców Krajowego Rejestru
						Sądowego prowadzonego przez sąd rejonowy dla m.st. Warszawy, XII
						Wydział Gospodarczy Krajowego Rejestru Sądowego, za numerem KRS
						0001014419, kapitał zakładowy 5 000,00 zł, REGON: 524266784.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Numer Identyfikacji Podatkowej (NIP): 7011126139.
					</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						Niniejsze Warunki Współpracy Partnerskiej regulują również kwestie
						dotyczące stosunku prawnego pomiędzy SYPAC a wszystkimi PARTNERAMI
						odpowiedzialnymi za wytwarzanie towarów, ich dostawę, a także
						odpowiedzialnymi za ich dostawę do odbiorców końcowych, chyba że
						zastosowanie określonych postanowień do
					</span>{' '}
					<span className="text-gray-90 font-bold">
						DOSTAWCÓW USŁUG TRANSPORTOWYCH
					</span>{' '}
					<span className="text-gray-90">lub</span>{' '}
					<span className="text-gray-90 font-bold">SPRZEDAJĄCYCH</span>{' '}
					<span className="text-gray-90">
						jest wyraźnie wykluczone, lub wyraźnie wskazano, że postanowienie ma
						zastosowanie wyłącznie do
					</span>{' '}
					<span className="text-gray-90 font-bold">
						DOSTAWCÓW USŁUG TRANSPORTOWYCH
					</span>{' '}
					<span className="text-gray-90">lub</span>{' '}
					<span className="text-gray-90 font-bold">PARTNERÓW.</span>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">I. CEL:</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ogólnym celem niniejszych Warunków Współpracy Partnerskiej jest
								zapewnienie ram prawnych (pod względem obowiązkowego stosunku
								prawnego powstałego pomiędzy SYPAC a PARTNEREM) w zakresie
								dostarczania produktów lub usług świadczonych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								na rzecz SYPAC, oferowanych przez SYPAC za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">Aplikacji SYPAC</span>
							<span className="text-gray-90">, który:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">
										świadczy usługi zgodnie z procedurą „Kup i Sprzedaj” w
										związku z pozyskiwaniem potencjalnych klientów, zawieraniem
										umów pomiędzy tymi klientami a SYPAC, oraz przetwarzaniem
										płatności i świadczeniem innych usług związanych ze
										sprzedażą i dostarczaniem produktów,
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										rozstrzyga kwestie dotyczące zdarzeń związanych z
										zamówieniami.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">W odniesieniu do</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERÓW</span>
							<span className="text-gray-90">, którzy korzystają z</span>{' '}
							<span className="text-gray-90 font-bold">
								USŁUG KUP I SPRZEDAJ, SYPAC:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">
										w żadnym razie nie ponosi odpowiedzialności za usługi
										przewozowe świadczone przez
									</span>{' '}
									<span className="text-gray-90 font-bold">
										PARTNERA – DOSTAWCĘ USŁUG TRANSPORTOWYCH, natomiast
										całkowita wynikająca z tego odpowiedzialność zostanie w
										pełni przyjęta przez PARTNERA;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										zobowiązuje się umożliwiać składanie zamówień Klientom
										APLIKACJI, w celu ustanowienia kontaktów z
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCAMI USŁUG TRANSPORTOWYCH
									</span>
									<span className="text-gray-90">
										, zapewniając odpowiedni moduł chatu w APLIKACJI.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								Stosunek prawny wynikający z niniejszych Warunków Współpracy
								Partnerskiej stanowi wyłącznie stosunek biznesowy pomiędzy
								niezależnymi osobami, które zobowiązują się zawsze funkcjonować
								na rynku jako niezależne podmioty, bez powodowania mylnego
								zrozumienia relacji pomiędzy usługami świadczonymi przez każdego
								z nich. Strony niniejszym zobowiązują się współpracować w dobrej
								wierze nad projektami, które wymagają takiej współpracy. Strony
								mogą wprowadzić zmiany do niniejszych Warunków Współpracy
								Partnerskiej w drodze obustronnego porozumienia w dowolnym
								terminie, poprzez przystąpienie do pisemnego aneksu. W razie
								wprowadzenia zmiany do niniejszych Warunków Współpracy
								Partnerskiej, postanowienia wprowadzone po tym terminie mają
								pierwszeństwo wobec postanowień aneksu.
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								Strona ponosi wyłączną odpowiedzialność za działania podejmowane
								przez jej pracowników w związku z realizacją niniejszych
								Warunków Współpracy Partnerskiej oraz za wykonanie zobowiązań
								przyjętych przez nią na mocy niniejszych Warunków. Na potrzeby
								niniejszych Warunków Współpracy Partnerskiej, pracownik, o
								którym mowa w zdaniu powyżej, oznacza wykonawców, podwykonawców
								oraz osoby świadczące usługi na mocy umowy cywilnoprawnej.
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNERZY</span>{' '}
							<span className="text-gray-90">
								zobowiązują się chronić i zwolnić SYPAC z odpowiedzialności za
								szkody spowodowane w związku z prowadzoną przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								sprzedażą towarów lub usług nabywanych lub zamawianych od
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>
							<span className="text-gray-90">
								, które nie spełniają uprzednio uzgodnionych standardów jakości
								lub nie są należycie świadczone przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA.</span>{' '}
							<span className="text-gray-90">
								Powyższe ma również zastosowanie do kosztów roszczeń
								gwarancyjnych zgłaszanych przez klientów będących osobami
								fizycznymi.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								odstępuje od odpowiedzialności z tytułu gwarancji w stosunkach
								pomiędzy klientami a przedsiębiorcami.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						II. DATA I OKRES OBOWIĄZYWANIA USŁUG
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się utrzymać swój profil w APLIKACJI w terminach
								uzgodnionych z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								przez okres DWUDZIESTU (12) miesięcy od daty akceptacji
								niniejszych Warunków Współpracy Partnerskiej, który to okres
								zostanie przedłużony na czas nieoznaczony na kolejne okresy
								DWUDZIESTU MIESIĘCY. Wygaśnięcie okresów korzystania z
								APLIKACJI, o których mowa w zdaniu powyżej, ma skutek na koniec
								miesiąca.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli którakolwiek ze Stron zechce rozwiązać wzajemne stosunki,
								ma obowiązek przekazać drugiej Stronie pisemne wypowiedzenie z
								zachowaniem przynajmniej JEDNOMIESIĘCZNEGO wyprzedzenia.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli SYPAC zechce wprowadzić zmianę do niniejszych Warunków
								Współpracy Partnerskiej, SYPAC ma obowiązek zawiadomić
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								z użyciem korespondencji przesłanej pocztą elektroniczną lub za
								pośrednictwem APLIKACJI i ja każdej zmianie na przynajmniej
								JEDEN MIESIĄC przed datą wejścia takiej zmiany w życie. Jeżeli
								powyżej określony okres wygaśnie bez zgłoszenia sprzeciwu ze
								strony
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>
							<span className="text-gray-90">
								, treść zgłoszonej zmiany będzie wiążąca wobec Stron i
								pozostanie w mocy przez okres obowiązywania umowy. Nieprzerwany
								dostęp do oraz używania APLIKACJI przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								po dacie wejścia takiej modyfikacji w życie będzie uznawane za
								akceptację modyfikacji przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">. Wszelkie zmiany, jakie</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								może zechcieć wprowadzić do niniejszych Warunków Współpracy
								Partnerskiej, w żadnym wypadku nie mają skutku wstecznego, chyba
								że
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								ma obowiązek przestrzegać stosownych regulacji prawnych lub
								takie zmiany okażą się korzystne dla
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">może złożyć skargę do</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, jeżeli nie zgadza się z przesłankami, na podstawie których
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								ogranicza, zawiesza lub eliminuje jego produkty lub usługi. Po
								otrzymaniu skargi, wszczynana jest procedura wewnętrzna, która
								podlega monitoringowi, przeprowadzone zostanie dochodzenie i
								wydane zostanie rozstrzygnięcie przez zespół powołany przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, przy założeniu, że odpowiedzialność za przeprowadzenie
								dochodzenia, wydanie rozstrzygnięcia i przekazanie informacji na
								temat wydanego postanowienia w stosownym terminie będzie w
								każdym przypadku odbywać się zgodnie z procedurą dostosowaną do
								danego przypadku oraz w uproszczony sposób.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">III. WARUNKI FINANSOWE</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">nabywa towary od</span>{' '}
							<span className="text-gray-90 font-bold">SPRZEDAJĄCYCH</span>{' '}
							<span className="text-gray-90">
								po uzgodnionej cenie (wyliczonej jako iloczyn ceny i ilości). W
								związku z dokonywaniem odsprzedaży tych towarów na Platformie
								SYPAC,
							</span>{' '}
							<span className="text-gray-90 font-bold">SPRZEDAJĄCY</span>{' '}
							<span className="text-gray-90">
								również wyrażają zgodę, aby, w przypadku gdy udostępniają
								oferowane przez nich towary na Platformie SYPAC, cena oraz
								dostępność zostały określone przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC. SYPAC</span>{' '}
							<span className="text-gray-90">
								dokona niezależnego (chyba że zawarta zostanie umowa, o której
								mowa w punkcie 3 poniżej) ustalenia marży od towarów oraz
								udostępni towary po cenie z marżą na Platformie SYPAC. W
								momencie składania przez Klienta zamówienia na Platformie SYPAC,
								pomiędzy PRODUCENTEM a SYPAC zawierana jest umowa sprzedaży
								określonej ilości towarów określonej przez Klienta, po cenie
								wskazanej na profilu PRODUCENTA w momencie składania zamówienia.
								Płatność za towary zostanie dokonana zgodnie z postanowienia
								punktu 5 poniżej.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Należność za korzystanie z usług transportowych świadczonych
								przez
							</span>{' '}
							<span className="text-gray-90 font-bold">
								DOSTAWCÓW USŁUG TRANSPORTOWYCH
							</span>{' '}
							<span className="text-gray-90">
								obejmuje kwotę zmienną wyliczoną z użyciem różnych czynników,
								takich jak obecność na terytorium, cena paliwa, odległość lub
								wolumen zamówienia, i jest wyliczona zgodnie ze wzorem: cena
								stała + stawka zmienna za km * odległość w kilometrach (dalej
								zwana „
							</span>
							<span className="text-gray-90 font-bold">
								Opłatą za Transport
							</span>
							<span className="text-gray-90">
								"). Stawka zmienna za kilometr oraz cena stała są określane
								przez DOSTAWCĘ USŁUG TRANSPORTOWYCH na jego profilu na
								Platformie SYPAC.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Strony mogą podjąć uzgodnienia w celu ustalenia współpracy na
								wyłączność w zakresie dozwolonym na mocy obowiązujących
								przepisów prawa. W razie braku takich uzgodnień, zakłada się, że
								zasada wyłączności nie została zastrzeżona.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W ramach uzgodnień podjętych w przedmiocie współpracy na
								wyłączność,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC PARTNER</span>{' '}
							<span className="text-gray-90">i</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								mogą ustalić specjalne warunki sprzedaży towarów lub usług
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>
							<span className="text-gray-90">
								, w szczególności obniżyć marżę, w celu zwiększenia popularności
								towarów
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								oraz zastrzec kary umowne w razie nieprzestrzegania uzgodnień w
								przedmiocie współpracy na wyłączność.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								dokonuje wszystkich rozliczeń z Klientami w przedmiocie
								realizowanych zamówień, wyłącznie z zastosowaniem systemu
								płatności zapewnionego za pośrednictwem
							</span>{' '}
							<span className="text-gray-90 font-bold">APLIKACJI; SYPAC</span>{' '}
							<span className="text-gray-90">dokonuje przelewu wpływów</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERÓW</span>{' '}
							<span className="text-gray-90">(dalej zwanych „</span>
							<span className="text-gray-90 font-bold">Należnością</span>
							<span className="text-gray-90">
								") zgodnie z następującymi postanowieniami: (i) z tytułu całej
								kwoty zysków wygenerowanych ze sprzedaży po odliczeniu zwrotów w
								okresie od 1-szego do 15-tego dnia każdego miesiąca włącznie,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								dokona płatności tytułem Należności w terminie
								nieprzekraczających trzech (3) dni roboczych od 5-tego dnia
								kolejnego miesiąca, oraz (ii) z tytułu całej kwoty zysków
								wygenerowanych ze sprzedaży po odliczeniu zwrotów w okresie od
								16-tego do ostatniego dnia miesiąca włącznie,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								dokona płatności tytułem Należności w terminie
								nieprzekraczającym trzech (3) dni roboczych od 20-tego dnia
								kolejnego miesiąca.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W przypadku niezrealizowania dostawy lub niekompletnej dostawy
								produktu lub usługi z przyczyn powstałych po stronie
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA, PARTNER</span>{' '}
							<span className="text-gray-90">
								ma obowiązek pokryć w pełni koszty (w tym również podatki) ceny
								niezrealizowanej dostawy produktu lub usługi, wraz z podatkiem
								naliczonym za niezrealizowaną dostawę lub niekompletną dostawę
								produktu lub usługi, które
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">ma obowiązek zapłacić</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">IV. OBOWIĄZKI PARTNERA</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">zobowiązuje się:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">przekazać</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										wszystkie wymagane informacje oraz dane dla każdego z jego
										produktów lub usług w formie wymaganej przez
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										oraz zapewnić odpowiednie ich aktualizacje.
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										zobowiązuje się ponosić wyłączną odpowiedzialność za
										przekazywanie takich informacji Klientom
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, a także chronić i zwolnić
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										z wszelkiej odpowiedzialności, jaką może ponieść z tytułu
										powstałych szkód. W każdym przypadku opis takich produktów
										lub usług widniejący w APLIKACJI będzie zawsze zgodny z
										informacjami przekazanymi przez
									</span>{' '}
									<span className="text-gray-90 font-bold">
										PARTNERA. PARTNER
									</span>{' '}
									<span className="text-gray-90">
										przyjmuje odpowiedzialność za wszelkie odstępstwa powstałe
										pomiędzy opisem widniejącym w APLIKACJI a produktem lub
										usługą faktycznie oferowaną przez
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										(takie jak, na przykład, różnice pomiędzy ceną oferowaną w
										APLIKACJI oraz ceną oferowaną w punkcie); w związku z
										powyższym
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										wszelkie koszty powstałe wskutek takich różnic czy
										rozbieżności.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">bezzwłocznie zgłaszać</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, z użyciem APLIKACJI, każdy przypadek niedostępności,
										zmiany informacji, o których mowa w podpunkcie (a) lub brak
										na stanie produktów lub usług oferowanych za pośrednictwem
										APLIKACJI. Jeżeli
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">nie przekaże</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										stosownych informacji na temat towarów lub usług, które
										posiada na stanie, dokona zmiany informacji zawartych w
										punkcie (a) na temat jego godzin otwarcia lub godzin
										zamknięcia lub odpowiednio godzin otwarcia lub godzin
										zamknięcia punktów sprzedaży, lub informacji na temat innych
										istotnych sprawach, wskutek czego
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										przekaże klientom niedokładne informacje w rezultacie
										zaniedbania po stronie
									</span>{' '}
									<span className="text-gray-90 font-bold">
										PARTNERA, PARTNER
									</span>{' '}
									<span className="text-gray-90">
										przyjmuje w takim przypadku odpowiedzialność za szkody
										spowodowaną w związku z powyższym Klientowi.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										utrzymywać ceny oraz aktualizować dostępność towarów
										oferowanych za pośrednictwem APLIKACJI, w szczególności
										jeżeli
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										nie posiada na stanie towarów, aby zrealizować zamówienie.
										Niewykonanie obowiązku do aktualizacji cen stanowi
										zobowiązanie do realizacji zamówień po cenach przekazanych w
										danym terminie.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">akceptować, że</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										dokonuje płatności na rzecz PARTNERA jedynie w drodze
										przelewu bankowego wykonanego zgodnie z mechanizmem
										split-payment, zgodnie z punktem III.5 powyżej.{' '}
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										utrzymywać aktywny status usług oraz zapewnić dostępność
										usług Klientom APLIKACJI przez czas nie krótszy niż 95%
										normalnych godzin prowadzenia działalności przez jego sklepy
										lub FOLDERY. f) przyjmować zamówienia w końcowym punkcie
										sprzedaży (aplikacji) w momencie wskazanym w zamówieniu, po
										otrzymaniu zawiadomienia, a także podejmować działania
										mające na celu kompletną realizację zamówienia.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										przygotowywać i dostarczać (w przypadku DOSTAWCÓW USŁUG
										TRANSPORTOWYCH) zamówienia przetwarzane za pośrednictwem
									</span>{' '}
									<span className="text-gray-90 font-bold">
										APLIKACJI SYPAC
									</span>{' '}
									<span className="text-gray-90">
										wyłącznie we własnym przedsiębiorstwie, w celu zapewnienia
										spełnienia wymogów mających zastosowanie do higieny i
										zdrowia publicznego, zgodnie z niniejszym Warunkami
										Współpracy Partnerskiej, a także w celu spełnienia
										uzgodnionych pomiędzy Stronami kryteriów mających
										zastosowanie do wydajności i logistyki.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										przekazywać prawdziwe informacji na temat rachunku bankowego
										i pokrywać koszty, jakie mogą powstać wskutek przekazania
										nieprawdziwych informacji na temat rachunku bankowego.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										może proponować, natomiast
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										może akceptować udział w kampaniach promocyjnych oraz innych
										działaniach mających na celu wsparcie pozycjonowania i
										widoczności
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										w APLIKACJI. Takie działania będą regulowane i będą podlegać
										warunkom uzgodnionym przez Strony.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										udzielać odpowiedzi za pośrednictwem APLIKACJI na skargi,
										zażalenia oraz zawiadomienia złożone i przekazane przez
										Klientów – zamawiających w terminie 2 dni roboczych od daty
										otrzymania takiej skargi, zażalenia lub zawiadomienia;
										nieudzielenie odpowiedzi zgodnie z powyższym będzie
										skutkować wstrzymaniem płatności za zamówienie, którego
										dotyczy skarga, chyba że stosowna kwota została już
										przekazana PARTNEROWI;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										wyrażać zgodę, że akceptacja zamówień odbywa się
										automatycznie (postanowienie to ma zastosowanie wyłącznie do
									</span>{' '}
									<span className="text-gray-90 font-bold">SPRZEDAJĄCYCH</span>
									<span className="text-gray-90">
										), z zastrzeżeniem że SPRZEDAJĄCY może odmówić dostawy, w
										przypadku gdy wybrany produkt nie będzie na stanie; w takiej
										sytuacji PARTNER ma również obowiązek zapewnić aktualizację
										informacji na temat dostępności towarów;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										akceptować lub odmówić przyjęcie oferty na dostawę, w którym
										to przypadku odmowa wymaga wypełnienia formularza odmowy
										(wskazanie obiektywnych przyczyn) w APLIKACJI, natomiast po
										przyjęciu zamówienia, zrealizować dostawę zawsze we
										wskazanym terminie (postanowienie to ma zastosowanie
										wyłącznie do
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCÓW USŁUG TRANSPORTOWYCH
									</span>
									<span className="text-gray-90">).</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>

				<div className="flex flex-row mt-5 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W odniesieniu do usług KUP i SPRZEDAJ,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">ma również obowiązek:</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium" className="mt-5">
									<span className="text-gray-90">
										przygotować zamówienie w najszybszym możliwym terminie, z
										zachowaniem najwyższych standardów ostrożności i wydajności;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										przygotować towary wskazane w zamówieniu w terminie
										określonym w zakładce zamówienia w APLIKACJI (ma
										zastosowanie wyłącznie do
									</span>{' '}
									<span className="text-gray-90 font-bold">SPRZEDAJĄCYCH</span>
									<span className="text-gray-90">
										). Dostawa towarów będzie odbywać się w terminie wskazanych
										w zakładce zamówienia w APLIKACJI, po potwierdzeniu przez
										PARTNERA (ma zastosowanie wyłącznie do
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCÓW USŁUG TRANSPORTOWYCH). SYPAC
									</span>{' '}
									<span className="text-gray-90">
										dokona oszacowania czasu realizacji dostawy, a także
										przekazanych informacji w przedmiocie czasu i sposobu
										realizacji zamówienia przez
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCÓW USŁUG TRANSPORTOWYCH.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">
										DOSTAWCA USŁUG TRANSPORTOWYCH
									</span>{' '}
									<span className="text-gray-90">
										ponosi wyłączną odpowiedzialność za określenie opłat za
										realizację dostawy, natomiast
									</span>{' '}
									<span className="text-gray-90 font-bold">SPRZEDAJĄCY</span>{' '}
									<span className="text-gray-90">
										ponosi wyłączną odpowiedzialność za określenie cen za
										oferowane przez niego produkty.
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">zapewnia</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, że produkty sprzedawane za pośrednictwem APLIKACJI
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										oraz opłata za dostawę zastosowana przez
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										nie będą oferowane Klientom
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										z zastosowaniem stawek wyższych niż stawki oferowane przez
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										odpowiednio na innych platformach, w jego punktach sprzedaży
										lub na jego platformie (która to zasada jest dalej zwana „
									</span>
									<span className="text-gray-90 font-bold">
										Modelem Parytetowym
									</span>
									<span className="text-gray-90">").</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCA USŁUG TRANSPORTOWYCH
									</span>{' '}
									<span className="text-gray-90">
										ma obowiązek zapewnić możliwość śledzenia dostawy z użyciem
									</span>{' '}
									<span className="text-gray-90 font-bold">
										Aplikacji SYPAC.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">SPRZEDAJĄCY</span>{' '}
									<span className="text-gray-90">
										ma obowiązek przekazać (w przypadku gdy określony towar
										wymaga specjalnych warunków przewozowych, np. odpowiedniej
										temperatury lub wilgotności)
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCOM USŁUG TRANSPORTOWYCH
									</span>{' '}
									<span className="text-gray-90">
										wymagania mające zastosowanie do obchodzenia się z towarami,
										aby zapewnić, by produkty były dostarczane Klientom w
										odpowiednim stanie oraz zgodnie z wymaganymi warunkami.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90 font-bold">
										DOSTAWCA USŁUG TRANSPORTOWYCH
									</span>{' '}
									<span className="text-gray-90">
										zapewni, aby jego pracownicy, wspólnicy lub podwykonawcy
										świadczący usługę dostawy na wskazany adres:
									</span>
									<p className="text-gray-90">
										a) posiadali wymagane prawo jazdy;
									</p>
									<p className="text-gray-90">
										b) posiadali obowiązującą polisę ubezpieczenia auto-casco,
										jeżeli ma zastosowanie, a także polisę ubezpieczeniową
										dotyczącą przewozu towarów;
									</p>
									<p className="text-gray-90">
										c) zostali objęci przez{' '}
										<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
										minimalną ochroną (np. ubezpieczenie od następstw
										nieszczęśliwych wypadków);
									</p>
									<p className="text-gray-90">
										d) dokonywali regularnych płatności tytułem zobowiązań
										podatkowych oraz zobowiązań na poczet składak na
										ubezpieczenie społeczne, oraz zostaną wyposażeni lub zostaną
										zobowiązani do posiadania urządzenia (np. smartfona), które
										umożliwi zainstalowanie APLIKACJI.
									</p>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Odpowiedzialność za wszystkie zdarzenia dotyczące realizacji
										zamówienia spoczywa na
									</span>{' '}
									<span className="text-gray-90 font-bold">
										DOSTAWCY USŁUG TRANSPORTOWYCH
									</span>
									<span className="text-gray-90">
										, natomiast odpowiedzialność za zdarzenia dotyczące jakości
										produktu spoczywa na
									</span>{' '}
									<span className="text-gray-90 font-bold">SPRZEDAJĄCYM.</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										używać APLIKACJI z zachowaniem ostrożności i powstrzymać się
										od bezpośredniego lub pośredniego promowania dostawy
										produktów lub świadczenia usług, które nie są dozwolone na
										mocy prawa.
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										nie ponosi odpowiedzialności za straty lub szkody, które
										mogą powstać wskutek używania APLIKACJI przez
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										w sposób niezgodny z prawem lub Warunkami Współpracy
										Partnerskiej. W szczególności
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										nie przyjmuje jakiejkolwiek odpowiedzialności za używanie
										APLIKACJI ani za przetwarzanie zamówień, które zawierają
										materiały o ograniczonych opcjach dostawy. Bez względu na
										powyższe postanowienia
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										zastrzega sobie prawo do wypowiedzenia umowy oraz ubiegania
										się o stosowną kompensację od
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>
									<span className="text-gray-90">
										, w razie powzięcia informacji na temat działań prowadzonych
										przez
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
									<span className="text-gray-90">
										, które okażą się niezgodne z prawem lub będą naruszać
										Warunki Współpracy Partnerskiej oraz warunki umowy.
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">
										może w związku z powyższym ustanowić konieczne filtry, w
										celu uniemożliwienia dostarczania pewnych produktów o
										ograniczonych opcjach dostawy do Klientów za pośrednictwem
										APLIKACJI.
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										używać poniżej opisany mechanizm odwołania zamówienia oraz
										zobowiązuje się go stosować:
									</span>{' '}
									<span className="text-gray-90 font-bold">PARTNER</span>{' '}
									<span className="text-gray-90">
										nie otrzyma kwoty zamówienia złożonego za pośrednictwem
										Aplikacji
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>
									<span className="text-gray-90">
										, w sytuacji gdy zamówienie zostanie odwołane przez Klienta
										przed terminem wskazanym SPRZEDAJĄCEMU przez pracownika
										DOSTAWCY USŁUG TRANSPORTOWYCH na odebranie zamówienia lub w
										terminie 24 godzin od momentu złożenia zamówienia (chyba że
										czas realizacji zamówienia nie przekroczy 24 godzin). Klient
										(który złożył zamówienie a następnie je odwołał) otrzyma w
										takiej sytuacji zwrot wpłaconych środków, w terminie 48
										godzin do momentu odwołania zamówienia. Odwołanie zamówienia
										jest jednoznaczne z wypowiedzeniem umów sprzedaży i dostawy
										zawartych na danym koncie.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								APLIKACJA zapewnia odrębne funkcje dla DOSTAWCÓW USŁUG
								TRANSPORTOWYCH i SPRZEDAJĄCYCH, w przypadku gdy PARTNER wyrazi
								pragnienie do pełnienia obu funkcji, w związku z powyższym ma
								obowiązek utrzymać działalność w APLIKACJI w obu funkcjach.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ze względu na charakter rynku, na którym działają SYPAC i
								PARTNERZY, SPRZEDAJĄCY akceptują zamówienia otrzymane wcześniej,
								z zastrzeżeniem że SPRZEDAJĄCY może odmówić przyjęcia realizacji
								zamówienia, jeżeli okaże się, że wybrany produkt jest
								niedostępny (postanowienie to nie ma zastosowania do piasku i
								żwiru), natomiast DOSTAWCY USŁUG TRANSPORTOWYCH mają obowiązek
								przyjąć takie zamówienie w APLIKACJI do realizacji.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">V. POZOSTAŁE POSTANOWIENIA</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Akceptując niniejsze Warunki,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się wykupić ubezpieczenie. Taka polisa
								ubezpieczeniowa zostanie wystawiona przez cieszące się uznaniem
								towarzystwo ubezpieczeniowe. Polisa taka nie może być
								unieważniona, natomiast zastrzeżona w niej suma ubezpieczenia
								nie może ulec istotnemu obniżeniu. Na żądanie
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA SYPAC</span>{' '}
							<span className="text-gray-90">
								zapewni potwierdzenie wykupienia ochrony ubezpieczeniowej
								zgodnie z wymogami określonymi w niniejszych Warunkach. W żadnym
								razie limity określone w polisie ubezpieczeniowej nie będą
								uznawane za ograniczenie odpowiedzialności
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								wynikającej z niniejszych Warunków Współpracy Partnerskiej.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się nie działać w sposób, który w jakikolwiek sposób
								zaszkodziłby wizerunkowi
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								lub jego reputacji, oraz nie będzie używać marki
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">ani marki</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>
							<span className="text-gray-90">
								, aby publicznie podawać się za podmiot stowarzyszony z użyciem
								dowolnych środków stosowanych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">oraz</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								w celu prowadzenia własnych działań promocyjnych i pozyskania
								klientów.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">zobowiązuje się informować</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								o wszelkich zmianach w warunkach używania APLIKACJI w
								odniesieniu do Klientów, którzy składają zamówienia na towary za
								pośrednictwem Platformy SYPAC. W sytuacji gdy
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								podejmie, bezpośrednio lub pośrednio, jakiekolwiek działania,
								które będą mogły zaszkodzić wizerunkowi i reputacji
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								(takie jak przekazywanie podmiotom konkurencyjnym informacji na
								temat
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								i działalności prowadzonej przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, wspieranie innych platform, z użyciem marki
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, np. podczas działań promocyjnych, w ulotkach oraz używanie
								innych materiałów informacyjnych na potrzeby własnej platformy
								lub platform obsługiwanych przez podmioty zewnętrzne w związku z
								zamówieniami realizowanymi za pośrednictwem APLIKACJI
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">),</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do wypowiedzenia niniejszych Warunków
								Współpracy Partnerskiej, a także postanowień umów zawartych z
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNEREM</span>
							<span className="text-gray-90">
								, oraz do podjęcia czynności prawnych i zgłoszenia roszczenia
								odszkodowawczego wobec
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się nie przekazywać tajemnic handlowych ani
								informacji poufnych, do których może uzyskać dostęp w ramach
								stosunków biznesowych ustanowionych z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Ponadto, akceptując niniejsze Warunki Współpracy Partnerskiej,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się nie tworzyć ani nie wprowadzać na rynek
								aplikacji, która okaże się bezpośrednio konkurencyjna wobec
								APLIKACJI SYPAC, w okresie obowiązywania stosunku umownego.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Wszystkie marki, nazwy domen, oprogramowanie oraz inne dzieła,
								które podlegają ochronie prawami własności przemysłowej oraz
								prawami własności intelektualnej w odniesieniu do APLIKACJI, w
								tym również wszelkie ewentualne przyszłe zmiany, stanowią
								własność
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC. PARTNER</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się nie dokonywać rejestracji ani nie składać
								zgłoszenia w przedmiocie rejestracji takich lub podobnych dzieł,
								bez względu na lokalizację, ani nie wprowadzać zmian,
								modyfikacji ani nie usuwać ich, oraz wyraźnie potwierdza, że
								żadne z postanowień niniejszych Warunków Współpracy Partnerskiej
								nie przyznaje mu takich praw w przyszłości.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Jeżeli postanowienia niniejszych Warunków Współpracy
								Partnerskiej lub załączników do nich nie stanowią wyraźnie
								inaczej, PARTNER SYPAC nie będzie, bez uzyskania uprzedniej
								wyraźnej zgody
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA SYPAC</span>
							<span className="text-gray-90">
								, wydawać jakichkolwiek komunikatów prasowych ani odnosić się do
								SYPAC w jakikolwiek sposób w związku z niniejszymi Warunkami
								Współpracy Partnerskiej lub na innej podstawie.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">8.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Przez okres obowiązywania niniejszych Warunków Współpracy
								Partnerskiej,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">niniejszym udziela</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								niepodlegającą opłatom licencję, która jest niewyłączna i ważna
								na całym świecie, a także prawo do używania jego praw własności
								intelektualnej, w tym również między innymi majątkowe prawa
								autorskie, tajemnice handlowe, know-how, znaki towarowe, obrazy,
								tekst oraz co do zasady całą treść opublikowaną na jego stronie
								internetowej („
							</span>
							<span className="text-gray-90 font-bold">
								Prawa Własności Intelektualnej PARTNERA
							</span>{' '}
							<span className="text-gray-90">") przekazaną przez</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								na potrzeby i) wykonywania niniejszych Warunków Współpracy
								Partnerskiej, oraz ii) prowadzenia działań marketowych w związku
								z zamówieniami składanymi elektronicznie, oraz iii) w innych
								powiązanych celach – w żadnym przypadku powyższe postanowienie
								nie oznacza, że
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								dokonuje przeniesienia jego praw własności intelektualnej na
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								(chyba że postanowienia odrębnej umowy będą stanowić inaczej).
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								niniejszym oświadcza, zapewnia i potwierdza, że posiada prawa
								własności do Praw Własności Intelektualnej
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">
								(który jest ich ostatecznym właścicielem lub licencjobiorcą), a
								także że
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								posiada prawo do udzielania licencji lub podlicencji na używanie
								takich Praw Własności Intelektualnej
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								w celach związanych z niniejszymi Warunkami, i nie posiada
								informacji na temat naruszenia jego praw własności
								intelektualnej przez osoby trzecie.
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								nie przystąpi do dalszych umów, które skutkowałyby ograniczeniem
								prawa
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">do używania</span>{' '}
							<span className="text-gray-90 font-bold">
								Praw Własności Intelektualnej PARTNERA.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">9.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								oświadcza, że jest świadom obowiązujących w Polsce oraz unijnych
								regulacji dotyczących ochrony danych, a także że przestrzega
								takich regulacji w związku z prowadzoną przez niego
								działalnością.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">10.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								potwierdza, że przetwarzanie danych osobowych klientów przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zgodnie z umową, o której mowa w niniejszych Warunkach
								Współpracy Partnerskiej, będzie odbywać się według wyłącznego
								uznania
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">, oraz że</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								nie może uzyskać dostępu do nich w dowolnym czasie, bez
								uzyskania uprzedniej bezpośredniej zgody
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">11.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Celem umożliwienia</span>{' '}
							<span className="text-gray-90 font-bold">
								PARTNEROWI – DOSTAWCY USŁUG TRANSPORTOWYCH
							</span>
							<span className="text-gray-90">
								, który korzysta z USŁUG KUP I SPRZEDAJ, świadczenie usług
								dostawczych z użyciem jego własnej floty dostawców,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								przekaże za pośrednictwem APLIKACJI dane Klientów, które okażą
								się konieczne w celu realizacji zamówienia (tj. nazwę
								użytkownika oraz numer telefonu komórkowego oraz dane
								zamówienia), pod warunkiem że taki klienta złożył stosowne
								zamówienie w jego
							</span>{' '}
							<span className="text-gray-90 font-bold">FOLDERZE</span>{' '}
							<span className="text-gray-90">
								lub u franczyzobiorcą w APLIKACJI. Podstawą takiego
								przetwarzania jest uzasadniony interes Stron, a także
								świadczenie usług, gdyż to podmioty, których dotyczą dane,
								aktywnie podejmują działania w celu składania zamówień. Przed
								przekazaniem danych,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								powiadomi Klienta, że jego zamówienie będzie przetwarzane
								bezpośrednio przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">12.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								ma obowiązek, jeżeli będzie to wymagane na mocy obowiązujących
								przepisów, podpisać Umowę o przetwarzaniu Danych (dalej zwaną
								„Umową o Przetwarzaniu Danych”), która będzie określać obowiązki
								wynikające z przepisów prawa, które mogą mieć zastosowanie do
								niniejszych Warunków Współpracy Partnerskiej.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">13.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W celu wykonania zobowiązań wynikających z niniejszych Warunków
								Współpracy Partnerskiej,
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								oraz jego pracownicy, agenci i przedstawiciele zobowiązują się w
								pełni przestrzegać wszystkich obowiązujących lokalnie przepisów
								prawa dotyczących zakazu korupcji, prania brudnych pieniędzy i
								finansowania terroryzmu, przepisów prawa antymonopolowego oraz
								innych przepisów prawa, jakie mogą mieć zastosowanie do
								prowadzonej przez niego działalności.
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								ma również obowiązek prowadzić działalność zgodnie z
								najsurowszymi zasadami etycznymi, zasadami uczciwości oraz
								zasadami działania w dobrej wierze, unikając, bezpośrednio lub
								pośrednio, jakiegokolwiek zaangażowania w działania prowadzone
								niezgodnie z prawem.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">14.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								uzyska dostęp do poniżej wymienionych informacji wygenerowanych
								przez APLIKACJĘ: faktury, zamówienia, ratingi klienta, numery
								wygenerowanych zamówień, a także wszelkich innych informacji
								uzgodnionych pomiędzy stronami.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">15.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">W żadnych okolicznościach</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								nie uzyska dostępu do informacji uzyskanych od innych partnerów,
								z którymi
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">zawarł umowę. Jednakże</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								może uzyskać dostęp do informacji w łącznej formie na temat
								wolumenu niezrealizowanych zamówień, czasu przygotowania,
								wielkości zamówienia lub innych stosownych informacji dotyczące
								miejscowości lub promienia działania, w których
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">oferuje produkty lub usługi.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">16.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								oświadcza, że przestrzega Standardowego Kodeksu Etycznego
								(Standards of Business Ethics and Conduct), który
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								stosuje w stosunkach z podmiotami zewnętrznymi („Wytyczne”), a
								także że zapoznał się i zapewnia, że przeczytał i zobowiązuje
								się nie naruszać jego postanowienia w przyszłych działaniach.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">17.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Na stosowne żądanie,</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">zobowiązuje się dostarczyć</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								w terminie nie krótszym niż MIESIĄC, dokumenty, które będą
								potwierdzać, że prowadzona przez niego działalność jest zgodna z
								przepisami prawa i odbywa się w ważny i zorganizowany sposób,
								wraz z wszelkimi dodatkowymi wyjaśnieniami działań, jakie mogą
								okazać się wskazane na potrzeby audytu. Nieprzekazanie takich
								dokumentów będzie skutkować zawieszeniem wszystkich przelewów do
								momentu dostarczenia dokumentów żądanych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">18.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do zastosowania parametrów, jakie uzna za
								stosowne w celu zaklasyfikowania oraz pozycjonowania
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNERA</span>{' '}
							<span className="text-gray-90">w</span>{' '}
							<span className="text-gray-90 font-bold">APLIKACJI</span>
							<span className="text-gray-90">
								. Główne parametry rankingowe obejmują preferencje klienta oraz
								historię wyszukiwania
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, ratingi klienta, dostępność produktów lub usług, czas
								przygotowania, niezrealizowane zamówienia, promień działania,
								obszar dostaw oraz szacowany czas dostawy.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">19.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zastrzega sobie prawo do dokonania przeniesienia jego stosunku
								umownego na podmiot, który bezpośrednio lub pośrednio należy do
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">(spółek należących do grupy</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, jego podmiotów dominujących oraz jego podmiotów zależnych). W
								sytuacji gdy
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								lub dowolna ze spółek należąca do grupy spółek
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								ub jej podmiot zależny będzie stroną połączenia, konsolidacji,
								zmiany kontroli, istotnego przeniesienia środków majątkowych,
								restrukturyzacji lub likwidacji,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								może, według własnego uznania, dokonać przeniesienia lub cesji
								stosunku wzajemnego na powiązaną stronę lub dowolny z jej
								podmiotów zależnych.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">20.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Jeżeli</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>{' '}
							<span className="text-gray-90">
								zgłosi zdarzenie, problem, skargę lub roszczenie odnoszące się
								do usług świadczonych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, może wówczas skontaktować się nieodpłatnie z
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								za pośrednictwem APLIKACJI. Po otrzymaniu skargi lub roszczenia
								zgłoszonych przez
							</span>{' '}
							<span className="text-gray-90 font-bold">PARTNER</span>
							<span className="text-gray-90">
								, wszczęta zostanie procedura wewnętrzna, w ramach której zespół
								wsparcia rozpatrzy zgłoszoną sprawę i przystąpi do jej badania i
								wydania ostatecznego rozstrzygnięcia.
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								zobowiązuje się rozpatrzyć skargę w stosownym terminie oraz
								przekazać wyniki zgłoszonego zażalenia odpowiednio do
								indywidualnych okoliczności i w uproszczony sposób.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">VI. PRAWO WŁAŚCIWE I SĄDY</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Niniejsze Warunki Współpracy Partnerskiej są regulowane
								przepisami prawa polskiego.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Wszelkie spory będą rozstrzygane przez strony w drodze
								obustronnego porozumienia. W razie niemożności dojścia do
								porozumienia, Strony mogą przedłożyć spór do rozstrzygnięcia
								przez sąd powszechny, właściwy dla siedziby
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								W razie jakichkolwiek rozbieżności pomiędzy niniejszymi
								Warunkami Współpracy Partnerskiej oraz innymi warunkami,
								zastosowanie mają niniejsze warunki, chyba że Strony uzgodnią
								inaczej w formie pisemnej.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditionsPartnersPl;
