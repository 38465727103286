import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';

import { T } from '@tolgee/react';
import classNames from 'classnames';
import TermsAndConditionsPartners from './TermsAndConditionsPartners';
import TermsAndConditionsCustomer from './TermsAndConditionsCustomer';

export interface TermsAndConditionsSecondStepProps {
	isOpen: boolean;
	finish: () => void;
	target: string;
}

const TermsAndConditionsSecondStep = (
	props: TermsAndConditionsSecondStepProps,
) => {
	const { isOpen, finish, target } = props;
	const [acceptedTerms] = useState(true);
	const [isVisible, setIsVisible] = useState(false);

	const listInnerRef = useRef<HTMLDivElement>(null);

	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			const threshold = 10;
			const isNearBottom = scrollTop + clientHeight + threshold >= scrollHeight;

			if (isNearBottom) {
				setIsVisible(true);
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			const listInnerElement = listInnerRef.current;

			if (listInnerElement) {
				listInnerElement.addEventListener('scroll', onScroll);

				return () => {
					listInnerElement.removeEventListener('scroll', onScroll);
				};
			}
		}, 500);
	}, []);

	return (
		<Modal
			isOpen={isOpen}
			className="modal-inside w-[70%] max-w-[700px] outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<div className="flex flex-col">
				<div className="flex flex-row items-center justify-between px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="termsAndConditions.termsAndConditions">
								Terms and Conditions
							</T>
						</p>
					</SypacText>
				</div>
				<div className="flex flex-col items-start justify-center modal-confirmation-code-border px-6 pt-5 pb-10 gap-4">
					<div
						className="overflow-x-auto h-[calc(100vh-450px)]"
						ref={listInnerRef}
					>
						<div className="rounded-10 flex flex-col bg-gray-10-opacity-50 w-full">
							<div className="p-5">
								<SypacText variant="heading-4">
									<p className="text-gray-80">
										<T keyName="termsAndConditions.generalTermsOfUse">
											General terms of use and contractual conditions
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium" className="mt-3">
									<p className="text-gray-60">
										{['producer', 'transporter'].includes(target) ? (
											<T keyName="termsAndConditions.forPartnersIntending">
												For Partners intending to offer their goods and
												transport services.
											</T>
										) : (
											<T keyName="termsAndConditions.customersIntended">
												For Customers intended to use.
											</T>
										)}
									</p>
								</SypacText>
							</div>
						</div>
						<div className="mt-5">
							{['producer', 'transporter'].includes(target) ? (
								<TermsAndConditionsPartners />
							) : (
								<TermsAndConditionsCustomer />
							)}
						</div>
					</div>
				</div>

				<div className="flex justify-between py-5 mx-6">
					<div className="flex flex-row items-center flex-nowrap cursor-pointer">
						{isVisible ?? (
							<></>
							// <>
							// 	<SypacCheckbox size="md">
							// 		<input
							// 			id="acceptedTerms"
							// 			type="checkbox"
							// 			name="acceptedTerms"
							// 			onChange={handleChange}
							// 			checked={acceptedTerms}
							// 		/>
							// 	</SypacCheckbox>
							// 	<label htmlFor="acceptedTerms">
							// 		<SypacText variant="body-regular-small" className="ml-3">
							// 			<p className="text-gray-80">
							// 				<T keyName="termsAndConditions.agreeWithTermsAndConditions">
							// 					Agree with Terms and Conditions.
							// 				</T>
							// 			</p>
							// 		</SypacText>
							// 	</label>
							// </>
						)}
					</div>
					<SypacButton variant="secondary" size="small">
						<button
							className={classNames(
								'px-15 py-2.5 border-primary-violet hover:border-kimberly bg-primary-violet hover:bg-kimberly',
								{
									'bg-kimberly border-kimberly': !acceptedTerms,
								},
							)}
							onClick={finish}
							disabled={!acceptedTerms}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="termsAndConditions.finish">Yes, I Accept</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default TermsAndConditionsSecondStep;
