import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import {
	CommonNotificationsEnums,
	NotificationInterface,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';
import DriverArrivedLoading from '../../../../../components/Notification/DriverArrivedLoading';
import DriverConfirmation from '../../../../../components/Notification/DriverConfirmation';
import AssignedTo from '../../../../../components/Notification/AssignedTo';
import DriverMultipleNotification from '../../../../../components/Notification/DriverMultipleNotification';
import Default from '../../../../../components/Notification/Default';
import { useTranslate } from '@tolgee/react';

export interface NotificationsProps {
	selectedOrder: OrderInterface | undefined;
}

const Notifications: React.FC<NotificationsProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder } = props;
	const [notifications, setNotifications] = useState<NotificationInterface[]>(
		[],
	);

	useEffect(() => {
		const notificationList = selectedOrder?.notifications?.items?.length
			? selectedOrder?.notifications?.items
			: [];
		setNotifications(notificationList);
	}, [selectedOrder?.notifications?.items]);

	const quantity = useMemo(
		() => selectedOrder?.quantity,
		[selectedOrder?.quantity],
	);

	const truck = 'default';

	return (
		<>
			{notifications.map((item, index) => {
				const date = dayjs(item.createdAt).format('DD MMM, YYYY — HH:mm');
				return (
					<div key={item.id}>
						{item.category === CommonNotificationsEnums.ORDER_PAID ? (
							<div className="flex flex-row gap-6">
								<SypacVerticalTimeline>
									<SypacVerticalTimelineStep
										isActive={index === 0}
										minWidth={98}
									>
										{date}
									</SypacVerticalTimelineStep>
								</SypacVerticalTimeline>
								<div className="h-fit w-full">
									<Default
										notification={index === 0}
										title={t('notifications.orderPayed', 'Order paid')}
										description={t(
											'notifications.thankYouCooperation',
											'Thank you for cooperation!',
										)}
									/>
								</div>
							</div>
						) : item.category === CommonNotificationsEnums.ORDER_COMPLETE ? (
							<div className="flex flex-row gap-6">
								<SypacVerticalTimeline>
									<SypacVerticalTimelineStep
										isActive={index === 0}
										minWidth={98}
									>
										{date}
									</SypacVerticalTimelineStep>
								</SypacVerticalTimeline>
								<div className="h-fit w-full">
									<Default
										notification={index === 0}
										title={t(
											'notifications.pendingOrderPayment',
											'Pending order payment',
										)}
										description={t(
											'notifications.thankYouCooperationPaymentPending',
											'Thank you for your cooperation, your payment is pending.',
										)}
										tag={`${t('notifications.orderId', 'Order ID:')} ${
											selectedOrder?.id
										}`}
									/>
								</div>
							</div>
						) : item.category ===
						  CommonNotificationsEnums.ORDER_CONFIRM_LOADING_GOODS ? (
							<div className="flex flex-row gap-6">
								<SypacVerticalTimeline>
									<SypacVerticalTimelineStep
										isActive={index === 0}
										minWidth={280}
									>
										{date}
									</SypacVerticalTimelineStep>
								</SypacVerticalTimeline>
								<div className="h-fit w-full">
									<DriverMultipleNotification
										notification={index === 0}
										title={t(
											'notifications.driverHasBeenLoaded',
											'The driver has been loaded',
										)}
										arrivalDate={item.createdAt}
										loadingPlace={selectedOrder?.pickupAddress}
										product={selectedOrder?.product}
										quantity={selectedOrder?.product.quantity}
									/>
								</div>
							</div>
						) : item.category ===
						  CommonNotificationsEnums.ORDER_ARRIVED_TO_LOADING ? (
							<div className="flex flex-row gap-[25px]">
								<SypacVerticalTimeline>
									<SypacVerticalTimelineStep
										isActive={index === 0}
										minWidth={167}
									>
										{date}
									</SypacVerticalTimelineStep>
								</SypacVerticalTimeline>
								<div className="h-fit w-full">
									<DriverArrivedLoading
										notification={index === 0}
										title={t(
											'notifications.driverArrivedAtLoadingPlace',
											'The driver arrived at the loading place',
										)}
										description={t(
											'notifications.confirmPickupDetails',
											'Confirm pick-up details',
										)}
										loadingPlace={selectedOrder?.pickupAddress || ''}
										arrivalTime={date}
										countryCode={selectedOrder?.pickupCountryCode || 'DEU'}
										color="primary"
									/>
								</div>
							</div>
						) : item.category ===
						  CommonNotificationsEnums.ORDER_START_TRIP_TO_LOADING ? (
							<div className="flex flex-row gap-[25px]">
								<SypacVerticalTimeline>
									<SypacVerticalTimelineStep
										isActive={index === 0}
										minWidth={247}
									>
										{date}
									</SypacVerticalTimelineStep>
								</SypacVerticalTimeline>
								<div className="h-fit w-full">
									<DriverConfirmation
										notification={index === 0}
										title={t(
											'notifications.driverHasBeenConfirmedPendingArrivingLoadingPlace',
											'The driver has been confirmed. Pending arriving at the loading place',
										)}
										description={t(
											'notifications.pendingConfirmationHisArrivalPickupDetails',
											'Pending confirmation for his arrival and pick-up details.',
										)}
										countryCode={selectedOrder?.pickupCountryCode || 'DEU'}
										truckType={`${truck} (payload: ${quantity} tonns)`}
										truckPlates={selectedOrder?.truck?.licensePlates!}
										address={selectedOrder?.pickupAddress || ''}
										driver={selectedOrder?.truck?.driverFullName!}
										color="primary"
									/>
								</div>
							</div>
						) : null}
					</div>
				);
			})}
			{selectedOrder?.assigneeId ? (
				<div className="flex flex-row gap-[25px]">
					<SypacVerticalTimeline>
						<SypacVerticalTimelineStep
							isActive={!notifications.length}
							isFinalStep={true}
							minWidth={247}
						>
							{dayjs(selectedOrder?.updatedAt).format('DD MMM, YYYY — HH:mm')}
						</SypacVerticalTimelineStep>
					</SypacVerticalTimeline>
					<div className="h-fit w-full">
						<AssignedTo
							notification={!notifications.length}
							title={t(
								'notifications.orderHasBeenAssignedTo',
								'The order has been assigned to:',
							)}
							description={t(
								'notifications.confirmPickupDetails',
								'Confirm pick-up details.',
							)}
							avatar={selectedOrder?.assigneeUser?.avatarUrl || ''}
							username={
								(selectedOrder?.assigneeUser?.profile?.firstName
									? `${selectedOrder?.assigneeUser?.profile?.firstName} ${selectedOrder?.assigneeUser?.profile?.lastName}`
									: selectedOrder?.assigneeUser?.name)!
							}
						/>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Notifications;
