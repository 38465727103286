import { SypacText } from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';

import { RenderIconByState } from '../../RenderIconByState/RenderIconByState';
import { LeftSideMenuItemInterface } from './LeftSideMenuItem.interface';

export const LeftSideMenuItem = ({
	state,
	label,
	count,
	active = false,
	onClick = () => {},
}: LeftSideMenuItemInterface) => {
	const { t } = useTranslate();
	return (
		<div
			className={`flex items-center rounded-lg text-black py-1.25 px-5 cursor-pointer hover:text-black hover:bg-gray-10 ${
				active ? 'bg-gradient-primary text-white hover:text-white' : ''
			}`}
			onClick={onClick}
		>
			<div className="flex items-center gap-x-5">
				<div className="w-max h-max">
					<RenderIconByState state={state} isActive={active} />
				</div>
				<SypacText variant="body-regular-medium">
					<p className={active ? 'text-white' : ''}>
						{t(
							`leftSideMenuItem.${label ? label.toLowerCase() : state}`,
							label ? label : state,
						)}
					</p>
				</SypacText>
			</div>
			<div className="ml-auto px-2.5 py-1.25 bg-[#F2F2F2] rounded-2xl text-black">
				{count}
			</div>
		</div>
	);
};
