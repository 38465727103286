export const RejectButton = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="42"
			height="42"
			fill="none"
			viewBox="0 0 42 42"
		>
			<rect width="41" height="41" x="0.5" y="0.5" fill="#fff" rx="7.5"></rect>
			<path
				stroke="#F44A77"
				strokeWidth="1.3"
				d="M14.397 27.843c.96 1.156 2.746 1.156 6.318 1.156h.577c3.572 0 5.359 0 6.318-1.156m-13.213 0c-.96-1.157-.63-2.912.028-6.423.468-2.497.702-3.746 1.59-4.483m-1.618 10.906s0 0 0 0zm13.213 0c.96-1.157.63-2.912-.027-6.423-.469-2.497-.703-3.746-1.591-4.483m1.618 10.906s0 0 0 0zm-1.619-10.906c-.888-.738-2.158-.738-4.699-.738h-.577c-2.54 0-3.81 0-4.7.738m9.976 0s0 0 0 0zm-9.975 0s0 0 0 0z"
			></path>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				strokeWidth="1.3"
				d="M22.606 21l-3.2 3.2m0-3.2l3.2 3.2M18.602 16.2v-.8a2.4 2.4 0 114.8 0v.8"
			></path>
			<rect
				width="41"
				height="41"
				x="0.5"
				y="0.5"
				stroke="#E8E8E8"
				rx="7.5"
			></rect>
		</svg>
	);
};
