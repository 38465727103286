import {
	CompanyResponse,
	CompanyService,
} from '../../../services/company.services';
import React, { memo, useState } from 'react';
import CompanyDocuments from '../../../components/CompanyDocuments/CompanyDocuments';
import { FileService } from '../../../services/file.service';
import { FileInterface } from '../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { DOCEMENT_TYPES } from '../../../components/UlpoadDocuments/UlpoadDocuments';

interface Props {
	company: CompanyResponse;
	onFilesChange: (files: FileInterface[]) => void;
}

export const CompanyFiles: React.FC<Props> = memo(
	({ company, onFilesChange }: Props) => {
		const [files, setFiles] = useState(company.files || []);
		const [localFiles, setLocalFiles] = useState<FileInterface[]>([]);
		const [loading, setLoading] = useState<boolean>(false);

		const saveFiles = async (input: FileInterface[]) => {
			setLoading(true);

			const documents = input.map((item) => item.file).filter((r) => r);

			let newFiles: FileInterface[] = [];
			if (documents?.length) {
				const { data } = await FileService.uploadFiles(documents);
				newFiles = data.items.map((r) => {
					const values = r?.originalName?.split('.');
					const name = values?.length ? values[0] : '';
					const localFile = input.find((r) => r.name === name);
					return {
						name,
						type: localFile?.type || DOCEMENT_TYPES[0].value,
						size: localFile?.size,
						mimeType: localFile?.mimeType,
						path: r.path?.replace('dev/', ''),
					};
				});
			}
			setLocalFiles(newFiles || []);
			setLoading(false);
		};

		const uploadFiles = async () => {
			const editDocuments = localFiles.filter((item) => !item.file);

			if (editDocuments?.length) {
				await CompanyService.companyVerificationStep2(company.id!, {
					files: editDocuments?.map((r) => ({ ...r, action: 'delete' })),
				});
			}

			const addFiles = [...editDocuments];
			if (addFiles.length) {
				const { data } = await CompanyService.companyVerificationStep2(
					company.id!,
					{
						files: addFiles.map((r) => ({ ...r, action: 'add' })),
					},
				);
				setFiles(data.files || []);
				setLocalFiles([]);
				onFilesChange(files);
			}
		};

		const deleteDocument = async (input: FileInterface[]) => {
			if (input?.length) {
				const { data } = await CompanyService.companyVerificationStep2(
					company.id!,
					{
						files: input.map((r) => ({ ...r, action: 'delete' })),
					},
				);
				setFiles(data.files || []);
				onFilesChange(files);
			}
		};

		return (
			<CompanyDocuments
				files={files || []}
				saveFiles={saveFiles}
				uploadFiles={uploadFiles}
				deleteDocument={deleteDocument}
				docsLoading={loading}
			/>
		);
	},
);
