import React, { useEffect, useState } from 'react';
import { SypacBadge } from '@sypac/component-library-react';
import { useTranslate } from '@tolgee/react';
import {
	StatusColors,
	StatusMultiple,
	StatusMultipleExcluded,
} from '../../constants/order-status.constants';
import { StatusClock } from '../../assets/StatusClock';
import { StatusBadgeProps } from './StatusBadge.interface';
import { Tooltip } from 'react-tooltip';
import { CheckmarkCircle } from '../../assets/CheckmarkCircle';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';

const StatusBadge: React.FC<StatusBadgeProps> = ({
	status,
	isTruckStatus,
	deliveryIncluded,
	target,
}) => {
	const { t } = useTranslate();
	const [currentStatus, setCurrentStatus] = useState(status);
	const [nextStatus, setNextStatus] = useState('');
	const [animate, setAnimate] = useState('');

	useEffect(() => {
		if (status !== currentStatus) {
			setNextStatus(status);
			setAnimate('animate-slide-out');
			setTimeout(() => {
				setCurrentStatus(status);
				setNextStatus('');
				setAnimate('');
			}, 600);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const getStatusProps = (status: string) => {
		if (target === OrderTarget.producer) {
			return {
				label:
					status === 'loaded'
						? t('statuses.loaded', 'Loaded')
						: t('statuses.pendingLoading', 'Pending loading'),
				color: status === 'loaded' ? 'green' : 'yellow',
			};
		}
		if (target === OrderTarget.transporter) {
			return {
				label: t('statuses.available', 'Available'),
				color: 'green',
			};
		}
		return isTruckStatus
			? deliveryIncluded
				? {
						label:
							t(StatusMultiple[status]?.label) ||
							t(
								'transportSolution.awaitingConfirmation',
								'Awaiting confirmation',
							),
						color: StatusMultiple[status]?.color || 'gray',
				  }
				: {
						label: t(
							StatusMultipleExcluded[status]?.label ||
								StatusMultipleExcluded.to_do.label,
						),
						color:
							StatusMultipleExcluded[status]?.color ||
							StatusMultipleExcluded.to_do.color,
				  }
			: {
					label: t(StatusColors[status]?.label || StatusColors.created.label),
					color: StatusColors[status]?.color || 'gray',
			  };
	};

	const { label: currentLabel, color: currentBgColor } =
		getStatusProps(currentStatus);
	const { label: nextLabel, color: nextBgColor } = getStatusProps(nextStatus);

	return isTruckStatus ? (
		<SypacBadge color={currentBgColor} outlined={false}>
			<div className="flex w-fit gap-2.5 items-center text-sm font-medium whitespace-nowrap cursor-auto">
				<span className="p-0 m-0 flex scale-[1.2]">
					{(target === OrderTarget.producer && status === 'loaded') ||
					['completed', 'pending_payment', 'paid', 'available'].includes(
						status,
					) ? (
						<CheckmarkCircle />
					) : (
						<StatusClock />
					)}
				</span>
				<p className="m-0 mr-[5px] flex-1 text-center">{currentLabel}</p>
			</div>
		</SypacBadge>
	) : (
		<div className="flex overflow-hidden w-[146px]">
			<SypacBadge color={currentBgColor} outlined={false}>
				<div
					data-tooltip-id="status-badge"
					data-tooltip-content={
						currentStatus === 'created'
							? t(
									'orderStepper.simpleOrderIsBeingReviewed',
									'Your order is being reviewed',
							  )
							: ''
					}
					className={`flex justify-between items-center text-sm font-medium whitespace-nowrap min-w-[132px] ${animate} cursor-auto`}
				>
					<span className="p-0 m-0 flex scale-[1.2]">
						<StatusClock fillColor={'white'} />
					</span>
					<p className="m-0 flex-1 text-center">{currentLabel}</p>
				</div>
			</SypacBadge>
			<SypacBadge
				color={nextBgColor}
				outlined={false}
				className={`${nextStatus ? 'visible' : 'hidden'}`}
			>
				<div
					data-tooltip-id="status-badge"
					data-tooltip-content={
						currentStatus === 'created' ? 'Your order is being reviewed' : ''
					}
					className={`flex justify-between items-center text-sm font-medium whitespace-nowrap min-w-[132px] ${animate} cursor-auto`}
				>
					<span className="p-0 m-0 flex scale-[1.2]">
						<StatusClock fillColor={'white'} />
					</span>
					<p className="m-0 flex-1 text-center">{nextLabel}</p>
				</div>
			</SypacBadge>
			{(currentStatus === 'created' || nextStatus === 'created') && (
				<Tooltip
					place="top"
					id="status-badge"
					style={{
						backgroundColor: '#E8E8E8',
						color: '#000000',
					}}
				/>
			)}
		</div>
	);
};

export default StatusBadge;
