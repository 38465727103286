import React, { useState } from 'react';

import './user-card.css';
import UserCard from './UserCard';
import { UserCardGroupProps } from './UserCard.interface';

const UserCardGroup = (props: UserCardGroupProps) => {
	const { data, saveProfile } = props;

	const [currentCard, setCurrentCard] = useState(-1);

	return (
		<div className="flex flex-col gap-4">
			{data.map((item, index) => {
				return (
					<div
						onClick={() => setCurrentCard(index)}
						key={`${item.firstName}_${index}`}
					>
						<UserCard
							data={item}
							isOpen={currentCard === index}
							saveProfile={saveProfile}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default UserCardGroup;
