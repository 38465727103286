export const MenuIcon = () => {
	return (
		<svg
			width="16"
			height="14"
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="2" rx="1" fill="#454545" />
			<rect y="6" width="16" height="2" rx="1" fill="#454545" />
			<rect y="12" width="16" height="2" rx="1" fill="#454545" />
		</svg>
	);
};
