import React, { useMemo, useState } from 'react';
import { User } from '../types/user';

import { AuthContext } from './context';
import { AuthProviderProps } from './context.types';
import OrderContextProvider from './OrderContext/order.context';
import QuantityPickerContextProvider from './QuantityPickerContext/quantity-picker.context';

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [user, setUser] = useState<User>();

	const value = useMemo(
		() => ({
			loading,
			setLoading,
			user,
			setUser,
		}),
		[loading, user],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const GlobalProvider = ({ children }: any) => {
	return (
		<AuthProvider>
			<OrderContextProvider>
				<QuantityPickerContextProvider>
					{children}
				</QuantityPickerContextProvider>
			</OrderContextProvider>
		</AuthProvider>
	);
};

export default GlobalProvider;
