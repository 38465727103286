import React, { useCallback, useEffect, useState } from 'react';
import { SypacInput, SypacText } from '@sypac/component-library-react';

import { useDetectClickOutside } from 'react-detect-click-outside';
import { Geo } from '../../SearchLocation/locations.interface';
import useDebounce from '../../../hooks/useDebounce';
import { GeoService } from '../../../services/geo.services';
import Flag from 'react-world-flags';
import './location-input.css';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';

interface LocationInputProps {
	setLocation: (item: Geo | undefined) => void;
	placeholder?: string;
	className?: string;
	defaultValue?: string;
	role: string;
	errorLocation?: string | undefined;
}

export const LocationInput = ({
	setLocation,
	placeholder,
	className,
	defaultValue,
	role,
	errorLocation,
}: LocationInputProps) => {
	const { t } = useTranslate();
	const [expandResults, setExpandResults] = useState(false);
	const [search, setSearch] = useState('');
	const [locations, setLocations] = useState<Geo[]>([]);
	const searchQuery = useDebounce(search, 500);
	const onSelectLocation = (location: Geo) => {
		const input = document.getElementById('location') as HTMLInputElement;
		setLocation(location);
		setExpandResults(false);
		setSearch(location.label);
		input.value = location.label;
	};

	const renderDisplayLocation = (location: Geo) => {
		const label = location.lat
			? location.label
			: [
					[location?.address?.street, location?.address?.houseNumber].join(
						' ',
					) || '',
					location?.address?.postalCode || '',
					location?.address?.city || '',
					location?.address?.country || '',
			  ]
					.filter((r) => r)
					.join(', ');

		return (
			<div
				key={location.locationId}
				className="flex items-center hover:bg-gray-10-opacity-50 rounded-[5px] cursor-pointer"
				onClick={() => onSelectLocation(location)}
			>
				<div className="mx-5">
					<Flag
						className="rounded-[3px]"
						code={location.countryCode.toLocaleUpperCase()}
						width={22}
						height={16}
					/>
				</div>
				<SypacText variant="body-normal-medium" className="my-[15px]">
					<p>{label}</p>
				</SypacText>
			</div>
		);
	};
	const getResult = useCallback(async () => {
		try {
			let data: React.SetStateAction<Geo[]> = [];
			let newLocations: Geo[] = [];
			if (role === 'producer') {
				const respAddresses = await GeoService.getAddresses({
					search: searchQuery,
					limit: 10,
					offset: 0,
				});
				newLocations =
					respAddresses?.data?.items?.map((r) => ({
						matchLevel: '1',
						label: r.address,
						countryCode: r.country,
						lat: r.lat,
						long: r.long,
					})) || [];
			}
			if (searchQuery?.length) {
				const resp = await GeoService.getLocations({
					phrase: searchQuery,
					maxResults: '10',
				});
				data = [...newLocations, ...resp.data];
			} else {
				data = newLocations;
			}
			setLocations(data);
		} catch (e) {
			console.log(e);
		}
	}, [role, searchQuery]);

	useEffect(() => {
		getResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const outsideRef = useDetectClickOutside({
		onTriggered: () => setExpandResults(false),
	});

	const setSearchValue = (value: string) => {
		setSearch(value);
		if (!value) {
			setLocation(undefined);
		}
	};

	return (
		<div className="relative" ref={outsideRef}>
			<SypacInput>
				<input
					autoComplete="off"
					id="location"
					name="location"
					type="text"
					className={classNames(
						className || 'location--input',
						errorLocation ? 'location--input-error' : '',
					)}
					placeholder={
						placeholder ||
						t('locationInput.enterLoadingPoint', 'Enter loading point')
					}
					onFocus={() => setExpandResults(true)}
					value={search || defaultValue}
					onChange={(event) => {
						setSearchValue(event.target.value);
					}}
				/>
			</SypacInput>
			<div
				className={`${
					locations.length && expandResults ? 'flex' : 'hidden'
				} location--select-area`}
			>
				<div className="location--select-list">
					{locations.map((location: Geo) => renderDisplayLocation(location))}
				</div>
			</div>
		</div>
	);
};
