import React from 'react';
import dayjs from 'dayjs';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import DriverMultipleNotification from '../../../components/Notification/DriverMultipleNotification';
import { ProductInterface } from '../../../interfaces/product.interface';
import { useTranslate } from '@tolgee/react';

interface NotificationDriverLoadedProps {
	notification: boolean;
	date?: string;
	loadingPlace?: string;
	product?: Partial<ProductInterface>;
	quantity?: string | number;
	loadingDate?: string;
	files?: { src: string }[];
}
const NotificationDriverLoaded = ({
	notification,
	date,
	product,
	quantity,
	loadingDate,
	loadingPlace,
	files,
}: NotificationDriverLoadedProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={280}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'notificationDriverLoaded.driverLoaded',
						'The driver has been loaded',
					)}
					description={t(
						'notificationDriverLoaded.pendingArrivingUnloadingPlace',
						'Pending arriving at the unloading place',
					)}
					loadingPlace={loadingPlace}
					product={product}
					quantity={quantity}
					loadingDate={dayjs(loadingDate).format('DD MMM, YYYY — HH:mm')}
					files={files}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverLoaded;
