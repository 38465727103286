import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import AwaitingConfirmation from '../../../components/Notification/AwaitingConfirmation';
import { useTranslate } from '@tolgee/react';

interface NotificationOrderConfirmedProps {
	notification: boolean;
	date: string;
	button: {
		text: string;
		onClick?: () => void;
	};
}

const NotificationOrderConfirmed = ({
	notification,
	date,
	button,
}: NotificationOrderConfirmedProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-[25px]">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={140}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<AwaitingConfirmation
					notification={notification}
					title={t(
						'notificationOrderConfirmed.orderConfirmed',
						'Order confirmed — awaiting truck allocation',
					)}
					description={t(
						'notificationOrderConfirmed.orderHasBeenConfirmed',
						'Your order has been confirmed',
					)}
					button={button}
				/>
			</div>
		</div>
	);
};

export default NotificationOrderConfirmed;
