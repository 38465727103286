import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-world-flags';
import GroupCard from '../../../components/GroupCard/GroupCard';
import { useGetCategories } from '../../../hooks/use-get-categories';
import Close from '../../../assets/Close';
import '../select-product.scss';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { GeoService } from '../../../services/geo.services';
import { T, useTranslate } from '@tolgee/react';
import { MapIcon } from '../../../assets/MapIcon';
import { MutatingDots } from 'react-loader-spinner';

const ClientSelectGroups: React.FC = () => {
	const { t } = useTranslate();
	const [coordinates, setCoordinates] = useState<number[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [groups, _, isLoading] = useGetCategories({ coordinates });
	const [activeGroup, setActiveGroup] = useState<string>('');
	const { order } = useContext(OrderContext);
	const navigate = useNavigate();
	const [categories] = useGetCategories({
		parentIds: [activeGroup!],
		coordinates,
	});

	// TODO: decorate category with subcategories count on get
	const address = useMemo(
		() =>
			order?.location
				? [
						[
							order?.location?.address?.street,
							order?.location?.address?.houseNumber,
						].join(' ') || '',
						order?.location?.address?.postalCode || '',
						order?.location?.address?.city || '',
						order?.location?.address?.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '',
		[order?.location],
	);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const resp = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([resp.data.Longitude, resp.data.Latitude]);
		}
	}, []);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	useEffect(() => {
		if (activeGroup && categories?.items && categories?.items.length > 0) {
			navigate(`${activeGroup}/categories/${categories?.items[0].id}/products`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeGroup, categories?.items]);

	return (
		<div className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			<div className="w-[1132px] xl:self-center flex justify-between items-center mb-4">
				<div className="flex flex-col gap-4">
					<SypacBreadcrumbs>
						<div
							className="text-cornflower-blue"
							onClick={() => navigate('/store/groups')}
						>
							<T keyName="clientSelectGroups.store">Store</T>
						</div>
					</SypacBreadcrumbs>
					<div className="flex items-center gap-4">
						<div className="hidden sm:flex gap-4">
							<span className="scale-[1.2]">
								<MapIcon green={true} />
							</span>
							<SypacText variant="body-regular-medium">
								<p className="align-middle text-gray-80">
									<T keyName="clientSelectQuantity.deliveryAddress">
										Delivery address:
									</T>
								</p>
							</SypacText>
						</div>

						<span className="mt-[2px]">
							<Flag
								className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
								code={order?.location?.countryCode?.toLocaleUpperCase()}
								width={24}
								height={16}
							/>
						</span>
						<SypacText variant="body-regular-medium">
							<p className="align-middle text-gray-80">{address}</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<a
								href="/store"
								className="flex flex-row justify-center items-center h-[22px] w-[22px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-md"
							>
								<Close width="10" height="10" />
							</a>
						</SypacButton>
					</div>
				</div>

				{/*<SearchBar
					placeholder={t(
						'clientSelectGroups.searchPlacehoder',
						'Search Products',
					)}
					showButton={false}
				/>*/}
			</div>
			<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

			<div className="w-[1132px] xl:self-center flex flex-col gap-4">
				<SypacText variant="body-regular-medium" className="mt-4">
					<p className="text-xl">
						<T keyName="clientSelectGroups.selectProductGroup">
							Select product group:
						</T>
					</p>
				</SypacText>

				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center mt-16">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
					</div>
				) : (
					<div className="mt-3 grid gap-6 md:grid-cols-[repeat(3,minmax(0,268px))] xl:grid-cols-4">
						{groups?.items?.map((item) => {
							return (
								<GroupCard
									key={item.id}
									imageUrl={item.imageUrl}
									title={item.name}
									description={`${item.countSubcategories} ${t(
										'clientSelectGroups.categoriesInThisGroup',
										'categories in this group',
									)}`}
									onClick={() => {
										setActiveGroup(item.id);
									}}
								/>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default ClientSelectGroups;
