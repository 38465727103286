import React from 'react';
import {
	SypacBadge,
	SypacTabButton,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';

import { BillingTab, BillingTabsProps } from './BillingTabs.interface';
import { useTranslate } from '@tolgee/react';

const BillingTabs = ({ data, callback, activeTab }: BillingTabsProps) => {
	const { t } = useTranslate();

	return (
		<SypacTabs className="box-border">
			{data
				? data?.map(({ label, value, color, count }: BillingTab) => {
						return (
							<SypacTabButton
								key={Math.random()}
								size="large"
								variant="gradient"
								label={t(`billingTabs.${label.toLowerCase()}`, label)}
								button-id={value ? value : label.toLowerCase()}
								onClick={() => callback(value ? value : label.toLowerCase())}
								is-active={
									activeTab === value || activeTab === label.toLowerCase()
								}
							>
								<SypacBadge color={color}>
									<SypacText variant="overline-regular-large">
										<p>{count}</p>
									</SypacText>
								</SypacBadge>
							</SypacTabButton>
						);
				  })
				: null}
		</SypacTabs>
	);
};

export default BillingTabs;
