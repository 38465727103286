export const TruckPinClient = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			fill="none"
			viewBox="0 0 38 38"
		>
			<circle cx="19" cy="19" r="19" fill="#fff"></circle>
			<path
				fill="url(#paint0_linear_5116_187905)"
				d="M20.939 13.176c-.71-1.732-3.163-1.732-3.874 0l-5.13 12.512a.718.718 0 00.955.93l3.54-1.564a6.366 6.366 0 015.145 0l3.54 1.564a.718.718 0 00.955-.93l-5.131-12.512z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_5116_187905"
					x1="-15857800000"
					x2="-15857800000"
					y1="1643850000"
					y2="1644300000"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#373A77"></stop>
					<stop offset="1" stopColor="#5682FA"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
};
