import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { OrderInterface } from '../../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrdersService,
	ProducerOrderAction,
} from '../../../../../../services/orders.services';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import Emitter, { EventType } from '../../../../../../services/events';
import { T } from '@tolgee/react';
import Stepper from '../../../../../../components/SharedOrder/Stepper/Stepper';
import BaseOrderDetails from '../../../../../../components/SharedOrder/BaseOrderDetails';
import LoadingSpinner from '../../../../../../assets/LoadingSpinner';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';

const AssignOrder: React.FC<ProducerOrderProps> = (props) => {
	const { selectedOrder, setOrder } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);

	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const handleClick = async () => {
		setIsLoading(true);
		try {
			await OrdersService.actionOrder(
				{ wight: 1, documents: [] },
				OrderTarget.producer,
				ProducerOrderAction.ASSIGNN,
				selectedOrder?.id!,
			);
			const { data } = await OrdersService.getOrder(
				selectedOrder?.id!,
				OrderTarget.producer,
			);
			setOrder?.(data as unknown as OrderInterface);
			Emitter.emit(EventType.ORDER_LIST_REFRESH, {
				id: data.id.toString(),
				recipientId: '',
				groupId: data.id.toString(),
				category: 'update_order',
			});
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	const referenceFormatter = (id: string) => {
		return id.substring(0, 3) + ' ' + id.substring(3);
	};

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	return (
		<div style={{ marginBottom: `${boxHeight}px` }}>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<Stepper
					current={1}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{!deliveryIncluded ? (
				<div className="px-10 pt-7 pb-3.5">
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				</div>
			) : null}

			<div className={`px-10 ${deliveryIncluded ? 'pt-7 pb-3.5' : 'py-3.5'}`}>
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<PickUpSchedule selectedOrder={selectedOrder} step={1} />
			</div>

			<div
				className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-gray-40 border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign"
				ref={boxRef}
			>
				<div className="flex flex-col justify-center items-start p-8 gap-5">
					<SypacText variant="overline-regular-large">
						<p className="text-base leading-6 tracking-tighter text-gray-40">
							{deliveryIncluded ? (
								<T keyName="boxToDoStep.assignThisOrderToYou">
									Assign this order to you
								</T>
							) : (
								<T keyName="boxToDoStep.orderTypeSelfService">
									Order type: Self-service
								</T>
							)}
						</p>
					</SypacText>

					<SypacText variant="heading-5">
						<p className="tracking-tighter text-gray-90">
							{deliveryIncluded ? (
								<T keyName="boxToDoStep.notBeenAssignedToAnyoneYet">
									This order has not been assigned to anyone yet
								</T>
							) : (
								<>
									<T keyName="boxToDoStep.referenceId">Reference ID:</T>{' '}
									{referenceFormatter(
										selectedOrder?.trucks! &&
											selectedOrder?.trucks[0].referenceId!,
									)}
								</>
							)}
						</p>
					</SypacText>

					{!deliveryIncluded ? (
						<SypacText variant="overline-regular-large">
							<p className="text-base leading-6 tracking-tighter text-gray-40">
								<T keyName="boxToDoStep.pleaseAssignOrder">
									Please assign this order to proceed.
								</T>
							</p>
						</SypacText>
					) : null}

					<SypacText variant="overline-regular-large">
						<p
							className={`text-base leading-6 tracking-tighter text-gray-40 ${
								!deliveryIncluded ? 'w-[420px]' : ''
							}`}
						>
							{deliveryIncluded ? (
								<T keyName="boxToDoStep.assignOrderToProceed">
									Please assign this order to proceed. Note: Delivery is
									organized by SYPAC. Ensure the cargo is ready for pickup.
								</T>
							) : (
								<T keyName="assignOrder.selfServiceOrderToProceed">
									Note: This is a self-service order; the buyer will handle
									pickup. Ensure the buyer presents the Truck Reference ID
									before loading.
								</T>
							)}
						</p>
					</SypacText>

					<SypacButton variant="primary" size="large">
						<button
							className="w-fit transition"
							onClick={handleClick}
							disabled={isLoading}
						>
							<SypacText
								variant="body-normal-medium"
								className="flex items-center gap-4"
							>
								<p className="m-0">
									{deliveryIncluded ? (
										<T keyName="boxToDoStep.assignToMe">Assign to me</T>
									) : (
										<T keyName="boxToDoStep.excludedAssignOrder">
											Assign order
										</T>
									)}
								</p>
								{isLoading ? <LoadingSpinner /> : null}
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</div>
	);
};

export default AssignOrder;
