import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { NumericFormat } from 'react-number-format';
import {
	SypacBadge,
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';

import './client-billing.scss';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { PaymentsTable } from '../../../components/PaymentsTable/PaymentsTable';
import BillingTabs from '../../../components/BillingTabs/BillingTabs';
import AvatarDropDown from '../../../components/AvatarDropDown/AvatarDropDown';
import { Sorting } from '../../../components/Sorting/Sorting';
import ModalFilterDate from '../../../components/ModalFilterDate/ModalFilterDate';
import ModalDeposit from '../../../components/ModalDeposit/ModalDeposit';
import { useGetStats } from '../../../hooks/use-get-stats';
import { useGetInvoices } from '../../../hooks/use-get-invoices';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import { BillingTab } from '../../../components/BillingTabs/BillingTabs.interface';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';
import { BillingService } from '../../../services/billing.service';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import Pagination from '../../../components/Pagination/Pagination';
import { LIMIT } from '../../../constants';
import OrderDetails from '../OrderDetails';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { OrdersService } from '../../../services/orders.services';
import { InvoiceInterface } from '../../../components/PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { T, useTranslate } from '@tolgee/react';

const ClientBilling: React.FC = () => {
	const { t } = useTranslate();
	const sortOptions = [
		{
			title: t('clientBilling.sortByDateDescending', 'Sort by date descending'),
			value: 'invoiceDate DESC',
		},
		{
			title: t('clientBilling.sortByDateAscending', 'Sort by date ascending'),
			value: 'invoiceDate ASC',
		},
		{
			title: t('clientBilling.sortByPriceAscending', 'Sort by price ascending'),
			value: 'totalAmount ASC',
		},
		{
			title: t(
				'clientBilling.sortByPriceDescending',
				'Sort by price descending',
			),
			value: 'totalAmount DESC',
		},
	];

	const [searchQuery, setSearchQuery] = useState<string>();
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [dateFrom, setDateFrom] = useState<string>();
	const [dateTo, setDateTo] = useState<string>();
	const [isOpenDateFilter, setOpenDateFilter] = useState<boolean>(false);
	const [isDepositOpen, setDepositOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [refresh, setRefresh] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const detailsRef = useRef<HTMLDivElement>(null);

	const selectTab = (tab: string) => {
		setPage(0);
		setCurrentTab(tab);
	};

	const [stats] = useGetStats({
		target: 'customer',
		refresh,
	});
	const hasOverduePayments = useCallback(
		(overdue: string | undefined) => {
			if (!overdue) {
				return true;
			}

			return parseInt(overdue, 10) > 0;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stats?.count?.overdue],
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesList, _, invoicesAreLoading] = useGetInvoices({
		orderId: searchQuery,
		status: currentTab,
		fromDate: dateFrom || undefined,
		toDate: dateTo || undefined,
		sorting: sorting?.value,
		limit: LIMIT,
		offset: page * LIMIT,
		refresh,
	});

	const lines =
		invoicesList?.items?.map((invoice) => invoice.invoiceLines[0]!) || [];

	const avatarsMap: Record<string, string> = {};
	lines?.forEach((line) => {
		avatarsMap[line.assigneeUser.uid] = line.assigneeUser.name;
	});
	const avatars: AvatarItem[] = Object.keys(avatarsMap).map((key) => {
		return {
			id: key,
			fullName: avatarsMap[key],
		};
	});

	const tabs: BillingTab[] = [
		{
			label: t('clientBilling.all', 'All'),
			value: 'all',
			color: 'white',
			count: stats?.count?.all!,
		},
		{
			label: t('clientBilling.pending', 'Pending'),
			value: 'pending',
			color: 'yellow',
			count: stats?.count?.pending!,
		},
		{
			label: t('clientBilling.overdue', 'Overdue'),
			value: 'overdue',
			color: 'red',
			count: stats?.count?.overdue!,
		},
		{
			label: t('clientBilling.paid', 'Paid'),
			value: 'paid',
			color: 'green',
			count: stats?.count?.paid!,
		},
	];

	const makeDeposit = async (val: string) => {
		try {
			await BillingService.createDeposit({
				paymentMethod: 'bank',
				amount: parseFloat(val),
			});
			toastVariant(
				t(
					`clientBilling.invoiceForDepositWhereCreated`,
					'Invoice for deposit where created',
				),
			);
			setRefresh(refresh + 1);
		} catch (e) {
			toastVariant(
				t(
					`clientBilling.depositCannotBeCreated`,
					'Deposit cannot be created. Please contact support for more details',
				),
				true,
			);
		}
	};

	const viewOrder = async (orderId?: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.customer,
			);
			setOrder(data as unknown as OrderInterface);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const aditionalClick = (value: InvoiceInterface | number, type?: string) => {
		if (type === PaymentTypeAction.view_order) {
			viewOrder(value as number);
		}
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const toastContainer = document.querySelector('.Toastify');

		if (
			!detailsRef.current?.contains(event.target as Node) &&
			!(toastContainer && toastContainer.contains(event.target as Node))
		) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	return (
		<section className="flex flex-col min-h-[calc(100vh-60px)] h-[calc(100vh-60px)] pb-7.5 gap-x-2.5 w-full gap-5">
			<div className="grid grid-cols-2 gap-7.5 md:grid-cols-1 lg:grid-cols-2">
				<div className="flex flex-col gap-2.5">
					<div className="flex justify-between">
						<SypacText variant="heading-4">
							<p className="text-tuna">
								<T keyName="clientBilling.manageYourWalletBilling">
									Manage your wallet & billing
								</T>
							</p>
						</SypacText>
						{/* <SypacButton variant="secondary" size="small">
							<button className="px-8 py-3">
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="clientBilling.contactToSypac">
											Contact to sypac
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton> */}
					</div>
					<SypacText variant="body-regular-small">
						<p className="text-nevada">
							<T keyName="clientBilling.downloadInvoicesControlBillingDetails">
								Download invoices and control your billing details.
							</T>
						</p>
					</SypacText>
					{/* <div className="mt-3 w-full">
						<SearchBar
							placeholder={t('clientBilling.searchOrder', 'Search order')}
							onClick={setSearchQuery}
						/>
					</div> */}
				</div>
				<SypacBox>
					<div className="flex p-5 gap-3 justify-between">
						<div className="flex-col gap-1">
							<SypacText variant="body-regular-small">
								<p className="text-nevada">
									<T keyName="clientBilling.currentBalance">Current balance:</T>
								</p>
							</SypacText>
							<div className="flex lg:flex-col 2xl:flex-row items-center gap-y-2 gap-8">
								<SypacText variant="heading-4">
									<p className="text-tuna">
										<NumericFormat
											type="text"
											displayType="text"
											thousandSeparator="."
											decimalSeparator=","
											value={parseFloat(stats?.amount || '0')}
											suffix={' zł'}
										/>
									</p>
								</SypacText>
								<SypacBadge color="gray" className="p-0">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 px-5 py-1">
											<T keyName="clientBilling.creditLimit">Credit limit:</T>
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseInt(stats?.creditLimit || '0')}
												suffix={' zł'}
											/>
										</p>
									</SypacText>
								</SypacBadge>
							</div>
							{hasOverduePayments(stats?.count?.overdue) && (
								<SypacText variant="body-regular-medium">
									<p className="text-red mt-3">
										<T keyName="clientBilling.youHave">You have </T>
										{stats?.count?.overdue}{' '}
										<T keyName="clientBilling.totalUnpaidAmount">
											overdue order, please make a payment. Total unpaid amount:
										</T>
										<NumericFormat
											className="px-1"
											type="text"
											displayType="text"
											thousandSeparator="."
											decimalSeparator=","
											value={parseFloat(stats?.amountTotal?.overdue || '0')}
											suffix={' zł'}
										/>
									</p>
								</SypacText>
							)}
						</div>
						<SypacButton variant="primary" size="small">
							<button onClick={() => setDepositOpen(true)}>
								<SypacText variant="body-regular-medium">
									<p className="leading-[22px]">
										<T keyName="clientBilling.deposit">Deposit</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</SypacBox>
			</div>
			<div className="flex gap-7.5 w-full md:flex-col lg:flex-row">
				<div className="flex w-full max-w-[745px] items-center">
					<BillingTabs
						data={tabs}
						callback={selectTab}
						activeTab={currentTab}
					/>
				</div>
				{/* <div className="flex flex-row w-full justify-between items-center">
					<AvatarDropDown items={avatars} />
					<SypacButton variant="link">
						<button
							className="underline"
							onClick={() => setOpenDateFilter(true)}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="clientBilling.filterByDateRange">
										Filter by date range
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<Sorting options={sortOptions} action={(item) => setSorting(item)} />
				</div> */}
			</div>
			{invoicesAreLoading ? (
				<div className="flex w-full h-full items-center justify-center">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			) : (
				<div
					className={`p-px mb-[35px] overflow-y-auto overflow-x-auto ${
						invoicesList && invoicesList?.count > 0
							? 'max-h-[calc(100%-190px)]'
							: 'h-[calc(100%-190px)]'
					}`}
				>
					<PaymentsTable
						target={OrderTarget.customer}
						rows={invoicesList?.items || []}
						rowClick={() => {}}
						aditionalClick={aditionalClick}
						currentTab={currentTab}
					/>
				</div>
			)}
			<div className="flex justify-between bg-white-100 absolute bottom-7 py-4 px-7 rounded-br-10 rounded-bl-10 border border-gray-10 border-solid w-[calc(100vw-404px)] shadow-pagination">
				<Pagination
					showText={true}
					count={invoicesList?.count}
					page={page}
					onClick={(item: any) => setPage(item)}
				/>
			</div>
			<ModalFilterDate
				isOpen={isOpenDateFilter}
				onSuccess={(from, to) => {
					setDateFrom(from);
					setDateTo(to);
					setOpenDateFilter(false);
				}}
				onClose={() => {
					setOpenDateFilter(false);
					setDateTo('');
					setDateFrom('');
				}}
			/>
			<ModalDeposit
				isOpen={isDepositOpen}
				onClose={() => setDepositOpen(false)}
				onSubmit={(val) => makeDeposit(val)}
			/>
			<div ref={detailsRef}>
				{isOpen && selectedOrder?.id ? (
					<OrderDetails
						isOpen={!!(isOpen && selectedOrder?.id)}
						selectedOrder={selectedOrder}
					/>
				) : null}
			</div>
		</section>
	);
};

export default ClientBilling;
