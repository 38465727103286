import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
	SypacButton,
	SypacCheckbox,
	SypacDatepicker,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import Flag from 'react-world-flags';
import * as Yup from 'yup';

import { CompanyDetailsProps, CompanyProps } from './CompanyDetails.interface';
import { COUNTRIES } from '../../../DropDownOption/countries.constant';
import { DropDownOption } from '../../../DropDownOption/DropDownOption';
import {
	colourErrorStyles,
	colourStyles,
} from '../../../DropDownOption/DropDownStyles';
import { WhatsUp } from '../../../../assets/WhatsUp';
import { Telegram } from '../../../../assets/Telegram';
import { Viber } from '../../../../assets/Viber';
import CustomOption, {
	customStylesCompanyDetails,
	optionsCompanyDetails,
} from './CustomOption/CustomOption';
import { useGetCompanyById } from '../../../../hooks/use-get-company-by-id';
import { CompanyService } from '../../../../services/company.services';
import { toastVariant } from '../../../CompaniesTable/toastVariant/toastVariant';
import LoadingSpinner from '../../../../assets/LoadingSpinner';

const CompanyDetails = ({
	companyId,
	onSubmit,
	onBack,
}: CompanyDetailsProps) => {
	const { t } = useTranslate();
	const [billingAddressCheckbox, setBillingAddressCheckbox] = useState(false);
	const [selectedCountry, selectCountry] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [company] = useGetCompanyById({
		id: companyId,
	});

	useEffect(() => {
		if (company?.countryCode) {
			const country = COUNTRIES.find(
				(item) => item.value === company.countryCode,
			);
			selectCountry({ value: country?.value, label: country?.label });
		}
	}, [company?.countryCode]);

	const setBillingAddressSame = () => {
		const isSame = !billingAddressCheckbox;
		setBillingAddressCheckbox(isSame);

		if (isSame) {
			formik.setFieldValue('billingAddress', formik.values.registryAddress);
		} else {
			formik.setFieldValue('billingAddress', '');
		}
	};

	const setCountry = (data: any, _: any) => {
		selectCountry(data);
		formik.setFieldValue('country', data.label);
	};

	function onChange(option: any) {
		formik.setFieldValue(
			'companyTypes',
			option ? option?.map((item: any) => item.value) : [],
		);
	}

	const getValue = () => {
		if (optionsCompanyDetails && formik.values.companyTypes?.length) {
			return optionsCompanyDetails.filter(
				(option) => formik.values.companyTypes!.indexOf(option.value) >= 0,
			);
		}
	};

	const formik = useFormik({
		initialValues: {
			name: company?.name,
			establishedDate: company?.establishedDate,
			companyTypes: company?.companyTypes || [],
			countryCode: company?.countryCode,
			vatId: company?.vatId,
			registrationNumber: company?.registrationNumber?.toString(),
			taxNumber: company?.taxNumber?.toString(),
			registryAddress: company?.registryAddress?.replace('-', ''),
			billingAddress: company?.billingAddress?.replace('-', ''),
			email: company?.contact?.email,
			secondaryEmail: company?.contact?.secondaryEmail,
			phoneNumberPrimary: company?.contact?.phone?.phoneNumber,
			phoneNumberSecondary: company?.contact?.secondaryPhone?.phoneNumber,
			primaryIsViber: company?.contact?.phone?.isViber,
			primaryIsTelegram: company?.contact?.phone?.isTelegram,
			primaryIsWhatsUp: company?.contact?.phone?.isWhatsapp,
			secondaryIsViber: company?.contact?.secondaryPhone?.isViber,
			secondaryIsTelegram: company?.contact?.secondaryPhone?.isTelegram,
			secondaryIsWhatsUp: company?.contact?.secondaryPhone?.isWhatsapp,
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			billingAddress: Yup.string().required(
				t(
					'companyInformationEditable.billingAddress',
					'Billing Address is required',
				),
			),
			registryAddress: Yup.string().required(
				t(
					'companyInformationEditable.registryAddress',
					'Registration address is required',
				),
			),
			taxNumber: Yup.string().required(
				t(
					'companyInformationEditable.taxNumber',
					'Tax Identification Number is required',
				),
			),
		}),
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				const companyData: CompanyProps = {
					name: values.name,
					establishedDate: values.establishedDate,
					companyTypes: values.companyTypes,
					countryCode: values.countryCode,
					vatId: values.vatId,
					registrationNumber: values?.registrationNumber?.toString(),
					registryAddress: values.registryAddress,
					billingAddress: values.billingAddress,
					contact: {
						email: values.email,
						secondaryEmail: values.secondaryEmail,
						phone: {
							phoneNumber: values.phoneNumberPrimary,
							isTelegram: values.primaryIsTelegram,
							isViber: values.primaryIsViber,
							isWhatsapp: values.primaryIsWhatsUp,
						},
						secondaryPhone: {
							phoneNumber: values.phoneNumberSecondary,
							isTelegram: values.secondaryIsTelegram,
							isViber: values.secondaryIsViber,
							isWhatsapp: values.secondaryIsWhatsUp,
						},
					},
				};

				await CompanyService.companyVerificationStep1(companyId, {
					...companyData,
				});
				onSubmit();
			} catch (e) {
			} finally {
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		if (billingAddressCheckbox) {
			formik.setFieldValue('billingAddress', formik.values.registryAddress);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingAddressCheckbox, formik.values.registryAddress]);

	const onFormSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
		formik.validateForm(formik.values);
		formik.handleSubmit(e);
		if (!formik.isValid) {
			toastVariant(
				t(
					'companyInformationEditable.pleaseFillRequiredFields',
					'Please fill in all required fields',
				),
				true,
			);
		}
	};

	return (
		<form onSubmit={onFormSubmit} className="flex flex-col gap-6">
			<div className="flex flex-col px-10 py-5 gap-6">
				<div className="flex justify-between items-center pb-5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							<T keyName="companyDetails.companyDetails">Company details</T>
						</p>
					</SypacText>
				</div>
				<div className="grid grid-cols-2 gap-4 company-information-editable-border-bottom pb-5">
					<SypacInput error={!!(formik.touched.name && formik.errors.name)}>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.companyName">Company Name</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="name"
							type="text"
							placeholder={t(
								'companyDetails.enterCompanyName',
								'Enter Company Name',
							)}
							value={formik.values.name}
							onChange={(event) => formik?.handleChange(event)}
						/>
					</SypacInput>
					<SypacInput
						error={
							!!(
								formik.touched.establishedDate && formik.errors.establishedDate
							)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.established">Established</T>
						</label>
						<SypacDatepicker
							name="establishedDate"
							placeholder={t('companyDetails.enterDate', 'Enter date')}
							min="1950-01-01"
							max={dayjs().toString()}
							value={dayjs(formik?.values.establishedDate).format(
								'DD MMM. YYYY',
							)}
							onCallback={({ detail }: any) =>
								formik.setFieldValue('establishedDate', detail)
							}
							inputReadOnly={true}
						/>
					</SypacInput>
					<SypacInput
						error={
							!!(formik.touched.companyTypes && formik.errors.companyTypes)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.companyType">Company type</T>
						</label>
						<Select
							placeholder={t(
								'companyDetails.selectCompanyType',
								'Select Company Type',
							)}
							isMulti
							name="companyTypes"
							isClearable={false}
							styles={customStylesCompanyDetails}
							options={optionsCompanyDetails}
							value={getValue()}
							onChange={onChange}
							components={{ Option: CustomOption }}
						/>
					</SypacInput>
					<SypacInput
						error={!!(formik.touched.countryCode && formik.errors.countryCode)}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.country">Country</T>
						</label>
						<Select
							placeholder={t('companyDetails.country', 'Country')}
							name="countryCode"
							onChange={setCountry}
							onBlur={formik.handleBlur}
							options={COUNTRIES}
							menuPlacement={'auto'}
							components={{
								Option: (options) =>
									DropDownOption(options, formik.values.countryCode),
							}}
							classNamePrefix="dropdown"
							value={
								formik.values.countryCode
									? {
											value: selectedCountry.value,
											label: (
												<div className="flex flex-row">
													<div
														className={`flex flex-row h-8 hover:bg-mercury-50 justify-start items-center w-full min-w-full`}
													>
														<Flag
															code={selectedCountry.value}
															className="h-6 w-10 rounded"
														/>
														<div className="ml-2 text-base text-gray-100 truncate">
															{selectedCountry.label}
														</div>
													</div>
												</div>
											),
									  }
									: ''
							}
							styles={
								formik.touched.countryCode && formik.errors.countryCode
									? { ...colourStyles, ...colourErrorStyles }
									: colourStyles
							}
						/>
					</SypacInput>
					<SypacInput error={!!(formik.touched.vatId && formik.errors.vatId)}>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.vatNumber">VAT Number</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="vatId"
							type="text"
							placeholder={t(
								'companyDetails.enterCompanyType',
								'Enter Company Type',
							)}
							value={formik.values.vatId}
							onChange={(event) => formik?.handleChange(event)}
						/>
					</SypacInput>
					<SypacInput
						error={!!(formik.touched.taxNumber && formik.errors.taxNumber)}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.companyTaxNumber">
								Tax Identification Number
							</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="taxNumber"
							type="text"
							placeholder={t(
								'companyDetails.enterCompanyTaxNumber',
								'Enter Tax Identification Number',
							)}
							value={formik.values.taxNumber}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik?.touched.taxNumber && formik?.errors.taxNumber ? (
							<span className="input-error">{formik.errors.taxNumber}</span>
						) : null}
					</SypacInput>
					<SypacInput
						error={
							!!(
								formik.touched.registrationNumber &&
								formik.errors.registrationNumber
							)
						}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="companyDetails.companyRegistryNumber">
								Company Registration Number
							</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="registrationNumber"
							type="text"
							placeholder={t(
								'companyDetails.enterCompanyRegistryNumber',
								'Enter Company Registration Number',
							)}
							value={formik.values.registrationNumber}
							onChange={(event) => formik?.handleChange(event)}
						/>
					</SypacInput>
				</div>
				<div className="flex flex-col gap-6">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							<T keyName="companyDetails.contactInfo">Contact Info</T>
						</p>
					</SypacText>
					<div className="grid grid-cols-2 gap-x-4 gap-y-5">
						<div className="flex flex-col gap-2">
							<SypacInput
								error={
									!!(
										formik.touched.registryAddress &&
										formik.errors.registryAddress
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="companyDetails.registryAddress">
										Registration address
									</T>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="registryAddress"
									type="text"
									placeholder={t(
										'companyDetails.enterRegistryAddress',
										'Enter Registration address',
									)}
									value={formik.values.registryAddress}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik?.touched.registryAddress &&
								formik?.errors.registryAddress ? (
									<span className="input-error">
										{formik.errors.registryAddress}
									</span>
								) : null}
							</SypacInput>
							<div className="flex flex-row items-center">
								<SypacCheckbox size="md">
									<input
										name="billingAddressCheckbox"
										checked={billingAddressCheckbox}
										onChange={setBillingAddressSame}
										type="checkbox"
										className="cursor-pointer"
									/>
								</SypacCheckbox>
								<SypacText variant="overline-normal-large">
									<p className="text-gray-90 pl-1">
										<T keyName="companyDetails.billingAddressSame">
											Billing address the same
										</T>
									</p>
								</SypacText>
							</div>
						</div>
						<SypacInput
							error={
								!!(
									formik.touched.billingAddress && formik.errors.billingAddress
								)
							}
							disabled={billingAddressCheckbox}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyDetails.billingAddress">Billing address</T>
							</label>
							<input
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="billingAddress"
								type="text"
								placeholder={t(
									'companyDetails.enterBillingAddress',
									'Enter Billing address',
								)}
								value={formik.values.billingAddress}
								onChange={(event) => formik?.handleChange(event)}
							/>
							{formik?.touched.billingAddress &&
							formik?.errors.billingAddress ? (
								<span className="input-error">
									{formik.errors.billingAddress}
								</span>
							) : null}
						</SypacInput>
						<SypacInput error={!!(formik.touched.email && formik.errors.email)}>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyDetails.emailAddress">Email address</T>
							</label>
							<input
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="email"
								type="text"
								placeholder={t(
									'companyDetails.enterEmailAddress',
									'Enter Email address',
								)}
								value={formik.values.email}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
						<SypacInput
							error={
								!!(
									formik.touched.secondaryEmail && formik.errors.secondaryEmail
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="companyDetails.secondaryEmailAddress">
									Secondary email address
								</T>
							</label>
							<input
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="secondaryEmail"
								type="text"
								placeholder={t(
									'companyDetails.enterSecondaryEmailAddress',
									'Enter Secondary email address',
								)}
								value={formik.values.secondaryEmail}
								onChange={(event) => formik?.handleChange(event)}
							/>
						</SypacInput>
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.phoneNumberPrimary &&
										formik.errors.phoneNumberPrimary
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="companyDetails.primaryPhoneNumber">
										Primary phone number
									</T>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="phoneNumberPrimary"
									type="text"
									placeholder={t(
										'companyDetails.enterPrimaryPhoneNumber',
										'Enter Primary Phone Number',
									)}
									value={formik.values.phoneNumberPrimary}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsWhatsUp"
											checked={formik.values.primaryIsWhatsUp}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<WhatsUp />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsTelegram"
											checked={formik.values.primaryIsTelegram}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Telegram />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsViber"
											checked={formik.values.primaryIsViber}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Viber />
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.phoneNumberSecondary &&
										formik.errors.phoneNumberSecondary
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="companyDetails.secondaryPhoneNumber">
										Secondary phone number
									</T>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="phoneNumberSecondary"
									type="text"
									placeholder={t(
										'companyDetails.enterSecondaryPhoneNumber',
										'Enter Secondary Phone Number',
									)}
									value={formik.values.phoneNumberSecondary}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsWhatsUp"
											checked={formik.values.secondaryIsWhatsUp}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<WhatsUp />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsTelegram"
											checked={formik.values.secondaryIsTelegram}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Telegram />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsViber"
											checked={formik.values.secondaryIsViber}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Viber />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex justify-center w-full py-5 gap-6 border-0 border-t border-solid border-gray-10">
				<SypacButton variant="secondary" size="small">
					<button className="px-6 py-3" onClick={onBack}>
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="companyDetails.back">Back</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
				<SypacButton variant="primary" size="small">
					<button className="px-6 py-3" type="submit">
						<SypacText
							variant="body-regular-medium"
							className="flex items-center gap-4"
						>
							<p>
								<T keyName="companyDetails.nextStep">Next step</T>
							</p>
							{isLoading ? <LoadingSpinner /> : null}
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</form>
	);
};

export default CompanyDetails;
