import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../containers/NotFound';
import TransporterGuardedRoute from '../guards/TransporterGuardedRoute';
import TopBar from '../../components/TopBar/TobBar';
import { LeftSideMenu } from '../../components/Producers/LeftSideMenu/LeftSideMenu';
import OrdersList from '../../containers/Transporter/OrdersList';
import Fleet from '../../containers/Transporter/Fleet';
import TransporterBilling from '../../containers/Transporter/TransporterBilling';
import { useTranslate } from '@tolgee/react';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';

const TransporterRouter: React.FC = () => {
	const { t } = useTranslate();

	const NEW_TRANSPORTER_MENU = [
		{
			logo: 'Document Add',
			alt: 'Orders',
			count: true,
			url: '/orders',
		},
		{
			logo: 'Bus',
			alt: 'My fleet',
			url: '/fleet',
		},
		{
			logo: 'Money Bag',
			alt: 'Billing',
			url: '/billing',
		},
	];

	const TRANSPORTER_MENU = {
		'top': [
			[
				{
					icon: 'Document Add',
					label: t('transporterRouter.orders', 'Orders'),
					count: true,
					url: '/orders',
				},
				// {
				// 	icon: 'Calendar',
				// 	label: 'Calendar',
				// 	url: '/calendar',
				// },
			],
			[
				{
					icon: 'Bus',
					label: t('transporterRouter.myFleet', 'My fleet'),
					url: '/fleet',
				},
				{
					icon: 'Money Bag',
					label: t('transporterRouter.billing', 'Billing'),
					url: '/billing',
				},
			],
		],
		'bottom': [
			[
				// {
				// 	icon: 'Users Group Two Rounded',
				// 	label: 'People',
				// 	url: '/people',
				// },
				// {
				// 	icon: 'Settings',
				// 	label: 'Settings',
				// 	url: '/settings',
				// },
			],
			[
				{
					icon: 'profile_dropdown',
					label: t('transporterRouter.profile', 'Profile'),
				},
			],
		],
	};

	return (
		<Routes>
			<Route
				path="/orders"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={NEW_TRANSPORTER_MENU}>
							<OrdersList />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={NEW_TRANSPORTER_MENU}>
							<TransporterBilling />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/calendar"
				element={
					<TransporterGuardedRoute>
						<section className="main-container flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar
								title={t('transporterRouter.forTransportation', 'For Carrier')}
							/>
							<LeftSideMenu groups={TRANSPORTER_MENU}>
								<div />
							</LeftSideMenu>
						</section>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/fleet"
				element={
					<TransporterGuardedRoute>
						<SideMenu groups={NEW_TRANSPORTER_MENU}>
							<Fleet />
						</SideMenu>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<TransporterGuardedRoute>
						<section className="main-container flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar
								title={t('transporterRouter.forTransportation', 'For Carrier')}
							/>
							<LeftSideMenu groups={TRANSPORTER_MENU}>
								<div />
							</LeftSideMenu>
						</section>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/people"
				element={
					<TransporterGuardedRoute>
						<section className="main-container flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar
								title={t('transporterRouter.forTransportation', 'For Carrier')}
							/>
							<LeftSideMenu groups={TRANSPORTER_MENU}>
								<div />
							</LeftSideMenu>
						</section>
					</TransporterGuardedRoute>
				}
			/>
			<Route
				path="/settings"
				element={
					<TransporterGuardedRoute>
						<section className="main-container flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar
								title={t('transporterRouter.forTransportation', 'For Carrier')}
							/>
							<LeftSideMenu groups={TRANSPORTER_MENU}>
								<div />
							</LeftSideMenu>
						</section>
					</TransporterGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default TransporterRouter;
