export const carrierTexts = {
	main: {
		all: 'noOrdersAvailable',
		to_do: 'noOrdersWithToDo',
		progress: 'noOrdersWithInProgress',
		delivered: 'noOrdersWithDelivered',
		completed: 'noOrdersWithCompleted',
	},
	sub: {
		all: 'allIncomingOrdersMatchingYourArea',
	},
};

export const producerTexts = {
	main: {
		all: 'noOrdersAvailable',
		to_do: 'noOrdersWithToDo',
		progress: 'noOrdersWithInProgress',
		delivered: 'noOrdersWithDelivered',
		completed: 'noOrdersWithCompleted',
	},
	sub: {
		all: 'ordersWillShowUpHereWhenProductsSelected',
	},
};

export const customerTexts = {
	main: {
		all: 'youHaventPlacedAnyOrdersYet',
		created: 'youHaveNoOrdersCurrently',
		preparing: 'noOrdersWithInPreparing',
		shipped: 'noOrdersWithInShipped',
		delivered: 'noOrdersWithInDelivered',
	},
	sub: {
		all: 'yourOrdersWillAppearHere',
		created: 'youHaveNoOrdersCurrentlyDescription',
		preparing: 'noOrdersWithInPreparingDescription',
		shipped: 'noOrdersWithInShippedDescription',
		delivered: 'noOrdersWithInDeliveredDescription',
	},
};
