interface Props {
	green?: boolean;
}

export const MapIcon = (props: Props) => {
	const { green } = props;

	return (
		<svg
			width="22"
			height="18"
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.8982 5.89844L13.6725 6.15656C14.4806 6.42593 14.8847 6.56062 15.1158 6.88127C15.3469 7.20192 15.3469 7.62784 15.3469 8.47967V13.1052C15.3469 14.1589 15.3469 14.6857 15.0696 15.0242C14.9755 15.1391 14.8611 15.236 14.7323 15.31C14.3528 15.5278 13.8332 15.4412 12.7938 15.268C11.7682 15.0971 11.2554 15.0116 10.7472 15.0541C10.5688 15.0691 10.3916 15.0957 10.2168 15.1339C9.7185 15.2427 9.24943 15.4772 8.3113 15.9463C7.08714 16.5584 6.47506 16.8644 5.82352 16.9588C5.62728 16.9872 5.42924 17.0013 5.23095 17.0009C4.57262 16.9996 3.92901 16.7851 2.64179 16.356L2.3287 16.2517C1.52058 15.9823 1.11652 15.8476 0.885409 15.527C0.654297 15.2063 0.654297 14.7804 0.654297 13.9286V9.90463C0.654297 8.55055 0.654297 7.87351 1.05297 7.50938C1.12273 7.44567 1.19963 7.39024 1.28213 7.34421C1.75364 7.08114 2.39594 7.29524 3.68054 7.72344"
				stroke={green ? '#20AC93' : '#8B8B8B'}
			/>
			<path
				d="M3.10352 5.65292C3.10352 3.08318 5.29622 1 8.00106 1C10.7059 1 12.8986 3.08318 12.8986 5.65292C12.8986 8.20252 11.3355 11.1777 8.89666 12.2416C8.32813 12.4896 7.67399 12.4896 7.10546 12.2416C4.66664 11.1777 3.10352 8.20252 3.10352 5.65292Z"
				stroke={green ? '#20AC93' : '#8B8B8B'}
			/>
			<circle
				cx="7.9997"
				cy="5.89619"
				r="1.63252"
				stroke={green ? '#20AC93' : '#8B8B8B'}
			/>
		</svg>
	);
};
