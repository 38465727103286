import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../containers/NotFound';
import Products from '../../containers/Products';
import ThankYou from '../../containers/ThankYou';
import TopBar from '../../components/TopBar/TobBar';
import SelectProductGroup from '../../containers/SelectProductGroup';
import SelectProduct from '../../components/SelectProuct/SelectProduct';
import ProducerGuardedRoute from '../guards/ProducerGuardedRoute';
import SelectProductCategory from '../../containers/SelectProductCategory';
import OrdersList from '../../containers/Producer/OrdersList';
import ProducerBilling from '../../containers/Producer/ProducerBilling';
import { useTranslate } from '@tolgee/react';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';

const ProducerRouter: React.FC = () => {
	const { t } = useTranslate();

	const PRODUCER_MENU = [
		{
			logo: 'Document Add',
			alt: t('producerRouter.orders', 'Orders'),
			count: true,
			url: '/orders',
		},
		{
			logo: 'Bag Check',
			alt: t('producerRouter.myProducts', 'My products'),
			url: '/products',
		},
		{
			logo: 'Money Bag',
			alt: t('producerRouter.billing', 'Billing'),
			url: '/billing',
		},
		// {
		// 	logo: 'Users Group Two Rounded',
		// 	alt: t('producerRouter.people', 'People'),
		// 	url: '/people',
		// },
		// {
		// 	logo: 'Settings',
		// 	alt: t('producerRouter.settings', 'Settings'),
		// 	url: '/settings',
		// },
	];

	return (
		<Routes>
			<Route
				path="products/new/thank-you"
				element={
					<ProducerGuardedRoute>
						<section className="min-h-[calc(100vh-60px)] h-[calc(100vh-60px)] flex flex-col p-7.5">
							<TopBar />
							<ThankYou client={false} />
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/products"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<Products />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/orders"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<OrdersList />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/calendar"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SideMenu groups={PRODUCER_MENU}>
								<div />
							</SideMenu>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<ProducerBilling />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/people"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SideMenu groups={PRODUCER_MENU}>
								<div />
							</SideMenu>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/settings"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SideMenu groups={PRODUCER_MENU}>
								<div />
							</SideMenu>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="products/new/groups"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SelectProductGroup />
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="products/new/groups/:groupId/categories"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SelectProductCategory />
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/products/new/groups/:groupId/categories/:categoryId"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<div className="h-full w-full flex flex-col items-center justify-center">
								<SelectProduct />
							</div>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default ProducerRouter;
