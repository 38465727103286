import React from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacBox,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { ModalDepositProps } from './ModalDeposit.interface';
import './modal-deposit.css';
import { T, useTranslate } from '@tolgee/react';
import InputBorder from './components/InputBorder/InputBorder';

const ModalDeposit = ({ isOpen, onClose, onSubmit }: ModalDepositProps) => {
	const { t } = useTranslate();

	const formik = useFormik({
		initialValues: {
			sum: '',
		},
		onSubmit: (values) => {
			onSubmit(values.sum);
			onCancel();
		},
		validationSchema: Yup.object({
			sum: Yup.number()
				.min(
					1,
					t(
						'modalDeposit.minimumDepositAmount',
						'Minimum deposit amount is 1 zł',
					),
				)
				.required(t('modalDeposit.amountIsRequired', 'Amount is required')),
		}),
	});

	const onInputChange = (event: any) => {
		formik?.setFieldValue('sum', event.target.value);
	};

	const onCancel = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[428px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
		>
			<form onSubmit={formik.handleSubmit}>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between px-6 py-2.5">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalDeposit.topUpYourAccount">
									Top up your account
								</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={onCancel}
						/>
					</div>
					<div className="flex flex-col modal-login-border px-6 py-5 gap-5">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalDeposit.providesAccessToSypac">
									Provides access to Sypac.com services according to license
									agreement.
								</T>
							</p>
						</SypacText>
						<SypacBox
							className={
								formik.touched.sum && formik.errors.sum ? 'border-red' : ''
							}
						>
							<div className="flex flex-col p-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90">
										<T keyName="modalDeposit.amount">Amount</T>
									</p>
								</SypacText>
								<InputBorder
									error={!!(formik.touched.sum && formik.errors.sum)}
								>
									<input
										placeholder="0.00"
										value={formik?.values.sum}
										name="sum"
										onChange={(event) => onInputChange(event)}
									/>
								</InputBorder>
								{formik.touched.sum && formik.errors.sum && (
									<SypacText variant="body-regular-small" className="mt-2">
										<p className="text-red">{formik.errors.sum}</p>
									</SypacText>
								)}
							</div>
						</SypacBox>
						<SypacBox>
							<div className="flex p-5 gap-4">
								<SypacBox className="flex items-center justify-center w-[46px] h-[46px]">
									<SypacIcon iconName="Safe Square" />
								</SypacBox>
								<div className="flex flex-col gap-1">
									<SypacText variant="body-regular-large">
										<p className="text-gray-80">
											<T keyName="modalDeposit.paymentViaBankAccount">
												Payment via bank account
											</T>
										</p>
									</SypacText>
									<SypacText variant="overline-regular-large">
										<p className="text-gray-40">
											<T keyName="modalDeposit.longerProcessing">
												Longer processing
											</T>
										</p>
									</SypacText>
								</div>
							</div>
						</SypacBox>
					</div>
					<div className="flex justify-center w-full py-5 gap-5">
						<SypacButton variant="secondary" size="small" onClick={onCancel}>
							<button className="px-10 py-2.5 hover:border-gray-2" type="reset">
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalDeposit.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small">
							<button
								className="px-15 py-2.5 border-primary-violet hover:border-kimberly"
								type="submit"
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalDeposit.confirm">Confirm</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ModalDeposit;
