import React from 'react';
import { SypacLink, SypacText } from '@sypac/component-library-react';

const TermsAndConditionsCustomerEn = () => {
	return (
		<div>
			<div>
				<SypacText variant="body-regular-medium">
					<span className="text-gray-90 font-bold">§ 1. Application</span>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-5">
					<span className="text-gray-90">
						General Terms and Conditions (hereinafter referred to as "
					</span>
					<span className="text-gray-90 font-bold">
						General Terms and Conditions
					</span>
					<span className="text-gray-90">
						") apply exclusively between the Customer and
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">
						. Different or conflicting terms and conditions do not constitute
						the content of the legal relationship with
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>
					<span className="text-gray-90">, unless</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">
						expressly and in writing consents. The versions of these General
						Terms and Conditions that are valid at the time of conclusion of the
						contract are available at{' '}
						<SypacLink className="contents">
							<a
								href="https://www.sypac.app/en/terms-and-conditions"
								target="_blank"
								rel="noreferrer"
							>
								https://www.sypac.app/en/terms-and-conditions
							</a>
						</SypacLink>{' '}
						shall have conclusive and binding force.
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">
						provides on its website the possibility to view only the current
						version of the General Terms and Conditions,
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC</span>{' '}
					<span className="text-gray-90">
						keeps previous versions of the General Terms and Conditions in its
						archives and provides access to them to Customers, upon their
						explicit request, provided that they were already Customers of the
					</span>{' '}
					<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
					<span className="text-gray-90">
						at the date of binding of the previous General Terms and Conditions.
					</span>
				</SypacText>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 2. Subject of the contract</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								operates an electronic platform for the sale of Products via the
								Internet, as well as
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								mobile application and website{' '}
								<SypacLink className="contents">
									<a
										href="https://www.sypac.app"
										target="_blank"
										rel="noreferrer"
									>
										www.sypac.app
									</a>
								</SypacLink>{' '}
								(hereinafter collectively referred to as "
							</span>
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">"). The</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC platform</span>{' '}
							<span className="text-gray-90">
								provides sales of Products and delivery of Products in
								transparent manner.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Contracts between</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								and its suppliers, transporters and other customers governing
								mutual obligations, including the obligations of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								's partners to prepare the Product and to deliver it to the
								Customer are executed in way of electronical civil law contract
								. By registering on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, you are entering into a contract with SYPAC as a Customer.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								In the event that you are an employee or other representative of
								one of your clients, which client has selected you to act on its
								behalf on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								as a Customer of that client's account, you may act on behalf of
								that client after authorizing your access in the main profile of
								the entity (Customer).
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								These General Terms and Conditions regulate the forms of the
								Customer's access to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								and the possible ways of using the platform.
							</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Considering that the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC platform</span>{' '}
							<span className="text-gray-90">
								allows for all of the Customers to use the platform on their
								behalf by employees, contractors and other authorized
								representatives (other users, as mentioned in section 3 above),
								the Customer is obliged to:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium">
									<span className="text-gray-90">
										Be responsible for all actions of other users, using SYPAC
										platform on Customer’s behalf, as for their own actions;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-10">
									<span className="text-gray-90">
										Require other users, using
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC platform</span>{' '}
									<span className="text-gray-90">
										on Customer’s behalf, to use the platform only in accordance
										with these regulations and other regulations adopted between
									</span>{' '}
									<span className="text-gray-90 font-bold">SYPAC</span>{' '}
									<span className="text-gray-90">and Customer.</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 3. Login authorization</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">All costs associated with</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								services are borne solely by the Customer base on the electronic
								civil law contract. Customers who are not customers, but only
								persons authorized by him, do not pay additional fees.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Use of the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								requires registration as a Customer. The availability of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								does not create an entitlement to expect registration by the
								Customer.
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								may, at its discretion, reject any application for registration
								on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">without giving reasons.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Only adults with full legal capacity can register and use the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. This requirement applies to both customers who are individuals
								and Customers who represent customers on the
							</span>
							<span className="text-gray-90 font-bold">SYPAC Platform.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Contact information and other information requested by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								during registration must be complete and true.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								reserves the right to check the accuracy of the data provided
								and to verify its completeness and reliability by contacting you
								or the entrepreneur you represent for this purpose.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Once you have successfully completed the registration process,
								your access will be activated and you will receive the
								appropriate notification by email or SMS (in the form selected
								by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								). This notification sent by email or SMS will have the effect
								of registering and entering into a contract with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								under these terms and conditions, provided that you confirm your
								contact information and complete the registration process on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								using the activation link. After receiving this notification by
								email or SMS, you may use
							</span>{' '}
							<span className="text-gray-90 font-bold">Platform SYPAC</span>{' '}
							<span className="text-gray-90">
								under the terms of these General Terms and Conditions.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 4. Responsibility for access data
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								During the registration process, you will be asked to enter your
								username and password. With these data, you can log in to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								after activating access and confirming in accordance with § 3
								sec. 6 It is the Customer's responsibility to make sure that
								his/her name does not infringe any third party rights, in
								particular any rights to the name or trademark, and does not
								contradict with principles of social coexistence, in particular
								it is free from vulgar expressions and phrases commonly regarded
								as insulting. Registration of Customers referred to in § 2 sec.
								3 (employees or other representatives) runs from the Customer's
								main account, who, when adding a subordinate user (employee or
								representative), enters his/her e-mail address, to which an
								activation link will be automatically sent.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The Customer agrees to keep the access data, including the
								password, confidential and not to share it with unauthorized
								third parties, regardless of whether the access data was chosen
								by the Customer himself or was provided to him for use by the
								customer.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The Customer is obliged to ensure that the use of services
								available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								using his/her access data is performed by him/her only
								personally. If there is a risk that an unauthorized third party
								has obtained or will obtain the Customer's access data,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								must be notified immediately. Until access is blocked (which
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								does immediately, no later than within one business day), all
								actions taken under a Customer's account are considered to have
								been taken by that Customer.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You are responsible for any actions taken on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								based on your access data, in accordance with applicable law.
								The customer will be obliged to compensate for any damages
								incurred by SYPAC and caused by inadequate storage of access
								data.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You are required to keep your profile information (including
								contact information) up to date at all times. If your
								information changes during your participation on the platform,
								you must immediately correct the information in your personal
								settings on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. If you fail to do so, inform
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								immediately of any changes to your information, such as by
								email.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 5. Termination of participation
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Access to the od</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								shall automatically terminate, without cancellation or other
								notice to you, upon termination of the contractual relationship
								between the Customer and
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								or upon termination of the Customer's status as an administrator
								or other user by the Customer in its relationship with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								. The customer is entitled to withdraw this status from the user
								at any time.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								reserves the right to temporarily or permanently block the
								Customer's access to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								in accordance with the applicable terms and conditions and with
								the consent of the Customer.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								shall be entitled to irrevocably delete all data created in the
								context of the Customer's participation 30 calendar days after
								the Customer's access to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								Platform has been blocked and any statutory retention periods
								have expired.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 6. General Customer Responsibilities
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium" className="mt-10">
					<p className="text-gray-90">
						In order to enable the SYPAC Platform to function properly so that
						SYPAC can properly provide its contractual services, and especially
						when there is a large number of Customers and other users, all users
						and direct Customers must comply with certain regulations and
						obligations.
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You shall comply with the instructions for use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">provided by</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								. The user will systematically check the sources of information
								provided on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, as well as the sources of information available on the
								Internet at www.sypac.app and will take them into account when
								using the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform.</span>
						</SypacText>
					</div>
				</div>

				<div className="flex flex-row mt-5 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You will systematically check the information made available by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">on the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								and, in particular, through the notification function available
								there, in order to familiarize yourself with any new news,
								documents, notifications and other information. Information sent
								to the User using the notification function on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								shall be considered received by the User no later than the end
								of the relevant business day on which the notification was sent.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You are required to use up-to-date anti-virus software on your
								local systems used to access the
							</span>
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, and to apply appropriate safeguards against hacking attacks,
								virus infections and comparable disruptions throughout the term
								of the contract, which includes, in particular, always
								installing without delay the security updates available for the
								operating system used.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">The User consents to</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								's access to his/her data to the extent necessary to analyze and
								correct errors and defects reported by the User, as well as in
								the context of the requested support, and to ensure the proper
								operation of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, and in particular for the purpose of analyzing functional
								processes and examining or testing file structures.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You are fully and solely responsible for the content you post on
								the
							</span>{' '}
							<span className="text-gray-90 font-bold">
								SYPAC Platform. SYPAC
							</span>{' '}
							<span className="text-gray-90">
								does not check the content provided for completeness, accuracy,
								legality, timeliness, quality or suitability for a particular
								purpose.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">You represent and warrant to</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								that you are the rightful owner of the rights to the content you
								submit or input (e.g. photos, names, names or phone numbers) or
								is at least otherwise authorized (e.g. based on a valid power of
								attorney granted by authorized persons) to transfer content to
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, to make it available for use on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, and to make it available to users on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. Therefore, the User shall ensure that he/she is entitled to
								exercise the rights to use this information or that the relevant
								files made available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								do not violate laws, good practices or the rights of third
								parties before submitting photos, plans, sketches and other
								information. The user may publish photos in which people can be
								recognized only after properly obtaining their effective
								consent.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">You release</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								from all claims and indemnify
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								against all claims made against
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								by third parties (including foreign users) due to alleged
								infringement of their rights by the uploaded or entered content
								or due to other use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. This right of release specifically includes the costs of any
								necessary legal defense undertaken by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, including all court costs and legal fees.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">8.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								reserves the right to refuse to share or introduce content, to
								edit, block or delete shared content without notice, if the
								shared content can be interpreted as violating § 6 para. 6 or if
								there is a strong presumption that there may be a violation of §
								6 para. 6.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">9.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								stores for the Customer, as a technical service provider, the
								content and data that the Customer and its users provide in the
								course of using the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								and that arise in the course of using the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. The user is obliged not to post or use content and data the
								posting of which is subject to criminal liability or otherwise
								unlawful absolutely or in relation to third parties, as well as
								not to use programs containing viruses or other malware and not
								to expose the notification software to such threats. You are the
								controller of this personal data and must therefore always
								verify that the processing of this data on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								is permitted on the basis of proper consent in accordance with
								applicable law. You agree to receive emails, SMS and other
								notifications generated by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, especially during the initial registration process. You may
								revoke this consent in whole or in part at any time with respect
								to
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, but in this case any further use by you of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								may not be possible or will only allow limited functionality.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">10.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">The use of the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								and its applications is permitted solely and expressly for the
								purposes set forth in Paragraph 2(2). 2. Any use beyond this
								scope is prohibited. You may not use the contact information of
								other users that is available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								for any purpose other than the commercial communication of
								Customers, and you are permitted to use it only to the extent
								that you have consented or as permitted by applicable law.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">11.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">Interference with the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								is prohibited. In particular, it is forbidden to use measures
								that may lead to excessive load on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								(e.g. by sending mass notifications or messages, sending
								unwanted messages (spam) or a Denial of Services attack) or to
								unreasonably engage the attention of other users.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">12.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Any electronic attacks against the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								(including the hardware and software used to ensure its
								operation) or against individual users are prohibited. Such
								electronic attacks include, but are not limited to, the measures
								indicated below:
							</span>
						</SypacText>
						<ul>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										Hacking attacks, i.e. Attempts to take over, bypass or
										otherwise disable the security features of the
									</span>{' '}
									<span className="text-gray-90 font-bold">
										SYPAC Platform;
									</span>{' '}
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										The use or distribution of viruses, computer worms, Trojan
										horses and other harmful or malicious files;
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										Attacks of following types: "brute force", "reverse
										engineering" or "data mining";
									</span>
								</SypacText>
							</li>
							<li>
								<SypacText variant="body-regular-medium" className="mt-6">
									<span className="text-gray-90">
										Other means or procedures or exploit programs that may
										disrupt the SYPAC Platform, including the hardware and
										software used to ensure its operation, or harm SYPAC, or any
										of users.
									</span>
								</SypacText>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">
						§ 7. Availability and operation of the Platform FALL
					</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								guarantees, as one of its obligations, the average annual
								availability of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">at 99.99%. Normally,</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								services are provided from Monday to Sunday. From 00:00 to 24:00
								every day (24 hours a day, 7 days a week). The availability
								calculation does not include scheduled system service periods,
								which will fall on weekdays from hrs. 2:00 am to hrs. 4:00 a.m.
								and around the clock on Sundays and holidays. The
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC platform</span>{' '}
							<span className="text-gray-90">
								is hosted on one or more servers. The transfer point of the
								service is the Internet connection using the relevant data
								center. You are required to provide your own Internet
								connection, provide or maintain a network connection with the
								relevant data center, and acquire and provide network access
								equipment to enable Internet use on the customer side of the
								platform. Access to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC platform</span>
							<span className="text-gray-90">, provided by</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								at no additional cost, is via a browser or using applications
								designed for use on smartphones or tablets using encryption
								technology, such as the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">. SSL. Use of the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								requires a stable Internet connection. This is especially true
								for accessing the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								via apps or mobile devices. Use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								is not possible without maintaining an uninterrupted Internet
								connection.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You have the right to use the services available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">only within the limits of</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								's technical and operational capabilities.
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								strives to ensure the uninterrupted use of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								to the extent technically possible. However, technical failures
								(e.g. power outages, hardware and software errors, technical
								problems on the links used to transmit data) may cause temporary
								restrictions or interruptions.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">shall ensure that the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								operates exclusively on the basis of data processing systems in
								force in the Republic of Poland, in another Member State of the
								European Union or in another state that is a party to the
								Agreement on the Establishment of the European Economic Area,
								and that the Client's data are stored without exception in the
								Republic of Poland, another Member State of the European Union
								or another state that is a party to the Agreement on the
								Establishment of the European Economic Area. However,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								has the right to change this place of performance at any time
								within the boundaries of the aforementioned countries at its
								discretion.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								ensures that the data of individual contracting parties is
								administered separately so that other Customers or other
								unauthorized third parties do not have access to their data.
								Excluded from this are the data of other
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								users, such as. suppliers and freight forwarders, the services
								of which
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								uses to the extent necessary for the delivery of products
								ordered by the Customer.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">5.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Systematically, and at least once a day according to the
								schedule,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								backs up the data stored on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, especially data on orders, deliveries, invoices, etc., in
								order to ensure that lost data and information can be restored
								as part of the resumption of system operations during the day.
								Data changes, whether intentional (e.g. data entry), as well as
								inadvertent (e.g. deletion of data), made between backups are
								not saved. However, the customer is solely responsible for
								compliance with the storage deadlines under common law.
								Long-term storage of data backups for archiving purposes is not
								part of the services provided by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">under the</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">6.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								strives to continuously develop the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">. Therefore,</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								is authorized to make changes to user interfaces, functions,
								services and applications provided on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								at any time, especially regarding layout and design, and to
								restore their availability. Such ongoing development may or may
								not involve reducing or even removing particular partial
								functionality in subdomains on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								. If the legitimate interests of the Customer may be
								significantly affected by a change in service affecting the
								declared state of the
							</span>
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">,</span>
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will inform the Customer of the change at least three months
								prior to its implementation. In this case, the Customer may
								object to the change of action within one month after its
								announcement. The objection must be submitted in writing. If
							</span>
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								does not allow the Customer to use the
							</span>
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								in a previously available form immediately after the objection,
								the partner may terminate the agreement on an emergency basis
								with immediate effect within two weeks from the effective date
								of the service change in question.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">7.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Due to the nature of the goods sold, warranty liability to
								Customers conducting business (entrepreneurs) who are Parties to
								contracts with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								shaped by these General Terms and Conditions is excluded but
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will be engaged in settling any warranty issues
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 8. Blocking access</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								may block a Customer's or any other user’s access to the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, in whole or in part, temporarily or permanently, if there are
								indications that there has been a violation of these General
								Terms and Conditions, other terms and conditions of the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								or applicable law on the part of the Customer, or if
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								has any other legitimate interest in blocking access. In
								deciding whether to block access,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will give due consideration to the legitimate interests of the
								Customer and the legitimate interests of the Customer.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								If access to the Platform is temporarily or permanently blocked,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will block the relevant access privileges and inform the
								Customer by email.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 9. Term and Termination</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">This</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								Agreement is concluded for an indefinite period of time. Either
								party may terminate it with one (1) full month's notice
								effective at the end of the month. If available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, the Customer may also resign without notice by using the
								resignation or deletion function available on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								and by doing so terminating the contract with immediate effect
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Either party may terminate the contract without notice for good
								cause.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Any termination notice must at least preserve the documentary
								form of the statement of intent. In this regard, the opt-out
								function provided to the Customer on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, if applicable, is excluded.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 10. Responsibility SYPAC</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								's liability for damages, regardless of cause, including delay,
								improper performance of an obligation, breach of contract,
								breach of duty in negotiating a contract, and torts is limited
								to damages caused by wilful misconduct.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								To the extent of personal injury caused by
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, its legal representatives or agents,
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								shall also be liable for damages resulting from gross
								negligence.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Any further liability on the part of
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								is excluded regardless of the legal nature of the claims made.
								This applies in particular to tort claims. To the extent that
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								's liability has been excluded or limited, such exclusion or
								limitation shall also include the personal liability of
								employees, representatives or agents.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								To the extent not regulated in this paragraph, the provisions of
								the Polish Civil Code shall apply, in particular regarding tort
								and contractual liability.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 11. Data protection</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								will comply with the applicable data protection laws, in
								particular those in force in the Republic of Poland or in the
								country where the data subject resides, and will oblige its
								employees involved in the cooperation between the Customer and
								the Customer, on the one hand, and
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">
								, on the other hand, to maintain the confidentiality of the
								data, to the extent that they are not already obliged to do so
								under general rules.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								If you collect, process or use personal data using the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>
							<span className="text-gray-90">
								, you will warrant that you have the right to do so in
								accordance with applicable laws, particularly data protection
								laws, and that you will protect
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								from third-party claims in the event of a breach.
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								Detailed information on data protection obligations and
								regulations is available in the relevant applicable
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								data protection regulations that are part of the contract
								(listed at the beginning of these General Terms and Conditions).
								In accordance with legal requirements, data protection issues
								that require regulation are included in these regulations.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90 font-bold">§ 12. Provisions final</p>
				</SypacText>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">1.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								You may assign your rights and obligations under this Agreement
								to third parties only with
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">'s express written consent.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">2.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								All disputes arising in connection with this Agreement
								(including those concerning its validity) shall be resolved in
								the first instance exclusively by the Polish courts with
								jurisdiction over
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>
							<span className="text-gray-90">'s registered office.</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">3.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								This Agreement shall be governed exclusively by Polish law,
								excluding the United Nations Convention on Contracts for the
								International Sale of Goods of April 11, 1980. (UN Sales
								Convention).
							</span>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-row mt-10 ml-1">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">4.</p>
					</SypacText>
					<div className="flex flex-col ml-1">
						<SypacText variant="body-regular-medium">
							<span className="text-gray-90">
								The entire contractual legal relationship formed between the
								Customer represented by the Customer and
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC</span>{' '}
							<span className="text-gray-90">
								as a result of the Customer's registration on the
							</span>{' '}
							<span className="text-gray-90 font-bold">SYPAC Platform</span>{' '}
							<span className="text-gray-90">
								is formed by these General Terms and Conditions, supplemented by
								the Purchase Regulations, the Terms of Service and the Privacy
								and Personal Data Policy.
							</span>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditionsCustomerEn;
