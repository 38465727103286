const BackArrow = () => {
	return (
		<svg
			width="32"
			height="14"
			viewBox="0 0 32 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.83008 13L2.24429 8.41421C1.46324 7.63317 1.46324 6.36684 2.24429 5.58579L6.83008 1"
				stroke="#454545"
				strokeLinecap="round"
			/>
			<path
				d="M2 7L31 7"
				stroke="#454545"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default BackArrow;
