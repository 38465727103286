import classNames from 'classnames';
import { SypacBadge, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import Modal from 'react-modal';

import { Logout } from '../../assets/Logout';
import { AuthsService } from '../../services/auths.service';
import { useContext } from 'react';
import { AuthContext } from '../../context/context';
import { RenderIconByTarget } from '../RenderIconByTarget/RenderIconByTarget';
import {
	colorsTarget,
	titlesTarget,
} from '../../constants/company-target.constants';
import { RadioCheked } from '../../assets/RadioCheked';
import { Radio } from '../../assets/Radio';

interface Props {
	isLogoutOnly?: boolean;
	isOpen: boolean;
	onClose: () => void;
	isModal?: boolean;
}

export const ProfileModal = ({
	isOpen,
	onClose,
	isLogoutOnly = false,
	isModal = true,
}: Props) => {
	const { user } = useContext(AuthContext);
	if (
		(user?.company?.industries?.length === 1 || !user?.company?.industries) &&
		!isLogoutOnly
	) {
		isLogoutOnly = true;
	}

	const logout = () => {
		AuthsService.logout();
	};

	const changeCompany = (companyType: string) => {
		let newUrl = '';
		if (companyType === 'transporter') {
			newUrl = window.location.origin.replace('producer', 'transporter');
		}
		if (companyType === 'producer') {
			newUrl = window.location.origin.replace('transporter', 'producer');
		}
		if (newUrl?.length) {
			const lang = localStorage.getItem('lang') || 'pl';
			const accessToken = localStorage.getItem('access_token');
			const refreshToken = localStorage.getItem('refresh_token');
			window.location.replace(
				`${newUrl}?access_token=${accessToken}&refresh_token=${refreshToken}&lang=${lang}`,
			);
		}
	};

	const renderContent = (
		<>
			{!isLogoutOnly && (
				<div className="p-5">
					<SypacText variant="body-regular-large">
						<p>
							<T keyName="profileModal.profile">Profile</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small" className="mt-3">
						<p>
							<T keyName="profileModal.switchBetweenProfiles">
								Seamlessly switch between profiles with ease.
							</T>
						</p>
					</SypacText>
					<div className="flex flex-col mt-5 w-full">
						{user?.company.industries?.map((r, index) => {
							return (
								<button
									key={index}
									className={classNames(
										'flex flex-row items-center justify-between rounded-10 border border-solid border-gray-40 p-3 relative',
										{ 'cursor-pointer': !window.location.host.includes(r) },
										{
											'pointer-events-none': !!window.location.host.includes(r),
										},
										{ 'mt-3': index > 0 },
									)}
									onClick={() => changeCompany(r)}
								>
									<div className="flex flex-row items-center">
										<div className="mt-1">
											{window.location.host.includes(r) ? (
												<RadioCheked />
											) : (
												<Radio />
											)}
										</div>
										<SypacText variant="body-regular-medium" className="ml-5">
											<p className="text-gray-80 -tracking-[0.01em]">
												<T keyName={`profileModal.${r}`}>{titlesTarget[r]}</T>
											</p>
										</SypacText>
									</div>
									<SypacBadge color={colorsTarget[r]} size="large">
										<div className="flex items-center justify-center gap-2 py-0 px-2.5">
											<RenderIconByTarget target={r} />
										</div>
									</SypacBadge>
								</button>
							);
						})}
					</div>
				</div>
			)}
			<div
				className={classNames(
					'flex flex-row items-center p-5 border-0 cursor-pointer',
					{ 'border-t border-solid border-gray-10': !isLogoutOnly },
				)}
				onClick={logout}
			>
				<Logout />
				<SypacText variant="body-regular-medium" className="ml-5">
					<p>
						<T keyName="producerRouter.logOut">Log out</T>
					</p>
				</SypacText>
			</div>
		</>
	);

	return (
		<>
			{isModal ? (
				<Modal
					isOpen={isOpen}
					onRequestClose={onClose}
					className="absolute bottom-28 left-12 w-[350px] bg-white border border-solid border-gray-10 shadow-profile-modal rounded-lg outline-none"
					overlayClassName="modal-overlay-without-bg"
					shouldCloseOnOverlayClick={true}
				>
					{renderContent}
				</Modal>
			) : (
				<div className="mb-6 ml-0 w-[350px] bg-white border border-solid border-gray-10 shadow-profile-modal rounded-lg outline-none">
					{renderContent}
				</div>
			)}
		</>
	);
};
