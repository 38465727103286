import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import { CompanyResponse } from '../services/company.services';

interface Props {
	id: number;
	refresh?: number;
}

type UseGetCompanyById = [
	CompanyResponse | undefined,
	Error | undefined,
	boolean,
];

export const useGetCompanyById = ({
	id,
	refresh = 0,
}: Props): UseGetCompanyById => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		if (!id) {
			return;
		}
		facade.client
			.get(`/v1/admin/companies/${id}`, {})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, refresh, id]);

	return [data, error, isLoading];
};
