export enum OrderStatus {
	created = 'created',
	to_do = 'to_do',
	accepted = 'accepted',
	in_progress = 'in_progress',
	canceled = 'canceled',
	shipped = 'shipped',
	reviewing = 'reviewing',
	delivered = 'delivered',
	awaiting_confirmation = 'awaiting_confirmation',
	sent_to_driver = 'sent_to_driver',
}
export enum ProductUnitType {
	m3 = 'm3',
	ton = 'ton',
}

export enum QuantityType {
	custom = 'custom',
	aluminium = 'aluminium',
	steel = 'steel',
	kempf_aluminium = 'kempf_aluminium',
	kempf_steel = 'kempf_steel',
	hooklifter = 'hooklifter',
}

export enum PaymentMethod {
	card = 'card',
	bank = 'bank',
	apple_pay = 'apple_pay',
	google_pay = 'google_pay',
}

export enum OrderTarget {
	customer = 'customer',
	producer = 'producer',
	transporter = 'transporter',
	admin = 'admin',
}
