import React, { useCallback, useContext, useEffect, useState } from 'react';

import './left-side-menu.css';
import { LeftSideMenuProps } from './LeftSideMenuProps';
import { LeftSideMenuItem } from './LeftSideMenuItem/LeftSideMenuItem';
import { LogoBlack } from '../../../assets/LogoBlack';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import { LineStrokeVertical } from '../../../assets/LineStrokeVertical';
import { LineStroke } from '../../../assets/LineStroke';
import Emitter, { EventType } from '../../../services/events';
import { ProfileModal } from '../../ProfileModal/ProfileModal';
import { T } from '@tolgee/react';
import { NotificationsService } from '../../../services/notifications.services';
import { AuthContext } from '../../../context/context';
import useDebounce from '../../../hooks/useDebounce';

export const LeftSideMenu = ({ groups, children }: LeftSideMenuProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [count, setCount] = useState<number>(0);
	const [openedProfile, openProfile] = useState<boolean>(false);
	const { user } = useContext(AuthContext);
	const showCount = useDebounce(count, 500);

	const getCount = useCallback(async () => {
		try {
			if (user?.uid) {
				const resp = await NotificationsService.getCount({
					recipientId: user?.uid,
					limit: 10,
				});
				if (resp.data.count) {
					// const notificationIds = resp.data?.items
					// 	?.filter((r) => !r.readAt)
					// 	?.map((r) => r.id);
					// await NotificationsService.readNotify(notificationIds);
					setCount(resp.data.count);
					Emitter.emit(EventType.ORDER_REFRESH, resp.data.items);
				}
			}
		} catch (e) {}
	}, [user?.uid]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getCount();
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count) => {
			setCount((prev) => (prev - count >= 0 ? prev - count : 0));
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getCount]);

	const openModal = () => {
		openProfile(true);
	};

	const closeModal = () => {
		openProfile(false);
	};

	useEffect(() => {
		getCount();
	}, [getCount]);

	return (
		<div className="flex flex-row gap-4 h-full">
			<div
				className={`flex-none w-side-bar z-10 max-w-full relative ${
					groups.logo ? 'h-[calc(100vh-60px)]' : 'h-[calc(100vh-147px)]'
				}`}
			>
				<SypacBox
					className="flex flex-col justify-between bg-gray-1 side-bar-border side-bar-shadow w-full"
					hover={false}
					box-style="none"
				>
					<div className="">
						{groups.logo ? (
							<>
								<div className="p-4 pb-0 flex flex-row gap-4 items-center">
									<a href="/">
										{groups.logo.text.includes('sideMenu.forProducers') ||
										groups.logo.text.includes(
											'transporterRouter.forTransportation',
										) ? (
											<LogoBlack width="97" height="18" />
										) : (
											<LogoBlack />
										)}
									</a>
									<LineStrokeVertical />
									<SypacText variant="overline-regular-medium">
										<p className="text-gray-80">
											<T keyName={groups.logo.text}>{groups.logo.text}</T>
										</p>
									</SypacText>
								</div>
								<div className={`${!groups.logo && 'hidden'}`}>
									<LineStroke />
								</div>
							</>
						) : (
							''
						)}
						{groups.top?.map((items: any, index: number) => {
							return (
								<div key={`${Math.random()}-${index}`}>
									<div
										className={`${
											!groups.logo && 'border-bottom'
										} p-4 flex flex-col gap-4`}
									>
										{items.map((item: any, i: number) => {
											return (
												<LeftSideMenuItem
													key={`${Math.random()}-${i}`}
													icon={item.icon}
													label={item.label}
													count={item.count && showCount ? showCount : 0}
													url={item.url}
													onClick={() => (item.onClick ? item.onClick() : '')}
													openProfile={openModal}
												/>
											);
										})}
									</div>
									<div className={`${!groups.logo && 'hidden'}`}>
										<LineStroke />
									</div>
								</div>
							);
						})}
					</div>
					<div>
						{groups.bottom?.map((items: any, index: number) => {
							return (
								<div key={`${Math.random()}-${index}`}>
									<div
										className={`${
											!groups.logo && 'border-top'
										} p-4 flex flex-col gap-4`}
									>
										{items.map((item: any, i: number) => {
											return (
												<LeftSideMenuItem
													key={`${Math.random()}-${i}`}
													icon={item.icon}
													label={item.label}
													url={item.url}
													onClick={() => (item.onClick ? item.onClick() : '')}
													openProfile={openModal}
												/>
											);
										})}
									</div>
									<div
										className={`${
											!groups.logo ? 'hidden' : 'last-of-type:hidden'
										}`}
									>
										<LineStroke />
									</div>
								</div>
							);
						})}
					</div>
				</SypacBox>
				{openedProfile ? (
					<ProfileModal isOpen={openedProfile} onClose={closeModal} />
				) : null}
			</div>
			<div className="w-full h-full">{children}</div>
		</div>
	);
};
