export const Logout = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			height="34"
			fill="none"
			viewBox="0 0 31 34"
		>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				strokeWidth="2"
				d="M9.004 9c.02-3.48.174-5.365 1.403-6.594C11.813 1 14.076 1 18.601 1h1.6c4.526 0 6.788 0 8.194 1.406 1.406 1.406 1.406 3.669 1.406 8.194v12.8c0 4.526 0 6.788-1.406 8.194C26.99 33 24.727 33 20.201 33h-1.6c-4.525 0-6.788 0-8.194-1.406-1.23-1.23-1.384-3.114-1.403-6.594"
			></path>
			<path
				stroke="#F44A77"
				strokeWidth="2"
				d="M9 29c-3.771 0-5.657 0-6.828-1.172C1 26.657 1 24.771 1 21v-8c0-3.771 0-5.657 1.172-6.828C3.343 5 5.229 5 9 5"
			></path>
			<path
				stroke="#F44A77"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M20.2 17H5.8m0 0L9 20.2M5.8 17L9 13.8"
			></path>
		</svg>
	);
};
