import { SypacCheckbox, SypacText } from '@sypac/component-library-react';
import { useState, useRef, useEffect } from 'react';

import Dropdown from './Dropdown';
import classNames from 'classnames';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';

interface MenuItemsInterface {
	items: Record<string, any>;
	depthLevel: number;
	setSelected: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
	selected: any[];
	setChecked?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
	selectedItems: any[];
	handleMouseLeave?: any;
}

const MenuItems = ({
	items,
	depthLevel,
	setSelected,
	setChecked,
	selectedItems,
	selected,
	handleMouseLeave,
}: MenuItemsInterface) => {
	const [classDrop, setClass] = useState<string>('top-0');
	const [dropdown, setDropdown] = useState<boolean>(false);
	const [checked, setLocalChecked] = useState<boolean>(
		selectedItems?.find((el) => el === items.variant),
	);
	let ref = useRef<any>(null);

	const onMouseEnter = () => {
		window.innerWidth > 960 && setDropdown(true);
	};

	const onMouseLeave = () => {
		window.innerWidth > 960 && setDropdown(false);
	};

	const handleCheckboxChange = () => {
		setLocalChecked((prev) => !prev);
	};

	const skipCheckBox = ['business_days', 'full_week'];

	const variants = [...skipCheckBox, 'days'];

	const onClick = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
		event.preventDefault();
		handleCheckboxChange();
		setChecked && setChecked(item);
		setSelected((prev) =>
			items.variant === prev[0].variant
				? prev
				: variants.includes(items.variant)
				? [items]
				: [{ variant: 'custom', title: 'Custom' }, items],
		);
		handleMouseLeave();
	};

	useEffect(() => {
		const points = ref.current.getBoundingClientRect();
		if (points.bottom) {
			const distance = window.innerHeight - points.bottom;
			if (distance < 410) {
				setClass('top-[-300%]');
			}
		}
	}, []);

	const openDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setDropdown((prev) => !prev);
	};

	return (
		<li
			className={`menu-items relative w-full`}
			ref={ref}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{items.variant && items.submenu ? (
				<>
					<button
						type="button"
						aria-haspopup="menu"
						className={
							'hover:bg-gray-2 text-black flex justify-between text-center'
						}
						aria-expanded={dropdown ? 'true' : 'false'}
						onClick={openDropDown}
					>
						{depthLevel === 0 ? (
							<SypacText variant="body-normal-medium">
								<div>{items.title}</div>
							</SypacText>
						) : (
							<SypacText>
								<div>{items.title}</div>
							</SypacText>
						)}
						<span
							className={classNames(
								'flex my-auto transform mr-1 -rotate-90 transition-all',
								{
									'rotate-0': dropdown,
								},
							)}
						>
							<ChevronRightIcon />
						</span>
					</button>
					<Dropdown
						depthLevel={(depthLevel += 1)}
						submenus={items.submenu}
						dropdown={dropdown}
						setSelected={setSelected}
						setChecked={setChecked}
						selectedItems={selectedItems}
						selected={selected}
						classDrop={classDrop}
					/>
				</>
			) : !items.variant && items.submenu ? (
				<>
					<button
						type="button"
						className={
							'hover:bg-gray-2 text-black mr-1 flex justify-between text-center'
						}
						aria-haspopup="menu"
						aria-expanded={dropdown ? 'true' : 'false'}
						onClick={openDropDown}
					>
						<SypacText variant="body-normal-medium">
							<div>{items.title}</div>
						</SypacText>
						<span
							className={classNames(
								'flex my-auto transform mr-1 -rotate-90 transition-all',
								{
									'rotate-0': dropdown,
								},
							)}
						>
							<ChevronRightIcon />
						</span>
					</button>
					<Dropdown
						depthLevel={(depthLevel += 1)}
						submenus={items.submenu}
						dropdown={dropdown}
						setSelected={setSelected}
						setChecked={setChecked}
						selectedItems={selectedItems}
						selected={selected}
						classDrop={classDrop}
					/>
				</>
			) : (
				<button
					className={`cursor-pointer flex items-center gap-2.5 ${
						depthLevel > 0 ? 'hover:bg-transparent' : 'hover:bg-gray-2'
					}`}
					onClick={(e) => onClick(e, items)}
				>
					{!skipCheckBox.includes(items.variant) ? (
						<SypacCheckbox size="md">
							<input
								className={classNames('pointer-events-none')}
								type="checkbox"
								onChange={handleCheckboxChange}
								checked={checked}
							/>
						</SypacCheckbox>
					) : null}
					<SypacText variant="body-normal-medium">
						<div className="mt-0.5">{items.title}</div>
					</SypacText>
				</button>
			)}
		</li>
	);
};

export default MenuItems;
