import { LeftSideMenuItem } from '../LeftSideMenuItem/LeftSideMenuItem';

interface StatsI {
	value: string;
	label: string;
	status: string;
}
interface LeftSideMenuI {
	stats: StatsI[];
	selectedStatus: string;
	setStatus: (status: string) => void;
}

export const LeftSideMenu = ({
	stats,
	selectedStatus,
	setStatus,
}: LeftSideMenuI) => {
	return (
		<section className="p-4 border-px rounded-2xl border-[#E8E8E8]">
			{stats.map((item, i) => {
				return (
					<LeftSideMenuItem
						key={i}
						state={item.status}
						label={item.label}
						count={item.value}
						active={item.status === selectedStatus}
						onClick={() => setStatus(item.status)}
					/>
				);
			})}
		</section>
	);
};
