import React from 'react';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import './modal-store-product.css';
import { ModalStoreProductProps } from './ModalStoreProduct.interface';
import { T } from '@tolgee/react';
import { Future } from '../../assets/Future';

const ModalStoreProduct = ({ isOpen, onClose }: ModalStoreProductProps) => {
	return isOpen ? (
		<div className="modal-overlay" onClick={() => onClose()}>
			<div
				className="modal-inside outline-none max-w-[475px]"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between px-6 py-4">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalStoreProduct.ViewInStore">View in store</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={onClose}
						/>
					</div>
					<div className="flex flex-col items-center justify-center modal-delete-document-border px-6 py-10">
						<div className="mt-3">
							<Future />
						</div>
						<SypacText variant="heading-5" className="mt-20">
							<p className="text-gray-80">
								<T keyName="modalStoreProduct.feature">
									Feature is not available at the moment
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small" className="mt-6">
							<p className="text-gray-40 text-center">
								<T keyName="modalStoreProduct.featureDescription">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt
								</T>
							</p>
						</SypacText>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalStoreProduct;
