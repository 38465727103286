import React from 'react';
import {
	SypacVerticalTimeline,
	SypacVerticalTimelineStep,
} from '@sypac/component-library-react';

import DriverMultipleNotification from '../../../components/Notification/DriverMultipleNotification';
import { useTranslate } from '@tolgee/react';

interface NotificationDriverArrivedUnloadingProps {
	notification: boolean;
	date?: string;
	loadingPlace?: string;
}
const NotificationDriverArrivedUnloading = ({
	notification,
	date,
	loadingPlace,
}: NotificationDriverArrivedUnloadingProps) => {
	const { t } = useTranslate();

	return (
		<div className="flex flex-row gap-6">
			<SypacVerticalTimeline>
				<SypacVerticalTimelineStep isActive={notification} minWidth={150}>
					{date}
				</SypacVerticalTimelineStep>
			</SypacVerticalTimeline>
			<div className="h-fit w-full">
				<DriverMultipleNotification
					notification={notification}
					title={t(
						'notificationDriverArrivedUnloading.driverArrivedUnloadingPlace',
						'The driver arrived at the unloading place',
					)}
					description={t(
						'notificationDriverArrivedUnloading.pendingUnloadingGoods.',
						'Pending unloading of goods.',
					)}
					loadingPlace={loadingPlace}
				/>
			</div>
		</div>
	);
};

export default NotificationDriverArrivedUnloading;
