import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from 'rc-table';
import dayjs from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import {
	SypacAvatar,
	SypacButton,
	SypacButtonIcon,
	SypacButtonOutside,
	SypacIcon,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';

import './payments_table.css';
import { OrderContext } from '../../context/OrderContext/order.context';
import { Search } from '../../assets/Search';
import { PaymentsTableInterface } from './interfaces/PaymentsTable.interface';
import { InvoiceInterface } from './interfaces/Payment.interface';
import { PaymentTypeAction } from './interfaces/PaymentStatus.interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { Expanded } from '../../assets/Expanded';
import { NotExpanded } from '../../assets/NotExpanded';
import { PaymentStatusEnum } from '../PaymentStatus/PaymentStatus.enum';
import PaymentStatusPayment from '../PaymentStatus/PaymentStatusPayment';
import { LineHorizontal } from '../../assets/LineHorizontal';
import PaymentStatus from '../PaymentStatus/PaymentStatus';
import { PaymentPeriod } from '../PyamentPeriod';
import { T, useTranslate } from '@tolgee/react';
import { Billing } from '../../assets/Billing';
import PaymentStatusIcon from '../PaymentStatus/PaymentStatusIcon';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import CompanyDetails from '../../containers/Admin/ComanyDetails';
import { AuthContext } from '../../context/context';
import { Tooltip } from 'react-tooltip';

export const PaymentsTable = ({
	rows,
	rowClick,
	caption,
	search,
	target = 'customer',
	aditionalClick,
	currentTab = 'all',
}: PaymentsTableInterface) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const { selectedOrderId } = useContext(OrderContext);
	const [keyRows, setRows] = useState<InvoiceInterface[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showDetails, setShowDetails] = useState<boolean>(false);

	useEffect(() => {
		const newRows = rows
			? rows.map((row: InvoiceInterface) => ({
					...row,
					key: row?.id,
			  }))
			: [];
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: InvoiceInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass =
			record.id === selectedOrderId
				? `payment-table-row-selected payment-table-row-${record.id}`
				: `payment-table-row-${record.id}`;
		const isOverdue =
			record.status === PaymentStatusEnum.PENDING &&
			dayjs(record.dueDate).toISOString() < dayjs().toISOString();
		const status = isOverdue ? PaymentStatusEnum.OVERDUE : record.status;
		const bgClass = ['customer'].includes(target)
			? ` payment-table-row-${status}`
			: '';

		return `${baseClass}${bgClass}`;
	};

	const companyClick = (id: number) => {
		return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.stopPropagation();
			e.preventDefault();
			setSelectedCompany(id);
			setShowDetails(true);
		};
	};
	const actionClick = (type: string, payment: InvoiceInterface | number) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment as InvoiceInterface);
			case PaymentTypeAction.view_invoice:
				return viewInvoice((payment as InvoiceInterface)?.invoiceUrl);
			case PaymentTypeAction.view_order:
				return (
					aditionalClick &&
					aditionalClick(payment, PaymentTypeAction.view_order)
				);
			case PaymentTypeAction.confirm_payment:
				return aditionalClick && aditionalClick(payment!);
			case PaymentTypeAction.extend_invoice:
				return onExpandForAdmin(payment as InvoiceInterface);
			case PaymentTypeAction.paid_payment:
				return aditionalClick && aditionalClick(payment!);
		}
	};

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.invoiceUrl) {
				return;
			}

			const companyName = user?.company.name;
			const invoiceSort =
				companyName
					?.split(' ')
					?.map((r) => r[0])
					?.join('')
					?.toLocaleUpperCase() || 'FV';
			const response = await fetch(invoice.invoiceUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			const fileName = invoice.invoiceNr
				? `${invoice.invoiceNr}.pdf`
				: `${invoiceSort}${dayjs(invoice.createdAt).format('DD-MM-YYYY')}${
						invoice.id
				  }.pdf`;
			link.setAttribute('download', fileName);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setUrl(url);
		setShowView(!!url);
	};

	const onExpandForAdmin = (record: InvoiceInterface) => {
		const exist = expandedRowKeys.some((r) => r === record.id);
		if (exist) {
			setExpandedRowKeys([]);
		} else {
			setExpandedRowKeys([record.id]);
		}
	};

	const onExpand = (expanded: boolean, record: InvoiceInterface) => {
		if (expanded) {
			setExpandedRowKeys([record.id]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	const expandedRowRender = (record: InvoiceInterface) => {
		return (
			<div className="table-payment w-full">
				<table>
					<tr>
						<td>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-center px-2">
									<T keyName="paymentsTable.createdDate">Сreated date</T>
								</p>
							</SypacText>
						</td>
						<td>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-center px-2">
									<T keyName="paymentsTable.amountPaid">Amount paid</T>
								</p>
							</SypacText>
						</td>
						<td>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 text-center px-2">
									<T keyName="paymentsTable.amountOpen">Amount open</T>
								</p>
							</SypacText>
						</td>
						<td>
							<div className="grid grid-cols-4">
								<div
									className={`flex flex-row justify-center w-36 ${
										['admin_customer', 'admin_producer'].includes(target)
											? 'col-span-3'
											: 'col-span-4'
									} `}
								>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 text-center px-2">
											<T keyName="paymentsTable.paymentStatus">
												Payment status
											</T>
										</p>
									</SypacText>
								</div>
								{['admin_customer'].includes(target) ? (
									<div className="flex flex-row justify-start">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80 text-left px-2">
												<T keyName="paymentsTable.approvedBy">Approved by</T>
											</p>
										</SypacText>
									</div>
								) : null}
								{['admin_producer'].includes(target) ? (
									<div className="flex flex-row justify-start">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80 text-left px-2">
												<T keyName="paymentsTable.solvedBy">Solved by</T>
											</p>
										</SypacText>
									</div>
								) : null}
							</div>
						</td>
					</tr>

					{record.payments.map((r, index) => {
						const isOverdue =
							dayjs(record.dueDate).toISOString() <
							dayjs(r.paymentDate).toISOString();

						const days = dayjs(record.dueDate).diff(r.paymentDate, 'day');
						const firstPartial =
							index === record?.payments?.length - 1 &&
							record?.payments?.length > 1;
						const secondPartial =
							record.status === PaymentStatusEnum.PENDING ||
							record?.payments?.length > 1;
						let secondStatus = PaymentStatusEnum.PENDING;
						if (index === 0 && record.status === PaymentStatusEnum.PAID) {
							secondStatus = PaymentStatusEnum.PAID;
						} else {
							secondStatus = isOverdue
								? PaymentStatusEnum.OVERDUE
								: PaymentStatusEnum.PENDING;
						}
						let firsStatus = isOverdue
							? PaymentStatusEnum.OVERDUE
							: PaymentStatusEnum.PENDING;
						const payDate = r?.paymentDate
							? dayjs(r?.createdAt).format('DD/MM/YYYY HH:mm:ss')
							: '';

						const paymentAmount =
							secondStatus === PaymentStatusEnum.PAID &&
							r.paymentAmount !== record.totalAmount
								? record.totalAmount
								: r.paymentAmount;

						const previousPayments = record.payments
							.slice(0, index)
							.reduce((acc, payment) => acc + payment.paymentAmount!, 0);
						const amountDue =
							record.totalAmount - previousPayments - r.paymentAmount!;

						return (
							<tr>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80 text-center">
											{dayjs(r.createdAt).format('DD MMM. YYYY')}
										</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80 text-center font-bold">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(paymentAmount?.toFixed(2) || '0')}
												suffix={' zł'}
											/>
										</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80 text-center font-bold">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(amountDue?.toFixed(2) || '0')}
												suffix={' zł'}
											/>
										</p>
									</SypacText>
								</td>
								<td>
									<div className="grid grid-cols-4 px-5 py-3">
										<div
											className={`flex flex-row justify-start ${
												['admin_customer', 'admin_producer'].includes(target)
													? 'col-span-3'
													: 'col-span-4'
											} `}
										>
											<PaymentStatusPayment
												days={days}
												state={firsStatus}
												isPartial={firstPartial}
											/>
											<div className="flex items-center justify-start mx-6">
												<LineHorizontal />
											</div>
											<PaymentStatusPayment
												days={days}
												state={secondStatus}
												isPartial={secondPartial}
											/>
										</div>
										{['admin_customer', 'admin_producer'].includes(target) ? (
											<div className="flex flex-row justify-start">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														{r?.confirmedUser?.name}
													</p>
												</SypacText>

												<SypacText
													variant="body-regular-medium"
													className="ml-3"
												>
													<p className="text-gray-80">{payDate}</p>
												</SypacText>
											</div>
										) : null}
									</div>
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		);
	};

	const expandedRowRenderHistory = (record: InvoiceInterface) => {
		return (
			<div className="table-payment w-full h-full">
				<table>
					<tr>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.paymentStatus">Payment status</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.date">Date</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.amount">Amount</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.assignee">Assignee</T>
								</p>
							</SypacText>
						</th>
						<th>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.user">User</T>
								</p>
							</SypacText>
						</th>
					</tr>
					{record.invoiceLines.map((r, index) => {
						const isOverdue =
							record.status === PaymentStatusEnum.PENDING &&
							dayjs(record.dueDate).toISOString() < dayjs().toISOString();
						const status = isOverdue
							? PaymentStatusEnum.OVERDUE
							: record.status;
						const user = r?.assigneeUser;
						const orderId = r?.orderId;

						return (
							<tr>
								<td>
									<div className="px-5">
										<PaymentStatus state={status} isPartial={false} />
									</div>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">
											{dayjs(r.createdAt).format('DD MMM. YYYY')}
										</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator="."
												decimalSeparator=","
												value={parseFloat(
													record.totalAmount?.toFixed(2) || '0',
												)}
												suffix={' zł'}
											/>
										</p>
									</SypacText>
								</td>
								<td>
									<SypacText
										variant="body-regular-medium"
										className="px-5 py-3"
									>
										<p className="text-gray-80">
											{record?.companyType === 'transporter'
												? 'Transport service'
												: record?.invoiceLines?.at(0)?.description}
										</p>
									</SypacText>
								</td>
								<td>
									<div className="flex justify-between items-center">
										{user && user.name ? (
											<div className="flex items-center justify-center h-full pl-6">
												<SypacAvatar
													size="sm"
													initials={user.email || user?.name}
												>
													{user?.avatarUrl ? (
														<img
															src={user?.avatarUrl}
															alt={user.email || user?.name}
														/>
													) : null}
												</SypacAvatar>
											</div>
										) : null}
										{orderId ? (
											<SypacButtonOutside className="px-6">
												<button
													onClick={() =>
														actionClick(PaymentTypeAction.view_order, orderId)
													}
												>
													<SypacButtonIcon>
														<SypacIcon size="md" iconName="Eye" />
													</SypacButtonIcon>
												</button>
											</SypacButtonOutside>
										) : null}
									</div>
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		);
	};

	const expandIcon = (props: RenderExpandIconProps<InvoiceInterface>) => {
		switch (target) {
			case 'withdraw_history':
				return props.record.invoiceLines?.length ? (
					props.expanded ? (
						<button
							onClick={() => onExpand(!props.expanded, props.record)}
							className="bg-transparent border-0 outline-0 cursor-pointer"
						>
							<Expanded />
						</button>
					) : (
						<button
							onClick={() => onExpand(!props.expanded, props.record)}
							className="bg-transparent border-0 outline-0 cursor-pointer"
						>
							<NotExpanded />
						</button>
					)
				) : null;
			default:
				return props.record.payments?.length ? (
					props.expanded ? (
						<button
							onClick={() => onExpand(!props.expanded, props.record)}
							className="bg-transparent border-0 outline-0 cursor-pointer"
						>
							<Expanded />
						</button>
					) : (
						<button
							onClick={() => onExpand(!props.expanded, props.record)}
							className="bg-transparent border-0 outline-0 cursor-pointer"
						>
							<NotExpanded />
						</button>
					)
				) : null;
		}
	};

	const getColumns = () => {
		switch (target) {
			case 'withdraw_history':
				return columnsWithdrawHistory(rowClick, actionClick, search);
			case 'admin_customer':
				return columnsClientAdmin(rowClick, actionClick, search);
			case 'admin_producer':
				return columnsProducerAdmin(rowClick, actionClick, search);
			default:
				return columns(rowClick, actionClick, search);
		}
	};

	const columnsWithdrawHistory = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsWithdrawHistory.paymentDate', 'Payment date'),
			dataIndex: '',
			className: 'text-left w-[180px]',
			key: 'paymentDate',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === PaymentStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const status = isOverdue ? PaymentStatusEnum.OVERDUE : value.status;

				return (
					<div
						className="flex items-center box-border h-full w-full px-6 py-5 gap-7"
						onClick={() => rowClick(value)}
					>
						<PaymentStatusIcon state={status} />
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{dayjs(value.invoiceDate).format('DD MMM. YYYY')}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsWithdrawHistory.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsWithdrawHistory.orders', 'Orders'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value?.invoiceLines?.length}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: ' ',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;
				return (
					<div className="flex flex-row justify-between items-center h-full px-6">
						<div className="flex flex-row justify-between items-center">
							<SypacButtonOutside>
								<button
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.downloadInvoice',
										'Download Invoice',
									)}
									onClick={() => actionClick(PaymentTypeAction.download, value)}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Download Minimalistic" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
							{orderId ? (
								<SypacButtonOutside>
									<button
										data-tooltip-id="invoice-table"
										data-tooltip-content={t(
											'paymentsWithdrawHistory.viewInvoice',
											'View Invoice',
										)}
										onClick={() =>
											actionClick(PaymentTypeAction.view_invoice, value)
										}
									>
										<SypacButtonIcon>
											<SypacIcon size="md" iconName="Eye" />
										</SypacButtonIcon>
									</button>
								</SypacButtonOutside>
							) : null}
						</div>
					</div>
				);
			},
		},
	];

	const columnsClientAdmin = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableClientAdmin.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === PaymentStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value.dueDate, 'day')
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? PaymentStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === PaymentStatusEnum.PENDING &&
					!!value.payments?.length;
				isPartial =
					value.status === PaymentStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start px-6 py-5 w-fit"
						onClick={() => rowClick(value)}
					>
						<PaymentStatus days={days} state={status} isPartial={isPartial} />
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.created', 'Сreated'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.dueDate', 'Due date'),
			dataIndex: '',
			className: 'text-left',
			key: 'dueDate',
			render(value: InvoiceInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.user', 'User'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const user = value?.invoiceLines?.at(0)?.assigneeUser;
				const company = value?.company;

				return (
					<div
						className="flex items-left justify-between px-6 py-3"
						onClick={() => rowClick(value)}
					>
						{company && company?.name ? (
							<SypacLink>
								<a href="/" onClick={companyClick(company.id)}>
									{company?.name}
								</a>
							</SypacLink>
						) : null}
						{user && user.name ? (
							<div>
								<SypacAvatar size="sm" initials={user.email || user?.name}>
									{user?.avatarUrl ? (
										<img src={user?.avatarUrl} alt={user.email || user?.name} />
									) : null}
								</SypacAvatar>
							</div>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.invoiceAmount', 'Invoice amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6 bg-texas-rose-10"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.balanceDue?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.amountPaid', 'Amount paid'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountPaid',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6 bg-mountain-meadow-10"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(
										(value.totalAmount - value.balanceDue).toFixed(2),
									)}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.type', 'Type'),
			dataIndex: '',
			className: 'text-left',
			key: 'transaction',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.invoiceType}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('', 'Order ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'order',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;

				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{orderId}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.approvedBy', 'Approved by'),
			dataIndex: '',
			className: 'text-left',
			key: 'approvedBy',
			render(value: InvoiceInterface) {
				const payment = value?.payments?.length
					? value?.payments?.at(value?.payments?.length - 1)
					: null;
				const payDate = payment?.createdAt
					? dayjs(payment?.createdAt).format('DD/MM/YYYY HH:mm:ss')
					: '';

				return (
					<div
						className="flex items-center h-full px-6 py-3 flex-row"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{payment?.confirmedUser?.name}</p>
						</SypacText>

						<SypacText variant="body-regular-medium" className="ml-3">
							<p className="text-gray-80">{payDate}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const isConfirmed =
					value?.payments?.length > 0 &&
					value.status === PaymentStatusEnum.PAID;
				return (
					<div className="flex items-center justify-center h-full gap-3 px-6 py-3 flex-row">
						{isConfirmed ? (
							<div className="bg-gray-1 rounded-[46px]">
								<SypacText
									variant="overline-regular-large"
									className="px-8 py-1"
								>
									<p className="text-gray-80">Confirmed</p>
								</SypacText>
							</div>
						) : (
							<SypacButton
								variant="gradient"
								size="small"
								onClick={() =>
									actionClick(PaymentTypeAction.confirm_payment, value)
								}
							>
								<button>Confirm</button>
							</SypacButton>
						)}
						{value?.invoiceUrl ? (
							<SypacButtonOutside>
								<button
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.downloadInvoice',
										'Download Invoice',
									)}
									className="p-0"
									onClick={() => actionClick(PaymentTypeAction.download, value)}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Download Minimalistic" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
						) : null}
						{value?.invoiceUrl ? (
							<SypacButtonOutside>
								<button
									className="p-0"
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.viewInvoice',
										'View Invoice',
									)}
									onClick={() =>
										actionClick(PaymentTypeAction.view_invoice, value)
									}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Eye" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
						) : null}
					</div>
				);
			},
		},
	];

	const columnsProducerAdmin = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableAdminProducer.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === PaymentStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value.dueDate, 'day')
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? PaymentStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === PaymentStatusEnum.PENDING &&
					!!value.payments?.length;
				isPartial =
					value.status === PaymentStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start px-6 py-5 w-fit"
						onClick={() => rowClick(value)}
					>
						<PaymentStatus days={days} state={status} isPartial={isPartial} />
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.created', 'Сreated'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.dueDate', 'Due date'),
			dataIndex: '',
			className: 'text-left',
			key: 'dueDate',
			render(value: InvoiceInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.company', 'Company'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const user = value?.invoiceLines?.at(0)?.assigneeUser;
				const company = value?.company;

				return (
					<div
						className="flex items-left justify-between px-6 py-3"
						onClick={() => rowClick(value)}
					>
						{company && company?.name ? (
							<SypacLink>
								<a href="/" onClick={companyClick(company.id)}>
									{company?.name}
								</a>
							</SypacLink>
						) : null}
						{user && user.name ? (
							<div>
								<SypacAvatar size="sm" initials={user.email || user?.name}>
									{user?.avatarUrl ? (
										<img src={user?.avatarUrl} alt={user.email || user?.name} />
									) : null}
								</SypacAvatar>
							</div>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center px-6 h-full bg-mountain-meadow-10"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.order', 'Order'),
			dataIndex: '',
			className: 'text-left',
			key: 'order',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;

				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{orderId}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.paymentMethod', 'Payment method'),
			dataIndex: '',
			className: 'text-left',
			key: 'order',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center px-6 py-3"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.paymentMethod}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.solvedBy', 'Solved by'),
			dataIndex: '',
			className: 'text-left',
			key: 'solvedBy',
			render(value: InvoiceInterface) {
				const payment = value?.payments?.length
					? value?.payments?.at(value?.payments?.length - 1)
					: null;
				const payDate = payment?.createdAt
					? dayjs(payment?.createdAt).format('DD/MM/YYYY HH:mm:ss')
					: '';

				return (
					<div
						className="flex items-center px-6 py-3 flex-row"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{payment?.confirmedUser?.name}</p>
						</SypacText>

						<SypacText variant="body-regular-medium" className="ml-3">
							<p className="text-gray-80">{payDate}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const isConfirmed =
					value?.payments?.length > 0 &&
					value.status === PaymentStatusEnum.PAID;
				return (
					<div className="flex items-center justify-center gap-3 px-6 py-3 flex-row">
						{isConfirmed ? (
							<div className="bg-mountain-meadow-10 rounded-[46px] flex flex-row px-3 py-1 justify-center items-center">
								<Billing />
								<SypacText variant="overline-regular-large" className="ml-3">
									<p className="text-gray-80">Paid</p>
								</SypacText>
							</div>
						) : (
							<SypacButton
								variant="secondary"
								size="small"
								onClick={() =>
									actionClick(PaymentTypeAction.paid_payment, value)
								}
							>
								<button>Pay</button>
							</SypacButton>
						)}
						{value?.invoiceUrl ? (
							<SypacButtonOutside>
								<button
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.downloadInvoice',
										'Download Invoice',
									)}
									className="p-0"
									onClick={() => actionClick(PaymentTypeAction.download, value)}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Download Minimalistic" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
						) : null}
						{value?.invoiceUrl ? (
							<SypacButtonOutside>
								<button
									className="p-0"
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.viewInvoice',
										'View Invoice',
									)}
									onClick={() =>
										actionClick(PaymentTypeAction.view_invoice, value)
									}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Eye" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
						) : null}
					</div>
				);
			},
		},
	];

	const columns = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface | number) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableClient.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === PaymentStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value.dueDate, 'day')
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? PaymentStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === PaymentStatusEnum.PENDING &&
					!!value.payments?.length;
				isPartial =
					value.status === PaymentStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit px-4 py-5"
						onClick={() => rowClick(value)}
					>
						<PaymentStatus days={days} state={status} isPartial={isPartial} />
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.transaction', 'Transaction'),
			dataIndex: '',
			className: 'text-left',
			key: 'transaction',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.invoiceType}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountOpen', 'Amount Open'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.balanceDue?.toFixed(2) || '0')}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountPaid', 'Amount Paid'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountPaid',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(
										(value.totalAmount - value.balanceDue).toFixed(2),
									)}
									suffix={' zł'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.createdDate', 'Created date'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = dayjs(value.createdAt).format('DD MMM. YYYY');
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.user', 'User'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const user = value?.invoiceLines?.at(0)?.assigneeUser;

				return user && user.name ? (
					<div
						className="flex justify-center items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
					</div>
				) : null;
			},
		},
		{
			title: t('paymentsTableClient.paymentMethod', 'Payment method'),
			dataIndex: '',
			className: 'text-left',
			key: 'paymentMethod',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex flex-row justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.paymentMethod}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: ' ',
			dataIndex: '',
			className: 'text-left',
			key: 'actions',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;
				return (
					<div className="flex flex-row justify-start items-center h-full px-4">
						<div className="flex flex-row justify-start md:gap-2 2xl:gap-5">
							<SypacButtonOutside>
								<button
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.downloadInvoice',
										'Download Invoice',
									)}
									className="p-0"
									onClick={() => actionClick(PaymentTypeAction.download, value)}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Download Minimalistic" />
									</SypacButtonIcon>
								</button>
							</SypacButtonOutside>
							<SypacButtonOutside>
								<button
									className="p-0"
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.viewInvoice',
										'View Invoice',
									)}
									onClick={() =>
										actionClick(PaymentTypeAction.view_invoice, value)
									}
								>
									<SypacButtonIcon>
										<SypacIcon size="md" iconName="Eye" />
									</SypacButtonIcon>
									<SypacText
										className="md:hidden 2xl:flex"
										variant="body-regular-medium"
									>
										<p className="text-gray-80">
											<T keyName="paymentsTable.viewInvoice">View invoice</T>
										</p>
									</SypacText>
								</button>
							</SypacButtonOutside>
							{orderId ? (
								<SypacButtonOutside>
									<button
										className="p-0"
										onClick={() =>
											actionClick(PaymentTypeAction.view_order, orderId)
										}
									>
										<SypacButtonIcon>
											<SypacIcon size="md" iconName="Document Add" />
										</SypacButtonIcon>
										<SypacText
											className="md:hidden 2xl:flex"
											variant="body-regular-medium"
										>
											<p className="text-gray-80">
												<T keyName="paymentsTable.viewOrderDetails">
													View order details
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButtonOutside>
							) : null}
						</div>
					</div>
				);
			},
		},
	];

	const emptyText = useMemo(() => {
		const tabTextMap = {
			all: 'noWithdrawalsMadeYet',
			pending: 'noPendingPaymentsAtTheMoment',
			overdue: 'noPaymentsAreCurrentlyOverdue',
			paid: 'yourAccountHasNoPaidOrdersCurrently',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	const emptySubText = useMemo(() => {
		const tabTextMap = {
			all: 'noWithdrawalsDescription',
			pending: 'noPendingPaymentsDescription',
		} as const;

		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	return (
		<>
			<Table
				className={`w-full payment-table pb-14 ${
					rows.length === 0 ? 'h-full' : ''
				}`}
				expandable={
					// eslint-disable-next-line eqeqeq
					target != 'withdraw_history'
						? {
								expandedRowKeys,
								expandIcon,
								expandedRowRender,
						  }
						: {
								expandedRowKeys,
								expandIcon,
								expandedRowRender: expandedRowRenderHistory,
						  }
				}
				caption={
					caption ? (
						<div className="flex justify-between p-4">
							<div>
								<T keyName="paymentsTable.withdrawHistory">Withdraw history</T>
							</div>
							<div>
								<T keyName="paymentsTable.withdrawInterval">
									Withdraw interval:
								</T>{' '}
								<PaymentPeriod value={caption.paymentPeriod} />
							</div>
						</div>
					) : null
				}
				columns={getColumns()}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={() => {
					return (
						<div className="flex flex-col justify-center items-center h-full gap-8">
							<Search />
							<SypacText variant="heading-3">
								<p className="text-gray-80">
									<T keyName={`paymentsTable.${emptyText}`}>{emptyText}</T>
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									{emptySubText ? (
										<T keyName={`paymentsTable.${emptySubText}`}>
											{emptySubText}
										</T>
									) : null}
								</p>
							</SypacText>
						</div>
					);
				}}
			/>
			{openView ? (
				<DocumentsViewer
					url={url!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
			{selectedCompany !== undefined && showDetails ? (
				<CompanyDetails
					onClose={() => setShowDetails(false)}
					companyId={selectedCompany}
					onVerification={() => {
						setShowDetails(false);
					}}
				/>
			) : null}
			<Tooltip
				place="top"
				id="invoice-table"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
			/>
		</>
	);
};
