import { useEffect, useState } from 'react';

import { TruckResponse, TruckService } from '../services/truck.services';

type UseGetFleet = [TruckResponse | undefined, Error | undefined, boolean];

export interface UseGetFleetQuery {
	limit?: number;
	offset?: number;
	status?: string;
	companyId?: number;
	target?: string;
}

export const useGetFeet = (params: UseGetFleetQuery): UseGetFleet => {
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);
	const {
		limit = 10,
		offset = 0,
		status = 'all',
		target = 'transporter',
		companyId,
	} = params;

	useEffect(() => {
		if (status !== 'all') {
			setData({
				items: [],
				count: 0,
			});
			return;
		}
		setIsLoading(true);
		TruckService.getTrucks({ limit, offset, companyId }, target)
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [limit, offset, status, target, companyId]);

	return [data, error, isLoading];
};
