export const TruckApprove = () => {
	return (
		<svg
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15.1996" cy="11.7992" r="1.6" stroke="#A2A2A2" />
			<circle cx="7.19961" cy="11.7992" r="1.6" stroke="#A2A2A2" />
			<path
				d="M5.59922 11.7776C4.72184 11.7338 4.1745 11.6033 3.78501 11.2138C3.39551 10.8243 3.22126 10 3.22126 10M8.79922 11.7996H13.5992M16.7992 11.7776C17.6766 11.7338 18.2239 11.6033 18.6134 11.2138C19.1992 10.628 19.1992 9.68523 19.1992 7.79961V6.19961H15.4392C14.8436 6.19961 14.5458 6.19961 14.3048 6.1213C13.8177 5.96303 13.4358 5.58114 13.2775 5.09404C13.1992 4.85302 13.1992 4.55522 13.1992 3.95961C13.1992 3.06619 13.1992 2.61949 13.0818 2.25797C12.8444 1.52732 12.2715 0.954477 11.5409 0.717074C11.1793 0.599609 10.7326 0.599609 9.83922 0.599609H3.19922"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.1992 2.19922H14.6562C15.8205 2.19922 16.4026 2.19922 16.8764 2.48219C17.3501 2.76515 17.6261 3.27771 18.1781 4.30282L19.1992 6.19922"
				stroke="#A2A2A2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.19922 5.4L2.79922 7L6.79922 3"
				stroke="#20AC93"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
